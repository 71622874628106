/*!

=========================================================
* Paper Dashboard React - v1.3.1
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

* Licensed under MIT (https://github.com/creativetimofficial/paper-dashboard-react/blob/main/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useState, useEffect, useRef } from "react";
import { auth, db } from "/Users/michaeltucker/Documents/coding-projects/client/src/firebase.js";
import { setDoc, doc, deleteField, getDoc } from "firebase/firestore";
import NotificationAlert from "react-notification-alert";
import backgroundOptionsData from "/Users/michaeltucker/Documents/coding-projects/client/src/BackgroundOptionsData.js";
import ReactBSAlert from "react-bootstrap-sweetalert";
import { saveContentBoxTemplate } from "saveTemplate";
import { useLocation, useHistory } from "react-router-dom";
import ReactHtmlParser from 'react-html-parser';
import { Card, CardBody, Col, CardFooter, Button, Modal, ModalBody, ModalHeader, ModalFooter, Container, Row, FormGroup, Label, Input, UncontrolledTooltip} from "reactstrap";
import TipTapEditor from "../TipTapEditor";
import stampIcon from "/Users/michaeltucker/Documents/coding-projects/client/src/assets/img/stamp-solid.svg";
import { createRandomId } from "RandomId";
import FullscreenModal from "../FullscreenModal";
import ActiveDatesModal from "../ActiveDatesModal";


function ContentBox({content, setHeading, updateContentBox, classId, user, navbarMD, view, multiBox, updateContentBoxWithinMultiBox, printActive, readOnly, singleLineFormattingToolbar, navigationVisible, inFullscreenModal}) {
  const [editedContent, setEditedContent] = useState();
  const [cardHeight, setCardHeight] = useState({card: "auto", editor: "auto"}); // Initially set the height to 'auto'
  const [headingInsideContentBox, setHeadingInsideContentBox] = useState();
  const [contentPreview, setContentPreview] = useState();
  const [showMainEditButton, setShowMainEditButton] = useState(false);
  const [showButtons, setShowButtons] = useState(false);
  const [alert, setAlert] = useState(null);
  const location = useLocation();
  const [editable, setEditable] = useState(false);
  const [boxToCopyId, setBoxToCopyId] = useState();
  const [copyBoxModal, setCopyBoxModal] = useState(false);
  const [classesSelectedForContentBoxCopy, setClassesSelectedForContentBoxCopy] = useState([]);
  const [fullscreenModal, setFullscreenModal] = useState(false);
  const history = useHistory();

  const toggleFullscreenModal = () => {
    setFullscreenModal(!fullscreenModal)
  }

  useEffect(() => {
    // Function to update the card height based on window height
    const updateCardHeight = () => {
      if (multiBox) {
        const card = document.getElementById(`editor-box-card-${content.contentBoxId}`);
        const cardHeight = card.getBoundingClientRect().height;
        // setCardHeight({card: `${cardHeight}px`, editor: `${cardHeight-27}px`});
        setCardHeight({card: `${navigationVisible ? cardHeight : cardHeight+50}px`, editor: `${cardHeight-27}px`});
      } else {
        const windowHeight = window.innerHeight;
        const desiredDistance = navbarMD?.clock === '0' && navbarMD?.marquee === '0' ? 93 : 188; // Desired distance from the bottom
        const cardHeight = windowHeight - desiredDistance;
        // setCardHeight({card: `${cardHeight}px`, editor: `${cardHeight-27}px`});
        setCardHeight({card: `${navigationVisible ? cardHeight : cardHeight+50}px`, editor: `${cardHeight-27}px`});
      }
    };

    // Call the updateCardHeight function when the window is resized
    window.addEventListener("resize", updateCardHeight);

    // Initial call to set the card height
    updateCardHeight();

    // Remove the event listener when the component is unmounted
    return () => {
      window.removeEventListener("resize", updateCardHeight);
    };
  }, [navbarMD, navigationVisible]);

  // to stop the warning of calling setState of unmounted component
  React.useEffect(() => {
    return function cleanup() {
      var id = window.setTimeout(null, 0);
      while (id--) {
        window.clearTimeout(id);
      }
    };
  }, []);

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      handleOnBlur();
      // Optional: Display a confirmation dialog in some browsers
      event.preventDefault();
      event.returnValue = ""; // This is required for Chrome
    };
  
    const unlisten = history.listen((location, action) => {
      if (action === "PUSH" || action === "POP" || action === "REPLACE") {
        handleOnBlur(); // Call your save logic when navigation happens
      }
    });
  
    // Attach the event listener for browser tab close or reload
    window.addEventListener("beforeunload", handleBeforeUnload);
  
    return () => {
      // Cleanup
      window.removeEventListener("beforeunload", handleBeforeUnload);
      unlisten(); // Stop listening to history changes
    };
  }, [history]);

  const handleSave = () => {
    updateContentBox(content.contentBoxId, {...editedContent})
    setEditedContent()
    setShowSave(false)
  }

// let debounceTimer;
// const autoSaveContentBox = async (contentBoxId, updatedContent) => {
//   if (debounceTimer) clearTimeout(debounceTimer);

//   debounceTimer = setTimeout(async () => {
//     try {
//       const docRef = doc(db, "users", user.uid);
//       const userCopy = JSON.parse(JSON.stringify(user));

//       // Find the class index based on classId
//       const classIndex = userCopy.classes.findIndex(
//         (c) => c.classId === classId
//       );

//       if (classIndex !== -1) {
//         // Find the content box index based on contentBoxId
//         const contentBoxIndex = userCopy.classes[classIndex].contentBoxes.findIndex(
//           (box) => box.contentBoxId === contentBoxId
//         );

//         if (contentBoxIndex !== -1) {
//           const updatedContentBox = {
//             ...userCopy.classes[classIndex].contentBoxes[contentBoxIndex],
//           };

//           // Update content
//           updatedContentBox.content = updatedContent.content;

//           // Safely handle heading and content preview
//           const safeHeading = headingInsideContentBox || "";
//           updatedContentBox.heading =
//             safeHeading.length > 9
//               ? safeHeading.substring(0, 9) + "..."
//               : safeHeading || "Type here...";

//           const safeContentPreview = contentPreview || "";
//           updatedContentBox.contentPreview =
//             safeContentPreview.substring(0, 30) + "...";

//           // Date formatting
//           const currentDate = new Date();
//           const formattedDate = `${(currentDate.getMonth() + 1)
//             .toString()
//             .padStart(2, "0")}/${currentDate
//             .getDate()
//             .toString()
//             .padStart(2, "0")}/${currentDate.getFullYear()}`;
//           updatedContentBox.lastEdited = formattedDate;

//           // Update user copy and Firestore
//           userCopy.classes[classIndex].contentBoxes[contentBoxIndex] = updatedContentBox;
//           await setDoc(docRef, userCopy);

//           showAlertForContentBoxSave();
//         }
//       }
//     } catch (error) {
//       console.error("Error updating document:", error);
//       window.alert(JSON.stringify(updatedContent));

//       if (!error.message.includes("Cannot read properties of undefined (reading 'content')")) {
//         showAlertForContentBoxSaveFailure();
//       }
//     }
//   }, 300); // Adjust debounce delay as needed (300ms in this example)
// };

let debounceTimer;
const autoSaveContentBox = async (contentBoxId, updatedContent) => {
  if (debounceTimer) clearTimeout(debounceTimer);

  debounceTimer = setTimeout(async () => {
    try {
      const docRef = doc(db, "users", user.uid);
      const userCopy = JSON.parse(JSON.stringify(user));

      // Find the class index based on classId
      const classIndex = userCopy.classes.findIndex(
        (c) => c.classId === classId
      );

      if (classIndex !== -1) {
        // Find the content box index based on contentBoxId
        const contentBoxIndex = userCopy.classes[classIndex].contentBoxes.findIndex(
          (box) => box.contentBoxId === contentBoxId
        );

        if (contentBoxIndex !== -1) {
          const updatedContentBox = {
            ...userCopy.classes[classIndex].contentBoxes[contentBoxIndex],
          };

          // Update content
          updatedContentBox.content = updatedContent.content;

          // Safely handle heading and content preview
          const safeHeading = headingInsideContentBox || "";
          if (!updatedContentBox.userHeading) {
            updatedContentBox.heading =
            safeHeading.length > 9
              ? safeHeading.substring(0, 9) + "..."
              : safeHeading || "Type here...";
          }

          const safeContentPreview = contentPreview || "";
          updatedContentBox.contentPreview =
            safeContentPreview.substring(0, 30) + "...";

          // Date formatting
          const currentDate = new Date();
          const formattedDate = `${(currentDate.getMonth() + 1)
            .toString()
            .padStart(2, "0")}/${currentDate
            .getDate()
            .toString()
            .padStart(2, "0")}/${currentDate.getFullYear()}`;
          updatedContentBox.lastEdited = formattedDate;

          // Update user copy and Firestore
          userCopy.classes[classIndex].contentBoxes[contentBoxIndex] = updatedContentBox;

          // Check if the box is a linkedBox and update linked boxes in other classes
          if (updatedContentBox.linkedBox) {
            // Loop through all classes and find boxes with the same contentBoxId and linkedBox: true
            userCopy.classes.forEach((classItem) => {
              classItem.contentBoxes.forEach((box, index) => {
                if (box.contentBoxId === updatedContentBox.contentBoxId && box.linkedBox) {
                  // Update the linked box in other classes
                  classItem.contentBoxes[index] = updatedContentBox;
                }
              });
            });
          }

          // Update the document in Firestore
          await setDoc(docRef, userCopy);

          showAlertForContentBoxSave();
        }
        // setBoxData(localAgendaBoxData);
      }
    } catch (error) {
      console.error('Error updating document:', error);
    }
  }, 0); // Adjust debounce delay as needed (300ms in this example)
};



const autoSaveContentBoxWithinMultiBox = async (contentBoxId, updatedContent) => {
  try {
    const docRef = doc(db, "users", user.uid);

    const userCopy = JSON.parse(JSON.stringify(user));

    // Find the class index based on classId
    const classIndex = userCopy.classes.findIndex(
      (c) => c.classId === classId
    );

    if (classIndex !== -1) {
      // Find the content box index based on contentBoxId
      const contentBoxIndex = userCopy.classes[classIndex].contentBoxes.findIndex(
        (box) => box.contentBoxId === contentBoxId
      );

      if (contentBoxIndex !== -1) {
        // Make a copy of the existing content box using the spread operator
        const updatedContentBox = { ...userCopy.classes[classIndex].contentBoxes[contentBoxIndex] };
        // console.log(updatedContentBox)

        // Update the content box with the new data
        updatedContentBox.content = updatedContent.content;
        if (headingInsideContentBox) {
          if (headingInsideContentBox.length > 9) {
            updatedContentBox.heading = headingInsideContentBox.substring(0, 9) + "..."
          } else if (headingInsideContentBox.length === 0) {
            updatedContentBox.heading = "Type here..."
          } else {
            updatedContentBox.heading = headingInsideContentBox
          }
        }

        updatedContentBox.contentPreview = contentPreview?.substring(0, 30) + "..."

        // Create a new Date object
        var currentDate = new Date();

        // Get individual components of the date
        var year = currentDate.getFullYear();
        var month = currentDate.getMonth() + 1; // Months are zero-based, so add 1
        var day = currentDate.getDate();

        // Create a formatted string
        var formattedDate = (month < 10 ? '0' + month : month) + '/' + (day < 10 ? '0' + day : day) + '/' + year;

        updatedContentBox.lastEdited = formattedDate;

        // Update the content box in the document data
        userCopy.classes[classIndex].contentBoxes[contentBoxIndex] = updatedContentBox;

        // Update the document in Firestore
        await setDoc(docRef, userCopy);
        showAlertForContentBoxSave();
      }
    }
  } catch (error) {
    console.error('Error updating document:', error);
    // Check if the error message does not include the specific error you want to exclude
    if (!error.message.includes("Cannot read properties of undefined (reading 'content')")) {
      showAlertForContentBoxSaveFailure();
    }
  }
};

const [activeDatesModal, setActiveDatesModal] = useState(false)

  const toggleSetActiveDatesModal = () => {
    setActiveDatesModal(!activeDatesModal)
  }

  const [activeDates, setActiveDates] = useState({
    startDate: content.startDate === undefined ? "" : content.startDate,
    endDate: content.endDate === undefined ? "" : content.endDate
  });

  const saveContentBoxActiveDates = async (datesFromSetActiveDatesModal) => {
    try {
      const docRef = doc(db, "users", user.uid);
  
      const userCopy = JSON.parse(JSON.stringify(user));
  
      // Find the class index based on classId
      const classIndex = userCopy.classes.findIndex(
        (c) => c.classId === classId
      );
  
      if (classIndex !== -1) {
        // Find the content box index based on contentBoxId
        const contentBoxIndex = userCopy.classes[classIndex].contentBoxes.findIndex(
          (box) => box.contentBoxId === content.contentBoxId
        );
  
        if (contentBoxIndex !== -1) {
          // Make a copy of the existing content box using the spread operator
          const updatedContentBox = { ...userCopy.classes[classIndex].contentBoxes[contentBoxIndex]};
  
          // Update the content box with the new data
          updatedContentBox.startDate = datesFromSetActiveDatesModal.startDate;
          updatedContentBox.endDate = datesFromSetActiveDatesModal.endDate;
  
          // Update the content box in the document data
          userCopy.classes[classIndex].contentBoxes[contentBoxIndex] = updatedContentBox;
  
          // Update the document in Firestore
          await setDoc(docRef, userCopy);
  
        }
        toggleSetActiveDatesModal();
      }
    } catch (error) {
      console.error('Error updating document:', error);
    }
};

const [backgroundContentBoxModal, setBackgroundContentBoxModal] = useState(false);
const [backgroundContentBoxIdToChange, setBackgroundContentBoxIdToChange] = useState('');

const toggleBackgroundContentBoxModal = (contentBoxId) => {
  setBackgroundContentBoxModal(!backgroundContentBoxModal);
  setBackgroundContentBoxIdToChange(contentBoxId);
};

const saveBackgroundContentBox = async (imageFileName) => {
  const thing = `url(${imageFileName})`
  try {
    const docRef = doc(db, "users", user.uid);

    const userCopy = JSON.parse(JSON.stringify(user));

    const classIndex = userCopy.classes.findIndex(
      (c) => c.classId === classId
    );

    if (multiBox) {
      updateContentBoxWithinMultiBox({...content, background: thing }, true)
    } else {
      const contentBoxIndex = userCopy.classes[classIndex].contentBoxes.findIndex(
        (box) => box.contentBoxId === content.contentBoxId
      );
    
      userCopy.classes[classIndex].contentBoxes[contentBoxIndex].background = thing;
    
      // Update the document in Firestore
      await setDoc(docRef, userCopy);
    }

  } catch (error) {
    console.error('Error updating document:', error);
  }
}

const sendBoxToStorage = async () => {
  try {
    const docRef = doc(db, "users", user.uid);

    const userCopy = JSON.parse(JSON.stringify(user));

    const classIndex = userCopy.classes.findIndex(
      (c) => c.classId === classId
    );

    const contentBoxIndex = userCopy.classes[classIndex].contentBoxes.findIndex(
      (box) => box.contentBoxId === content.contentBoxId
    );

    userCopy.classes[classIndex].contentBoxes[contentBoxIndex].active = false;

    // Update the document in Firestore
    await setDoc(docRef, userCopy);

  } catch (error) {
    console.error('Error updating document:', error);
  }
}

const notificationAlert = useRef();

function showAlertForContentBoxSave() {
  const options = {
    place: "tc",
    message: (
      <div>
        <div>
          <b>Content Box Saved!</b>
        </div>
      </div>
    ),
    type: "info",
    icon: "now-ui-icons ui-1_bell-53",
    autoDismiss: 3,
    closeButton: false,
  };

  if (notificationAlert.current) {
    notificationAlert.current.notificationAlert(options);
  } else {
    console.warn("Notification alert reference is unavailable.");
  }
}

function showAlertForContentBoxSaveFailure() {
  var options = {};
  options = {
    place: 'tc',
    message: (
      <div>
        <div>
          <b>Content Box NOT Saved!</b>
        </div>
      </div>
    ),
    type: 'danger',
    icon: "now-ui-icons ui-1_bell-53",
    autoDismiss: 3,
    closeButton: false,
  };
  if (notificationAlert.current) {
    notificationAlert.current.notificationAlert(options);
  }
}

const editBoxNameAlert = (contentBoxId) => {
  setAlert(
    <ReactBSAlert
      input
      showCancel
      style={{ display: "block", marginTop: "-100px" }}
      title="What would you like to name this box?"
      onConfirm={(e) => updateContentBoxHeading(contentBoxId, e)}
      onCancel={() => hideAlert()}
      confirmBtnBsStyle="info"
      cancelBtnBsStyle="danger"
      btnSize=""
      defaultValue={content.heading}
    />
  );
};

const updateContentBoxHeading = async (contentBoxId, updatedHeading) => {
  hideAlert()
  try {
    const docRef = doc(db, "users", user.uid);

    const userCopy = JSON.parse(JSON.stringify(user));

    // Find the class index based on classId
    const classIndex = userCopy.classes.findIndex(
      (c) => c.classId === classId
    );

    if (classIndex !== -1) {
      // Find the content box index based on contentBoxId
      const contentBoxIndex = userCopy.classes[classIndex].contentBoxes.findIndex(
        (box) => box.contentBoxId === contentBoxId
      );

      if (contentBoxIndex !== -1) {
        // Make a copy of the existing content box using the spread operator
        const updatedContentBox = { ...userCopy.classes[classIndex].contentBoxes[contentBoxIndex], userHeading: true };

        // Update the content box with the new data
        if (updatedHeading.length > 9) {
          updatedContentBox.heading = updatedHeading.substring(0, 9) + "..."
        } else if (updatedHeading.length === 0) {
          updatedContentBox.heading = "Type here..."
        } else {
          updatedContentBox.heading = updatedHeading
        }

        // Update the content box in the document data
        userCopy.classes[classIndex].contentBoxes[contentBoxIndex] = updatedContentBox;

        // Update the document in Firestore
        await setDoc(docRef, userCopy); // Replace with the correct path to your document

        // Update the local state with the updated document data
        // setBoxes(userCopy.classes[classIndex].contentBoxes);

        successAlert()
      }
    }
  } catch (error) {
    console.error('Error updating document:', error);
  }
};

const warningWithConfirmAndCancelMessage = (id) => {
  setAlert(
    <ReactBSAlert
      warning
      style={{ display: "block", marginTop: "-100px" }}
      title="Are you sure?"
      onConfirm={() => deleteContentBox(id)}
      onCancel={() => cancelDelete()}
      confirmBtnBsStyle="info"
      confirmBtnStyle={{backgroundColor: '#dc3545'}}
      cancelBtnBsStyle="danger"
      confirmBtnText="Yes, delete it!"
      cancelBtnText="Cancel"
      showCancel
      btnSize=""
    >
      You will not be able to recover this content box!
    </ReactBSAlert>
  );
};

const successDelete = () => {
  setAlert(
    <ReactBSAlert
      success
      style={{ display: "block", marginTop: "-100px" }}
      title="Deleted!"
      onConfirm={() => hideAlert()}
      onCancel={() => hideAlert()}
      confirmBtnBsStyle="info"
      btnSize=""
    >
      Your content box has been deleted.
    </ReactBSAlert>
  );
};

const cancelDelete = () => {
  setAlert(
    <ReactBSAlert
      danger
      style={{ display: "block", marginTop: "-100px" }}
      title="Cancelled"
      onConfirm={() => hideAlert()}
      onCancel={() => hideAlert()}
      confirmBtnBsStyle="info"
      btnSize=""
    >
      Your content box is safe :)
    </ReactBSAlert>
  );
};

const deleteContentBox = async (contentBoxId) => {
  try {
    const docRef = doc(db, "users", user.uid);

    const userCopy = JSON.parse(JSON.stringify(user));

    const classIndex = userCopy.classes.findIndex(
      (c) => c.classId === classId
    );

    const contentBoxIndex = userCopy.classes[classIndex].contentBoxes.findIndex(
      (box) => box.contentBoxId === contentBoxId
    );

    // Remove the content box at the specified index
    userCopy.classes[classIndex].contentBoxes.splice(contentBoxIndex, 1);

    // Update the document in Firestore
    await setDoc(docRef, userCopy);

    // // Update the local state with the updated document data
    // setBoxes(userCopy.classes[classIndex].contentBoxes);

    successDelete()

    console.log('Content box deleted successfully!');
  } catch (error) {
    console.error('Error deleting content box:', error);
  }
}


const addTemplateAlert = (contentBox) => {
  setAlert(
    <ReactBSAlert
      input
      showCancel
      style={{ display: "block", marginTop: "-100px" }}
      title="What would you like to call this template?"
      onConfirm={(e) => addTemplateConfirmAlert(e, contentBox)}
      onCancel={() => hideAlert()}
      confirmBtnBsStyle="info"
      cancelBtnBsStyle="danger"
      btnSize=""
      defaultValue={contentBox.heading}
    />
  );
};

const addTemplateConfirmAlert = (e, contentBox) => {
  saveContentBoxTemplate(user, e, contentBox);
  setAlert(
    <ReactBSAlert
      style={{ display: "block", marginTop: "-100px" }}
      onConfirm={() => hideAlert()}
      onCancel={() => hideAlert()}
      confirmBtnBsStyle="info"
      btnSize=""
      title={
        <p>
          You entered: <b>{e}</b>
        </p>
      }
    />
  );
};
  
const successAlert = () => {
  setAlert(
    <ReactBSAlert
      success
      style={{ display: "block", marginTop: "-100px" }}
      title="Saved!"
      onConfirm={() => hideAlert()}
      onCancel={() => hideAlert()}
      confirmBtnBsStyle="info"
      btnSize=""
    >
    </ReactBSAlert>
  );
};

const hideAlert = () => {
  setAlert(null);
};

const isMobile = false // /Mobi/.test(navigator.userAgent);

const today = new Date().toISOString().slice(0,10)

const handleOnBlur = () => {
  if (editedContent) multiBox ? 
    updateContentBoxWithinMultiBox({...content, content: editedContent.content}, true)
  : 
    autoSaveContentBox(content.contentBoxId, editedContent)
}

const toggleCopyBoxModal = () => {
  setCopyBoxModal(!copyBoxModal)
  setClassesSelectedForContentBoxCopy([])
}

const handleCheckboxChange = (classId) => {
  setClassesSelectedForContentBoxCopy(prev => {
    if (prev.includes(classId)) {
      // If classId is already selected, remove it
      return prev.filter(id => id !== classId);
    } else {
      // If classId is not selected, add it
      return [...prev, classId];
    }
  });
};

const copyBoxToClasses = async () => {
  try {
    const docRef = doc(db, "users", user.uid);

    const userCopy = JSON.parse(JSON.stringify(user));

    // Find the class index based on classId
    const classIndex = userCopy.classes.findIndex(
      (c) => c.classId === classId
    );

    let boxToCopy = {};

    if (multiBox) {
      boxToCopy = {...content, header: 'Text Editor Box'}
    } else {
      boxToCopy = userCopy.classes[classIndex].contentBoxes.find(box => box.contentBoxId === boxToCopyId);
    }
    
    boxToCopy = { ...boxToCopy, contentBoxId: createRandomId() };

    let classesCopy = [...userCopy.classes];

    classesSelectedForContentBoxCopy.forEach(classId => {
      const classIndex = classesCopy.findIndex(c => c.classId === classId);

      if (classIndex !== -1) {
        // Copy the contentBoxes array and add the new box
        const updatedContentBoxes = [...classesCopy[classIndex].contentBoxes, boxToCopy];
        classesCopy[classIndex] = {
          ...classesCopy[classIndex],
          contentBoxes: updatedContentBoxes
        };
      } else {
        console.log(`Class with ID ${classId} not found.`);
      }
    });

    userCopy.classes = classesCopy;

    await setDoc(docRef, userCopy);

    toggleCopyBoxModal()

  } catch (error) {
    console.log("failed", error);
  }
};



const [boxToLinkId, setBoxToLinkId] = useState()
const [linkBoxModal, setLinkBoxModal] = useState(false)
const [classesSelectedForContentBoxLink, setClassesSelectedForContentBoxLink] = useState([]);

const toggleLinkedBoxModal = () => {
  if (linkBoxModal) {
    setClassesSelectedForContentBoxLink([])
  } else {
    setClassesSelectedForContentBoxLink([classId])
  }
  setLinkBoxModal(!linkBoxModal)
}

const handleCheckboxChangeForLinkedBoxes = (classId) => {
  setClassesSelectedForContentBoxLink(prev => {
    if (prev.includes(classId)) {
      // If classId is already selected, remove it
      return prev.filter(id => id !== classId);
    } else {
      // If classId is not selected, add it
      return [...prev, classId];
    }
  });
};

const addLinkedBoxToClasses = async () => {
  try {
    const docRef = doc(db, "users", user.uid);
    
    const userCopy = JSON.parse(JSON.stringify(user));
    
    // Find the class index based on classId
    const currentClassIndex = userCopy.classes.findIndex(
      (c) => c.classId === classId
    );
    
    let boxToCopy = userCopy.classes[currentClassIndex].contentBoxes.find(box => box.contentBoxId === boxToLinkId);
    
    // Add "linkedBox: true" property
    boxToCopy = { ...boxToCopy, linkedBox: true };
    
    let classesCopy = [...userCopy.classes];

    classesSelectedForContentBoxLink.forEach(targetClassId => {
      const targetClassIndex = classesCopy.findIndex(c => c.classId === targetClassId);

      if (targetClassIndex !== -1) {
        // If the target class is the current class, remove the old box before adding the updated one
        if (targetClassId === classId) {
          const updatedContentBoxes = classesCopy[targetClassIndex].contentBoxes.filter(box => box.contentBoxId !== boxToLinkId);
          classesCopy[targetClassIndex] = {
            ...classesCopy[targetClassIndex],
            contentBoxes: [...updatedContentBoxes, boxToCopy]  // Add the updated box with `linkedBox: true`
          };
        } else {
          // For other classes, just add the new box to contentBoxes
          classesCopy[targetClassIndex] = {
            ...classesCopy[targetClassIndex],
            contentBoxes: [...classesCopy[targetClassIndex].contentBoxes, boxToCopy]
          };
        }
      } else {
        console.log(`Class with ID ${targetClassId} not found.`);
      }
    });

    userCopy.classes = classesCopy;

    console.log("userCopy => ", userCopy)

    await setDoc(docRef, userCopy);

    toggleLinkedBoxModal();

  } catch (error) {
    console.log("failed", error);
  }
};





  return (
    <>

    <Modal isOpen={copyBoxModal} toggle={toggleCopyBoxModal}>
      <ModalBody>
        <h5>Copy box to:</h5>
        {user && user.classes && user.classes.map(x => 
          <FormGroup check>
            <Label check>
            <Input
              type="checkbox"
              defaultChecked={classesSelectedForContentBoxCopy.length > 0 && classesSelectedForContentBoxCopy.includes(x.classId)}
              onChange={() => handleCheckboxChange(x.classId)}
            />                
            <span className="form-check-sign" />
            {x.className}
            </Label>
          </FormGroup>
        )}
      </ModalBody>
      <ModalFooter>
        <Button disabled={classesSelectedForContentBoxCopy.length < 1} onClick={() => copyBoxToClasses()}>Copy</Button>
        <Button onClick={toggleCopyBoxModal}>Cancel</Button>
      </ModalFooter>
    </Modal>

    <Modal isOpen={linkBoxModal} toggle={toggleLinkedBoxModal}>
      <ModalBody>
        <h5>Add this Linked Content Box to:</h5>
        {user && user.classes && user.classes.map(x => 
          <FormGroup check>
            <Label check>
            <Input
              type="checkbox"
              defaultChecked={classesSelectedForContentBoxLink.length > 0 && classesSelectedForContentBoxLink.includes(x.classId)}
              onChange={() => handleCheckboxChangeForLinkedBoxes(x.classId)}
              disabled={x.classId === classId}
            />                
            <span className="form-check-sign" />
            {x.className}
            </Label>
          </FormGroup>
        )}
      </ModalBody>
      <ModalFooter>
        <Button disabled={classesSelectedForContentBoxLink.length < 1} onClick={() => addLinkedBoxToClasses()}>Add</Button>
        <Button onClick={toggleLinkedBoxModal}>Cancel</Button>
      </ModalFooter>
    </Modal>

    { !isMobile ? (
    <Col 
      style={{ flex: "1", minHeight: "0", padding: '4px', height: '100%', border: showButtons ? 'rgb(11, 192, 223) solid 2px' : 'transparent solid 2px', borderRadius: '15px'}}
      onMouseEnter={() => setShowMainEditButton(true)}
      onMouseLeave={() => { setShowMainEditButton(false); setShowButtons(false)}}
    >
      <NotificationAlert className="content-box-save-alert" ref={notificationAlert} zIndex={9999} style={{width: '100px'}}/>
      {alert}
        <Card
          id={`editor-box-card-${content.contentBoxId}`}
          key={content.contentBoxId}
          style={{ height: multiBox || inFullscreenModal ? '100%' : cardHeight.card, backgroundImage: location.pathname !== '/print' && content.background || '', zoom: location.pathname === '/print' ? '.6' : '1', border: printActive === true ? '4px solid blue' : location.pathname === '/print' ? 'solid 1px' : 'none'}}
          // onMouseEnter={() => setShowMainEditButton(true)}
          // onMouseLeave={() => { setShowMainEditButton(false); setShowButtons(false)}}
        >
          {!inFullscreenModal && showMainEditButton && location.pathname !== '/add-edit-classes' && location.pathname !== '/print' && !location.pathname.includes('/share/') && !readOnly && (
            <>
              <Button
                // className="btn-round btn-icon"
                id={`options-${content.contentBoxId}`}
                type="button"
                style={{ backgroundColor: 'rgb(11, 192, 223) !important', color: 'white', position: 'absolute', transform: 'translateY(-60%)', right: '-12px', borderRadius: '30px', height: '30px', width: '30px', padding: '7px 11px', zIndex: '1049', overflow: 'visible'}}
                size="sm"
                onClick={() => setShowButtons(!showButtons)}
              >
                <i className={showButtons === false ? "fa fa-caret-down" : "fa fa-caret-up"} />
              </Button>
                <UncontrolledTooltip
                  delay={0}
                  target={`options-${content.contentBoxId}`}
                  placement="left"
                >
                    Options
                </UncontrolledTooltip>
                {showButtons && location.pathname !== '/add-edit-classes' && (
                  <>
                    {!multiBox &&
                    <>
                      <Button
                        // className="btn-round btn-icon"
                        id="editContentBoxHeading"
                        type="button"
                        style={{ backgroundColor: 'rgb(11, 192, 223) !important', color: 'white', position: 'absolute', transform: 'translateY(60%)', right: '-12px', borderRadius: '30px', height: '30px', width: '30px', padding: '6px 10px', zIndex: '1049', overflow: 'visible'}}
                        size="sm"
                        onClick={() => editBoxNameAlert(content.contentBoxId)}
                      >
                      <i className="fa fa-pencil" />
                      </Button>
                      <UncontrolledTooltip
                        delay={0}
                        target="editContentBoxHeading"
                        placement="left"
                      >
                          Edit Heading
                      </UncontrolledTooltip>
                    </>
                    }
                    <Button
                      // className="btn-round btn-icon"
                      id="changeContentBoxBackground"
                      type="button"
                      style={{ backgroundColor: 'rgb(11, 192, 223) !important', color: 'white', position: 'absolute', transform: multiBox ? 'translateY(60%)' : 'translateY(180%)', right: '-12px', borderRadius: '30px', height: '30px', width: '30px', padding: '6px 8px', zIndex: '1049'}}
                      size="sm"
                      onClick={() => toggleBackgroundContentBoxModal()}
                    >
                      <i class="nc-icon nc-palette"></i>             
                    </Button>
                      <UncontrolledTooltip
                        delay={0}
                        target="changeContentBoxBackground"
                        placement="left"
                      >
                          Change Background
                      </UncontrolledTooltip>
                      {user.settings.useActiveDates && !multiBox ? (
                        <>
                          <Button
                            // className="btn-round btn-icon"
                            id="setContentBoxActiveDates"
                            type="button"
                            style={{ backgroundColor: 'rgb(11, 192, 223) !important', color: 'white', position: 'absolute', transform: multiBox ? 'translateY(180%)' : 'translateY(300%)', right: '-12px', borderRadius: '30px', height: '30px', width: '30px', padding: '4px 9px', zIndex: '1049'}}
                            size="sm"
                            onClick={() => toggleSetActiveDatesModal()}
                            // onClick={null}
                          >
                            <i className="fa fa-calendar" />
                          </Button>
                          <UncontrolledTooltip
                            delay={0}
                            target="setContentBoxActiveDates"
                            placement="left"
                          >
                              Set Active Dates
                          </UncontrolledTooltip>
                        </>
                      ) : !multiBox &&
                      (
                        <>
                        <Button
                        // className="btn-round btn-icon"
                        id="sendBoxToStorage"
                        type="button"
                        style={{ backgroundColor: 'rgb(11, 192, 223) !important', color: 'white', position: 'absolute', transform: multiBox ? 'translateY(180%)' : 'translateY(300%)', right: '-12px', borderRadius: '30px', height: '30px', width: '30px', padding: '5px 8px', zIndex: '1049'}}
                        size="sm"
                        onClick={() => sendBoxToStorage()}
                        // onClick={null}
                        >
                          <i className="nc-icon nc-box" />
                        </Button>
                          <UncontrolledTooltip
                            delay={0}
                            target="sendBoxToStorage"
                            placement="left"
                          >
                              Send Box to Storage
                          </UncontrolledTooltip>
                        </>
                      )}
                    <Button
                      // className="btn-round btn-icon"
                      id="copyBox"
                      type="button"
                      style={{ backgroundColor: 'rgb(11, 192, 223) !important', color: 'white', position: 'absolute', transform: multiBox ? 'translateY(180%)' : 'translateY(420%)', right: '-12px', borderRadius: '30px', height: '30px', width: '30px', padding: '5px 8px', zIndex: '1049'}}
                      size="sm"
                      onClick={() => {setBoxToCopyId(content.contentBoxId); toggleCopyBoxModal()}}
                      // onClick={null}
                      >
                        <i className="fa fa-copy" />
                      </Button>
                        <UncontrolledTooltip
                          delay={0}
                          target="copyBox"
                          placement="left"
                        >
                            Duplicate Box
                        </UncontrolledTooltip>
                    <Button
                      // className="btn-round btn-icon"
                      id="saveContentBoxAsTemplate"
                      type="button"
                      style={{ backgroundColor: 'rgb(11, 192, 223) !important', color: 'white', position: 'absolute', transform: multiBox ? 'translateY(300%)' : 'translateY(540%)', right: '-12px', borderRadius: '30px', height: '30px', width: '30px', padding: '4px 8px', zIndex: '1049'}}
                      size="sm"
                      onClick={() => addTemplateAlert(content)}
                    >
                      <img
                          src={stampIcon}
                          alt="stamp"
                          style={{ height: '13px'}}
                      />
                    </Button>
                      <UncontrolledTooltip
                        delay={0}
                        target="saveContentBoxAsTemplate"
                        placement="left"
                      >
                          Save as Template
                      </UncontrolledTooltip>
                    <Button
                        // className="btn-round btn-icon"
                        id="toggleFullscreenModal"
                        type="button"
                        style={{ backgroundColor: 'rgb(11, 192, 223) !important', color: 'white', position: 'absolute', transform: multiBox ? 'translateY(420%)' : 'translateY(660%)', right: '-12px', borderRadius: '30px', height: '30px', width: '30px', padding: '4px 8px', zIndex: '1049'}}
                        size="sm"
                        onClick={() => {toggleFullscreenModal()}}
                      >
                        <i className="fa fa-expand"></i>                        
                      </Button>
                      <UncontrolledTooltip
                        delay={0}
                        target="toggleFullscreenModal"
                        placement="left"
                      >
                          Expand
                      </UncontrolledTooltip>
                    {!multiBox && 
                      <>
                        <Button
                          // className="btn-round btn-icon"
                          id="makeLinkedBox"
                          type="button"
                          style={{ backgroundColor: 'rgb(11, 192, 223) !important', color: 'white', position: 'absolute', transform: 'translateY(780%)', right: '-12px', borderRadius: '30px', height: '30px', width: '30px', padding: '4px 8px', zIndex: '1049'}}
                          size="sm"
                          onClick={() => {setBoxToLinkId(content.contentBoxId); toggleLinkedBoxModal()}}
                        >
                          <i class="fa fa-link"></i>
                        </Button>
                        <UncontrolledTooltip
                          delay={0}
                          target="makeLinkedBox"
                          placement="left"
                        >
                            Make Linked Box (Beta)
                        </UncontrolledTooltip>
                        <Button
                          className="button-delete"
                          id="deleteContentBox"
                          type="button"
                          style={{color: 'white', position: 'absolute', transform: multiBox ? 'translateY(540%)' : 'translateY(900%)', right: '-12px', borderRadius: '30px', height: '30px', width: '30px', padding: '4px 9px', zIndex: '1049'}}
                          size="sm"
                          onClick={() => warningWithConfirmAndCancelMessage(content.contentBoxId)}
                        >
                          <i className="fa fa-trash" />
                        </Button>
                          <UncontrolledTooltip
                            delay={0}
                            target="deleteContentBox"
                            placement="left"
                          >
                              Delete
                          </UncontrolledTooltip>
                      </>
                    }
                  </>
                  )}
            </>
          )}
  
          <CardBody>
            <div id="editable" style={{ height: '100%', overflowY: 'scroll' }}>
              <TipTapEditor setEditedContent={setEditedContent} initialValue={content.content} handleOnBlur={handleOnBlur} readOnly={readOnly} singleLineFormattingToolbar={singleLineFormattingToolbar} settings={user?.settings}/>
            </div>
          </CardBody>
        </Card>
          {/* <Modal 
            isOpen={activeDatesModal}
            toggle={toggleSetActiveDatesModal}
            scrollable
            style={{padding: '20px'}}
          >
            <ModalHeader toggle={toggleSetActiveDatesModal}>Set Active Dates</ModalHeader>
            <br></br>
            <ModalBody>
              <Container>
                <Row xs="2">
                  <FormGroup>
                    <Label for="startDate">
                      Start Date
                    </Label>
                    <Input
                        id="startDate"
                        name="startDate"
                        type="date"
                        onChange={handleActiveDateChange}
                        // defaultValue={"2024-02-02"}
                        value={activeDates.startDate || today}
                    >
                    </Input>
                  </FormGroup>
                  <FormGroup>
                    <Label for="endDate">
                      End Date
                    </Label>
                    <Input
                        id="endDate"
                        name="endDate"
                        type="date"
                        onChange={handleActiveDateChange}
                        value={activeDates.endDate || today}
                    >
                    </Input>
                  </FormGroup>
                </Row>
                <Row>
                  <a href="https://lessonlaunchpad.com/faq#active-dates">What are Active Dates?</a>
                </Row>
              </Container>
            </ModalBody>
            <Button onClick={() => saveContentBoxActiveDates(content.contentBoxId)}>Save Active Dates</Button>
          </Modal> */}
          <ActiveDatesModal activeDatesModal={activeDatesModal} toggleSetActiveDatesModal={toggleSetActiveDatesModal} activeDates={activeDates} saveActiveDates={saveContentBoxActiveDates}/>
          <Modal 
            isOpen={backgroundContentBoxModal}
            toggle={toggleBackgroundContentBoxModal}
            backdrop={false}
            scrollable
          >
            <ModalHeader toggle={toggleBackgroundContentBoxModal} className="background-modal-header"/>
            <ModalBody style={{padding: '0'}} className="agenda-body">
              <Container>
                <Row xs="6">
                  {backgroundOptionsData.map((option, index) => (
                    <Col key={index} onClick={() => saveBackgroundContentBox(option.image)} style={{ padding: '0px' }}>
                      <div className="image-container">
                        <img src={option.thumbnail} alt={`Background option ${index + 1}`} />
                      </div>
                    </Col>
                  ))}
                </Row>
              </Container>
            </ModalBody>
          </Modal>
          <FullscreenModal 
            fullscreenModal={fullscreenModal} 
            toggleFullscreenModal={toggleFullscreenModal}
            box={content} 
            navbarMD={navbarMD} 
            classIdRef={classId}
            view={view}
            user={user}
            multiBox={true}
            updateContentBox={updateContentBox}
            navigationVisible={navigationVisible}
          />        
        </Col>

    ) : (

      <Col style={{zoom: '1' }}>
        <Card 
          key={content.contentBoxId}
          style={{ height: '95vh'}}
        >
          <CardBody>
            <div id="editable" style={{ overflowY: 'auto' }}>
            <Editor style={{ height: '93vh'}}
              apiKey="oaerr57l3odwcwxvr9j5en2h3v40g4nhjfopxvyhugjrurbq"
              onEditorChange={(newValue, editor) => {
                  setEditedContent({...editedContent, content: newValue})
                  setContentPreview(editor.getContent({format: 'text'}))
                  if (!content.userHeading) {
                    setHeading(editor.getContent({format: 'text'}))
                    setHeadingInsideContentBox(editor.getContent({format: 'text'}))
                  }
                  
              }}
              onBlur={() => {
                  autoSaveContentBox(content.contentBoxId, editedContent)
              }}
              init={{
                resize: false,
                branding: false,
                statusbar: false,
                min_height: 200,
                selector: 'div#editable',
                inline: true,
                menubar: false,
                nonbreaking_force_tab: true,
                toolbar_mode: 'floating',
                placeholder: 'Type here...',
                font_size_formats: '8pt 10pt 12pt 14pt 16pt 18pt 24pt 30pt 36pt 42pt 48pt 56pt 64pt 72pt',
                plugins: 'image link lists media table emoticons nonbreaking',
                toolbar: ['undo redo | fontfamily fontsize | bold italic underline strikethrough backcolor forecolor ',
                          'align lineheight | checklist numlist bullist indent outdent | link image media table mergetags emoticons charmap hr | copy paste'],
              }}
              initialValue={content.content}
            />
            </div>
          </CardBody>
        </Card>
        </Col>

    )}
    </>
  );
}

export default ContentBox;
