import { useEffect, useRef, useState } from "react";

const usePersistentTimer = () => {
    const [elapsedTime, setElapsedTime] = useState(0);
    const workerRef = useRef(null);

    useEffect(() => {
        workerRef.current = new Worker(new URL("../workers/simpleWorker.js", import.meta.url));

        workerRef.current.onmessage = () => {
            setElapsedTime((prev) => prev + 1); // Increment every second
        };

        return () => {
            workerRef.current.terminate();
        };
    }, []);

    return { elapsedTime };
};

export default usePersistentTimer;
