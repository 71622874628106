/*!

=========================================================
* Paper Dashboard PRO React - v1.3.1
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import {React, useState, useEffect} from "react";
import {NavLink, Route} from "react-router-dom";
import ReactBSAlert from "react-bootstrap-sweetalert";
import { useSelector } from "react-redux";
import { selectUser } from "features/userSlice";
import { setDoc, doc} from "firebase/firestore";
import { db } from "/Users/michaeltucker/Documents/coding-projects/client/src/firebase.js";
import GoogleSlidesBox from "../../components/ContentBoxes/GoogleSlidesBox.js"
import TimerStopwatchContentBox from "components/ContentBoxes/TimerStopwatchContentBox";
import PdfBox from "components/ContentBoxes/TimerStopwatchContentBox";
import ActiveDatesModal from "/Users/michaeltucker/Documents/coding-projects/client/src/components/ActiveDatesModal.js";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Label,
  FormGroup,
  Input,
  Table,
  Row,
  Col,
  UncontrolledTooltip,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
  CardSubtitle,
  Container
} from "reactstrap";
import ContentBox from "components/ContentBoxes/ContentBox";
import AgendaBox from "components/ContentBoxes/AgendaBox";
import CanvaBox from "components/ContentBoxes/CanvaBox";
import MultiBox from "components/ContentBoxes/MultiBox";
import { createRandomId } from "RandomId";
import CountdownBox from "components/ContentBoxes/CountdownBox";
import GoogleDocSheetBox from "components/ContentBoxes/GoogleDocSheetBox";
import IframeBox from "components/ContentBoxes/IframeBox.js";

function ContentBoxesTable({props, date}) {

    const [alert, setAlert] = useState(null);
    const user = useSelector(selectUser);
    const thisClass = user.classes.find((classObj) => classObj.classId === props.current)
    const [activeBoxes, setActiveBoxes] = useState()
    const [storageBoxes, setStorageBoxes] = useState()
    const [numberOfWeeks, setNumberOfWeeks] = useState(user.settings.numberOfWeeks || 2)
    const [differentTimes, setDifferentTimes] = useState(user.settings.differentTimes)
    const dayMapping = {
      "Monday": "M",
      "Tuesday": "T",
      "Wednesday": "W",
      "Thursday": "Th",
      "Friday": "F",
      "Saturday": "S",
      "Sunday": "Su",
    };
    const dayOrder = ["M", "T", "W", "Th", "F", "S", "Su"];
    const weeks = ["A", "B", "C", "D"];
    const weeksOrder = weeks.slice(0, numberOfWeeks)
    const [boxToCopyId, setBoxToCopyId] = useState()
    const [copyBoxModal, setCopyBoxModal] = useState(false)
    const [classesSelectedForContentBoxCopy, setClassesSelectedForContentBoxCopy] = useState([]);
    const days = ['Sun', 'Mon', 'Tues', 'Wed', 'Thur', 'Fri', 'Sat'];
    const today = new Date().toISOString().slice(0,10)
    const [sortOrder, setSortOrder] = useState("asc"); // Default to ascending
    const [datesSortOrder, setDatesSortOrder] = useState("asc"); // Default to ascending

    // useEffect(() => {
    //   if (thisClass) {
    //     if (user.settings.useActiveDates === true) {
    //       const activeContentBoxes = thisClass.contentBoxes.filter((box) => {
    //         const startDate = new Date(box.startDate);
    //         startDate.setHours(0, 0, 0, 1); // Normalize start date
    //         const endDate = new Date(box.endDate);
    //         endDate.setHours(24); // Adjust end date to include the whole day
    //         const dayToCompare = new Date(date.year, date.month, date.day)
    //         dayToCompare.setHours(0,0,0,0)
    //         return startDate <= dayToCompare && endDate >= dayToCompare || box.startDate === undefined;
    //       });
    //       setActiveBoxes(activeContentBoxes)

    //       const storageContentBoxes = thisClass.contentBoxes.filter((box) => {
    //         const startDate = new Date(box.startDate);
    //         startDate.setHours(0, 0, 0, 1); // Normalize start date
    //         const endDate = new Date(box.endDate);
    //         endDate.setHours(24); // Adjust end date to include the whole day
    //         const dayToCompare = new Date(date.year, date.month, date.day)
    //         dayToCompare.setHours(0,0,0,0)
    //         return startDate > dayToCompare || startDate < dayToCompare && endDate < dayToCompare;
    //       });
    //       setStorageBoxes(storageContentBoxes)
    //     } else {
    //       setActiveBoxes(thisClass.contentBoxes.filter((x) => x.active === true))
    //       setStorageBoxes(thisClass.contentBoxes.filter((x) => x.active === false || x.active === undefined))
    //     }
    //   }
    // },[user, date])

    useEffect(() => {
      if (thisClass) {
        const sortFunction = user.settings.useActiveDates ? sortByDatesActive : sortByLastEdited;
    
        if (user.settings.useActiveDates === true) {
          const activeContentBoxes = thisClass.contentBoxes.filter((box) => {
            if (Array.isArray(box.startDate)) {
              if (box.startDate.length > 0) {
                if (box.startDate.includes(date.dayNamePlural)) return true
              }
            } else {
              const startDate = new Date(box.startDate);
              startDate.setHours(0, 0, 0, 1); // Normalize start date
              const endDate = new Date(box.endDate);
              endDate.setHours(24); // Adjust end date to include the whole day
              const dayToCompare = new Date(date.year, date.month, date.day);
              dayToCompare.setHours(0, 0, 0, 0);
              return (
                startDate <= dayToCompare &&
                endDate >= dayToCompare ||
                box.startDate === undefined
              );
            }
          });
          setActiveBoxes(sortFunction(activeContentBoxes));
    
          const storageContentBoxes = thisClass.contentBoxes.filter((box) => {
            if (Array.isArray(box.startDate)) {
              if (box.startDate.length > 0) {
                if (!box.startDate.includes(date.dayNamePlural)) return true
              }
            } else {
              const startDate = new Date(box.startDate);
              startDate.setHours(0, 0, 0, 1); // Normalize start date
              const endDate = new Date(box.endDate);
              endDate.setHours(24); // Adjust end date to include the whole day
              const dayToCompare = new Date(date.year, date.month, date.day);
              dayToCompare.setHours(0, 0, 0, 0);
              return (
                startDate > dayToCompare ||
                (startDate < dayToCompare && endDate < dayToCompare)
              );
            }
          });
          setStorageBoxes(sortFunction(storageContentBoxes));
        } else {
          setActiveBoxes(
            sortFunction(thisClass.contentBoxes.filter((x) => x.active === true))
          );
          setStorageBoxes(
            sortFunction(
              thisClass.contentBoxes.filter((x) => x.active === false || x.active === undefined)
            )
          );
        }
      }
    }, [user, date, sortOrder, datesSortOrder]);

    const warningWithConfirmAndCancelMessage = (contentBoxId) => {
      setAlert(
        <ReactBSAlert
          warning
          style={{ display: "block", marginTop: "-100px" }}
          title="Are you sure?"
          onConfirm={() => deleteContentBox(contentBoxId)}
          onCancel={() => cancelDelete()}
          confirmBtnBsStyle="info"
          cancelBtnBsStyle="danger"
          confirmBtnText="Yes, delete it!"
          cancelBtnText="Cancel"
          showCancel
          btnSize=""
        >
          You will not be able to recover this Content Box!
        </ReactBSAlert>
      );
    };
  
    const successDelete = () => {
      setAlert(
        <ReactBSAlert
          success
          style={{ display: "block", marginTop: "-100px" }}
          title="Deleted!"
          onConfirm={() => hideAlert()}
          onCancel={() => hideAlert()}
          confirmBtnBsStyle="info"
          btnSize=""
        >
          Your conetent box has been deleted.
        </ReactBSAlert>
      );
    };
  
    const cancelDelete = () => {
      setAlert(
        <ReactBSAlert
          danger
          style={{ display: "block", marginTop: "-100px" }}
          title="Cancelled"
          onConfirm={() => hideAlert()}
          onCancel={() => hideAlert()}
          confirmBtnBsStyle="info"
          btnSize=""
        >
          Your content box is safe :)
        </ReactBSAlert>
      );
    };

    const hideAlert = () => {
      setAlert(null);
    };

    const deleteContentBox = async (contentBoxId) => {
      console.log(contentBoxId)
      try {
        const docRef = doc(db, "users", user.uid);
    
        const userCopy = JSON.parse(JSON.stringify(user));
    
        const classIndex = userCopy.classes.findIndex(
          (c) => c.classId === thisClass.classId
        );
    
        const contentBoxIndex = userCopy.classes[classIndex].contentBoxes.findIndex(
          (box) => box.contentBoxId === contentBoxId
        );
    
        // Remove the content box at the specified index
        userCopy.classes[classIndex].contentBoxes.splice(contentBoxIndex, 1);
    
        // Update the document in Firestore
        await setDoc(docRef, userCopy);
    
        // // Update the local state with the updated document data
        // setStateContent(userCopy);
    
        successDelete()
    
        console.log('Content box deleted successfully!');
      } catch (error) {
        console.log('Error deleting content box:', error);
      }
    }

    const handleMoveUp = (contentBoxId, index) => {
      if (index > 0) { // Ensure it's not the first active box
        const classIndex = user.classes.findIndex(
          (c) => c.classId === thisClass.classId
        );
        if (classIndex === -1) return; // Exit if the class isn't found
    
        // Locate the content box in the full array
        const indexInContentBoxesArray = user.classes[classIndex].contentBoxes.findIndex(
          (box) => box.contentBoxId === contentBoxId
        );
    
        if (indexInContentBoxesArray <= 0) return; // Already at the start or not found
    
        // Locate the previous active box (in activeBoxes array) in the full array
        const prevActiveBoxId = activeBoxes[index - 1].contentBoxId;
        const indexOfPrevActiveBoxInFullArray = user.classes[classIndex].contentBoxes.findIndex(
          (box) => box.contentBoxId === prevActiveBoxId
        );
    
        // Prepare the content boxes array for manipulation
        const updatedBoxes = [...user.classes[classIndex].contentBoxes];
    
        // Remove the target box from its current position
        const [objectToMove] = updatedBoxes.splice(indexInContentBoxesArray, 1);
    
        // Calculate the correct position and re-insert the box
        // Since we're moving "up", we insert at the index of the previous active box
        updatedBoxes.splice(indexOfPrevActiveBoxInFullArray, 0, objectToMove);
    
        // Log the changes (optional)
        console.log(`updatedBoxes after => ${JSON.stringify(updatedBoxes)}`);
    
        // Update the content boxes in your data structure/database
        updateContentBoxes(updatedBoxes);
      }
    };
  
    const handleMoveDown = (contentBoxId, index) => {
      // Check if the box is not the last active box
      if (index < activeBoxes.length - 1) {
        const classIndex = user.classes.findIndex(
          (c) => c.classId === thisClass.classId
        );
        if (classIndex === -1) return; // Exit if the class isn't found
    
        // Locate the content box in the full array
        const indexInContentBoxesArray = user.classes[classIndex].contentBoxes.findIndex(
          (box) => box.contentBoxId === contentBoxId
        );
    
        // Ensure the box isn't already at the end of the array
        if (indexInContentBoxesArray >= user.classes[classIndex].contentBoxes.length - 1) return;
    
        // Locate the next active box (in activeBoxes array) in the full array
        const nextActiveBoxId = activeBoxes[index + 1].contentBoxId;
        const indexOfNextActiveBoxInFullArray = user.classes[classIndex].contentBoxes.findIndex(
          (box) => box.contentBoxId === nextActiveBoxId
        );
    
        // Prepare the content boxes array for manipulation
        const updatedBoxes = [...user.classes[classIndex].contentBoxes];
    
        // Remove the target box from its current position
        const [objectToMove] = updatedBoxes.splice(indexInContentBoxesArray, 1);
    
        // Since we're moving down, we need to adjust the insertion index considering the box removal
        // Insert it after the next active box (hence the +1)
        const insertIndex = indexOfNextActiveBoxInFullArray > indexInContentBoxesArray ? indexOfNextActiveBoxInFullArray : indexOfNextActiveBoxInFullArray + 1;
        updatedBoxes.splice(insertIndex, 0, objectToMove);
    
        // Update the content boxes in your data structure/database
        updateContentBoxes(updatedBoxes);
      }
    };

    const updateContentBoxes = async (updatedBoxes) => {
      console.log(updatedBoxes)
      try {
        const docRef = doc(db, "users", user.uid);

        const userCopy = JSON.parse(JSON.stringify(user));

        const classIndex = userCopy.classes.findIndex(
          (c) => c.classId === thisClass.classId
        );
    
        userCopy.classes[classIndex].contentBoxes = updatedBoxes
        // Update the document in Firestore
        await setDoc(docRef, userCopy);
      } catch (error) {
        console.log('Error updating document:', error);
      }
    }

    const moveToStorage = async (boxFromClick) => {
      try {
        const docRef = doc(db, "users", user.uid);
    
        const userCopy = JSON.parse(JSON.stringify(user));
    
        // Find the class index based on classId
        const classIndex = userCopy.classes.findIndex(
          (c) => c.classId === props.current
        );
  
        const contentBoxIndex = userCopy.classes[classIndex].contentBoxes?.findIndex(
          (box) => box.contentBoxId === boxFromClick.contentBoxId
        );
  
        const copyOfBox = {...boxFromClick, active: false}
  
        // Update the content box in the document data
        userCopy.classes[classIndex].contentBoxes[contentBoxIndex] = copyOfBox;
  
        // Update the document in Firestore
        await setDoc(docRef, userCopy); // Replace with the correct path to your document
  
      } catch (error) {
        console.error('Error updating document:', error);
      }
    };

    const moveToActive = async (boxFromClick) => {
      try {
        const docRef = doc(db, "users", user.uid);
    
        const userCopy = JSON.parse(JSON.stringify(user));
    
        // Find the class index based on classId
        const classIndex = userCopy.classes.findIndex(
          (c) => c.classId === props.current
        );
  
        const contentBoxIndex = userCopy.classes[classIndex].contentBoxes?.findIndex(
          (box) => box.contentBoxId === boxFromClick.contentBoxId
        );
  
        const copyOfBox = {...boxFromClick, active: true}
  
        // Update the content box in the document data
        userCopy.classes[classIndex].contentBoxes[contentBoxIndex] = copyOfBox;
  
        // Update the document in Firestore
        await setDoc(docRef, userCopy); // Replace with the correct path to your document
  
      } catch (error) {
        console.error('Error updating document:', error);
      }
    };

  const [previewModal, setPreviewModal] = useState(false);
  const [previewBox, setPreviewBox] = useState();

  const toggleBoxPreview = (boxFromClick) => {
    hideAlert()
    setPreviewBox(boxFromClick)
    setPreviewModal(!previewModal)
  }

  function convertDateFormat(startDate, endDate) {
      if (Array.isArray(startDate)) {
        console.log(startDate.length)
        return startDate.length === 5 ? "Everyday" : startDate.join(", ")
      }
      // Split the input date string on "/"
      const startParts = startDate.split('-');
      const endParts = endDate.split('-');
      // Rearrange the parts to the desired format "MM/DD/YYYY"
      const newStart = `${startParts[1]}/${startParts[2]}/${startParts[0]}`;
      const newEnd = `${endParts[1]}/${endParts[2]}/${endParts[0]}`;
      return startDate === endDate ? newStart : `${newStart} - ${newEnd}`;
  }

  const toggleSetActiveDatesModal = (box) => {
    if (!activeDatesModal) {
      setActiveDates({
        startDate: box.startDate === undefined ? "" : box.startDate,
        endDate: box.endDate === undefined ? "" : box.endDate,
        contentBoxId: box.contentBoxId
      })
    }
    setActiveDatesModal(!activeDatesModal)
  }

  const [activeDatesModal, setActiveDatesModal] = useState(false)

  const [activeDates, setActiveDates] = useState();

  const handleActiveDateChange = (event) => {
    const { name, value } = event.target;
    setActiveDates(prevDates => ({
      ...prevDates,
      [name]: value
    }));
  };

  const saveContentBoxActiveDates = async (datesFromSetActiveDatesModal) => {
    try {
      const docRef = doc(db, "users", user.uid);
  
      const userCopy = JSON.parse(JSON.stringify(user));
  
      // Find the class index based on classId
      const classIndex = userCopy.classes.findIndex(
        (c) => c.classId === props.current
      );
  
      if (classIndex !== -1) {
        // Find the content box index based on contentBoxId
        const contentBoxIndex = userCopy.classes[classIndex].contentBoxes.findIndex(
          (box) => box.contentBoxId === activeDates.contentBoxId
        );
  
        if (contentBoxIndex !== -1) {
          // Make a copy of the existing content box using the spread operator
          const updatedContentBox = { ...userCopy.classes[classIndex].contentBoxes[contentBoxIndex]};
  
          // Update the content box with the new data
          updatedContentBox.startDate = datesFromSetActiveDatesModal.startDate;
          updatedContentBox.endDate = datesFromSetActiveDatesModal.endDate;
  
          console.log("updatedContentBox => ", updatedContentBox)
          // Update the content box in the document data
          userCopy.classes[classIndex].contentBoxes[contentBoxIndex] = updatedContentBox;
  
          // Update the document in Firestore
          await setDoc(docRef, userCopy); // Replace with the correct path to your document
  
        }
      }
    } catch (error) {
      console.error('Error updating document:', error);
    }
    setActiveDatesModal(!activeDatesModal)
};

const toggleCopyBoxModal = () => {
  setCopyBoxModal(!copyBoxModal)
  setClassesSelectedForContentBoxCopy([])
}

const handleCheckboxChange = (classId) => {
  setClassesSelectedForContentBoxCopy(prev => {
    if (prev.includes(classId)) {
      // If classId is already selected, remove it
      return prev.filter(id => id !== classId);
    } else {
      // If classId is not selected, add it
      return [...prev, classId];
    }
  });
};

const copyBoxToClasses = async () => {
  try {
    const docRef = doc(db, "users", user.uid);

    const userCopy = JSON.parse(JSON.stringify(user));

    let boxToCopy = thisClass.contentBoxes.find(box => box.contentBoxId === boxToCopyId);
    boxToCopy = { ...boxToCopy, contentBoxId: createRandomId() };

    let classesCopy = [...user.classes];

    classesSelectedForContentBoxCopy.forEach(classId => {
      const classIndex = classesCopy.findIndex(c => c.classId === classId);

      if (classIndex !== -1) {
        // Copy the contentBoxes array and add the new box
        const updatedContentBoxes = [...classesCopy[classIndex].contentBoxes, boxToCopy];
        classesCopy[classIndex] = {
          ...classesCopy[classIndex],
          contentBoxes: updatedContentBoxes
        };
      } else {
        console.log(`Class with ID ${classId} not found.`);
      }
    });

    userCopy.classes = classesCopy;

    await setDoc(docRef, userCopy);

    toggleCopyBoxModal()

  } catch (error) {
    console.log("failed", error);
  }
};

const sortByLastEdited = (boxes) => {
  return [...boxes].sort((a, b) => {
    const dateA = new Date(a.lastEdited);
    const dateB = new Date(b.lastEdited);

    if (sortOrder === "asc") {
      return dateA - dateB;
    } else {
      return dateB - dateA;
    }
  });
};

const sortByDatesActive = (boxes) => {
  return [...boxes].sort((a, b) => {
    const dateA = a.startDate ? new Date(a.startDate) : null;
    const dateB = b.startDate ? new Date(b.startDate) : null;

    if (datesSortOrder === "asc") {
      if (!dateA) return 1; // Undefined or null dates go to the end
      if (!dateB) return -1;
      return dateA - dateB;
    } else {
      if (!dateA) return -1; // Undefined or null dates go to the start
      if (!dateB) return 1;
      return dateB - dateA;
    }
  });
};

const handleSortByLastEdited = () => {
  const newSortOrder = sortOrder === "asc" ? "desc" : "asc";
  setSortOrder(newSortOrder);

  // Sort and update state
  if (activeBoxes) setActiveBoxes(sortByLastEdited(activeBoxes));
  if (storageBoxes) setStorageBoxes(sortByLastEdited(storageBoxes));
};

const handleSortByDatesActive = () => {
  const newSortOrder = datesSortOrder === "asc" ? "desc" : "asc";
  setDatesSortOrder(newSortOrder);

  // Sort and update state
  if (activeBoxes) setActiveBoxes(sortByDatesActive(activeBoxes));
  if (storageBoxes) setStorageBoxes(sortByDatesActive(storageBoxes));
};


  return (
    <>
      <div className="content">
        <Modal contentClassName="content-box-preview-modal" isOpen={previewModal} toggle={toggleBoxPreview}>
          <ModalHeader toggle={toggleBoxPreview}/>
          <ModalBody >
            {previewBox && (
              previewBox.slideshow ? (
                <GoogleSlidesBox content={previewBox} user={user}/>
              ) : (previewBox.timer || previewBox.stopwatch) ? (
                <TimerStopwatchContentBox content={previewBox} />
              ) : (previewBox.agendaBox) ? (
                <AgendaBox content={previewBox} user={user}/>
              ) : (previewBox.canvaBox) ? (
                <CanvaBox content={previewBox} user={user}/>
              ) : (previewBox.multiBox) ? (
                <MultiBox content={previewBox} classId={{current: 1}} user={user}/>
              ) : (previewBox.countdown) ? (
                <CountdownBox content={previewBox} user={user}/>
              ) : (previewBox.googleDocSheet) ? (
                <GoogleDocSheetBox content={previewBox} user={user}/>
              ) : (previewBox.pdf) ? (
                <PdfBox content={previewBox} user={user}/>
              ) : (previewBox.iFrame) ? (
                <IframeBox content={previewBox} user={user}/>
              ) : (
                <ContentBox content={previewBox} user={user}/>
              )
            )}
          </ModalBody>
        </Modal>
    
        <Modal isOpen={copyBoxModal} toggle={toggleCopyBoxModal}>
          <ModalBody>
            <h5>Copy box to:</h5>
            {user.classes?.map(x => 
              <FormGroup check>
                <Label check>
                <Input
                  type="checkbox"
                  defaultChecked={classesSelectedForContentBoxCopy.length > 0 && classesSelectedForContentBoxCopy.includes(x.classId)}
                  onChange={() => handleCheckboxChange(x.classId)}
                />                
                <span className="form-check-sign" />
                {x.className}
                </Label>
              </FormGroup>
            )}
          </ModalBody>
          <ModalFooter>
            <Button disabled={classesSelectedForContentBoxCopy.length < 1} onClick={() => copyBoxToClasses()}>Copy</Button>
            <Button onClick={toggleCopyBoxModal}>Cancel</Button>
          </ModalFooter>
        </Modal>

        { activeDates &&
          <ActiveDatesModal activeDatesModal={activeDatesModal} toggleSetActiveDatesModal={toggleSetActiveDatesModal} activeDates={activeDates} saveActiveDates={saveContentBoxActiveDates}/>
        }

                <Row>
                    <Col>
                      <Card>
                        <CardHeader>
                          <CardTitle tag="h4" style={{ fontWeight: "bold", color: "#004aad" }}>Active Boxes</CardTitle>
                          {user.settings.useActiveDates ? (
                            <CardSubtitle>These are the boxes that will appear on your "Present" screen on the selected date.</CardSubtitle>
                          ) : (
                            <CardSubtitle>These are the boxes that will appear on your "Present" screen.</CardSubtitle>
                          )}
                        </CardHeader>
                        <CardBody>
                          <Table responsive>
                            <thead className="text-primary">
                              <tr>
                                <th className="text-center" id="Heading">Heading</th>
                                <th className="text-center" id="Content">Content</th>
                                {user.settings.useActiveDates && <th className="text-center" id="Active">Dates Active</th> }
                                <th className="text-center" id="Name">Last Edited</th>
                                <th className="text-center" id="Order">Order</th>
                                <th className="text-center">Actions</th>
                              </tr>
                              <UncontrolledTooltip delay={0} target={"Heading"}>The name you've chosen for your class.</UncontrolledTooltip>
                            </thead>
                            {activeBoxes && activeBoxes.map((item, index) => {
                            return(
                            <tbody key={index}>
                              <tr>
                                <td className="text-center">{item.heading}</td>
                                <td className="text-center">{item.contentPreview}</td>
                                {user.settings.useActiveDates && 
                                  <td className="text-center">
                                    {
                                      item?.startDate === "2000-01-02" && item?.endDate === "2099-12-30" ? "Everday" :
                                      item?.startDate !== undefined ? convertDateFormat(item.startDate, item.endDate) :  
                                      'n/a'
                                    }
                                  </td>
                                }
                                <td className="text-center">{item.lastEdited}</td>
                                <td className="text-center">
                                <Button
                                    className="btn-icon btn-neutral"
                                    color="secondary"
                                    id="tooltip159182282"
                                    size="md"
                                    type="button"
                                    onClick={() => handleMoveUp(item.contentBoxId, index)}
                                  >
                                  <i className="nc-icon nc-minimal-up" />
                                  </Button>
                                  <Button
                                    className="btn-icon btn-neutral"
                                    color="secondary"
                                    id="tooltip159185983"
                                    size="md"
                                    type="button"
                                    onClick={() => handleMoveDown(item.contentBoxId, index)}
                                  >
                                  <i className="nc-icon nc-minimal-down" />
                                  </Button>
                                </td>
                                <td className="text-center">
                                  <Button
                                    className="btn-icon btn-neutral"
                                    color="info"
                                    id="tooltip946942735"
                                    size="md"
                                    type="button"
                                    onClick={() => toggleBoxPreview(item)}
                                  >
                                    <i className="nc-icon nc-zoom-split" />
                                  </Button>
                                  <UncontrolledTooltip
                                    delay={0}
                                    target="tooltip946942735"
                                  >
                                    Preview
                                  </UncontrolledTooltip>
                                  {user.settings.useActiveDates ? (
                                    <>
                                      <Button
                                        className="btn-icon btn-neutral"
                                        color="info"
                                        id="tooltip946933335"
                                        size="md"
                                        type="button"
                                        onClick={() => toggleSetActiveDatesModal(item)}
                                      >
                                        <i className="fa fa-calendar"/>
                                      </Button>
                                      <UncontrolledTooltip
                                        delay={0}
                                        target="tooltip946933335"
                                      >
                                        Set Active Dates
                                      </UncontrolledTooltip>
                                    </>
                                  ) : (
                                    <>
                                      <Button
                                        className="btn-icon btn-neutral"
                                        color="info"
                                        id="tooltip153942735"
                                        size="md"
                                        type="button"
                                        onClick={() => moveToStorage(item)}
                                      >
                                        <i className="nc-icon nc-box" />
                                      </Button>
                                      <UncontrolledTooltip
                                        delay={0}
                                        target="tooltip153942735"
                                      >
                                        Move to Storage
                                      </UncontrolledTooltip>
                                    </>
                                  )}
                                  <Button
                                        className="btn-icon btn-neutral"
                                        color="info"
                                        id="tooltip3840209385"
                                        size="md"
                                        type="button"
                                        onClick={() => {setBoxToCopyId(item.contentBoxId); toggleCopyBoxModal()}}
                                      >
                                        <i className="fa fa-copy" />
                                      </Button>
                                      <UncontrolledTooltip
                                        delay={0}
                                        target="tooltip3840209385"
                                      >
                                        Duplicate Box
                                      </UncontrolledTooltip>
                                  <Button
                                    className="btn-icon btn-neutral"
                                    color="danger"
                                    id="tooltip808966390"
                                    size="md"
                                    type="button"
                                    onClick={() => warningWithConfirmAndCancelMessage(item.contentBoxId)}
                                  >
                                    <i className="fa fa-trash icon-delete" />
                                  </Button>
                                  <UncontrolledTooltip
                                    delay={0}
                                    target="tooltip808966390"
                                  >
                                    Delete
                                  </UncontrolledTooltip>
                                </td>
                              </tr>
                              </tbody>
                            )})}
                          </Table>
                          {alert}
                        </CardBody>
                        <CardHeader>
                          <CardTitle tag="h4" style={{ fontWeight: "bold", color: "#004aad" }}>Boxes Storage</CardTitle>
                          {user.settings.useActiveDates ? (
                            <CardSubtitle>These are boxes that are in storage and will not appear on your "Present" screen.</CardSubtitle>
                          ) : (
                            <CardSubtitle>These are boxes that are in storage and will not appear on your "Present" screen.</CardSubtitle>
                          )}
                        </CardHeader>
                        <CardBody>
                          <Table responsive>
                            <thead className="text-primary">
                              <tr>
                                <th className="text-center" id="Heading">Heading</th>
                                <th className="text-center" id="Content">Content</th>
                              {user.settings.useActiveDates && 
                                <th
                                  className="text-center"
                                  id="Active"
                                  onClick={handleSortByDatesActive}
                                  style={{ cursor: "pointer" }}
                                >
                                  Dates Active {datesSortOrder === "asc" ? "↑" : "↓"}
                                </th>
                              }
                                <th
                                  className="text-center"
                                  id="Name"
                                  onClick={handleSortByLastEdited}
                                  style={{ cursor: "pointer" }}
                                >
                                  Last Edited {sortOrder === "asc" ? "↑" : "↓"}
                                </th>
                                <th className="text-center" id="Order">Order</th>
                                <th className="text-center">Actions</th>
                              </tr>
                              <UncontrolledTooltip delay={0} target={"Heading"}>The name you've chosen for your class.</UncontrolledTooltip>
                              {/* <UncontrolledTooltip delay={0} target={"Giraffe"}>If checked, Class will be available in the Present Classes dropdown.</UncontrolledTooltip> */}
                            </thead>
                            {storageBoxes && storageBoxes.map((item, index) => {
                            return(
                            <tbody key={index}>
                              <tr>
                                <td className="text-center">{item.heading}</td>
                                <td className="text-center">{item.contentPreview}</td>
                                {user.settings.useActiveDates && <td className="text-center">{item?.startDate !== undefined ? convertDateFormat(item.startDate, item.endDate) : 'n/a'}</td> }
                                <td className="text-center">{item.lastEdited}</td>
                                {/* <td className="text-center">{item.active ? <i className="fa fa-check" /> : <i className="fa fa-times" />}</td> */}
                                <td className="text-center">
                                <Button
                                    className="btn-icon btn-neutral"
                                    color="secondary"
                                    id="tooltip159182282"
                                    size="md"
                                    type="button"
                                    onClick={() => handleMoveUp(index)}
                                  >
                                  <i className="nc-icon nc-minimal-up" />
                                  </Button>
                                  <Button
                                    className="btn-icon btn-neutral"
                                    color="secondary"
                                    id="tooltip159185983"
                                    size="md"
                                    type="button"
                                    onClick={() => handleMoveDown(index)}
                                  >
                                  <i className="nc-icon nc-minimal-down" />
                                  </Button>
                                </td>
                                <td className="text-center">
                                  <Button
                                    className="btn-icon btn-neutral"
                                    color="info"
                                    id="tooltip946942735"
                                    size="md"
                                    type="button"
                                    onClick={() => toggleBoxPreview(item)}
                                  >
                                    <i className="nc-icon nc-zoom-split" />
                                  </Button>
                                  <UncontrolledTooltip
                                    delay={0}
                                    target="tooltip946942735"
                                  >
                                    Preview
                                  </UncontrolledTooltip>
                                  {user.settings.useActiveDates ? (
                                    <>
                                      <Button
                                        className="btn-icon btn-neutral"
                                        color="info"
                                        id="tooltip946933335"
                                        size="md"
                                        type="button"
                                        onClick={() => toggleSetActiveDatesModal(item)}
                                      >
                                        <i className="fa fa-calendar"/>
                                      </Button>
                                      <UncontrolledTooltip
                                        delay={0}
                                        target="tooltip946933335"
                                      >
                                        Set Active Dates
                                      </UncontrolledTooltip>
                                    </>
                                  ) : (
                                    <>
                                      <Button
                                        className="btn-icon btn-neutral"
                                        color="info"
                                        id="tooltip159182296"
                                        size="md"
                                        type="button"
                                        onClick={() => moveToActive(item)}
                                      >
                                        <i className="nc-icon nc-basket" />
                                      </Button>
                                      <UncontrolledTooltip
                                        delay={0}
                                        target="tooltip159182296"
                                      >
                                        Move to Active
                                      </UncontrolledTooltip>
                                    </>
                                  )}
                                  <Button
                                    className="btn-icon btn-neutral"
                                    color="info"
                                    id="tooltip3840209385"
                                    size="md"
                                    type="button"
                                    onClick={() => {setBoxToCopyId(item.contentBoxId); toggleCopyBoxModal()}}
                                  >
                                    <i className="fa fa-copy" />
                                  </Button>
                                  <UncontrolledTooltip
                                    delay={0}
                                    target="tooltip3840209385"
                                  >
                                    Duplicate Box
                                  </UncontrolledTooltip>
                                  <Button
                                    className="btn-icon btn-neutral"
                                    color="danger"
                                    id="tooltip808966390"
                                    size="md"
                                    type="button"
                                    onClick={() => warningWithConfirmAndCancelMessage(item.contentBoxId)}
                                  >
                                    <i className="fa fa-trash icon-delete" />
                                  </Button>
                                  <UncontrolledTooltip
                                    delay={0}
                                    target="tooltip808966390"
                                  >
                                    Delete
                                  </UncontrolledTooltip>
                                </td>
                              </tr>
                              </tbody>
                            )})}
                          </Table>
                          {alert}
                        </CardBody>
                      </Card>
                    </Col>         
                </Row>
      </div>
    </>
  );
}

export default ContentBoxesTable;