import React, { useState } from 'react';
import {
    Modal,
    ModalHeader,
    ModalBody,
    Button,
    Container,
    Row,
    Col,
    FormGroup,
    Label,
    Input,
    Badge
} from 'reactstrap';

const ActiveDatesModal = ({ activeDatesModal, toggleSetActiveDatesModal, activeDates, saveActiveDates }) => {

    const today = new Date().toLocaleDateString('en-CA');
    const [activeDatesInModal, setActiveDatesInModal] = useState({
        startDate: activeDates.startDate || today,
        endDate: activeDates.endDate || today,
    });

    const [selectedDays, setSelectedDays] = useState([]); // To track multiple day selection

    const daysOfWeek = ["Mondays", "Tuesdays", "Wednesdays", "Thursdays", "Fridays"];

    const toggleDaySelection = (day) => {
        const updatedDays = selectedDays.includes(day)
          ? selectedDays.filter((d) => d !== day) // Remove day if already selected
          : [...selectedDays, day]; // Add day if not selected
      
        setSelectedDays(updatedDays);
      
        // Update startDate and endDate conditionally
        setActiveDatesInModal((prevState) => {
          if (updatedDays.length === 0) {
              let thing = {
                startDate: activeDates.startDate || today,
                endDate: activeDates.endDate || today,
              };

            // Reset to default values if no days are selected
            return thing;
          }
          // Otherwise, update startDate with selected days
          return {
            ...prevState,
            startDate: updatedDays,
          };
        });
    };      

    // Helper function to get the start (Monday) and end (Friday) of the current week
    const getWeekRange = () => {
      const currentDate = new Date();
      const dayOfWeek = currentDate.getDay(); // 0 (Sunday) to 6 (Saturday)
  
      let monday, friday;
  
      if (dayOfWeek === 0) { // If Sunday
          monday = new Date(currentDate);
          monday.setDate(currentDate.getDate() + 1); // Move to Monday (next day)
  
          friday = new Date(monday);
          friday.setDate(monday.getDate() + 4); // Move to Friday
      } else if (dayOfWeek === 6) { // If Saturday
          monday = new Date(currentDate);
          monday.setDate(currentDate.getDate() + 2); // Move to Monday
  
          friday = new Date(monday);
          friday.setDate(monday.getDate() + 4); // Move to Friday
      } else { // Normal weekdays (Monday-Friday)
          const diffToMonday = 1 - dayOfWeek; // Monday offset
          const diffToFriday = 5 - dayOfWeek; // Friday offset
  
          monday = new Date(currentDate);
          monday.setDate(currentDate.getDate() + diffToMonday);
  
          friday = new Date(currentDate);
          friday.setDate(currentDate.getDate() + diffToFriday);
      }
  
      // Format as YYYY-MM-DD
      const formatDate = (date) => date.toLocaleDateString('en-CA');
  
      return {
          monday: formatDate(monday),
          friday: formatDate(friday),
      };
    };

    const getNextWeekRange = () => {
      const currentDate = new Date();
      const dayOfWeek = currentDate.getDay(); // 0 (Sunday) to 6 (Saturday)
  
      let nextMonday, nextFriday;
  
      if (dayOfWeek === 0) { // If today is Sunday
          nextMonday = new Date(currentDate);
          nextMonday.setDate(currentDate.getDate() + 8); // Next Monday (1 + 7 days)
  
          nextFriday = new Date(nextMonday);
          nextFriday.setDate(nextMonday.getDate() + 4); // Move to next Friday
      } else if (dayOfWeek === 6) { // If today is Saturday
          nextMonday = new Date(currentDate);
          nextMonday.setDate(currentDate.getDate() + 9); // Next Monday (2 + 7 days)
  
          nextFriday = new Date(nextMonday);
          nextFriday.setDate(nextMonday.getDate() + 4); // Move to next Friday
      } else { // If today is a weekday (Monday-Friday)
          const diffToMonday = 1 - dayOfWeek; // Monday offset
          const diffToFriday = 5 - dayOfWeek; // Friday offset
  
          nextMonday = new Date(currentDate);
          nextMonday.setDate(currentDate.getDate() + diffToMonday + 7); // Next Monday
  
          nextFriday = new Date(currentDate);
          nextFriday.setDate(currentDate.getDate() + diffToFriday + 7); // Next Friday
      }
  
      // Format as YYYY-MM-DD
      const formatDate = (date) => date.toLocaleDateString('en-CA');
  
      return {
          monday: formatDate(nextMonday),
          friday: formatDate(nextFriday),
      };
    };

    const handleActiveDateChange = (e) => {
        const { name, value } = e.target;

        setActiveDatesInModal((prevDates) => {
        if (name === 'startDate') {
            return {
            ...prevDates,
            startDate: value,
            endDate: new Date(value) > new Date(prevDates.endDate) ? value : prevDates.endDate,
            };
        }
        if (name === 'endDate') {
            return {
            ...prevDates,
            endDate: value,
            };
        }
        return prevDates;
        });
    };

    const setToday = () => {
        setActiveDatesInModal({
        startDate: today,
        endDate: today,
        });
    };

    const setThisWeek = () => {
        const { monday, friday } = getWeekRange();
        setActiveDatesInModal({
        startDate: monday,
        endDate: friday,
        });
    };

  return (
    <Modal
      isOpen={activeDatesModal}
      toggle={toggleSetActiveDatesModal}
      scrollable
      style={{
        padding: "20px",
        maxWidth: "750px",
        borderRadius: "12px",
      }}
    >
      <ModalHeader toggle={toggleSetActiveDatesModal} style={{ borderBottom: "none" }} className="active-dates-modal-header">
        <h4 style={{ fontWeight: "bold", color: "#004aad", margin: "0px" }}>Set Active Dates</h4>
      </ModalHeader>
      <ModalBody>
        <Container>
          <Row>
            <Col md="6">
              <FormGroup>
                <Label for="startDate" style={{ fontWeight: "bold" }}>
                  Start Date
                </Label>
                {Array.isArray(activeDatesInModal.startDate) ? (
                  <div
                    style={{
                      borderRadius: "8px",
                      border: "1px solid #ccc",
                      padding: "9px 10px",
                      color: "#66615b",
                      fontSize: "14px",
                      backgroundColor: "rgb(215,215,215)",
                    }}
                  >
                    {activeDatesInModal.startDate.length === 5 ? "Everday" : activeDatesInModal.startDate.length > 0 ? activeDatesInModal.startDate.join(", ") : "No Days Selected"}
                  </div>
                ) : (
                  <Input
                    id="startDate"
                    name="startDate"
                    type="date"
                    onChange={handleActiveDateChange}
                    value={activeDatesInModal.startDate}
                    style={{
                      borderRadius: "8px",
                      border: "1px solid #ccc",
                    }}
                  />
                )}
              </FormGroup>
            </Col>
            <Col md="6">
              <FormGroup>
                <Label for="endDate" style={{ fontWeight: "bold" }}>
                  End Date
                </Label>
                {Array.isArray(activeDatesInModal.startDate) ? (
                  <div
                    style={{
                      borderRadius: "8px",
                      border: "1px solid #ccc",
                      padding: "9px 10px",
                      color: "#66615b",
                      fontSize: "14px",
                      backgroundColor: "rgb(215,215,215)",
                    }}
                  >
                    {activeDatesInModal.startDate.length === 5 ? "Everday" : activeDatesInModal.startDate.length > 0 ? activeDatesInModal.startDate.join(", ") : "No Days Selected"}
                  </div>
                ) : (
                  <Input
                    id="endDate"
                    name="endDate"
                    type="date"
                    onChange={handleActiveDateChange}
                    value={activeDatesInModal.endDate}
                    style={{
                      borderRadius: "8px",
                      border: "1px solid #ccc",
                    }}
                  />
                )}
              </FormGroup>
            </Col>
          </Row>
          <Row className="justify-content-center my-4">
            <Col md="12">
                <FormGroup>
                <Label style={{ fontWeight: "bold", marginBottom: '0px' }}>Select Date Ranges</Label>
                    <div className="d-flex justify-content-center gap-2">
                    <Button
                        color="primary"
                        onClick={setToday}
                        className="me-2"
                        style={{
                            padding: "10px 20px",
                            borderRadius: "20px",
                            fontSize: ".9rem",
                            fontWeight: "bold",
                        }}
                    >
                    Today
                    </Button>
                    <Button
                        color="secondary"
                        onClick={setThisWeek}
                        style={{
                            padding: "10px 20px",
                            borderRadius: "20px",
                            fontSize: ".9rem",
                            fontWeight: "bold",
                        }}
                    >
                    This Week
                    </Button>
                    <Button
                        color="info"
                        onClick={() => {
                        const { monday, friday } = getNextWeekRange();
                        setActiveDatesInModal({
                            startDate: monday,
                            endDate: friday,
                        });
                        }}
                        style={{
                        padding: '10px 20px',
                        borderRadius: '20px',
                        fontSize: '.9rem',
                        fontWeight: 'bold',
                        }}
                    >
                        Next Week
                    </Button>
                    <Button
                        color="info"
                        onClick={() => {
                            setActiveDatesInModal((prevState) => {
                                return {...prevState, startDate: daysOfWeek}
                            });
                        }}
                        style={{
                        padding: '10px 20px',
                        borderRadius: '20px',
                        fontSize: '.9rem',
                        fontWeight: 'bold',
                        }}
                    >
                        Everyday
                    </Button>
                    </div>
                    </FormGroup>
                </Col>
          </Row>
          <Row>
            <Col md="12">
                <FormGroup>
                    <Label style={{ fontWeight: "bold", marginBottom: '0px' }}>Select Days</Label>
                    <div className="d-flex justify-content-center gap-2">
                    {daysOfWeek.map((day) => (
                        <Button
                        key={day}
                        onClick={() => toggleDaySelection(day)}
                        style={{
                            padding: "10px 10px",
                            borderRadius: "20px",
                            fontSize: ".9rem",
                            fontWeight: "bold",
                            backgroundColor: selectedDays.includes(day) ? "#004aad" : "#f8f9fa", // Change color if selected
                            color: selectedDays.includes(day) ? "white" : "#004aad", // Change text color
                            border: "1px solid #004aad", // Consistent border
                        }}
                        >
                        {day}
                        </Button>
                    ))}
                    </div>
                </FormGroup>
            </Col>
          </Row>
          <Row className="justify-content-center">
            <Col>
                <div
                style={{
                    textAlign: "center",
                    fontSize: "0.9rem",
                    color: "#555",
                }}
                >
                <a
                    href="https://lessonlaunchpad.com/faq#active-dates"
                    style={{
                        textDecoration: "none",
                        color: "#004aad",
                        fontWeight: "bold",
                    }}
                    onMouseEnter={(e) => (e.target.style.textDecoration = "underline")}
                    onMouseLeave={(e) => (e.target.style.textDecoration = "none")}
                >
                    What are Active Dates?
                </a>
                </div>
            </Col>
          </Row>
        </Container>
      </ModalBody>
      <div className="d-flex justify-content-center p-3">
        <Button
          onClick={() => saveActiveDates(activeDatesInModal)}
          color="success"
          style={{
            padding: "10px 30px",
            borderRadius: "30px",
            fontSize: "1.1rem",
            fontWeight: "bold",
          }}
        >
          Save Active Dates
        </Button>
      </div>
    </Modal>
  );
};

export default ActiveDatesModal;
