import { React, useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { selectUser } from "features/userSlice";
import { setDoc, doc } from "firebase/firestore";
import { db, storage } from "/Users/michaeltucker/Documents/coding-projects/client/src/firebase.js";
import ReactBSAlert from "react-bootstrap-sweetalert";
import { useLocation, useHistory } from 'react-router-dom';
import { createMusicSegmentId } from 'RandomId';
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
  UncontrolledTooltip,
  ModalHeader,
  Dropdown, DropdownToggle, DropdownMenu, DropdownItem, Label
} from "reactstrap";
import PlaylistModal from "components/PlaylistModal";
import centerMiddle from "/Users/michaeltucker/Documents/coding-projects/client/src/assets/img/center-middle.png";
import centerTop from "/Users/michaeltucker/Documents/coding-projects/client/src/assets/img/center-top.png";
import centerBottom from "/Users/michaeltucker/Documents/coding-projects/client/src/assets/img/center-bottom.png";
import leftBottom from "/Users/michaeltucker/Documents/coding-projects/client/src/assets/img/left-bottom.png";
import leftMiddle from "/Users/michaeltucker/Documents/coding-projects/client/src/assets/img/left-middle.png";
import leftTop from "/Users/michaeltucker/Documents/coding-projects/client/src/assets/img/left-top.png";
import rightBottom from "/Users/michaeltucker/Documents/coding-projects/client/src/assets/img/right-bottom.png";
import rightMiddle from "/Users/michaeltucker/Documents/coding-projects/client/src/assets/img/right-middle.png";
import rightTop from "/Users/michaeltucker/Documents/coding-projects/client/src/assets/img/right-top.png";
import fullScreen from "/Users/michaeltucker/Documents/coding-projects/client/src/assets/img/full-screen.png";
import UseAutoMusic from "AutoMusic";
import isScheduleNormal from "ScheduleChecker";

function AddEditMusicForm() {
  const location = useLocation();
  const { state } = location;
  const item = state?.segment;
  const user = useSelector(selectUser);
  const history = useHistory();
  const [validated, setValidated] = useState(false);
  const [multiWeek, setMultiWeek] = useState(user?.settings?.multiWeek || false);
  const [numberOfWeeks, setNumberOfWeeks] = useState(user?.settings?.numberOfWeeks || 2);
  const [differentTimes, setDifferentTimes] = useState(user?.settings?.differentTimes || false);
  const [currentWeek, setCurrentWeek] = useState("A");
  const [alert, setAlert] = useState(null);
  const [playlistDropdown, setPlaylistDropdown] = useState(false);
  const [addEditPlaylistModal, setAddEditPlaylistModal] = useState(false);
  const [playlistToEditId, setPlaylistToEditId] = useState()
  const [locationDropdownOpen, setLocationDropdownOpen] = useState(false);
  const [userForPreview, setUserForPreview] = useState(null);
  const [missingPlaylist, setMissingPlaylist] = useState(false);

  const toggleLocationDropdown = () => setLocationDropdownOpen((prevState) => !prevState);

  const togglePlaylistDropdown = () => setPlaylistDropdown((prevState) => !prevState);

  // const toggleAddEditPlaylistModal = (playlistId) => {
  //   if (playlistId) {
  //     setPlaylistToEditId(playlistId);
  //   }
  //   setAddEditPlaylistModal((prevState) => !prevState);
  // }

  // const toggleAddEditPlaylistModal = (playlistId = null) => {
  //   if (!playlistId) {
  //       setPlaylistToEditId(null); // ✅ Reset to ensure fresh data for new playlists
  //   } else {
  //       setPlaylistToEditId(playlistId);
  //   }
  //   setAddEditPlaylistModal((prevState) => !prevState);
  // };

  const toggleAddEditPlaylistModal = (playlistId = null) => {
    // I'm pretty sure ChatGPT misunderstood my request... Commenting for now...
    // if (!validatePlaylistExists()) {
    //     return; // 🚨 Don't allow modal to open if playlist is missing
    // }

    if (!playlistId) {
        setPlaylistToEditId(null);
    } else {
        setPlaylistToEditId(playlistId);
    }
    setAddEditPlaylistModal((prevState) => !prevState);
  };



  const modalOptions = [
    { value: "left-top", label: "Left-Top", image: leftTop },
    { value: "center-top", label: "Center-Top", image: centerTop },
    { value: "right-top-auto-music", label: "Right-Top", image: rightTop },
    { value: "left-middle", label: "Left-Middle", image: leftMiddle },
    { value: "center-middle", label: "Center-Middle", image: centerMiddle },
    { value: "right-middle-auto-music", label: "Right-Middle", image: rightMiddle },
    { value: "left-bottom", label: "Left-Bottom", image: leftBottom },
    { value: "center-bottom", label: "Center-Bottom", image: centerBottom },
    { value: "right-bottom-auto-music", label: "Right-Bottom", image: rightBottom },
    { value: "full-screen", label: "Fullscreen", image: fullScreen },
  ];

  function cycleWeeks(direction) {
    const weekLetterOptions = ["A", "B", "C", "D", "E", "F", "G"];
    let currentIndex = weekLetterOptions.indexOf(currentWeek);

    if (direction === "forward") {
      if (currentIndex === numberOfWeeks-1) {
        currentIndex = 0;
      } else {
        currentIndex++;
      }
    } else if (direction === "backward") {
      if (currentIndex === 0) {
        currentIndex = numberOfWeeks-1;
      } else {
        currentIndex--;
      }
    }
  
    setCurrentWeek(weekLetterOptions[currentIndex]);
  }

  const blankSchedule = {
    "A": {
      "Monday": {active: true, startTime: '00:00', endTime: '00:00'},
      "Tuesday": {active: true, startTime: '00:00', endTime: '00:00'},
      "Wednesday": {active: true, startTime: '00:00', endTime: '00:00'},
      "Thursday": {active: true, startTime: '00:00', endTime: '00:00'},
      "Friday": {active: true, startTime: '00:00', endTime: '00:00'},
      "Saturday": {active: true, startTime: '00:00', endTime: '00:00'},
      "Sunday": {active: true, startTime: '00:00', endTime: '00:00'},
    },
    "B": {
      "Monday": {active: true, startTime: '00:00', endTime: '00:00'},
      "Tuesday": {active: true, startTime: '00:00', endTime: '00:00'},
      "Wednesday": {active: true, startTime: '00:00', endTime: '00:00'},
      "Thursday": {active: true, startTime: '00:00', endTime: '00:00'},
      "Friday": {active: true, startTime: '00:00', endTime: '00:00'},
      "Saturday": {active: true, startTime: '00:00', endTime: '00:00'},
      "Sunday": {active: true, startTime: '00:00', endTime: '00:00'},
    },
    "C": {
      "Monday": {active: true, startTime: '00:00', endTime: '00:00'},
      "Tuesday": {active: true, startTime: '00:00', endTime: '00:00'},
      "Wednesday": {active: true, startTime: '00:00', endTime: '00:00'},
      "Thursday": {active: true, startTime: '00:00', endTime: '00:00'},
      "Friday": {active: true, startTime: '00:00', endTime: '00:00'},
      "Saturday": {active: true, startTime: '00:00', endTime: '00:00'},
      "Sunday": {active: true, startTime: '00:00', endTime: '00:00'},
    },
    "D": {
      "Monday": {active: true, startTime: '00:00', endTime: '00:00'},
      "Tuesday": {active: true, startTime: '00:00', endTime: '00:00'},
      "Wednesday": {active: true, startTime: '00:00', endTime: '00:00'},
      "Thursday": {active: true, startTime: '00:00', endTime: '00:00'},
      "Friday": {active: true, startTime: '00:00', endTime: '00:00'},
      "Saturday": {active: true, startTime: '00:00', endTime: '00:00'},
      "Sunday": {active: true, startTime: '00:00', endTime: '00:00'},
    },
    "E": {
      "Monday": {active: true, startTime: '00:00', endTime: '00:00'},
      "Tuesday": {active: true, startTime: '00:00', endTime: '00:00'},
      "Wednesday": {active: true, startTime: '00:00', endTime: '00:00'},
      "Thursday": {active: true, startTime: '00:00', endTime: '00:00'},
      "Friday": {active: true, startTime: '00:00', endTime: '00:00'},
      "Saturday": {active: true, startTime: '00:00', endTime: '00:00'},
      "Sunday": {active: true, startTime: '00:00', endTime: '00:00'},
    },
    "F": {
      "Monday": {active: true, startTime: '00:00', endTime: '00:00'},
      "Tuesday": {active: true, startTime: '00:00', endTime: '00:00'},
      "Wednesday": {active: true, startTime: '00:00', endTime: '00:00'},
      "Thursday": {active: true, startTime: '00:00', endTime: '00:00'},
      "Friday": {active: true, startTime: '00:00', endTime: '00:00'},
      "Saturday": {active: true, startTime: '00:00', endTime: '00:00'},
      "Sunday": {active: true, startTime: '00:00', endTime: '00:00'},
    },
    "G": {
      "Monday": {active: true, startTime: '00:00', endTime: '00:00'},
      "Tuesday": {active: true, startTime: '00:00', endTime: '00:00'},
      "Wednesday": {active: true, startTime: '00:00', endTime: '00:00'},
      "Thursday": {active: true, startTime: '00:00', endTime: '00:00'},
      "Friday": {active: true, startTime: '00:00', endTime: '00:00'},
      "Saturday": {active: true, startTime: '00:00', endTime: '00:00'},
      "Sunday": {active: true, startTime: '00:00', endTime: '00:00'},
    },
  };

  const [data, setData] = useState({
    duration: item?.duration || 0,
    active: item?.active === false ? false : item?.active === true ? true : true,
    name: item?.name || '',
    playlistId: item?.playlistId || '',
    schedule: item?.schedule || blankSchedule,
    segmentId: item?.segmentId || null,
    showVideo: item?.showVideo === false ? false : item?.active === true ? true : false,
    showVideoLocation: item?.showVideoLocation || 'center-middle',
    showVideoBackdrop: item?.showVideoBackdrop === false ? false : item?.showVideoBackdrop === true ? true : true,
  });

  useEffect(() => {
    return () => {
      let id = window.setTimeout(() => {}, 0);
      while (id > 0) {
        window.clearTimeout(id--);
      }
    };
  }, []);

  // const handleSubmit = async (e) => {
  //   e.preventDefault();

  //   try {
  //       // 🛑 Prevent saving if required fields are missing
  //       if (!data.playlistId) {
  //           window.alert("❌ Please select a playlist before saving.");
  //           return;
  //       }

  //       // 🔄 Clone user object to avoid direct mutations
  //       const docRef = doc(db, "users", user.uid);
  //       const userCopy = JSON.parse(JSON.stringify(user));

  //       // 🛑 Ensure `autoMusic` and `segments` exist
  //       if (!userCopy.autoMusic) {
  //           userCopy.autoMusic = { playlists: [], segments: [] };
  //       }

  //       if (!userCopy.autoMusic.segments) {
  //           userCopy.autoMusic.segments = [];
  //       }

  //       // ✅ Create new segment structure
  //       const newSegment = {
  //           duration: data.duration,
  //           name: data.name, // Playlist name
  //           playlistId: data.playlistId, // Playlist ID
  //           showVideo: data.showVideo ?? false,
  //           showVideoLocation: data.showVideoLocation,
  //           showVideoBackdrop: data.showVideoBackdrop,
  //           active: data.active ?? true,
  //           schedule: data.schedule,
  //           segmentId: createMusicSegmentId()
  //       };

  //       // 🔍 Check if segment already exists
  //       const existingSegmentIndex = userCopy.autoMusic.segments.findIndex(
  //           (segment) => segment.segmentId === data.segmentId
  //       );

  //       if (existingSegmentIndex !== -1) {
  //           // ✅ Update existing segment
  //           userCopy.autoMusic.segments[existingSegmentIndex] = newSegment;
  //       } else {
  //           // ✅ Add new segment
  //           userCopy.autoMusic.segments.push(newSegment);
  //       }

  //       // 📝 Save to Firestore
  //       await setDoc(docRef, userCopy);

  //       console.log("✅ Successfully saved:", userCopy);

  //       // 🎉 Show success alert
  //       successAlert();

  //   } catch (error) {
  //       console.error("❌ Error updating document:", error);
  //       window.alert("❌ Error updating document: " + error.message);
  //   }
  // };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // 🚨 Check if playlist is valid
    if (!validatePlaylistExists()) {
        return;
    }

    try {
        const docRef = doc(db, "users", user.uid);
        const userCopy = JSON.parse(JSON.stringify(user));

        if (!userCopy.autoMusic) {
            userCopy.autoMusic = { playlists: [], segments: [] };
        }

        if (!userCopy.autoMusic.segments) {
            userCopy.autoMusic.segments = [];
        }

        const newSegment = {
            duration: data.duration,
            name: data.name,
            playlistId: data.playlistId,
            showVideo: data.showVideo ?? false,
            showVideoLocation: data.showVideoLocation,
            showVideoBackdrop: data.showVideoBackdrop,
            active: data.active ?? true,
            schedule: data.schedule,
            segmentId: data.segmentId || createMusicSegmentId()
        };

        const existingSegmentIndex = userCopy.autoMusic.segments.findIndex(
            (segment) => segment.segmentId === data.segmentId
        );

        if (existingSegmentIndex !== -1) {
            userCopy.autoMusic.segments[existingSegmentIndex] = newSegment;
        } else {
            userCopy.autoMusic.segments.push(newSegment);
        }

        await setDoc(docRef, userCopy);

        console.log("✅ Successfully saved:", userCopy);
        successAlert();

    } catch (error) {
        console.error("❌ Error updating document:", error);
        window.alert("❌ Error updating document: " + error.message);
    }
  };

  const potentialInvalidScheduleAlert = (e) => {
    const event = e;
    setAlert(
      <ReactBSAlert
        warning
        style={{ display: "block", marginTop: "-100px" }}
        title="Are you sure?"
        onConfirm={(e) => (data.duration < 10) ? durationSetToZeroAlert(event) : handleSubmit(event)}
        onCancel={() => hidePotentialInvalidScheduleAlert()}
        confirmBtnBsStyle="info"
        confirmBtnStyle={{borderRadius: '30px', backgroundColor: 'rgb(11, 192, 223)'}}
        cancelBtnBsStyle="danger"
        cancelBtnStyle={{borderRadius: '30px', backgroundColor: '#fa5656'}}
        confirmBtnText="Continue with Save!"
        cancelBtnText="Let me check again."
        showCancel
        btnSize=""
      >
        It appears as though your scheduled start time(s) for this music segment aren't typical.
      </ReactBSAlert>
    );
  }

  const durationSetToZeroAlert = (e) => {
    const event = e;
    setAlert(
      <ReactBSAlert
        warning
        style={{ display: "block", marginTop: "-100px" }}
        title="Are you sure?"
        onConfirm={(e) => handleSubmit(event)}
        onCancel={() => hideDurationSetToZeroAlert()}
        confirmBtnBsStyle="info"
        confirmBtnStyle={{borderRadius: '30px', backgroundColor: 'rgb(11, 192, 223)'}}
        cancelBtnBsStyle="danger"
        cancelBtnStyle={{borderRadius: '30px', backgroundColor: '#fa5656'}}
        confirmBtnText="Continue with Save!"
        cancelBtnText="Let me check again."
        showCancel
        btnSize=""
      >
        {`Your duration is set to ${data.duration}, which means your music will only play for ${data.duration} seconds.`}
      </ReactBSAlert>
    );
  }

  const successAlert = () => {
    setAlert(
      <ReactBSAlert
        success
        style={{ display: "block", marginTop: "-100px" }}
        title="Music Segment Saved!"
        onConfirm={() => hideAlert()}
        onCancel={() => hideAlert()}
        confirmBtnBsStyle="info"
        btnSize=""
      ></ReactBSAlert>
    );
  };

  const hidePotentialInvalidScheduleAlert = () => {
    setAlert(null);
  }

  const hideDurationSetToZeroAlert = (e) => {
    setAlert(null);
  }

  const hideAlert = () => {
    setAlert(null);
    setMissingPlaylist(true);
  };

  // ✅ Disable Save Button when playlist is missing
  const isSaveDisabled = missingPlaylist || !data.playlistId;

  const userModelForPreview = data && user && {
    settings: {
      multiWeek: false,
    },
    autoMusic: {
      playlists: user.autoMusic?.playlists,
      segments: [
        {
          duration: data.duration,
          name: data.name, // Playlist name
          playlistId: data.playlistId, // Playlist ID
          showVideo: data.showVideo ?? false,
          showVideoLocation: data.showVideoLocation,
          showVideoBackdrop: data.showVideoBackdrop,
          active: data.active ?? true,
          schedule: {
            "A": {
                "Monday": { "active": true, "startTime": "00:27", "endTime": "00:00" },
                "Tuesday": { "active": true, "startTime": "00:27", "endTime": "00:00" },
                "Wednesday": { "active": true, "startTime": "00:27", "endTime": "00:00" },
                "Thursday": { "active": true, "startTime": "00:27", "endTime": "00:00" },
                "Friday": { "active": true, "startTime": "00:27", "endTime": "00:00" },
                "Saturday": { "active": true, "startTime": "19:41", "endTime": "00:00" },
                "Sunday": { "active": true, "startTime": "04:10", "endTime": "00:00" }
              }
          }
        },
      ]
    }
  } || {};

  function getTimePlusSecondsForPreview() {
    const now = new Date();
    now.setSeconds(now.getSeconds() + 1); // Add 1 seconds

    // Format to HH:MM:SS
    const formattedTime = now.toLocaleTimeString('en-US', {
        hour12: false, 
        hour: '2-digit', 
        minute: '2-digit', 
        second: '2-digit'
    });

    return formattedTime;
  }

  function updateStartTimes(obj) {
    const updatedObj = JSON.parse(JSON.stringify(obj)); // Deep clone to avoid mutating original object
    const newStartTime = getTimePlusSecondsForPreview(); // Get new time

    function traverseAndUpdate(node) {
        if (typeof node === "object" && node !== null) {
            for (const key in node) {
                if (key === "startTime") {
                    node[key] = newStartTime; // Update startTime
                } else {
                    traverseAndUpdate(node[key]); // Recurse deeper
                }
            }
        }
    }

    traverseAndUpdate(updatedObj);
    return updatedObj;
  }

  const launchPreview = () => {
    const updatedUserModel = updateStartTimes(userModelForPreview);
    setUserForPreview(updatedUserModel);
  }

  const handleSingleStartTime = (e) => {
    const startTime = e.target.value;
    const updatedSchedule = JSON.parse(JSON.stringify(data.schedule));

    for (const group in updatedSchedule) {
      for (const day in updatedSchedule[group]) {
        updatedSchedule[group][day] = { ...updatedSchedule[group][day], startTime };
      }
    }

    setData({ ...data, schedule: updatedSchedule, startTime: startTime });
  };

  const handleFormNonDays = (e) => {
    const { id, value, type, checked } = e.target;

    if (type === 'checkbox') {
      setData((prevData) => ({
        ...prevData,
        [id]: checked,
      }));
    } else {
      setData((prevData) => ({
        ...prevData,
        [id]: value,
      }));
    }
  };

  const handleScheduleChange = (week, day, field, value) => {
    const updatedSchedule = JSON.parse(JSON.stringify(data.schedule));
    updatedSchedule[week][day][field] = value;
    setData((prevData) => ({
      ...prevData,
      schedule: updatedSchedule,
    }));
  };

  const handleDurationChange = (e) => {
    const { id, value } = e.target;
    const parsedValue = Math.max(0, Number(value)); // Ensure non-negative number
  
    setData((prevData) => {
      let newDuration = prevData.duration || 0; // Default to 0 if undefined
  
      if (id === "minutes") {
        newDuration = parsedValue * 60 + (prevData.duration % 60); // Update minutes
      } else if (id === "seconds") {
        newDuration = Math.floor(prevData.duration / 60) * 60 + parsedValue; // Update seconds
      }
  
      return { ...prevData, duration: newDuration };
    });
  };

  const handlePlaylistSelect = (playlistName, playlistId) => {
    setData((prevData) => ({
      ...prevData,
      name: playlistName,
      playlistId: playlistId
    }));
  }

  const handleVideoLocationSelect = (location) => {
    setData((prevData) => ({
      ...prevData,
      showVideoLocation: location,
    }));
  }

  /**
   * This is to add the new playlist to the new music segment automatically so that way the user doesn't have to go select
   * the new playlist they just added from the playlist dropdown. This does it automatically.
   * 
   * @param newPlaylist - The new playlist from the Add Playlist Modal.
   */
  const onChangeToAddNewPlaylistToMusicSegment = (newPlaylist) => {
    setData((prevData) => ({
      ...prevData,
      playlistId: newPlaylist.playlistId,
      name: newPlaylist.name
    }));
  }

//   const validatePlaylistExists = () => {
//     // Get the list of valid playlists
//     const validPlaylistIds = user?.autoMusic?.playlists?.map(playlist => playlist.playlistId) || [];

//     // Check if the selected playlist exists
//     if (!validPlaylistIds.includes(data.playlistId)) {
//         setAlert(
//             <ReactBSAlert
//                 warning
//                 style={{ display: "block", marginTop: "-100px" }}
//                 title="Playlist Missing!"
//                 onConfirm={() => hideAlert()}
//                 onCancel={() => hideAlert()}
//                 confirmBtnBsStyle="danger"
//                 confirmBtnStyle={{ borderRadius: "30px", backgroundColor: "#fa5656" }}
//                 confirmBtnText="Close"
//                 btnSize=""
//             >
//                 Please select a playlist or add a new one for this music segment.
//             </ReactBSAlert>
//         );
//         return false; // Prevent action
//     }

//     return true; // Playlist is valid
// };

const validatePlaylistExists = () => {
  // Get the list of valid playlists
  const validPlaylistIds = user?.autoMusic?.playlists?.map(playlist => playlist.playlistId) || [];

  // Check if the selected playlist exists
  if (!validPlaylistIds.includes(data.playlistId)) {
      setMissingPlaylist(true); // 🛑 Track that the playlist is missing

      setAlert(
          <ReactBSAlert
              warning
              style={{ display: "block", marginTop: "-100px" }}
              title="Playlist Missing!"
              onConfirm={hideAlert} // ⬅️ No need to pass function reference
              onCancel={hideAlert}
              confirmBtnBsStyle="danger"
              confirmBtnStyle={{ borderRadius: "30px", backgroundColor: "#fa5656" }}
              confirmBtnText="OK"
              btnSize=""
          >
            Please select a playlist or add a new one for this music segment.
          </ReactBSAlert>
      );

      return false; // Prevent further actions
  }



  return true; // ✅ Playlist is valid
};



  const daysOfWeek = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday"];

  const ScheduleCheckboxes = ({ schedule, handleScheduleChange, week }) => (
    <Row style={{ margin: "10px 0px", display: "flex", justifyContent: "space-evenly" }}>
      {daysOfWeek.map((day) => (
        <Col key={day} style={{ display: "flex", alignItems: "center", flexDirection: "column" }}>
          <Label for={day.toLowerCase()} style={{ fontSize: "14px", fontWeight: "bold" }}>{day}</Label>
          <FormGroup style={{ display: "flex", alignItems: "center" }}>
            <Input
              onChange={(e) => handleScheduleChange(week, day, "active", e.target.checked)}
              type="checkbox"
              id={day.toLowerCase()}
              checked={schedule?.[week]?.[day]?.active || false}
              style={{
                transform: "scale(2)",
                position: "initial",
                marginLeft: "0px",
              }}
            />
          </FormGroup>
        </Col>
      ))}
    </Row>
  );

  const WeekSelectorForMultiWeek = () => (
    <Row
      style={{
        marginTop: "30px",
        marginBottom: "10px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <i
        className="nc-icon nc-minimal-left"
        style={{ cursor: "pointer", fontSize: "20px" }}
        onClick={() => cycleWeeks("backward")}
        onMouseEnter={(e) => (e.currentTarget.style.transform = "scale(1.12)")}
        onMouseLeave={(e) => (e.currentTarget.style.transform = "scale(1)")}
      />
      <h3
        style={{
          marginLeft: "15px",
          marginRight: "15px",
          marginBottom: "0px",
          fontWeight: "bold", 
          color: "#004aad"
        }}
      >
        Week {currentWeek}
      </h3>
      <i
        className="nc-icon nc-minimal-right"
        style={{ cursor: "pointer", fontSize: "20px" }}
        onClick={() => cycleWeeks("forward")}
        onMouseEnter={(e) => (e.currentTarget.style.transform = "scale(1.12)")}
        onMouseLeave={(e) => (e.currentTarget.style.transform = "scale(1)")}
      />
    </Row>
  )

  const ScheduleRow = ({ week, day, handleScheduleChange, data }) => (
    <Row style={{ marginLeft: "10px", display: "flex", justifyContent: "space-evenly" }}>
      {/* Checkbox for Active Days */}
      <Col md="3"></Col>
      <Col
        md="2"
        style={{ display: "flex", alignItems: "center", flexDirection: "column" }}
      >
        <label id={day.toLowerCase()} style={{ fontSize: "14px" }}>
          {day}
        </label>
        <FormGroup style={{ display: "flex", alignItems: "center" }}>
          <Input
            onChange={(e) => handleScheduleChange(week, day, "active", e.target.checked)}
            type="checkbox"
            id={day.toLowerCase()}
            checked={data.schedule[week]?.[day]?.active || false}
            style={{ transform: "scale(2)", position: "initial", marginLeft: "0px", marginTop: '14px' }}
          />
        </FormGroup>
      </Col>
  
      {/* Start Time Input */}
      <Col md="4">
        <label id="timeTip" style={{ fontSize: "14px" }}>
          Start Time
        </label>
        <FormGroup>
          <Input
            onChange={(e) => handleScheduleChange(week, day, "startTime", e.target.value)}
            type="time"
            id="startTime"
            style={{borderRadius: '8px'}}
            value={
              data.schedule[week]?.[day]?.active
                ? data.schedule[week]?.[day]?.startTime || ""
                : ""
            }
            step="1"  // Allows seconds input
          />
        </FormGroup>
      </Col>
      <Col md="3"></Col>
    </Row>
  );
  
  

  return (
    <>
      <div className="content">
        <Row style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', minHeight: '100vh' }}>
          <Col md="8">
            <Card style={{ padding: '20px' }}>
              <ModalHeader className="add-edit-form-close-toggle" toggle={() =>  history.goBack()} style={{border: 'none', padding: '0px'}}>
                <h4 style={{ fontWeight: "bold", color: "#004aad", margin: "0px" }}>
                  {item === undefined ? "Add Music" : "Edit Music"}
                </h4>
              </ModalHeader>
              <CardBody>
                <Form className="form-horizontal" noValidate validated={validated}>
                  <Row style={{ marginTop: "30px", display: 'flex', justifyContent: 'space-evenly' }}>
                    <Col md="2" style={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
                        <label id="activeTipAutoMusicForm" style={{ fontSize: '14px', fontWeight: "bold" }}>Active</label>
                        <FormGroup>
                          <UncontrolledTooltip delay={0} target={"activeTipAutoMusicForm"} placement="top">
                            If checked, this music segment will start at the time requested.
                          </UncontrolledTooltip>
                          <Input
                            onChange={(e) => handleFormNonDays(e)}
                            type="checkbox"
                            id="active"
                            checked={data.active}
                            defaultChecked={true}
                            style={{
                              marginLeft: '-5px',
                              marginTop: '12px',
                              transform: 'scale(2)'
                            }}
                          />
                        </FormGroup>
                      </Col>
                    {!differentTimes && (
                      <Col md="3">
                        <UncontrolledTooltip delay={0} target={"startTimeAutoMusicForm"} placement="top">
                          This is the time you want the music segment to start playing.
                        </UncontrolledTooltip>
                        <label id="startTimeAutoMusicForm" style={{ fontSize: '14px', display: 'flex', alignItems: 'center', flexDirection: 'column', fontWeight: "bold" }}>Start Time</label>
                        <FormGroup>
                          <Input
                            required
                            onChange={(e) => handleSingleStartTime(e)}
                            type="time"
                            id="startTime"
                            value={data.schedule["A"]["Monday"].startTime}
                            step="1"  // Allows seconds input
                            style={{ borderRadius: '8px' }}
                          />                        
                        </FormGroup>
                      </Col>
                    )}
                    <UncontrolledTooltip delay={0} target={"durationAutoMusicForm"} placement="top">
                      This is how long you want the music to play for. The music will automatically stop after this length of time.
                    </UncontrolledTooltip>
                    <Col md="4">
                      <label id="durationAutoMusicForm" style={{ fontSize: '14px', display: 'flex', alignItems: 'center', flexDirection: 'column', fontWeight: "bold" }}>Duration</label>
                      <FormGroup>
                        <div style={{ display: "flex", alignItems: "center", justifyContent: 'center', gap: "15px" }}>
                          {/* Minutes Input */}
                          <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
                            <Input 
                              required 
                              onChange={handleDurationChange} 
                              type="number" 
                              min={0} 
                              max={30} 
                              id="minutes" 
                              value={Math.floor(Number(data.duration) / 60)}  // ✅ Convert total seconds to minutes
                              style={{ borderRadius: "8px", width: "60px", textAlign: "center" }}
                            />
                            <span style={{ fontSize: "10px", fontWeight: "lighter" }}>minutes</span>
                          </div>

                          {/* Seconds Input */}
                          <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
                            <Input 
                              required 
                              onChange={handleDurationChange} 
                              type="number" 
                              min={0} 
                              max={59} 
                              id="seconds" 
                              value={Number(data.duration) % 60}  // ✅ Get the remaining seconds
                              style={{ borderRadius: "8px", width: "60px", textAlign: "center" }}
                            />
                            <span style={{ fontSize: "10px", fontWeight: "lighter" }}>seconds</span>
                          </div>
                        </div>
                      </FormGroup>
                    </Col>
                    <UncontrolledTooltip delay={0} target={"playlistAutoMusicForm"} placement="top">
                      This is the playlist of songs that this music segment will use.
                    </UncontrolledTooltip>
                    <Col md="3">
                      <label id="playlistAutoMusicForm" style={{ fontSize: '14px', display: 'flex', alignItems: 'center', flexDirection: 'column', fontWeight: "bold" }}>Playlist</label>
                      <Dropdown isOpen={playlistDropdown} toggle={togglePlaylistDropdown} direction="start">
                        <DropdownToggle 
                          caret 
                          style={{ 
                            borderRadius: "8px", 
                            width: "100%", 
                            margin: '0px', 
                            backgroundColor: 'white',
                            border: '1px solid #DDDDDD',
                            color: '#66615b',
                          }}
                        >
                          {user?.autoMusic?.playlists?.find((playlist) => playlist.playlistId === data.playlistId)?.name || "Select/Add"}
                        </DropdownToggle>
                        <DropdownMenu style={{height: '400px', overflowY: 'auto', borderRadius: '8px'}} className="font-size-picker-menu">
                          <DropdownItem onClick={() => toggleAddEditPlaylistModal()}>
                            <i id="removeNames" name="removeNames" style={{ marginRight: '5px' }} className="fa fa-plus" />
                            {' '}Add Playlist
                          </DropdownItem>
                          <DropdownItem divider />
                          {user?.autoMusic?.playlists?.map(list => (
                            <DropdownItem key={list.playlistId} onClick={(e) => handlePlaylistSelect(list.name, list.playlistId)} style={{paddingRight: '5px', borderRadius: '0px'}}>
                              <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
                                <span>{list.name}</span>
                                <UncontrolledTooltip delay={0} target={"playlistEditAutoMusicForm"} placement="top">
                                  Click to edit this playlist.
                                </UncontrolledTooltip>
                                <Button
                                  // className="btn-round btn-icon"
                                  id="playlistEditAutoMusicForm"
                                  type="button"
                                  style={{ background: 'white', color: 'rgb(11, 192, 223)', position: 'absolute', right: '5px', borderRadius: '30px', height: '30px', width: '30px', padding: '6px 10px', zIndex: '1049', overflow: 'visible'}}
                                  size="sm"
                                  onClick={() => {toggleAddEditPlaylistModal(list.playlistId)}}
                                >
                                  <i
                                    id="editPlaylistIcon"
                                    name="editPlaylist"
                                    style={{ marginLeft: 'auto', marginRight: '5px', cursor: 'pointer' }}
                                    className="fa fa-pencil"
                                  />
                                </Button>
                              </div>
                            </DropdownItem>
                          ))}
                        </DropdownMenu>
                      </Dropdown>
                    </Col>
                  </Row>
                  <hr style={{marginBottom: '20px'}}></hr>
                  <Row style={{ marginTop: "30px", display: 'flex', justifyContent: 'space-evenly' }}>
                    <Col md="4" style={{ display: 'flex', alignItems: 'center', flexDirection: 'column', marginTop: '12px' }}>
                      <label id="showVideoTipAutoMusicForm" style={{ fontSize: '14px', fontWeight: "bold", transform: 'translateY(-12px)' }}>Show Video</label>
                      <FormGroup>
                        <UncontrolledTooltip delay={0} target={"showVideoTipAutoMusicForm"} placement="top">
                          If checked, the YouTube video for these songs will play on your screen.
                        </UncontrolledTooltip>
                        <Input
                          onChange={(e) => handleFormNonDays(e)}
                          type="checkbox"
                          id="showVideo"
                          checked={data.showVideo}
                          defaultChecked={false}
                          style={{
                            marginLeft: '-5px',
                            marginTop: '20px',
                            transform: 'scale(2) translateY(-10px)'
                          }}
                        />
                      </FormGroup>
                    </Col>
                    <Col md="4" style={{ display: 'flex', alignItems: 'center', flexDirection: 'column', marginTop: '12px' }}>
                      <label id="backdropAutoMusicForm" style={{ fontSize: '14px', fontWeight: "bold", transform: 'translateY(-12px)' }}>Show Backdrop</label>
                      <FormGroup>
                        <UncontrolledTooltip delay={0} target={"backdropAutoMusicForm"} placement="top">
                          If checked, the rest of the screen behind your video will be darker.
                        </UncontrolledTooltip>
                        <Input
                          disabled={!data.showVideo}
                          onChange={(e) => handleFormNonDays(e)}
                          type="checkbox"
                          id="showVideoBackdrop"
                          checked={data.showVideoBackdrop}
                          defaultChecked={true}
                          style={{
                            marginLeft: '-5px',
                            marginTop: '20px',
                            transform: 'scale(2) translateY(-10px)'
                          }}
                        />
                      </FormGroup>
                    </Col>
                    <Col md="4" style={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
                    <label id="locationTipAutoMusicForm" style={{ fontSize: '14px', display: 'flex', alignItems: 'center', flexDirection: 'column', fontWeight: "bold" }}>Location</label>
                        <UncontrolledTooltip delay={0} target={"locationTipAutoMusicForm"} placement="top">
                          This is the location on the screen where your video will show.
                        </UncontrolledTooltip>
                        <Dropdown disabled={!data.showVideo} isOpen={locationDropdownOpen} toggle={toggleLocationDropdown} style={{ width: "200px" }}>
                        <DropdownToggle disabled={!data.showVideo}
                          caret 
                          style={{ 
                            borderRadius: "8px", 
                            width: "100%", 
                            margin: '0px', 
                            backgroundColor: 'white',
                            border: '1px solid #DDDDDD',
                            color: '#66615b',
                          }}
                        >
                          {data.showVideoLocation || "Select Position"}
                        </DropdownToggle>
                        <DropdownMenu className="countdown-timer-modal-location-picker" disabled={!data.showVideo}> 
                          {modalOptions.map((option) => (
                            <DropdownItem
                              key={option.value} 
                              value={option.value}
                              onClick={() => handleVideoLocationSelect(option.value)} 
                              style={{ 
                                display: "flex", 
                                flexDirection: "column",
                                alignItems: "center", 
                                justifyContent: "center", 
                                padding: "2px 10px 5px 10px", 
                                textAlign: "center" 
                              }}
                            >
                              <span style={{ fontSize: "14px", fontWeight: "bold", marginBottom: "5px" }}>
                                {option.label}
                              </span>
                              
                              {locationDropdownOpen && ( // ✅ Only render images when dropdown is open to avoid page height issue
                                <img
                                  src={option.image}
                                  alt={option.label}
                                  style={{ width: "auto", height: "85px" }}
                                />
                              )}
                              
                            </DropdownItem>
                          ))}
                        </DropdownMenu>
                      </Dropdown>
                    </Col>
                  </Row>
                  <hr></hr>
                { differentTimes && !multiWeek && (
                  <>
                    {daysOfWeek.map((day) => (
                      <ScheduleRow 
                        key={day} 
                        week="A" 
                        day={day} 
                        handleScheduleChange={handleScheduleChange} 
                        data={data} 
                      />
                    ))}
                  </>
                )}

                {differentTimes && multiWeek && (
                  <>
                    <WeekSelectorForMultiWeek />
                    {/* Days of the Week Selection with Start Time Input */}
                      {daysOfWeek.map((day) => (
                        <ScheduleRow 
                          key={day} 
                          week={currentWeek} 
                          day={day} 
                          handleScheduleChange={handleScheduleChange} 
                          data={data} 
                        />
                      ))}
                  </>
                )}


                {!multiWeek && !differentTimes && <ScheduleCheckboxes schedule={data.schedule} handleScheduleChange={handleScheduleChange} week="A" />}

                {multiWeek && !differentTimes && (
                  <>
                    <WeekSelectorForMultiWeek />
                    <ScheduleCheckboxes schedule={data.schedule} handleScheduleChange={handleScheduleChange} week={currentWeek} />             
                  </>
                )}

                </Form>
              </CardBody>
              {/* {console.log(`duration => ${data.duration}, playlistId => ${data.playlistId}`)} */}
              <CardFooter style={{display: 'flex', alignContent: 'center', justifyContent: 'center', marginTop: '20px'}}>
                {(data.duration !== 0 && data.playlistId !== '') && (
                <Button 
                  className="btn-round"
                  color="info"
                  type="submit"
                  disabled={data.duration === 0 || data.playlistId === ''}
                  onClick={() => validatePlaylistExists() ? launchPreview() : null}
                  onMouseEnter={(e) => (e.currentTarget.style.transform = "scale(1.03)")}
                  onMouseLeave={(e) => (e.currentTarget.style.transform = "scale(1)")}
                  style={{
                    padding: "10px 30px",
                    borderRadius: "30px",
                    fontSize: "1.1rem",
                    fontWeight: "bold",
                    margin: '0px 10px',
                    backgroundColor: '#28C76F'
                  }}>
                  <i className="fa fa-play" />{' '}
                  Preview
                </Button>
                )}
                <Button
                  className="btn-round"
                  color="info"
                  type="submit"
                  onClick={(e) => (isScheduleNormal(data.schedule) && data.duration >= 10) ? handleSubmit(e) : isScheduleNormal(data.schedule) === false ? potentialInvalidScheduleAlert(e) : data.duration < 10 ? durationSetToZeroAlert(e) : null}
                  onMouseEnter={(e) => (e.currentTarget.style.transform = "scale(1.03)")}
                  onMouseLeave={(e) => (e.currentTarget.style.transform = "scale(1)")}
                  // disabled={isSaveDisabled} // ✅ Prevents save if playlist is missing
                  style={{
                    padding: "10px 30px",
                    borderRadius: "30px",
                    fontSize: "1.1rem",
                    fontWeight: "bold",
                    margin: '0px 10px'
                  }}>
                    <i className='fa fa-save' />
                    {' '}Save
                </Button>
              </CardFooter>
            </Card>
            {alert}
          </Col>
        </Row>
      </div>
      <PlaylistModal showPlaylistModal={addEditPlaylistModal} togglePlaylistModal={toggleAddEditPlaylistModal} playlistToEditId={playlistToEditId} onChangeToAddNewPlaylistToMusicSegment={onChangeToAddNewPlaylistToMusicSegment}/>
      {userForPreview && data && <UseAutoMusic userFromPreview={userForPreview} setUserForPreview={setUserForPreview}/>}
    </>
  );
}

export default AddEditMusicForm;
