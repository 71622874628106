export function createRandomId() {
    return "id" + Math.random().toString(16).slice(2)
}

export function createShareCode() {
  const letters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
  const numbers = '0123456789';
  let result = '';

  for (let i = 0; i < 6; i++) {
    if (i % 2 === 0) {
      // Even index, add a letter
      const randomIndex = Math.floor(Math.random() * letters.length);
      result += letters[randomIndex];
    } else {
      // Odd index, add a number
      const randomIndex = Math.floor(Math.random() * numbers.length);
      result += numbers[randomIndex];
    }
  }

  return result;
}

export function createPlaylistId() {
  return "playlistId_" + Math.random().toString(36).slice(2);
}

export function createMusicSegmentId() {
  return "musicSegmentId_" + Math.random().toString(36).slice(2);
}
  