import React, { useState } from "react";
import ReactDatetime from "react-datetime";
import { Button, UncontrolledTooltip } from "reactstrap";

const ActiveDatesDatePicker = ({
  date,
  showDatePicker,
  setShowDatePicker,
  handleDateSet,
  handleDateBackward,
  handleDateForward,
  noActiveBoxesAlert,
  noBoxesSetToShowAlert
}) => {
  return (
    <>
      <Button
        color="secondary"
        size="md"
        type="button"
        onClick={handleDateBackward}
        className="date-picker-button left"
      >
        <i className="nc-icon nc-minimal-left" />
      </Button>

      {showDatePicker ? (
        <div style={{backgroundColor: 'rgb(11, 192, 223)', width: '10vw', height: '45px'}}>
            <ReactDatetime
                open
                initialValue={date}
                className="present-date-picker"
                timeFormat={false}
                closeOnSelect
                closeOnClickOutside={true}
                onClose={() => setShowDatePicker(!showDatePicker)}
                onChange={(e) => handleDateSet(e)}
                inputProps={{ placeholder: "Time Picker Here" }}
            />
        </div>
      ) : (
        <div
          className={`date-display ${
            noActiveBoxesAlert || noBoxesSetToShowAlert ? "no-active-boxes-alert" : ""
          }`}
          onClick={() => setShowDatePicker(!showDatePicker)}
        >
          <div className="day-name">{date.dayName}</div>
          <div className={`date-string ${date.dateString.length > 19 ? "small-text" : ""}`}>
            {date.dateString}
          </div>
        </div>
      )}

      <Button
        color="secondary"
        size="md"
        type="button"
        onClick={handleDateForward}
        className="date-picker-button right"
      >
        <i className="nc-icon nc-minimal-right" />
      </Button>
    </>
  );
};

export default ActiveDatesDatePicker;
