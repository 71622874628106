import {React, useState} from "react";
import {NavLink, Route} from "react-router-dom";
import ReactBSAlert from "react-bootstrap-sweetalert";
import { useSelector } from "react-redux";
import { selectUser } from "features/userSlice";
import { setDoc, doc} from "firebase/firestore";
import { db } from "/Users/michaeltucker/Documents/coding-projects/client/src/firebase.js";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  Table,
  Row,
  Col,
  UncontrolledTooltip,
  CardSubtitle,
  Badge
} from "reactstrap";

function AutoReminderTable({props}) {

    const [alert, setAlert] = useState(null);
    const user = useSelector(selectUser);
    const [multiWeek, setMultiWeek] = useState(user.settings.multiWeek)
    const [numberOfWeeks, setNumberOfWeeks] = useState(user.settings.numberOfWeeks || 2)
    const [differentTimes, setDifferentTimes] = useState(user.settings.differentTimes)
    const dayMapping = {
      "Monday": "M",
      "Tuesday": "T",
      "Wednesday": "W",
      "Thursday": "Th",
      "Friday": "F",
      "Saturday": "S",
      "Sunday": "Su",
    };
    const dayOrder = ["M", "T", "W", "Th", "F", "S", "Su"];
    const weeks = ["A", "B", "C", "D", "E", "F", "G"];
    const weeksOrder = weeks.slice(0, numberOfWeeks)

    const warningWithConfirmAndCancelMessage = (index) => {
      setAlert(
        <ReactBSAlert
          warning
          style={{ display: "block", marginTop: "-100px" }}
          title="Are you sure?"
          onConfirm={() => deleteReminder(index)}
          onCancel={() => cancelDelete()}
          confirmBtnBsStyle="info"
          cancelBtnBsStyle="danger"
          confirmBtnText="Yes, delete it!"
          cancelBtnText="Cancel"
          showCancel
          btnSize=""
        >
          You will not be able to recover this timer!
        </ReactBSAlert>
      );
    };
  
    const successDelete = () => {
      setAlert(
        <ReactBSAlert
          success
          style={{ display: "block", marginTop: "-100px" }}
          title="Deleted!"
          onConfirm={() => hideAlert()}
          onCancel={() => hideAlert()}
          confirmBtnBsStyle="info"
          btnSize=""
        >
          Your reminder has been deleted.
        </ReactBSAlert>
      );
    };
  
    const cancelDelete = () => {
      setAlert(
        <ReactBSAlert
          danger
          style={{ display: "block", marginTop: "-100px" }}
          title="Cancelled"
          onConfirm={() => hideAlert()}
          onCancel={() => hideAlert()}
          confirmBtnBsStyle="info"
          btnSize=""
        >
          Your reminder is safe :)
        </ReactBSAlert>
      );
    };

    const hideAlert = () => {
      setAlert(null);
    };

    const deleteReminder = async (index) => {
      console.log(index)
      try {
        const docRef = doc(db, "users", user.uid);
    
        const userCopy = JSON.parse(JSON.stringify(user));
    
        // Remove the class at the specified index
        userCopy.reminders.splice(index, 1);
        console.log(userCopy)
    
        // Update the document in Firestore
        await setDoc(docRef, userCopy);
  
        successDelete()
    
        console.log('Countdown Timer deleted successfully!');
      } catch (error) {
        console.error('Error deleting content box:', error);
      }
    }


    const updateClasses = async (updatedClasses) => {
      try {
        const docRef = doc(db, "users", user.uid);

        const userCopy = JSON.parse(JSON.stringify(user));
    
        userCopy.classes = updatedClasses
        // Update the document in Firestore
        await setDoc(docRef, userCopy);

        console.log("saved")
      } catch (error) {
        alert('Error updating document:', error);
      }
    }

    const duplicateReminder = async (index) => {
      try {
        const docRef = doc(db, "users", user.uid);
    
        const userCopy = JSON.parse(JSON.stringify(user));
    
        // Make a copy of the selected reminder
        const reminderCopy = {...userCopy.reminders[index]};

        // Add copy to the reminderCopy array
        userCopy.reminders.push(reminderCopy);

        // Update the document in Firestore
        await setDoc(docRef, userCopy);
      
      } catch (error) {
        console.error('Error deleting content box:', error);
      }
    }

    function convertTo12HourFormat(time24) {
      // Split the time into hours and minutes
      const [hours, minutes] = time24.split(':').map(Number);
    
      // Determine whether it's AM or PM
      const period = hours >= 12 ? 'PM' : 'AM';
    
      // Convert hours to 12-hour format
      const hours12 = hours % 12 || 12;
    
      // Format the time as "hh:mm AM/PM"
      const time12 = `${hours12}:${String(minutes).padStart(2, '0')} ${period}`;
    
      return time12;
    }

    function listActiveStartTimes(data) {
      const uniqueStartTimes = new Set();
    
      for (const key in data) {
        const days = data[key];
    
        for (const dayKey in days) {
          const day = days[dayKey];
    
          if (day.active) {
            uniqueStartTimes.add(convertTo12HourFormat(day.startTime));
          }
        }
      }
    
      return Array.from(uniqueStartTimes).join(', ');
    }

  return (
    <>
      <div className="content">
                <Row>
                    <Col>
                      <Card>
                        <CardHeader style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                          <h2 style={{ fontWeight: "bold", color: "#004aad", margin: "0px" }}>Auto Reminders</h2>
                        </CardHeader>
                        <CardSubtitle style={{textAlign: 'center', padding: '0px 20px'}}>
                          Reminders can be used to trigger messages to popup at set times. Have a group of students that are supposed to leave at a certain time? Always forget to take attendance? Lesson Launchpad will automatically launch your message at the time you request.
                        </CardSubtitle>
                        <CardBody>
                          <Table responsive>
                            <thead className="text-primary">
                              <tr>
                                <th className="text-center" id="uoalkbasad">Start Time</th>
                                <th className="text-center" id="utbakdbenq">Duration</th>
                                <th className="text-center" id="oiqweprihb">Days Active</th>
                                <th className="text-center" id="iakemglaje">Message</th>
                                <th className="text-center" id="qaebasdkli">Active</th>
                                <th className="text-center">Actions</th>
                              </tr>
                              <UncontrolledTooltip delay={0} target={"uoalkbasad"}>This is the time at which your reminder will launch.</UncontrolledTooltip>
                              <UncontrolledTooltip delay={0} target={"utbakdbenq"}>Your reminder will show on the screen for this length of time.</UncontrolledTooltip>
                              <UncontrolledTooltip delay={0} target={"oiqweprihb"}>Your reminder will be active on these days only.</UncontrolledTooltip>
                              <UncontrolledTooltip delay={0} target={"iakemglaje"}>This is the reminder message that you want displayed.</UncontrolledTooltip>
                              <UncontrolledTooltip delay={0} target={"qaebasdkli"}>If checked, this reminder message will be "active" and trigger at the requested time. Otherwise, it will be skipped.</UncontrolledTooltip>
                            </thead>
                            {user && user.reminders && user.reminders?.map((reminder, index) => {
                            return(
                            <tbody key={index}>
                              <tr>
                                <td className="text-center" >{!differentTimes ? convertTo12HourFormat(reminder.startTime) : listActiveStartTimes(reminder.schedule)}</td>
                                <td className="text-center" >{reminder.duration} seconds</td>
                                <td className={user.settings.multiWeek ? "text-left" : "text-center"}>
                                {!multiWeek && reminder.schedule?.A ? (
                                  <div>
                                    {dayOrder
                                      .filter(day => reminder.schedule.A[Object.keys(dayMapping).find(key => dayMapping[key] === day)].active)
                                      .join(', ')}
                                  </div>
                                ) : null}
                                {multiWeek && weeksOrder.map((week) => (
                                  <div key={week}>
                                    <strong>Week {week}:</strong>{" "}
                                    {dayOrder
                                      .filter((day) =>
                                      reminder.schedule && reminder.schedule[week] && reminder.schedule[week][Object.keys(dayMapping).find((key) => dayMapping[key] === day)].active
                                      )
                                      .join(', ')}
                                  </div>
                                ))}
                                </td>
                                <td className="text-center">"{reminder.reminderMessage}"</td>
                                <td className="text-center">{reminder.active ? <Badge className="button-success">Yes</Badge> : <Badge className="button-delete">No</Badge>}</td>
                                <td className="text-center">
                                  <Button
                                    className="btn-icon btn-neutral"
                                    color="success"
                                    id="tooltip159182735"
                                    size="md"
                                    type="button"
                                    onMouseEnter={(e) => (e.currentTarget.style.transform = "scale(1.12)")}
                                    onMouseLeave={(e) => (e.currentTarget.style.transform = "scale(1)")}
                                  >
                                    <NavLink to={{pathname: "/reminder-form", state: {reminder, index}}}><i className="fa fa-edit icon-edit" /></NavLink>
                                  </Button>
                                  <UncontrolledTooltip
                                    delay={0}
                                    target="tooltip159182735"
                                  >
                                    Edit
                                  </UncontrolledTooltip>
                                  <Button
                                    className="btn-icon btn-neutral"
                                    color="info"
                                    id="tooltip8049533849302"
                                    size="md"
                                    type="button"
                                    onClick={() => duplicateReminder(index)}
                                    onMouseEnter={(e) => (e.currentTarget.style.transform = "scale(1.12)")}
                                    onMouseLeave={(e) => (e.currentTarget.style.transform = "scale(1)")}
                                  >
                                    <i className="fa fa-copy" />
                                  </Button>
                                  <UncontrolledTooltip
                                    delay={0}
                                    target="tooltip8049533849302"
                                  >
                                    Duplicate
                                  </UncontrolledTooltip>
                                  <Button
                                    className="btn-icon btn-neutral"
                                    color="danger"
                                    id="tooltip808966390"
                                    size="md"
                                    type="button"
                                    onClick={() => warningWithConfirmAndCancelMessage(index)}
                                    onMouseEnter={(e) => (e.currentTarget.style.transform = "scale(1.12)")}
                                    onMouseLeave={(e) => (e.currentTarget.style.transform = "scale(1)")}
                                  >
                                    <i className="fa fa-trash icon-delete" />
                                  </Button>
                                  <UncontrolledTooltip
                                    delay={0}
                                    target="tooltip808966390"
                                  >
                                    Delete
                                  </UncontrolledTooltip>
                                </td>
                              </tr>
                              </tbody>
                            )})}
                          </Table>
                          {alert}
                          <NavLink to={{pathname: "/reminder-form"}} style={{color: 'white'}}>
                            <Button
                              style={{
                                padding: "10px 20px",
                                borderRadius: "20px",
                                fontSize: ".9rem",
                                fontWeight: "bold",
                                width: '185px'
                              }}
                            >
                              Add Reminder
                            </Button>
                          </NavLink>
                        </CardBody>
                      </Card>
                    </Col>         
                </Row>
      </div>
    </>
  );
}

export default AutoReminderTable;