export default function isScheduleNormal(schedule) {
    // Convert time string (HH:MM) to minutes for easy comparison
    const timeToMinutes = (timeStr) => {
        if (!timeStr) return null; // Handle undefined or empty startTime
        const [hours, minutes] = timeStr.split(":").map(Number);
        return hours * 60 + minutes;
    };

    const minTime = timeToMinutes("07:00"); // 7 AM in minutes
    const maxTime = timeToMinutes("17:00"); // 5 PM in minutes

    // Traverse the nested schedule object
    for (const week in schedule) {
        for (const day in schedule[week]) {
            const startTime = timeToMinutes(schedule[week][day]?.startTime);

            if (startTime !== null && startTime >= minTime && startTime <= maxTime) {
                return true; // ✅ Found a valid startTime, return true
            }
        }
    }

    return false; // ❌ No valid startTimes found
}
