import React, { useState } from 'react';
import { HexColorPicker, HexColorInput } from 'react-colorful';

const ColorPicker = ({ editor, visible, toggleVisible }) => {
  const [color, setColor] = useState('#000000');

  const handleChange = (newColor) => {
    setColor(newColor);
    editor.chain().focus().setColor(newColor).run();
  };

//   const handleChange = (newColor) => {
//     setColor(newColor);
//     editor.chain().focus().toggleHighlight({color: newColor}).run()
//   };

  return (
    <div style={{ position: 'relative'}}>
        <button onClick={toggleVisible} style={{ position: 'relative', padding: '4px 10px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <i className="fa fa-font" style={{ fontSize: '14px', position: 'relative', top: '-2px' }}></i>
            <div style={{ backgroundColor: color, height: '3px', width: '20px', position: 'absolute', bottom: '2px' }}></div>
        </button>      
      {visible && (
        <div style={{ position: 'absolute', top: '100%', left: 0, zIndex: 10, border: '1px solid lightgrey', borderRadius: '8px' }}>
          <div className='color-picker' style={{borderRadius: '8px', width: 'auto', backgroundColor: 'white'}}>
            <HexColorPicker color={color} onChange={handleChange} />
            <HexColorInput color={color} onChange={handleChange} style={{marginTop: '3px', marginBottom: '3px'}}/>
          </div>
        </div>
      )}
    </div>
  );
};

export default ColorPicker;
