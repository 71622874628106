import { React, useState } from "react";
import { useSelector } from "react-redux";
import { selectUser } from "features/userSlice";
import { setDoc, doc} from "firebase/firestore";
import { db } from "/Users/michaeltucker/Documents/coding-projects/client/src/firebase.js";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  Table,
  Row,
  Col,
  UncontrolledTooltip,
  CardSubtitle,
  Badge
} from "reactstrap";
import { NavLink } from 'react-router-dom';
import ReactBSAlert from "react-bootstrap-sweetalert";

function AutoMusicTable({}) {
  const user = useSelector(selectUser);
  const [alert, setAlert] = useState(null);
  const dayOrder = ["M", "T", "W", "Th", "F", "S", "Su"];
  const weeks = ["A", "B", "C", "D", "E", "F", "G"];
  const numberOfWeeks = user.settings.numberOfWeeks || 2;
  const weeksOrder = weeks.slice(0, numberOfWeeks);
  const multiWeek = user.settings.multiWeek;
  const dayMapping = {
    "Monday": "M",
    "Tuesday": "T",
    "Wednesday": "W",
    "Thursday": "Th",
    "Friday": "F",
    "Saturday": "S",
    "Sunday": "Su",
  };

  // const user1 = {
  //   autoMusic: {
  //     playlists: [
  //       {
  //         name: "Beginning Trumpet",
  //         playlistId: "b98hsadf8ba",
  //         playlistData: [
  //           {
  //               next: false,
  //               active: true,
  //               url: "https://www.youtube.com/watch?v=Co7fwXjOYXM"
  //           },
  //           {
  //               next: false,
  //               active: true,
  //               url: "https://www.youtube.com/watch?v=Co7fwXjOYXM"
  //           },
  //           {
  //               next: true,
  //               active: true,
  //               url: "https://www.youtube.com/watch?v=Co7fwXjOYXM"
  //           },
  //           {
  //               next: false,
  //               active: true,
  //               url: "https://www.youtube.com/watch?v=Co7fwXjOYXM"
  //           }
  //         ]
  //       }
  //     ],
  //     segments: [
  //       {
  //         startTime: "8:45",
  //         duration: "5:00",
  //         playlist: "Beginning Trumpet",
  //         playlistId: "b98hsadf8ba",
  //         showVideo: true,
  //         active: true
  //       },
  //       {
  //         startTime: "11:10",
  //         duration: "4:30",
  //         playlist: "Music",
  //         playlistId: "a328hsad8ba",
  //         showVideo: false,
  //         active: true
  //       }
  //     ]
  //   }
  // }

  const warningWithConfirmAndCancelMessage = (segmentId) => {
    setAlert(
      <ReactBSAlert
        warning
        style={{ display: "block", marginTop: "-100px" }}
        title="Are you sure?"
        onConfirm={() => deleteMusicSegment(segmentId)}
        onCancel={() => cancelDelete()}
        confirmBtnBsStyle="info"
        cancelBtnBsStyle="danger"
        confirmBtnText="Yes, delete it!"
        cancelBtnText="Cancel"
        showCancel
        btnSize=""
      >
        You will not be able to recover this Music Segment! This will NOT delete the playlist associated with this segment.
      </ReactBSAlert>
    );
  };

  const successDelete = () => {
    setAlert(
      <ReactBSAlert
        success
        style={{ display: "block", marginTop: "-100px" }}
        title="Deleted!"
        onConfirm={() => hideAlert()}
        onCancel={() => hideAlert()}
        confirmBtnBsStyle="info"
        btnSize=""
      >
        Your music segment has been deleted.
      </ReactBSAlert>
    );
  };

  const cancelDelete = () => {
    setAlert(
      <ReactBSAlert
        danger
        style={{ display: "block", marginTop: "-100px" }}
        title="Cancelled"
        onConfirm={() => hideAlert()}
        onCancel={() => hideAlert()}
        confirmBtnBsStyle="info"
        btnSize=""
      >
        Your music segment is safe :)
      </ReactBSAlert>
    );
  };

  const hideAlert = () => {
    setAlert(null);
  };

  const deleteMusicSegment = async (segmentId) => {
    try {
      const docRef = doc(db, "users", user.uid);
  
      const userCopy = JSON.parse(JSON.stringify(user));

      const segmentIndex = user.autoMusic.segments.findIndex((segment) => segment.segmentId === segmentId);
  
      if (segmentIndex != -1) {
        // Remove the class at the specified index
        userCopy.autoMusic.segments.splice(segmentIndex, 1);
      }
  
      // Update the document in Firestore
      await setDoc(docRef, userCopy);

      successDelete()
  
      console.log('Music segment deleted successfully!');
    } catch (error) {
      console.error('Error deleting music segment:', error);
    }
  }

  function listActiveStartTimes(data) {
    const uniqueTimes = new Set();
  
    for (const key in data) {
      const days = data[key];
  
      for (const dayKey in days) {
        const day = days[dayKey];
  
        if (day.active) {
          uniqueTimes.add(convertTo12HourFormat(day.startTime));
        }
      }
    }
  
    return Array.from(uniqueTimes).join(', ');
  }

  function convertTo12HourFormat(time24) {
    // Split the time into hours and minutes
    const [hours, minutes] = time24.split(':').map(Number);
  
    // Determine whether it's AM or PM
    const period = hours >= 12 ? 'PM' : 'AM';
  
    // Convert hours to 12-hour format
    const hours12 = hours % 12 || 12;
  
    // Format the time as "hh:mm AM/PM"
    const time12 = `${hours12}:${String(minutes).padStart(2, '0')} ${period}`;
  
    return time12;
  }

  return (
    <>
      {alert}
      <div className="content">
        <Row>
          <Col>
            <Card>
              <CardHeader style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                <h2 style={{ fontWeight: "bold", color: "#004aad", margin: "0px" }}>Auto Music</h2>
              </CardHeader>
              <CardSubtitle style={{textAlign: 'center', padding: '0px 20px'}}>
                Want music to play between classes? With Auto Music you can setup a playlist and music will play during the times you set up.
              </CardSubtitle>
              <CardBody>
                <Table responsive>
                  <thead className="text-primary">
                    <tr>
                      <th className="text-center" id="startTimeTipAutoMusicTable" style={{width: '10%'}}>Start Time</th>
                      <UncontrolledTooltip delay={0} target={"startTimeTipAutoMusicTable"}>The time this music segment will start playing.</UncontrolledTooltip>
                      <th className="text-center" id="durationTipAutoMusicTable" style={{width: '20%'}}>Duration</th>
                      <UncontrolledTooltip delay={0} target={"durationTipAutoMusicTable"}>The amount of time the music segment will play for.</UncontrolledTooltip>
                      <th className="text-center" id="daysActiveTipAutoMusicTable" style={{width: '20%'}}>Days Active</th>
                      <UncontrolledTooltip delay={0} target={"daysActiveTipAutoMusicTable"}>The days the music segment will play on.</UncontrolledTooltip>
                      <th className="text-center" id="playlistTipAutoMusicTable" style={{width: '20%'}}>Playlist</th>
                      <UncontrolledTooltip delay={0} target={"playlistTipAutoMusicTable"}>The playlist of music the music segment will play from.</UncontrolledTooltip>
                      <th className="text-center" id="wertshowVideoTipAutoMusicTable" style={{width: '10%'}}>Show Video</th>
                      <UncontrolledTooltip delay={0} target={"wertshowVideoTipAutoMusicTable"}>If checked, the YouTube video will play on screen, otherwise the music will play without the video on screen.</UncontrolledTooltip>
                      <th className="text-center" id="hjfgkactiveTipAutoMusicTable" style={{width: '10%'}}>Active</th>
                      <UncontrolledTooltip delay={0} target={"hjfgkactiveTipAutoMusicTable"}>If checked the music segment will be turned on and launched at the selected start time on any selected day.</UncontrolledTooltip>
                      <th className="text-center" style={{width: '10%'}}>Edit</th>
                    </tr>
                  </thead>
                  {user && user?.autoMusic?.segments?.map((segment, index) => {
                    return(
                      <tbody key={index}>
                        <tr>
                          <td className="text-center">{listActiveStartTimes(segment.schedule)}</td>
                          <td className="text-center">
                            {Math.floor(segment.duration / 60)} minutes {segment.duration % 60} seconds
                          </td>
                          <td className={user.settings.multiWeek ? "text-left" : "text-center"}>
                            {!multiWeek && segment.schedule?.A ? (
                              <div>
                                {dayOrder
                                  .filter(day => segment.schedule.A[Object.keys(dayMapping).find(key => dayMapping[key] === day)].active)
                                  .join(', ')}
                              </div>
                            ) : null}
                            {multiWeek && weeksOrder.map((week) => (
                              <div key={week}>
                                <strong>Week {week}:</strong>{" "}
                                {dayOrder
                                  .filter((day) =>
                                    segment.schedule && segment.schedule[week] && segment.schedule[week][Object.keys(dayMapping).find((key) => dayMapping[key] === day)].active
                                  )
                                  .join(', ')}
                              </div>
                            ))}
                          </td>                        
                          <td 
                            className="text-center"
                            style={{
                              backgroundColor: !user.autoMusic.playlists.find((playlist) => playlist.playlistId === segment.playlistId) ? "#ffcccc" : "inherit",
                              color: !user.autoMusic.playlists.find((playlist) => playlist.playlistId === segment.playlistId) ? "red" : "inherit",
                              fontWeight: !user.autoMusic.playlists.find((playlist) => playlist.playlistId === segment.playlistId) ? "bold" : "normal",
                              padding: "10px",
                            }}
                          >
                            {user.autoMusic.playlists.find((playlist) => playlist.playlistId === segment.playlistId) 
                              ? user.autoMusic.playlists.find((playlist) => playlist.playlistId === segment.playlistId)?.name 
                              : (
                                <>
                                  <i className="fa fa-exclamation-triangle" style={{ marginRight: "5px" }} /> {/* 🚨 Warning Icon */}
                                  <span>Missing Playlist</span>
                                </>
                              )
                            }
                          </td>
                          <td className="text-center">
                            {segment.showVideo ? <Badge className="button-success">Yes</Badge> : <Badge className="button-delete">No</Badge>}
                          </td>                          
                          <td className="text-center">
                            {segment.active ? <Badge className="button-success">Yes</Badge> : <Badge className="button-delete">No</Badge>}
                          </td>                          
                          <td className="text-center">
                            <Button
                              className="btn-icon btn-neutral"
                              color="info"
                              size="md"
                              type="button"
                              onMouseEnter={(e) => (e.currentTarget.style.transform = "scale(1.12)")}
                              onMouseLeave={(e) => (e.currentTarget.style.transform = "scale(1)")}
                            >
                              <NavLink to={{pathname: "/music-form", state: {segment}}}><i className="fa fa-edit icon-edit" /></NavLink>
                            </Button>
                            <Button
                              className="btn-icon btn-neutral"
                              color="danger"
                              id="tooltip808966390"
                              size="md"
                              type="button"
                              onClick={() => warningWithConfirmAndCancelMessage(segment.segmentId)}
                              onMouseEnter={(e) => (e.currentTarget.style.transform = "scale(1.12)")}
                              onMouseLeave={(e) => (e.currentTarget.style.transform = "scale(1)")}
                            >
                              <i className="fa fa-trash icon-delete" />
                            </Button>
                            <UncontrolledTooltip
                              delay={0}
                              target="tooltip808966390"
                            >
                              Delete
                            </UncontrolledTooltip>
                          </td>
                        </tr>
                      </tbody>
                    )
                  })}
                </Table>
                <NavLink to={{pathname: "/music-form"}} style={{color: 'white'}}>
                  <Button
                    style={{
                      padding: "10px 20px",
                      borderRadius: "20px",
                      fontSize: ".9rem",
                      fontWeight: "bold",
                      width: '185px'
                    }}
                  >
                  Add Music
                  </Button>
                </NavLink>
              </CardBody>
            </Card>
          </Col>         
        </Row>
      </div>
    </>
  );
}

export default AutoMusicTable;
