import React, { useState } from 'react';
import { Card, Row, Col, CardBody, CardFooter, Button } from "reactstrap";

const TestimonialCarousel = ({ testimonialData, imageDimensions, handleImageLoad, handleImageClick }) => {

  const isMobile = /Mobi/.test(navigator.userAgent);

  // State to keep track of the first visible card's index
  const [currentIndex, setCurrentIndex] = useState(0);
  
  // Number of cards visible at once
  const visibleCards = isMobile ? 1 : 3;
  
  const totalCards = Object.entries(testimonialData).length;

  // Function to go to the next card
  const nextCard = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % totalCards);
  };

  // Function to go to the previous card
  const prevCard = () => {
    setCurrentIndex((prevIndex) => (prevIndex - 1 + totalCards) % totalCards);
  };

  // Get the subset of testimonial data for visible cards
  const visibleTestimonialData = Object.entries(testimonialData).slice(currentIndex, currentIndex + visibleCards);

  // Handle wrapping back to the beginning if we are at the end of the list
  const wrappedTestimonialData = visibleTestimonialData.length < visibleCards
    ? visibleTestimonialData.concat(Object.entries(testimonialData).slice(0, visibleCards - visibleTestimonialData.length))
    : visibleTestimonialData
  ;

  return (
   !isMobile ? (
    <div style={{ position: 'relative', width: '100%', backgroundColor: '#004aad' }}>
      {/* Left Button */}
      <i 
        className="nc-icon nc-minimal-left"  
        onClick={prevCard} 
        style={{ position: 'absolute', left: '20px', top: '50%', zIndex: 1, cursor: 'pointer', fontSize: '40px', color: 'white' }} 
        onMouseEnter={(e) => (e.currentTarget.style.transform = "scale(1.12)")}
        onMouseLeave={(e) => (e.currentTarget.style.transform = "scale(1)")}
      />

      {/* Cards */}
      <Row
        xs="4"
        style={{
          minHeight: "475px", // Minimum height to keep background visible
          backgroundColor: "rgb(11, 192, 223)",
          alignItems: "stretch", // Forces all cards to have the same height
          justifyContent: "center",
          display: "flex", // Enables equal height stretching
          flexWrap: "wrap", // Allows wrapping if needed
          padding: "40px 0", // Adds space around
          clipPath: 'polygon(0 10%, 100% 0, 100% 90%, 0% 100%)'
        }}
      >
        {wrappedTestimonialData.map(([key, data]) => (
          <Card
            key={key}
            style={{
              margin: "20px",
              width: "320px",
              borderRadius: "15px",
              boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
              transition: "transform 0.3s ease-in-out",
              background: "linear-gradient(135deg, #ffffff, #f8f9fa)",
              overflow: "hidden",
              display: "flex",
              flexDirection: "column",
              height: "100%", // Ensures all cards match the tallest one
            }}
            onMouseEnter={(e) => (e.currentTarget.style.transform = "scale(1.02)")}
            onMouseLeave={(e) => (e.currentTarget.style.transform = "scale(1)")}
          >
            {/* Image Section */}
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "190px",
                overflow: "hidden",
                marginTop: "15px",
              }}
            >
              <img
                alt="User Testimonial"
                src={data.picture}
                height={180}
                width={imageDimensions[key] || 0}
                onLoad={(event) => handleImageLoad(key, event)}
                onClick={() => handleImageClick(data.picture)}
                style={{
                  objectFit: "cover",
                  cursor: "pointer",
                  borderRadius: "10px",
                  boxShadow: "0px 3px 10px rgba(0, 0, 0, 0.15)",
                }}
              />
            </div>

            {/* Testimonial Body */}
            <CardBody
              style={{
                padding: "15px",
                textAlign: "center",
                flexGrow: 1, // Makes all testimonials take equal space
                minHeight: "120px", // Ensures equal height for text content
              }}
            >
              <i
                style={{
                  fontSize: "1.1rem",
                  color: "#333",
                  fontWeight: "500",
                  lineHeight: "1.4",
                }}
              >
                {`"${data.testimonial}"`}
              </i>
            </CardBody>

            {/* Footer - Name & Info */}
            <CardFooter
              style={{
                textAlign: "center",
                paddingBottom: "15px",
                backgroundColor: "#f0f2f5",
                borderTop: "1px solid #e0e0e0",
              }}
            >
              <div style={{ fontSize: "18px", fontWeight: "600", color: "#004aad" }}>
                {data.firstName} {data.lastName}
              </div>
              <div style={{ fontSize: "14px", color: "#666" }}>
                {data.gradeLevel} {data.subject}, {data.state}
              </div>
            </CardFooter>
          </Card>
        ))}
      </Row>

      {/* Right Button */}
      <i 
        className="nc-icon nc-minimal-right"
        onClick={nextCard} 
        style={{ position: 'absolute', right: '20px', top: '50%', zIndex: 1, cursor: 'pointer', fontSize: '40px', color: 'white' }} 
        onMouseEnter={(e) => (e.currentTarget.style.transform = "scale(1.12)")}
        onMouseLeave={(e) => (e.currentTarget.style.transform = "scale(1)")}
      />
    </div>
    ) : (
      <div style={{ position: 'relative', width: '100%' }}>
      {/* Cards */}
      <Row style={{ height: 'auto', backgroundColor: "#004aad", alignItems: 'center', justifyContent: 'center', padding: '100px 0px' }}>
        <Col md="1">
          <i 
            className="nc-icon nc-minimal-left"
            onClick={prevCard} 
            style={{
              position: 'absolute',
              left: '30px',
              top: '50%',
              zIndex: 1,
              cursor: 'pointer',
              fontSize: '150px',
              color: 'white'
            }}
          />
        </Col>
        <Col md="10">
        {wrappedTestimonialData.map(([key, data]) => (
          <Card key={key} style={{ margin: '20px', height: 'auto', borderRadius: '60px' }}>
            <div style={{ 
                display: 'flex', 
                justifyContent: 'center', 
                alignItems: 'center',
                height: '800px',
                overflow: 'hidden',
                margin: '10px'
            }}>
              <img
                alt="Card"
                src={data.picture}
                height={600}
                width={imageDimensions[key] || 0}
                onLoad={(event) => handleImageLoad(key, event)}
                onClick={() => handleImageClick(data.picture)}
                style={{ 
                    objectFit: 'cover',
                    cursor: 'pointer',
                    borderRadius: '50px'
                }}                        
              />
            </div>
            <CardBody style={{ margin: '20px 40px', fontSize: '60px' }}>
              <i>{`"${data.testimonial}"`}</i>
            </CardBody>
            <CardFooter style={{ margin: '20px 40px', fontSize: '60px' }}>
              {data.firstName} {data.lastName}.
              <br />
              {data.gradeLevel} {data.subject}, {data.state}
            </CardFooter>
          </Card>
        ))}
        </Col>
        <Col md="1">
        <i className="nc-icon nc-minimal-right"  onClick={nextCard} style={{ position: 'absolute', right: '30px', top: '50%', zIndex: 1, cursor: 'pointer', fontSize: '150px', color: 'white' }} />
        </Col>
      </Row>
    </div>
    )
  );
};

export default TestimonialCarousel;
