import React, { useEffect, useState } from 'react';
import { useSelector } from "react-redux";
import { selectUser } from "features/userSlice";
import { useHistory } from 'react-router-dom';
import { useLocation } from "react-router-dom";
import { Card, CardBody, CardHeader } from "reactstrap";

const UseAutoNavigate = ({clockKey}) => {

const user = useSelector(selectUser);
const location = useLocation();
const history = useHistory();
const [currentWeek, setCurrentWeek] = useState(user.settings.multiWeek ? user.settings.currentWeek : "A")
const [nextClassDisplay, setNextClassDisplay] = useState();
const [timeDifferenceDisplay, setTimeDifferenceDisplay] = useState();
const [currentSystemTimeDisplay, setCurrentSystemTimeDisplay] = useState();
const [throttled, setThrottled] = useState(false); // State to track if the interval is being throttled


useEffect(() => {
  let lastRun = Date.now();
  let timeoutId;

  const checkAndTriggerLoop = () => {
    checkAndTrigger();
    const now = Date.now();
    const timeElapsed = now - lastRun;

    // If the time elapsed is much greater than 1000ms, throttling is likely happening
    if (timeElapsed > 1500) {
      setThrottled(true);
    } else {
      setThrottled(false);
    }

    lastRun = now;
    timeoutId = setTimeout(checkAndTriggerLoop, 1000);
  };

  checkAndTriggerLoop();

  return () => {
    clearTimeout(timeoutId);
  };
}, [user, clockKey]);

// useEffect(() => {
//   const intervalId = setInterval(() => {
//     checkAndTrigger();
//   }, 1000);

//   return () => {
//     clearInterval(intervalId);
//   };
// }, [user, clockKey]);


const checkAndTrigger = () => {
  const currentTime = new Date();
  setCurrentSystemTimeDisplay(formatCurrentAndStartTime(currentTime));

  const currentDayNumber = currentTime.getDay();
  let currentDay;

  switch (currentDayNumber) {
    case 1:
      currentDay = "Monday";
      break;
    case 2:
      currentDay = "Tuesday";
      break;
    case 3:
      currentDay = "Wednesday";
      break;
    case 4:
      currentDay = "Thursday";
      break;
    case 5:
      currentDay = "Friday";
      break;
    case 6:
      currentDay = "Saturday";
      break;
    case 0:
      currentDay = "Sunday";
      break;
    default:
      currentDay = "";
      break;
  }

  // console.log(user)
  // console.log(currentWeek)
  const classes = user && user.classes
    ? user.classes
      .filter(classMap => classMap.active && classMap.schedule?.[currentWeek || "A"]?.[currentDay]?.active)
      .map(classMap => {
        // console.log(classMap)
        const startTimeString = classMap.schedule[currentWeek || "A"]?.[currentDay].startTime || "0:00";
        const [startHours, startMinutes] = startTimeString.split(':');
        const parsedStartTime = new Date();
        parsedStartTime.setHours(startHours);
        parsedStartTime.setMinutes(startMinutes);
        parsedStartTime.setSeconds(0);
        const parsedUrl = '/' + classMap.classId;


        if (classMap.schedule[currentWeek || "A"]?.[currentDay]) {
          return {
            startTime: parsedStartTime,
            url: parsedUrl,
            classId: classMap.classId,
            showMarquee: classMap.showMarquee,
            showClock: classMap.showClock,
            className: classMap.className
          };
        }
      })
    : [];

    // console.log(classes)

  const filteredClasses = classes.filter(x => {
    const classTime = x?.startTime?.getTime();
    const currentTimeTime = currentTime.getTime();
    const tolerance = -3000; // 3 second tolerance
    return classTime >= currentTimeTime;
  });

  filteredClasses.sort((a, b) => a.startTime.getTime() - b.startTime.getTime());
 
  if (filteredClasses.length > 0) {
    const nextClass = filteredClasses[0];
    setNextClassDisplay(nextClass);
    const timeDifference = nextClass.startTime.getTime() - currentTime.getTime();
    setTimeDifferenceDisplay(timeDifference)
    // console.log("timeDifference => ", timeDifference)

     // If throttled, expand the window to 3000ms (3 seconds)
     const triggerWindow = throttled ? 3000 : 1000;

     if (timeDifference <= triggerWindow && timeDifference >= 0) {
       history.push(nextClass.url, {
         classId: nextClass.classId,
         showMarquee: nextClass.showMarquee,
         showClock: nextClass.showClock,
       });
     }
  }
};

const formatTimeUntilTriggered = (milliseconds) => {
  const hours = Math.floor(milliseconds / 3600000);
  const minutes = Math.floor((milliseconds % 3600000) / 60000);
  const seconds = Math.floor((milliseconds % 60000) / 1000);

  return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
};

const formatCurrentAndStartTime = (currentTime) => {
  const hours = currentTime.getHours();
  const minutes = currentTime.getMinutes();
  const seconds = currentTime.getSeconds();
  const ampm = hours >= 12 ? 'PM' : 'AM';
  const formattedHours = hours % 12 || 12; // Convert hours to 12-hour format
  return `${formattedHours}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')} ${ampm}`;
}

  return (
    <div>
      {location.pathname === "/auto-status" ? (
        <Card>
          <CardHeader className='h3 mb-0'>Auto Class Advance</CardHeader>
          <CardBody>
            <b>Next Class:</b> {nextClassDisplay && nextClassDisplay.className || "No classes scheduled for the rest of the day."}
            <br></br>
            <b>Next Class Start Time:</b> {nextClassDisplay && formatCurrentAndStartTime(nextClassDisplay.startTime) || "No classes scheduled for the rest of the day."}
            <br></br>
            <b>Current System Time:</b> {currentSystemTimeDisplay}
            <br></br>
            <b>Time Until Triggered:</b> {timeDifferenceDisplay && formatTimeUntilTriggered(timeDifferenceDisplay) || "No classes scheduled for the rest of the day."}
          </CardBody>
        </Card>
      ): <></>}
    </div>
  )
};

export default UseAutoNavigate;
