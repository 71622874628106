/*!

=========================================================
* Paper Dashboard PRO React - v1.3.1
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useState, useEffect } from "react";
import { NavLink, useHistory } from "react-router-dom"
import { confirmPasswordReset, sendPasswordResetEmail, signInWithEmailAndPassword, signOut } from "firebase/auth";
import { auth } from "/Users/michaeltucker/Documents/coding-projects/client/src/firebase.js";
import jwtDecode from "jwt-decode";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  Label,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Col,
  Row
} from "reactstrap";

function Login() {
  React.useEffect(() => {
    document.body.classList.toggle("login-page");
    return function cleanup() {
      document.body.classList.toggle("login-page");
    };
  });

  const [email, setEmail] = useState();
  const [password, setPassword] = useState();
  const [showPasswordReset, setShowPasswordReset] = useState(false);
  const [resetSendSuccess, setResetSendSuccess] = useState(false);
  const history = useHistory();

  const resetPassword = (e) => {
    e.preventDefault()
    sendPasswordResetEmail(
      auth,
      email
    ).then(
      setResetSendSuccess(true)
    ).catch((error) => {
      alert(error.message)
    })
  };

  const login = (e) => {
    e.preventDefault()
    signInWithEmailAndPassword(
      auth,
      email,
      password
      ).then((authUser) => {
        history.push("/")
      }).catch((error) => {
        alert(error.message)
      });
  }

  const handleEnterKeyPress = (e) => {
    if (e.key === "Enter") {
      e.preventDefault(); // Prevent form submission
      login(e); // Trigger the button click event
    }
  };

  useEffect(() => {
    /* global google*/
    google.accounts.id.initialize({
      client_id: '403855687421-c6q3mv8991jn1qpcrs9kga2s55vtqlnv.apps.googleusercontent.com',
      callback: handleGoogleLogin
    })

    google.accounts.id.renderButton(
      document.getElementById("googleLogin"),
      { theme: "outline", size: "large"}
    );
  }, [])

  const handleGoogleLogin = (response) => {
    console.log(jwtDecode(response.credential))
  }

  const isMobile = /Mobi/.test(navigator.userAgent);

  return (
    <>
    { !isMobile && !showPasswordReset ? (
    <div className="login-page">
      <Container>
        <Row>
          <Col className="ml-auto mr-auto" lg="9">
            <Form action="" className="form" method="">
              <Card className="card-login">
                <CardHeader>
                  <CardHeader>
                    <h3 style={{ fontWeight: "bold", color: "#004aad", margin: "0px", textAlign: 'center' }}>Login</h3>
                  </CardHeader>
                </CardHeader>
                {/* <div id="googleLogin" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', margin: '20px'}}></div> */}
                <CardBody>
                  <InputGroup>
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText style={{borderRadius: '8px 0px 0px 8px'}}>
                        <i className="nc-icon nc-single-02" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input onChange={(e) => setEmail(e.target.value)} placeholder="Email" type="email" style={{borderRadius: '0px 8px 8px 0px'}}/>
                  </InputGroup>
                  <InputGroup>
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText style={{borderRadius: '8px 0px 0px 8px'}}>
                        <i className="nc-icon nc-key-25" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      onChange={(e) => setPassword(e.target.value)}
                      placeholder="Password"
                      type="password"
                      autoComplete="off"
                      onKeyPress={handleEnterKeyPress} // Handle Enter key press
                      style={{borderRadius: '0px 8px 8px 0px'}}
                    />
                  </InputGroup>
                  <div 
                    style={{cursor: 'pointer', textAlign: 'right', textDecoration: 'underline'}}
                    onClick={() => setShowPasswordReset(!showPasswordReset)}
                  >
                    Forgot Password?
                  </div>
                  <br />
                </CardBody>
                <CardFooter style={{display: 'flex', alignContent: 'center', justifyContent: 'center'}}>
                  <Button
                    block
                    className="btn-round mb-3"
                    color="warning"
                    href="#"
                    onClick={(e) => login(e)}
                    style={{
                      padding: "10px 30px",
                      borderRadius: "30px",
                      fontSize: "1.1rem",
                      fontWeight: "bold",
                      width: '200px'
                    }}
                  >
                    Login
                  </Button>
                </CardFooter>
              </Card>
            </Form>
          </Col>
        </Row>
      </Container>
      <div
        // className="full-page-background"
        // style={{
        //   backgroundImage: `url(${require("assets/img/bg/fabio-mangione.jpg")})`
        // }}
      />
    </div>
    ) : !isMobile && showPasswordReset ? (
      <div className="login-page">
        <Container>
          <Row>
            <Col className="ml-auto mr-auto" lg="9">
              <Form action="" className="form" method="">
                <Card className="card-login">
                  <CardHeader>
                    <CardHeader>
                      <h3 className="header text-center">Reset Password</h3>
                    </CardHeader>
                  </CardHeader>
                  {/* <div id="googleLogin" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', margin: '20px'}}></div> */}
                  <CardBody>
                    <InputGroup>
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="nc-icon nc-single-02" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input onChange={(e) => setEmail(e.target.value)} placeholder="Email" type="email" onKeyPress={handleEnterKeyPress}/>
                    </InputGroup>
                    <br />
                  </CardBody>
                  <CardFooter>
                    <Button
                      block
                      className="btn-round mb-3"
                      color="warning"
                      href="#"
                      onClick={(e) => resetPassword(e)}
                    >
                      Reset Password
                    </Button>
                    {resetSendSuccess && (
                      <>
                      <div>Check your email.</div>
                      <div onClick={() => window.location.reload()} style={{cursor: 'pointer', textDecoration: 'underline'}}>Return to Login</div>
                      </>
                    )}
                  </CardFooter>
                </Card>
              </Form>
            </Col>
          </Row>
        </Container>
        <div
        />
      </div>
      ) : isMobile && !showPasswordReset ? (
      <div className="login-page" style={{width: '90vw'}}>
      <Container>
        <Row>
          <Col>
            <Form action="" className="form" method="">
              <Card className="card-login" style={{borderRadius: '20px'}}>
                <CardHeader>
                  <CardTitle tag="h1" style={{fontSize: '125px'}} className="text-center">Login</CardTitle>
                </CardHeader>
                {/* <div id="googleLogin" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', margin: '20px'}}></div> */}
                <CardBody>
                  <InputGroup style={{marginTop: '60px'}}>
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="nc-icon nc-single-02" style={{ fontSize: '60px'}}/>
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input 
                      onChange={(e) => setEmail(e.target.value)}
                      placeholder="Email"
                      type="email"
                      style={{ fontSize: '60px', marginLeft: '60px', border: 'solid', borderLeft: '0'}}
                    />
                  </InputGroup>
                  <InputGroup style={{marginTop: '60px'}}>
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="nc-icon nc-key-25" style={{ fontSize: '60px'}}/>
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      onChange={(e) => setPassword(e.target.value)}
                      placeholder="Password"
                      type="password"
                      autoComplete="off"
                      onKeyPress={handleEnterKeyPress} // Handle Enter key press
                      style={{ fontSize: '60px', marginLeft: '60px', border: 'solid', borderLeft: '0'}}
                    />
                  </InputGroup>
                  <Button 
                    style={{height: '55px', fontSize: '25px', borderRadius: '10px', width: '350px'}}
                    onClick={() => setShowPasswordReset(!showPasswordReset)}
                  >
                    Forgot Password?
                  </Button>
                  <br />
                </CardBody>
                <CardFooter>
                  <Button
                    className="btn-round mb-3"
                    color="warning"
                    onClick={(e) => login(e)}
                    style={{height: '110px', fontSize: '70px', borderRadius: '20px', margin: '50px', width: '600px'}}
                  >
                    Login
                  </Button>
                </CardFooter>
              </Card>
            </Form>
          </Col>
        </Row>
      </Container>
    </div>
    ) : (
      <div className="login-page" style={{width: '90vw'}}>
      <Container>
        <Row>
          <Col>
            <Form action="" className="form" method="">
              <Card className="card-login" style={{borderRadius: '20px'}}>
                <CardHeader>
                  <CardTitle tag="h1" style={{fontSize: '80px'}} className="text-center">Reset Password</CardTitle>
                </CardHeader>
                {/* <div id="googleLogin" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', margin: '20px'}}></div> */}
                <CardBody>
                  <InputGroup style={{marginTop: '60px'}}>
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="nc-icon nc-single-02" style={{ fontSize: '60px'}}/>
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input 
                      onChange={(e) => setEmail(e.target.value)}
                      placeholder="Email"
                      type="email"
                      style={{ fontSize: '60px', marginLeft: '60px', border: 'solid', borderLeft: '0'}}
                    />
                  </InputGroup>
                  <br />
                </CardBody>
                <CardFooter>
                  <Button
                    className="btn-round mb-3"
                    color="warning"
                    onClick={(e) => resetPassword(e)}
                    style={{height: '110px', fontSize: '45px', borderRadius: '20px', margin: '50px', width: '600px'}}
                  >
                    Reset Password
                  </Button>
                  {resetSendSuccess && (
                    <>
                      <br></br>
                      <div style={{fontSize: '35px', marginTop: '50px'}}>Check your email.</div>
                      <Button 
                        onClick={() => window.location.reload()}
                        style={{height: '55px', fontSize: '25px', borderRadius: '10px', width: '300px'}}
                      >
                        Return to Login
                      </Button>
                    </>
                  )}
                </CardFooter>
              </Card>
            </Form>
          </Col>
        </Row>
      </Container>
    </div>
    )}
    </>
  );
}

export default Login;
