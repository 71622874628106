import React, { useState, useEffect, useRef } from 'react';
import { Col, Card, CardBody, Button, Input, Row, FormGroup, UncontrolledTooltip, Label, CardFooter, DropdownMenu, DropdownItem, Dropdown, DropdownToggle } from "reactstrap";
import AddEditCountdownForm from 'views/forms/AddEditCountdownForm';
import AutoCountdownTableForTimerContentBox from './AutoCountdownTableForTimerContentBox';
import AlarmSound from "/Users/michaeltucker/Documents/coding-projects/client/src/assets/sounds/alarm-short.mp3";

const Timer = (props) => {
  const [minutes, setMinutes] = useState(parseInt(localStorage.getItem('timerMinutes'), 10) || 0);
  const [seconds, setSeconds] = useState(parseInt(localStorage.getItem('timerSeconds'), 10) || 0);
  const [isActive, setIsActive] = useState(false);
  const [isRunning, setIsRunning] = useState(false);
  const [alarmTone, setAlarmTone] = useState({active: true, iconColor: 'black'});
  const [scheduleView, setScheduleView] = useState(false);
  const [formView, setFormView] = useState(false);
  const audioRef = useRef(null);

  const toggleAlarmTone = () => {
    setAlarmTone((prev) => ({
      ...prev,
      active: !prev.active,
      iconColor: prev.iconColor === 'black' ? 'lightgrey' : 'black',
    }));
  }

  const toggleScheduleView = () => {
    setScheduleView(prev => !prev)
  }

  const toggleFormView = () => {
    setFormView(prev => !prev)
    setScheduleView(prev => !prev)
  }

  const handleAddEditCountdown = () => {
    setFormView(true)
    setScheduleView(false)
  }

  useEffect(() => {
    let timer;

    if (isActive) {
      timer = setInterval(() => {
        if (seconds === 0) {
          if (minutes === 0) {
            clearInterval(timer);
            setIsActive(false);
            setIsRunning(false);
            if (alarmTone.active) playSound(); // Play the sound when the timer reaches 0
          } else {
            setMinutes((prevMinutes) => prevMinutes - 1);
            setSeconds(59);
          }
        } else {
          setSeconds((prevSeconds) => prevSeconds - 1);
        }
      }, 1000);
    }

    return () => clearInterval(timer);
  }, [isActive, minutes, seconds]);

  const toggleTimer = () => {
    if (isRunning) {
      setIsActive(false);
      setIsRunning(false);
    } else {
      if (minutes > 0 || seconds > 0) {
        setIsActive(true);
        setIsRunning(true);
      }
    }
  };

  const resetTimer = () => {
    setIsActive(false);
    setIsRunning(false);
    setMinutes(parseInt(localStorage.getItem('timerMinutes'), 10) || 0);
    setSeconds(parseInt(localStorage.getItem('timerSeconds'), 10) || 0);
  };

  const playSound = () => {
    audioRef.current.play();
  };

  const [fontSize, setFontSize] = useState()

  useEffect(() => {
    const element = document.getElementById(`timer-${props.id}`);
    
    if (element) {
      const resizeObserver = new ResizeObserver(entries => {
        for (let entry of entries) {
          const newWidth = entry.contentRect.width;
          setFontSize(newWidth / 6); // Adjust this formula as needed
        }
      });
  
      // Start observing the element
      resizeObserver.observe(element);
  
      // Clean up the observer when the component unmounts
      return () => {
        resizeObserver.unobserve(element);
      };
    }
  }, [props.id]);

  return (
      <>
      { !scheduleView && !formView ? (
        <>
        <CardBody className="text-center" id={`timer-${props.id}`}>
          <h1 className='timer-digits' style={{fontSize: fontSize}}>
            {String(minutes).padStart(2, '0')}:{String(seconds).padStart(2, '0')}
          </h1>
          <FormGroup>
            <Row style={{justifyContent: 'center'}}>
              <Col md="6" style={{textAlign: 'center', maxWidth: '200px'}}>
                <p style={{marginBottom: '0px'}}>Minutes</p>
                <Input
                    style={{textAlign: 'center', borderRadius: '8px'}}
                    min={0}
                    type="number"
                    id="minutes"
                    placeholder="Minutes"
                    value={minutes}
                    onChange={(e) => {
                      localStorage.setItem('timerMinutes', parseInt(e.target.value, 10));
                      setMinutes(parseInt(e.target.value, 10));
                    }
                  }
                />
              </Col>
              <Col md="6" style={{textAlign: 'center', maxWidth: '200px'}}>
              <p style={{marginBottom: '0px'}}>Seconds</p>
                <Input
                    style={{textAlign: 'center', borderRadius: '8px'}}
                    min={0}
                    type="number"
                    id="seconds"
                    placeholder="Seconds"
                    value={seconds}
                    onChange={(e) => {
                      localStorage.setItem('timerSeconds', parseInt(e.target.value, 10));
                      setSeconds(parseInt(e.target.value, 10));
                    }
                  }
                />
              </Col>
            </Row>
          </FormGroup>
          <Row style={{display: 'flex', alignContent: 'center', justifyContent: 'center'}}>
            <Col md="1">
              <div style={{marginTop: '30px'}}>
                <UncontrolledTooltip
                    delay={0}
                    target={"bell"}
                    placement="bottom"
                  >
                    Click to turn alarm tone on/off
                  </UncontrolledTooltip>
                <i className="fa fa-bell" id="bell" style={{fontSize: '22px', cursor: 'pointer', color: alarmTone.iconColor}} onClick={toggleAlarmTone}/>
              </div>
            </Col>
            <Col md="10">
              <Button
                style={{ backgroundColor: '#0bc0df', borderRadius: '30px' }}
                onClick={toggleTimer}
              >
                {isRunning ? 'Stop' : 'Start'}
              </Button>
              <Button style={{ backgroundColor: '#0bc0df', borderRadius: '30px'  }} onClick={resetTimer}>Reset</Button>
            </Col>
            <Col md="1"></Col>
          </Row>
        </CardBody>
        <audio ref={audioRef}>
          <source src={AlarmSound} type="audio/mpeg" />
          Your browser does not support the audio element.
        </audio>
        </>
      ) : scheduleView ?
      (
      <>
        <div style={{margin: '10px'}}>
          <i className="fa fa-times" id="close" style={{fontSize: '22px', cursor: 'pointer', marginRight: '20px'}} onClick={toggleScheduleView} />
        </div>
        <AutoCountdownTableForTimerContentBox props={props} handleAddEditCountdown={handleAddEditCountdown}/>
      </>
      ) : formView ?
      (
        <>
        <div style={{margin: '10px'}}>
          <i className="fa fa-times" id="close" style={{fontSize: '22px', cursor: 'pointer', marginRight: '20px'}} onClick={toggleFormView} />
        </div>
        <AddEditCountdownForm props={[props.boxId, props.classId]}/>
      </>
      ) : null
      }
      </>
  );
};

export default Timer;
