import { useState } from "react";
import { Table, Progress } from "reactstrap";

const UserTable = ({ usersList }) => {
  const [sortConfig, setSortConfig] = useState({ key: null, direction: "asc" });

  // Sorting function
  const sortedUsers = [...usersList].sort((a, b) => {
    if (!sortConfig.key) return 0; // No sorting applied initially

    let aValue = a[sortConfig.key];
    let bValue = b[sortConfig.key];

    // Handle 'n/a' for sorting "Days Since"
    if (sortConfig.key === "daysSince") {
      aValue = aValue === "n/a" ? Infinity : aValue;
      bValue = bValue === "n/a" ? Infinity : bValue;
    }

    // Convert to numbers if sorting "Usage (%)"
    if (sortConfig.key === "percentage") {
      aValue = Number(aValue);
      bValue = Number(bValue);
    }

    if (aValue < bValue) return sortConfig.direction === "asc" ? -1 : 1;
    if (aValue > bValue) return sortConfig.direction === "asc" ? 1 : -1;
    return 0;
  });

  // Function to handle sorting changes
  const handleSort = (key) => {
    setSortConfig((prev) => ({
      key,
      direction: prev.key === key && prev.direction === "asc" ? "desc" : "asc",
    }));
  };

  return (
    <Table striped>
      <thead>
        <tr>
          <th>Email</th>
          <th>First Name</th>
          <th>Last Name</th>
          <th>Last Login</th>
          <th onClick={() => handleSort("daysSince")} style={{ cursor: "pointer" }}>
            Days Since {sortConfig.key === "daysSince" ? (sortConfig.direction === "asc" ? "⬆" : "⬇") : ""}
          </th>
          <th onClick={() => handleSort("percentage")} style={{ cursor: "pointer" }}>
            Usage (%) {sortConfig.key === "percentage" ? (sortConfig.direction === "asc" ? "⬆" : "⬇") : ""}
          </th>
        </tr>
      </thead>
      <tbody>
        {sortedUsers.map((user, index) => (
          <tr key={index}>
            <td>{user.email}</td>
            <td>{user.firstName}</td>
            <td>{user.lastName}</td>
            <td>{user.lastLogIn}</td>
            <td
              style={{
                backgroundColor:
                  user.daysSince === 0
                    ? "#28C76F"
                    : user.daysSince > 0 && user.daysSince <= 5
                    ? "#FFC107"
                    : user.daysSince > 5 || user.daysSince === "n/a"
                    ? "#FA5656"
                    : "transparent",
                color: "white",
                fontWeight: "bold",
                padding: "8px",
                borderRadius: "5px",
                textAlign: "center",
              }}
            >
              {user.daysSince}
            </td>
            <td>
              <Progress value={user.percentage} max={100} color={user.percentage > 80 ? "danger" : "primary"}>
                {user.percentage}%
              </Progress>
            </td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
};

export default UserTable;
