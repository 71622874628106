import React, { useEffect, useState } from 'react';
import { CardTitle } from "reactstrap";

const days = ['Sun', 'Mon', 'Tues', 'Wed', 'Thur', 'Fri', 'Sat'];
const daysLong = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'June', 'July', 'Aug', 'Sept', 'Octo', 'Nov', 'Dec'];
const monthsLong = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];

const daysFrench = ['dim', 'lun', 'mar', 'mer', 'jeu', 'ven', 'sam'];
const daysLongFrench = ['dimanche', 'lundi', 'mardi', 'mercredi', 'jeudi', 'vendredi', 'samedi'];
const monthsFrench = ['jan', 'fév', 'mar', 'avr', 'mai', 'juin', 'juil', 'août', 'sept', 'oct', 'nov', 'déc'];
const monthsLongFrench = ['janvier', 'février', 'mars', 'avril', 'mai', 'juin', 'juillet', 'août', 'septembre', 'octobre', 'novembre', 'décembre'];

const daysSpanish = ['dom', 'lun', 'mar', 'mié', 'jue', 'vie', 'sáb'];
const daysLongSpanish = ['domingo', 'lunes', 'martes', 'miércoles', 'jueves', 'viernes', 'sábado'];
const monthsSpanish = ['ene', 'feb', 'mar', 'abr', 'may', 'jun', 'jul', 'ago', 'sept', 'oct', 'nov', 'dic'];
const monthsLongSpanish = ['enero', 'febrero', 'marzo', 'abril', 'mayo', 'junio', 'julio', 'agosto', 'septiembre', 'octubre', 'noviembre', 'diciembre'];

const suffix = ['st', 'nd', 'rd', 'th', 'th', 'th', 'th', 'th', 'th', 'th', 'th', 'th', 'th', 'th', 'th', 'th', 'th', 'th', 'th', 'th', 'st', 'nd', 'rd', 'th', 'th', 'th', 'th', 'th', 'th', 'th', 'st'];

export const Clock = ({ showSeconds, clockVersion, clockFontColor, clockLanguage, clockKey }) => {
  const h24 = clockLanguage === "French" || clockLanguage === "Spanish";
  const [hour, setHour] = useState(0);
  const [minute, setMinute] = useState(0);
  const [second, setSecond] = useState(0);
  const [day, setDay] = useState(0);
  const [dayNumber, setDayNumber] = useState(0);
  const [month, setMonth] = useState(0);
  const [year, setYear] = useState(0);

  let formattedTime = `${hour}:${minute < 10 ? "0" + minute : minute}:${second < 10 ? "0" + second : second}`;

  const getFormattedDate = () => {
    if (clockLanguage === "French") {
      return `${daysLongFrench[day]} ${dayNumber === 1 ? "1er" : dayNumber} ${monthsLongFrench[month]} ${year}`;
    } else if (clockLanguage === "Spanish") {
      return `${daysLongSpanish[day]} ${dayNumber} de ${monthsLongSpanish[month]} de ${year}`;
    }
    return `${days[day]}, ${months[month]}. ${dayNumber + suffix[dayNumber - 1]}, ${year}`;
  };

  const getFormattedDateShort = () => {
    if (clockLanguage === "French") {
      return `${daysLongFrench[day]} ${dayNumber}/${month + 1}/${year}`;
    } else if (clockLanguage === "Spanish") {
      return `${daysLongSpanish[day]} ${dayNumber}/${month + 1}/${year}`;
    }
    return `${days[day]}. ${month + 1}/${dayNumber}/${year}`;
  };

  const getDayLongOnly = () => {
    if (clockLanguage === "French") {
      return `${daysLongFrench[day]}`;
    } else if (clockLanguage === "Spanish") {
      return `${daysLongSpanish[day]}`;
    }
    return `${daysLong[day]}`;
  };

  const getDateLongOnly = () => {
    if (clockLanguage === "French") {
      return `${dayNumber === 1 ? "1er" : dayNumber} ${monthsLongFrench[month]} ${year}`;
    } else if (clockLanguage === "Spanish") {
      return `${dayNumber} de ${monthsLongSpanish[month]} de ${year}`;
    }
    return `${monthsLong[month]} ${dayNumber + suffix[dayNumber - 1]}, ${year}`;
  };

  const getOption5 = () => {
    if (clockLanguage === "French" || clockLanguage === "Spanish") {
      return `${dayNumber}/${month + 1}/${year - 2000}`;
    }
    return `${month + 1}/${dayNumber}/${year - 2000}`;
  };

  if (!showSeconds) {
    formattedTime = formattedTime.replace(`:${second < 10 ? "0" + second : second}`, '');
  }

  useEffect(() => {
    const update = () => {
      const date = new Date();
      let hour = date.getHours();
      if (!h24) {
        hour = (hour % 12) || 12;
      }
      setHour(hour);
      setMinute(date.getMinutes());
      setSecond(date.getSeconds());
      setDay(date.getDay());
      setDayNumber(date.getDate());
      setMonth(date.getMonth());
      setYear(date.getFullYear());
    };

    update();
    const interval = setInterval(update, 1000);

    return () => clearInterval(interval);
  }, [clockLanguage, clockKey]);

  return (
    <div key={`clockKey-${clockKey}`}>
      {clockVersion === 1 && (
        <CardTitle className='h1' style={{ marginTop: '20px', color: clockFontColor }}>
          <div className="clock">{formattedTime}</div>
        </CardTitle>
      )}
      {clockVersion === 2 && (
        <CardTitle className='h1' style={{ marginTop: '0px', color: clockFontColor }}>
          <div className="clock">{formattedTime}</div>
          <div style={{ fontSize: '20px', textAlign: 'center' }}>{getFormattedDate()}</div>
        </CardTitle>
      )}
      {clockVersion === 3 && (
        <CardTitle className='h1' style={{ marginTop: '0px', color: clockFontColor }}>
          <div className="clock">{formattedTime}</div>
          <div style={{ fontSize: '27px', textAlign: 'center' }}>{getFormattedDateShort()}</div>
        </CardTitle>
      )}
      {clockVersion === 4 && (
        <CardTitle className='h1' style={{ marginTop: '10px', color: clockFontColor }}>
          <div style={{ fontSize: '35px', textAlign: 'center' }}>{getDayLongOnly()}</div>
          <div style={{ fontSize: '20px', textAlign: 'center' }}>{getDateLongOnly()}</div>
        </CardTitle>
      )}
      {clockVersion === 5 && (
        <CardTitle className='h2' style={{ marginTop: '15px', color: clockFontColor }}>
          <div style={{ fontSize: '55px', textAlign: 'center' }}>{getOption5()}</div>
        </CardTitle>
      )}
    </div>
  );
};

export default Clock;