import React, { useState, useEffect, useLayoutEffect, useRef } from "react";
import { db } from "/Users/michaeltucker/Documents/coding-projects/client/src/firebase.js";
import { setDoc, doc } from "firebase/firestore";
import backgroundOptionsData from "/Users/michaeltucker/Documents/coding-projects/client/src/BackgroundOptionsData.js";
import ReactBSAlert from "react-bootstrap-sweetalert";
import { saveContentBoxTemplate } from "saveTemplate";
import { useLocation } from "react-router-dom";
import { Card, CardBody, Col, CardFooter, Button, Modal, ModalBody, ModalHeader, ModalFooter, Container, Row, FormGroup, Label, Input, UncontrolledTooltip, ListGroup, ListGroupItem, Progress, CardTitle, CardHeader, InputGroup} from "reactstrap";
import ReactDatetime from "react-datetime";
import AgendaStep from "./AgendaStep";
import { createRandomId } from "RandomId";
import FontStylePicker from "../FontStylePicker";
import stampIcon from "/Users/michaeltucker/Documents/coding-projects/client/src/assets/img/stamp-solid.svg";
import ActiveDatesModal from "../ActiveDatesModal";
import AgendaBoxCopyForDebug from "./AgendaBoxCopyForDebug";
import moment from "moment";

function AgendaBox({content, classId, user, navbarMD, view, printActive, readOnly, clockKey, navigationVisible}) {
  const [cardHeight, setCardHeight] = useState({card: "auto", editor: "auto"}); // Initially set the height to 'auto'
  const [showMainEditButton, setShowMainEditButton] = useState();
  const [showButtons, setShowButtons] = useState();
  const [alert, setAlert] = useState(null);
  const location = useLocation();
  const [localAgendaBoxData, setLocalAgendaBoxData] = useState();
  const [boxData, setBoxData] = useState();
  const [boxToCopyId, setBoxToCopyId] = useState()
  const [boxToLinkId, setBoxToLinkId] = useState()
  const [copyBoxModal, setCopyBoxModal] = useState(false)
  const [linkBoxModal, setLinkBoxModal] = useState(false)
  const [classesSelectedForContentBoxCopy, setClassesSelectedForContentBoxCopy] = useState([]);
  const [classesSelectedForContentBoxLink, setClassesSelectedForContentBoxLink] = useState([]);
  const today = new Date().toISOString().slice(0,10)
  const stepRefs = useRef([]); // Ref array to track all steps
  const [agendaBoxVersion, setAgendaBoxVersion] = useState(0); //required to force re-render of AgendaBox preview in edit modal

  useEffect(() => {
    setShowMainEditButton(content.steps.length === 0 ? true : false);
    setShowButtons(content.steps.length === 0 ? true : false);
    setLocalAgendaBoxData(content);
    setBoxData(content);
  }, []);

  useEffect(() => {
    // Function to update the card height based on window height
    const updateCardHeight = () => {
      const windowHeight = window.innerHeight;
      const desiredDistance = navbarMD?.clock === '0' && navbarMD?.marquee === '0' ? 93 : 188; // Desired distance from the bottom
      const cardHeight = windowHeight - desiredDistance;
      setCardHeight({card: `${navigationVisible ? cardHeight : cardHeight+50}px`, editor: `${cardHeight-175}px`});
    };

    // Call the updateCardHeight function when the window is resized
    window.addEventListener("resize", updateCardHeight);

    // Initial call to set the card height
    updateCardHeight();

    // Remove the event listener when the component is unmounted
    return () => {
      window.removeEventListener("resize", updateCardHeight);
    };
  }, [navbarMD, navigationVisible]);

  // to stop the warning of calling setState of unmounted component
  React.useEffect(() => {
    return function cleanup() {
      var id = window.setTimeout(null, 0);
      while (id--) {
        window.clearTimeout(id);
      }
    };
  }, []);

const saveAgendaBoxData = async () => {
  try {
    const docRef = doc(db, "users", user.uid);
    
    const userCopy = JSON.parse(JSON.stringify(user));

    // Find the class index based on classId
    const classIndex = userCopy.classes.findIndex(
      (c) => c.classId === classId
    );

    if (classIndex !== -1) {
      // Find the content box index based on contentBoxId
      const agendaBoxIndex = userCopy.classes[classIndex].contentBoxes.findIndex(
        (box) => box.contentBoxId === content.contentBoxId
      );

      if (agendaBoxIndex !== -1) {
        // Make a copy of the existing content box using the spread operator
        const updatedAgendaBox = { ...localAgendaBoxData };

        // Create a new Date object for lastEdited
        var currentDate = new Date();
        var year = currentDate.getFullYear();
        var month = currentDate.getMonth() + 1;
        var day = currentDate.getDate();
        var formattedDate = (month < 10 ? '0' + month : month) + '/' + (day < 10 ? '0' + day : day) + '/' + year;

        updatedAgendaBox.lastEdited = formattedDate;

        // Update the content box in the current class
        userCopy.classes[classIndex].contentBoxes[agendaBoxIndex] = updatedAgendaBox;

        // Check if the box is a linkedBox and update linked boxes in other classes
        if (updatedAgendaBox.linkedBox) {
          // Loop through all classes and find boxes with the same contentBoxId and linkedBox: true
          userCopy.classes.forEach((classItem) => {
            classItem.contentBoxes.forEach((box, index) => {
              if (box.contentBoxId === updatedAgendaBox.contentBoxId && box.linkedBox) {
                // Update the linked box in other classes
                classItem.contentBoxes[index] = updatedAgendaBox;
              }
            });
          });
        }

        // Update the document in Firestore
        await setDoc(docRef, userCopy);

        setShowEditAgendaForm(!showEditAgendaForm);
      }
      setBoxData(localAgendaBoxData);
    }
  } catch (error) {
    console.error('Error updating document:', error);
  }
};

const [activeDatesModal, setActiveDatesModal] = useState(false)

  const toggleSetActiveDatesModal = () => {
    setActiveDatesModal(!activeDatesModal)
  }

  const [activeDates, setActiveDates] = useState({
    startDate: content?.startDate === undefined ? "" : content.startDate,
    endDate: content?.endDate === undefined ? "" : content.endDate
  });

  const addTemplateAlert = (contentBox) => {
    setAlert(
      <ReactBSAlert
        input
        showCancel
        style={{ display: "block", marginTop: "-100px" }}
        title="What would you like to call this template?"
        onConfirm={(e) => addTemplateConfirmAlert(e, contentBox)}
        onCancel={() => hideAlert()}
        confirmBtnBsStyle="info"
        cancelBtnBsStyle="danger"
        btnSize=""
        defaultValue={contentBox.heading}
      />
    );
  };
  
  const addTemplateConfirmAlert = (e, contentBox) => {
    saveContentBoxTemplate(user, e, contentBox);
    setAlert(
      <ReactBSAlert
        style={{ display: "block", marginTop: "-100px" }}
        onConfirm={() => hideAlert()}
        onCancel={() => hideAlert()}
        confirmBtnBsStyle="info"
        btnSize=""
        title={
          <p>
            You entered: <b>{e}</b>
          </p>
        }
      />
    );
  };

  const saveContentBoxActiveDates = async (datesFromSetActiveDatesModal) => {
    try {
      const docRef = doc(db, "users", user.uid);
  
      const userCopy = JSON.parse(JSON.stringify(user));
  
      // Find the class index based on classId
      const classIndex = userCopy.classes.findIndex(
        (c) => c.classId === classId
      );
  
      if (classIndex !== -1) {
        // Find the content box index based on contentBoxId
        const contentBoxIndex = userCopy.classes[classIndex].contentBoxes.findIndex(
          (box) => box.contentBoxId === content.contentBoxId
        );
  
        if (contentBoxIndex !== -1) {
          // Make a copy of the existing content box using the spread operator
          const updatedContentBox = { ...userCopy.classes[classIndex].contentBoxes[contentBoxIndex]};
  
          // Update the content box with the new data
          updatedContentBox.startDate = datesFromSetActiveDatesModal.startDate;
          updatedContentBox.endDate = datesFromSetActiveDatesModal.endDate;
  
          // Update the content box in the document data
          userCopy.classes[classIndex].contentBoxes[contentBoxIndex] = updatedContentBox;
  
          // Update the document in Firestore
          await setDoc(docRef, userCopy); // Replace with the correct path to your document

        }
        toggleSetActiveDatesModal();
      }
    } catch (error) {
      console.error('Error updating document:', error);
    }
};

const [backgroundContentBoxModal, setBackgroundContentBoxModal] = useState(false);

const toggleBackgroundContentBoxModal = () => {
  setBackgroundContentBoxModal(!backgroundContentBoxModal);
};

const toggleSetShowEditAgendaFormModal = () => {
  if (JSON.stringify(localAgendaBoxData) !== JSON.stringify(content)) {
    launchAlertForUnsavedAgendaData();
  } else {
    setShowEditAgendaForm(!showEditAgendaForm);
  }
};

const saveBackgroundContentBox = async (imageFileName) => {
  const thing = `url(${imageFileName})`
  try {
    const docRef = doc(db, "users", user.uid);

    const userCopy = JSON.parse(JSON.stringify(user));

    const classIndex = userCopy.classes.findIndex(
      (c) => c.classId === classId
    );

    const contentBoxIndex = userCopy.classes[classIndex].contentBoxes.findIndex(
      (box) => box.contentBoxId === content.contentBoxId
    );

    userCopy.classes[classIndex].contentBoxes[contentBoxIndex].background = thing;

    // Update the document in Firestore
    await setDoc(docRef, userCopy);

    setLocalAgendaBoxData((prev) => { 
      return {...prev, background: thing}
    })

  } catch (error) {
    console.error('Error updating document:', error);
  }
}

const sendBoxToStorage = async () => {
  try {
    const docRef = doc(db, "users", user.uid);

    const userCopy = JSON.parse(JSON.stringify(user));

    const classIndex = userCopy.classes.findIndex(
      (c) => c.classId === classId
    );

    const contentBoxIndex = userCopy.classes[classIndex].contentBoxes.findIndex(
      (box) => box.contentBoxId === content.contentBoxId
    );

    userCopy.classes[classIndex].contentBoxes[contentBoxIndex].active = false;

    // Update the document in Firestore
    await setDoc(docRef, userCopy);

  } catch (error) {
    console.error('Error updating document:', error);
  }
}

const zoomContentBox = async (direction) => {
  const zoomIncrement = direction === 'up' ? 0.1 : -0.1;
  try {
    const docRef = doc(db, "users", user.uid);

    const userCopy = JSON.parse(JSON.stringify(user));

    const classIndex = userCopy.classes.findIndex(
      (c) => c.classId === classId
    );

    const contentBoxIndex = userCopy.classes[classIndex].contentBoxes.findIndex(
      (box) => box.contentBoxId === content.contentBoxId
    );

    const previousZoom = userCopy.classes[classIndex].contentBoxes[contentBoxIndex].zoom || 1;

    userCopy.classes[classIndex].contentBoxes[contentBoxIndex].zoom = previousZoom + zoomIncrement;

    // Update the document in Firestore
    await setDoc(docRef, userCopy);

    // Update local state to trigger re-render
    setBoxData({
      ...boxData,
      zoom: previousZoom + zoomIncrement,
    });

  } catch (error) {
    console.error('Error updating document:', error);
  }
};

const warningWithConfirmAndCancelMessage = (id) => {
  setAlert(
    <ReactBSAlert
      warning
      style={{ display: "block", marginTop: "-100px" }}
      title="Are you sure?"
      onConfirm={() => deleteContentBox(id)}
      onCancel={() => cancelDelete()}
      confirmBtnBsStyle="info"
      confirmBtnStyle={{backgroundColor: '#dc3545'}}
      cancelBtnBsStyle="danger"
      confirmBtnText="Yes, delete it!"
      cancelBtnText="Cancel"
      showCancel
      btnSize=""
    >
      You will not be able to recover this content box!
    </ReactBSAlert>
  );
};

const successDelete = () => {
  setAlert(
    <ReactBSAlert
      success
      style={{ display: "block", marginTop: "-100px" }}
      title="Deleted!"
      onConfirm={() => hideAlert()}
      onCancel={() => hideAlert()}
      confirmBtnBsStyle="info"
      btnSize=""
    >
      Your content box has been deleted.
    </ReactBSAlert>
  );
};

const cancelDelete = () => {
  setAlert(
    <ReactBSAlert
      danger
      style={{ display: "block", marginTop: "-100px" }}
      title="Cancelled"
      onConfirm={() => hideAlert()}
      onCancel={() => hideAlert()}
      confirmBtnBsStyle="info"
      btnSize=""
    >
      Your content box is safe :)
    </ReactBSAlert>
  );
};

const launchAlertForUnsavedAgendaData = () => {
  setAlert(
    <ReactBSAlert
      warning
      style={{ display: "block", marginTop: "-100px" }}
      title="You have unsaved changes!"
      onConfirm={() => {saveAgendaBoxData(); hideAlert()}}
      onCancel={() => {setShowEditAgendaForm(!showEditAgendaForm); hideAlert()}}
      confirmBtnBsStyle="info"
      confirmBtnStyle={{backgroundColor: 'rgb(11, 192, 223)', borderRadius: '30px'}}
      cancelBtnBsStyle="danger"
      confirmBtnText="Save changes!"
      cancelBtnStyle={{backgroundColor: '#dc3545', borderRadius: '30px'}}
      cancelBtnText="Close anyway"
      showCancel
      btnSize=""
    >
      You will not be able to recover your edits!
    </ReactBSAlert>
  );
};

const deleteContentBox = async (contentBoxId) => {
  try {
    const docRef = doc(db, "users", user.uid);

    const userCopy = JSON.parse(JSON.stringify(user));

    const classIndex = userCopy.classes.findIndex(
      (c) => c.classId === classId
    );

    const contentBoxIndex = userCopy.classes[classIndex].contentBoxes.findIndex(
      (box) => box.contentBoxId === contentBoxId
    );

    // Remove the content box at the specified index
    userCopy.classes[classIndex].contentBoxes.splice(contentBoxIndex, 1);

    // Update the document in Firestore
    await setDoc(docRef, userCopy);

    // // Update the local state with the updated document data
    // setStateContent(userCopy);

    successDelete()

    console.log('Content box deleted successfully!');
  } catch (error) {
    console.error('Error deleting content box:', error);
  }
}
  
const successAlert = () => {
  setAlert(
    <ReactBSAlert
      success
      style={{ display: "block", marginTop: "-100px" }}
      title="Saved!"
      onConfirm={() => hideAlert()}
      onCancel={() => hideAlert()}
      confirmBtnBsStyle="info"
      btnSize=""
    >
    </ReactBSAlert>
  );
};

const hideAlert = () => {
  setAlert(null);
};

function convertSecondsToMinutes(step) {
  const totalSeconds = (Number(step.seconds) + (Number(step.minutes) * 60)) - Number(step.timeElapsed)
  const minutes = Math.floor(totalSeconds / 60);
  const remainingSeconds = totalSeconds % 60;
  return `${minutes}:${remainingSeconds.toString().padStart(2, '0')}`;
}

const [showEditAgendaForm, setShowEditAgendaForm] = useState(false);

const addAgendaStep = () => {
  const newStep = {
    stepId: createRandomId(),
    title: '', // Placeholder for step text
    minutes: 0,
    seconds: 0,
    subTask: false,
    subTaskSteps: []
  };
  // Add a new step object to the steps array
  setLocalAgendaBoxData(prevData => ({
    ...prevData,
    steps: [...prevData.steps, newStep]
  }));
};

const addAgendaSubTaskStep = (stepId) => {
  const stepIndex = localAgendaBoxData.steps.findIndex((step) => step.stepId === stepId);

  if (stepIndex !== -1) {
    const newSubTaskStep = {
      stepId: createRandomId(),
      title: '', // Placeholder for step text
      minutes: 0,
      seconds: 0,
    };

    setLocalAgendaBoxData(prevData => {
      const newSteps = [...prevData.steps]; // Create a copy of steps
      
      // Correcting how subTaskSteps are updated
      const updatedSubTaskSteps = newSteps[stepIndex].subTaskSteps 
        ? [...newSteps[stepIndex].subTaskSteps, newSubTaskStep] // If it exists, spread and add new
        : [newSubTaskStep]; // Otherwise, start a new array

      newSteps[stepIndex] = {...newSteps[stepIndex], subTaskSteps: updatedSubTaskSteps}; // Update the step object immutably

      return { ...prevData, steps: newSteps }; // Return new object with updated steps
    });
  }
};

function deleteAgendaStep(stepId) {
  const updatedData = localAgendaBoxData.steps.filter(step => step.stepId !== stepId);
  setLocalAgendaBoxData(prevData => ({
    ...prevData,
    steps: updatedData
  }));
}

function deleteAgendaSubTaskStep(stepIndex, subTaskStepId) {
  // Use a functional update to ensure you're working with the most up-to-date state
  setLocalAgendaBoxData(prevData => {
    // Create a deep copy of the steps to avoid any direct mutation
    let newSteps = prevData.steps.map(step => ({
      ...step,
      subTaskSteps: step.subTaskSteps ? [...step.subTaskSteps] : []
    }));

    // Filter the subTaskSteps array to remove the specified subTaskStep
    newSteps[stepIndex].subTaskSteps = newSteps[stepIndex].subTaskSteps.filter(subTaskStep => 
      subTaskStep.stepId !== subTaskStepId
    );

    // Return the updated state object
    return {
      ...prevData,
      steps: newSteps
    };
  });
}

const handleAgendaTitleChange = (e) => {
  setLocalAgendaBoxData((prevData) => ({
    ...prevData,
    heading: e.target.value,
  }));

  setAgendaBoxVersion((prev) => prev + 1); // Increment version to trigger re-render
};

const handleAgendaStartTimeChange = (e) => {
  const startTime = e.target.value;

  console.log(startTime)
  setLocalAgendaBoxData((prevData) => ({
    ...prevData,
    startTime: startTime,
  }));

  // console.log('old time => ', e._d.toTimeString())

  setAgendaBoxVersion((prev) => prev + 1); // Increment version to trigger re-render
};

// const handleAgendaStartTimeChange = (time) => {
//   if (!time || !moment(time).isValid()) return;

//   // Format the selected time to "h:mm A" (e.g., "2:43 PM")
//   const formattedTime = moment(time).format("h:mm A");

//   console.log("Adjusted Time:", formattedTime);

//   // Save the formatted time in the state
//   setLocalAgendaBoxData((prev) => ({ ...prev, startTime: formattedTime }));
//   setAgendaBoxVersion((prev) => prev + 1); // Increment version to trigger re-render
// };

// const handleAgendaStartTimeChange = (time) => {
//   let formattedTime = moment(time).format("h:mm A");

//   // Explicitly correct 12 AM and 12 PM cases
//   if (formattedTime.startsWith("12") && formattedTime.includes("AM")) {
//     formattedTime = "12:" + formattedTime.split(":")[1]; // Ensure AM
//   } else if (formattedTime.startsWith("12") && formattedTime.includes("PM")) {
//     formattedTime = "12:" + formattedTime.split(":")[1]; // Ensure PM
//   }

//   console.log("Adjusted Time:", formattedTime);
//   setLocalAgendaBoxData((prev) => ({ ...prev, startTime: formattedTime }));
// };

const handleStepTextChange = (stepId, e) => {
  setLocalAgendaBoxData((prevData) => {
    const updatedSteps = prevData.steps.map((step) =>
      step.stepId === stepId ? { ...step, title: e.target.value } : step
    );

    return { ...prevData, steps: updatedSteps };
  });

  setAgendaBoxVersion((prev) => prev + 1); // Increment version
};

const handleSubTaskStepTextChange = (stepIndex, stepId, e) => {
  setLocalAgendaBoxData((prevData) => {
    const updatedSteps = prevData.steps.map((step, index) => {
      if (index === stepIndex) {
        const updatedSubTaskSteps = step.subTaskSteps.map((subTaskStep) =>
          subTaskStep.stepId === stepId ? { ...subTaskStep, title: e.target.value } : subTaskStep
        );

        return { ...step, subTaskSteps: updatedSubTaskSteps };
      }
      return step;
    });

    return { ...prevData, steps: updatedSteps };
  });

  setAgendaBoxVersion((prev) => prev + 1); // Increment version
};

const handleStepDurationChange = (stepId, e, minute) => {
  const field = minute ? "minutes" : "seconds";
  setLocalAgendaBoxData((prevData) => {
    const updatedSteps = prevData.steps.map((step) =>
      step.stepId === stepId ? { ...step, [field]: e.target.value } : step
    );

    return { ...prevData, steps: updatedSteps };
  });

  setAgendaBoxVersion((prev) => prev + 1); // Increment version
};

const handleSubTaskStepDurationChange = (stepIndex, stepId, e, minute) => {
  const field = minute ? "minutes" : "seconds";
  const input = parseInt(e.target.value, 10) || 0;

  setLocalAgendaBoxData((prevData) => {
    const updatedSteps = prevData.steps.map((step, index) => {
      if (index === stepIndex) {
        const updatedSubTaskSteps = step.subTaskSteps.map((subTaskStep) =>
          subTaskStep.stepId === stepId ? { ...subTaskStep, [field]: input } : subTaskStep
        );

        // Calculate total duration from all subTaskSteps
        const totalMinutes = updatedSubTaskSteps.reduce((acc, sub) => acc + (sub.minutes || 0), 0);
        const totalSeconds = updatedSubTaskSteps.reduce((acc, sub) => acc + (sub.seconds || 0), 0);

        // Normalize total duration
        const normalizedMinutes = totalMinutes + Math.floor(totalSeconds / 60);
        const normalizedSeconds = totalSeconds % 60;

        return {
          ...step,
          subTaskSteps: updatedSubTaskSteps,
          minutes: normalizedMinutes,
          seconds: normalizedSeconds,
        };
      }
      return step;
    });

    return { ...prevData, steps: updatedSteps };
  });

  setAgendaBoxVersion((prev) => prev + 1); // Increment version
};

const handleAgendaFontChange = (e) => {
  setLocalAgendaBoxData((prevData) => ({
    ...prevData,
    [e.target.id]: e.target.value,
  }));

  setAgendaBoxVersion((prev) => prev + 1); // Increment version
};

const toggleCopyBoxModal = () => {
  setCopyBoxModal(!copyBoxModal)
  setClassesSelectedForContentBoxCopy([])
}

const toggleLinkedBoxModal = () => {
  if (linkBoxModal) {
    setClassesSelectedForContentBoxLink([])
  } else {
    setClassesSelectedForContentBoxLink([classId])
  }
  setLinkBoxModal(!linkBoxModal)
}

const handleCheckboxChange = (classId) => {
  setClassesSelectedForContentBoxCopy(prev => {
    if (prev.includes(classId)) {
      // If classId is already selected, remove it
      return prev.filter(id => id !== classId);
    } else {
      // If classId is not selected, add it
      return [...prev, classId];
    }
  });
};

const handleCheckboxChangeForLinkedBoxes = (classId) => {
  setClassesSelectedForContentBoxLink(prev => {
    if (prev.includes(classId)) {
      // If classId is already selected, remove it
      return prev.filter(id => id !== classId);
    } else {
      // If classId is not selected, add it
      return [...prev, classId];
    }
  });
};

const addLinkedBoxToClasses = async () => {
  try {
    const docRef = doc(db, "users", user.uid);
    
    const userCopy = JSON.parse(JSON.stringify(user));
    
    // Find the class index based on classId
    const currentClassIndex = userCopy.classes.findIndex(
      (c) => c.classId === classId
    );
    
    let boxToCopy = userCopy.classes[currentClassIndex].contentBoxes.find(box => box.contentBoxId === boxToLinkId);
    
    // Add "linkedBox: true" property
    boxToCopy = { ...boxToCopy, linkedBox: true };
    
    let classesCopy = [...userCopy.classes];

    classesSelectedForContentBoxLink.forEach(targetClassId => {
      const targetClassIndex = classesCopy.findIndex(c => c.classId === targetClassId);

      if (targetClassIndex !== -1) {
        // If the target class is the current class, remove the old box before adding the updated one
        if (targetClassId === classId) {
          const updatedContentBoxes = classesCopy[targetClassIndex].contentBoxes.filter(box => box.contentBoxId !== boxToLinkId);
          classesCopy[targetClassIndex] = {
            ...classesCopy[targetClassIndex],
            contentBoxes: [...updatedContentBoxes, boxToCopy]  // Add the updated box with `linkedBox: true`
          };
        } else {
          // For other classes, just add the new box to contentBoxes
          classesCopy[targetClassIndex] = {
            ...classesCopy[targetClassIndex],
            contentBoxes: [...classesCopy[targetClassIndex].contentBoxes, boxToCopy]
          };
        }
      } else {
        console.log(`Class with ID ${targetClassId} not found.`);
      }
    });

    userCopy.classes = classesCopy;

    console.log("userCopy => ", userCopy)

    await setDoc(docRef, userCopy);

    toggleLinkedBoxModal();

  } catch (error) {
    console.log("failed", error);
  }
};

const copyBoxToClasses = async () => {
  try {
    const docRef = doc(db, "users", user.uid);

    const userCopy = JSON.parse(JSON.stringify(user));

    // Find the class index based on classId
    const classIndex = userCopy.classes.findIndex(
      (c) => c.classId === classId
    );

    let boxToCopy = userCopy.classes[classIndex].contentBoxes.find(box => box.contentBoxId === boxToCopyId);
    boxToCopy = { ...boxToCopy, contentBoxId: createRandomId() };

    let classesCopy = [...userCopy.classes];

    classesSelectedForContentBoxCopy.forEach(classId => {
      const classIndex = classesCopy.findIndex(c => c.classId === classId);

      if (classIndex !== -1) {
        // Copy the contentBoxes array and add the new box
        const updatedContentBoxes = [...classesCopy[classIndex].contentBoxes, boxToCopy];
        classesCopy[classIndex] = {
          ...classesCopy[classIndex],
          contentBoxes: updatedContentBoxes
        };
      } else {
        console.log(`Class with ID ${classId} not found.`);
      }
    });

    userCopy.classes = classesCopy;

    await setDoc(docRef, userCopy);

    toggleCopyBoxModal()

  } catch (error) {
    console.log("failed", error);
  }
};

const handleStepMove = (index, direction) => {
  let updatedSteps = [...localAgendaBoxData.steps];
  
  if (index > 0 && direction === "up") {
    // Swap the current item with the one above it
    [updatedSteps[index], updatedSteps[index - 1]] = [
      updatedSteps[index - 1],
      updatedSteps[index],
    ];
  }
  if (index < localAgendaBoxData.steps.length - 1 && direction === "down") {
    // Swap the current item with the one below it
    [updatedSteps[index], updatedSteps[index + 1]] = [
      updatedSteps[index + 1],
      updatedSteps[index],
    ];
  }

  setLocalAgendaBoxData({
    ...localAgendaBoxData,
    steps: updatedSteps,
    version: (localAgendaBoxData.version || 0) + 1 // Increment the version to force re-render
  });
};

const handleSubTaskStepMove = (stepIndex, subTaskStepIndex, direction) => {
  // Create a deep copy of the steps array
  const updatedSteps = localAgendaBoxData.steps.map((step, idx) => {
    if (idx === stepIndex) {
      return {
        ...step,
        subTaskSteps: [...step.subTaskSteps] // Ensure subTaskSteps is a new array
      };
    }
    return step;
  });

  // Perform the swap on the copied subTaskSteps array
  const updatedSubTaskSteps = updatedSteps[stepIndex].subTaskSteps;
  if (subTaskStepIndex > 0 && direction === "up") {
    [updatedSubTaskSteps[subTaskStepIndex], updatedSubTaskSteps[subTaskStepIndex - 1]] = [
      updatedSubTaskSteps[subTaskStepIndex - 1],
      updatedSubTaskSteps[subTaskStepIndex],
    ];
  } else if (subTaskStepIndex < updatedSubTaskSteps.length - 1 && direction === "down") {
    [updatedSubTaskSteps[subTaskStepIndex], updatedSubTaskSteps[subTaskStepIndex + 1]] = [
      updatedSubTaskSteps[subTaskStepIndex + 1],
      updatedSubTaskSteps[subTaskStepIndex],
    ];
  }

  // Update the state with the new steps array
  setLocalAgendaBoxData({
    ...localAgendaBoxData,
    steps: updatedSteps,
    version: (localAgendaBoxData.version || 0) + 1 // Increment the version to force re-render
  });
};

const [showAddResourceLink, setShowAddResourceLink] = useState(false);
const [indexOfStepToEditResource, setIndexOfStepToEditResource] = useState();
const [indexOfSubStepToEditResource, setIndexOfSubStepToEditResource] = useState();
const [stepOrSubStep, setStepOrSubStep] = useState();
const [resourceLink, setResourceLink] = useState();
const [isResourceLinkValid, setIsResourceLinkValid] = useState(true);


const handleAddResourceLinkClick = (stepIndex, subStepIndex, stepOrSubStep) => {
  if (stepOrSubStep?.length > 0) { //Opening Modal
    setShowAddResourceLink(!showAddResourceLink);
    const resourceLinkToEdit = stepOrSubStep === "step" ? localAgendaBoxData.steps[stepIndex]?.resourceLink || null : localAgendaBoxData.steps[stepIndex].subTaskSteps[subStepIndex]?.resourceLink || null;
    setResourceLink(resourceLinkToEdit);
    setIndexOfStepToEditResource(stepIndex);
    setIndexOfSubStepToEditResource(subStepIndex)
    setStepOrSubStep(stepOrSubStep);
  } else { //Closing Modal
    setShowAddResourceLink(!showAddResourceLink);
    setResourceLink(null);
    setIndexOfStepToEditResource(null);
    setIndexOfSubStepToEditResource(null);
    setStepOrSubStep(null);
  }
}

const handleResourceLinkChange = (value) => {
  if (!/^https?:\/\//i.test(value)) {
    value = `https://${value}`;
  }
  setResourceLink(value);
  setIsResourceLinkValid(validateUrl(value));
}

const validateUrl = (value) => {
  try {
    new URL(value);
    return true;
  } catch (_) {
    return false;
  }
};

const handleResourceChangeSubmit = () => {
  if (stepOrSubStep === "step") {
    let updatedSteps = [...localAgendaBoxData.steps];

    // Create a new object with the updated resourceLink
    let updatedStep = {
      ...updatedSteps[indexOfStepToEditResource],
      resourceLink: resourceLink
    };

    // Replace the old step with the new one
    updatedSteps[indexOfStepToEditResource] = updatedStep;

    // Update the state with the new steps array
    setLocalAgendaBoxData({
      ...localAgendaBoxData,
      steps: updatedSteps,
    });
  } else if (stepOrSubStep === "subStep") {
    let updatedSteps = [...localAgendaBoxData.steps];

    // Create a new object with the updated resourceLink for the subStep
    let updatedSubTaskSteps = [...updatedSteps[indexOfStepToEditResource].subTaskSteps];
    updatedSubTaskSteps[indexOfSubStepToEditResource] = {
      ...updatedSubTaskSteps[indexOfSubStepToEditResource],
      resourceLink: resourceLink
    };

    // Create a new object for the step that contains the updated subTaskSteps
    let updatedStep = {
      ...updatedSteps[indexOfStepToEditResource],
      subTaskSteps: updatedSubTaskSteps
    };

    // Replace the old step with the new one
    updatedSteps[indexOfStepToEditResource] = updatedStep;

    // Update the state with the new steps array
    setLocalAgendaBoxData({
      ...localAgendaBoxData,
      steps: updatedSteps,
    });
  } else {
    window.alert("error");
  }

  setShowAddResourceLink(!showAddResourceLink);
};

const calculateTotalTime = () => {
  let runningTotal = 0; // Use let since the value is updated

  // Accumulate total seconds from steps
  localAgendaBoxData.steps.forEach(step => {
    const stepMinutes = step.minutes * 60 || 0; // Handle potential undefined values
    const stepSeconds = step.seconds || 0; // Handle potential undefined values
    runningTotal += Number(stepMinutes) + Number(stepSeconds);
  });

  // Calculate minutes and seconds
  const totalMinutes = Math.floor(runningTotal / 60); // Convert total seconds to minutes
  const remainingSeconds = runningTotal % 60; // Get the remaining seconds

  return `${totalMinutes} minutes ${remainingSeconds.toString()} seconds`; // Format with leading zero for seconds
};

useEffect(() => {
  if (boxData?.steps) {
    stepRefs.current = boxData.steps.map((_, i) => stepRefs.current[i] || React.createRef());
  }
}, [boxData?.steps]);

useLayoutEffect(() => {
  setTimeout(() => {
    if (boxData) {
      const activeStepIndex = boxData.steps.findIndex((step) => step.active);
      if (activeStepIndex !== -1 && stepRefs.current[activeStepIndex]?.current) {
        stepRefs.current[activeStepIndex].current.scrollIntoView({
          behavior: 'smooth',
          block: 'start',
        });
      }
    }
  }, 50); // Delay to ensure DOM updates
}, [boxData?.steps]);

  return boxData && (
    <>
      <Modal className="open-widget-modal" isOpen={showAddResourceLink} toggle={handleAddResourceLinkClick} size="sm">
        <ModalHeader toggle={handleAddResourceLinkClick}></ModalHeader>
        <ModalBody>
          <div>
            Input your link.
            <Input
              type="text"
              onChange={(e) => handleResourceLinkChange(e.target.value)}
              placeholder={"Your link here..."}
              required={true}
              defaultValue={resourceLink}
              valid={isResourceLinkValid}
              invalid={!isResourceLinkValid}
              prefix="http://"
            />
          </div>
        </ModalBody>
        <ModalFooter style={{display: 'flex', justifyContent: 'center', alignContent: 'center', border: '0px', paddingBottom: '10px'}}>
          <Button
            disabled={!isResourceLinkValid}
            onClick={() => handleResourceChangeSubmit()}
            style={{borderRadius: '30px'}}
          >
            Add Link
          </Button>
        </ModalFooter>
      </Modal>

    <Modal isOpen={copyBoxModal} toggle={toggleCopyBoxModal}>
      <ModalBody>
        <h5>Copy box to:</h5>
        {user && user.classes && user.classes.map(x => 
          <FormGroup check>
            <Label check>
            <Input
              type="checkbox"
              defaultChecked={classesSelectedForContentBoxCopy.length > 0 && classesSelectedForContentBoxCopy.includes(x.classId)}
              onChange={() => handleCheckboxChange(x.classId)}
            />                
            <span className="form-check-sign" />
            {x.className}
            </Label>
          </FormGroup>
        )}
      </ModalBody>
      <ModalFooter>
        <Button disabled={classesSelectedForContentBoxCopy.length < 1} onClick={() => copyBoxToClasses()}>Copy</Button>
        <Button onClick={toggleCopyBoxModal}>Cancel</Button>
      </ModalFooter>
    </Modal>

    <Modal isOpen={linkBoxModal} toggle={toggleLinkedBoxModal}>
      <ModalBody>
        <h5>Add this Linked Content Box to:</h5>
        {user && user.classes && user.classes.map(x => 
          <FormGroup check>
            <Label check>
            <Input
              type="checkbox"
              defaultChecked={classesSelectedForContentBoxLink.length > 0 && classesSelectedForContentBoxLink.includes(x.classId)}
              onChange={() => handleCheckboxChangeForLinkedBoxes(x.classId)}
              disabled={x.classId === classId}
            />                
            <span className="form-check-sign" />
            {x.className}
            </Label>
          </FormGroup>
        )}
      </ModalBody>
      <ModalFooter>
        <Button disabled={classesSelectedForContentBoxLink.length < 1} onClick={() => addLinkedBoxToClasses()}>Add</Button>
        <Button onClick={toggleLinkedBoxModal}>Cancel</Button>
      </ModalFooter>
    </Modal>


    <Col 
      style={{ flex: "1", minHeight: "0", padding: '4px',  height: '100%', border: showButtons ? 'rgb(11, 192, 223) solid 2px' : 'transparent solid 2px', borderRadius: '15px' }}
      onMouseEnter={() => content.steps.length !== 0 && setShowMainEditButton(true)}
      onMouseLeave={() => { content.steps.length !== 0 && setShowMainEditButton(false); setShowButtons(false)}}
    >
        {alert}
        <Card 
          key={content?.contentBoxId}
          style={{ height: location.pathname === '/print' ? '800px' : cardHeight.card, backgroundImage: location.pathname !== '/print' && content?.background || '', zoom: location.pathname === '/print' ? '.6' : '1', border: printActive === true ? '4px solid blue' : location.pathname === '/print' ? 'solid 1px' : 'none'}}
        >
          {showMainEditButton && location.pathname !== '/add-edit-classes' && location.pathname !== '/print' && !location.pathname.includes('/share/') && !readOnly && (
            <>
              <Button
                // className="btn-round btn-icon"
                id={`options-${content.contentBoxId}`}
                type="button"
                style={{ backgroundColor: 'rgb(11, 192, 223) !important', color: 'white', position: 'absolute', transform: 'translateY(-60%)', right: '-12px', borderRadius: '30px', height: '30px', width: '30px', padding: '7px 11px', zIndex: '1049', overflow: 'visible'}}
                size="sm"
                onClick={() => setShowButtons(!showButtons)}
              >
                <i className={showButtons === false ? "fa fa-caret-down" : "fa fa-caret-up"} />
              </Button>
                <UncontrolledTooltip
                  delay={0}
                  target={`options-${content.contentBoxId}`}
                  placement="left"
                >
                    Options
                </UncontrolledTooltip>
                {showButtons && location.pathname !== '/add-edit-classes' && (
                  <>
                    <Button
                      className={content.steps.length === 0 ? "empty-agenda-box-alert" : null}
                      id="editAgendaForm"
                      type="button"
                      style={{ backgroundColor: 'rgb(11, 192, 223) !important', color: 'white', position: 'absolute', transform: 'translateY(60%)', right: '-12px', borderRadius: '30px', height: '30px', width: '30px', padding: '6px 10px', zIndex: '1049', overflow: 'visible'}}
                      size="sm"
                      onClick={() => setShowEditAgendaForm(!showEditAgendaForm)}
                    >
                      <i className="fa fa-pencil" />
                    </Button>
                      <UncontrolledTooltip
                        delay={0}
                        target="editAgendaForm"
                        placement="left"
                      >
                          Edit Agenda
                      </UncontrolledTooltip>
                    <Button
                    // className="btn-round btn-icon"
                    id="changeContentBoxBackground"
                    type="button"
                    style={{ backgroundColor: 'rgb(11, 192, 223) !important', color: 'white', position: 'absolute', transform: 'translateY(180%)', right: '-12px', borderRadius: '30px', height: '30px', width: '30px', padding: '6px 8px', zIndex: '1049'}}
                    size="sm"
                    onClick={() => toggleBackgroundContentBoxModal()}
                    >
                      <i class="nc-icon nc-palette"></i>             
                    </Button>
                      <UncontrolledTooltip
                        delay={0}
                        target="changeContentBoxBackground"
                        placement="left"
                      >
                          Change Background
                      </UncontrolledTooltip>
                    <Button
                      // className="btn-round btn-icon"
                      id="agendaBoxBigger"
                      type="button"
                      style={{ backgroundColor: 'rgb(11, 192, 223) !important', color: 'white', position: 'absolute', transform: 'translateY(300%)', right: '-12px', borderRadius: '30px', height: '30px', width: '30px', padding: '6px 10px', zIndex: '1049'}}
                      size="sm"
                      onClick={() => zoomContentBox('up')}
                    >
                      <i class="fa fa-plus"></i>             
                    </Button>
                      <UncontrolledTooltip
                        delay={0}
                        target="agendaBoxBigger"
                        placement="left"
                      >
                        Bigger
                      </UncontrolledTooltip>
                    <Button
                      // className="btn-round btn-icon"
                      id="agendaBoxSmaller"
                      type="button"
                      style={{ backgroundColor: 'rgb(11, 192, 223) !important', color: 'white', position: 'absolute', transform: 'translateY(420%)', right: '-12px', borderRadius: '30px', height: '30px', width: '30px', padding: '6px 10px', zIndex: '1049'}}
                      size="sm"
                      onClick={() => zoomContentBox('down')}
                    >
                      <i class="fa fa-minus"></i>             
                    </Button>
                      <UncontrolledTooltip
                        delay={0}
                        target="agendaBoxSmaller"
                        placement="left"
                      >
                        Smaller
                      </UncontrolledTooltip>
                      {user.settings?.useActiveDates ? (
                        <>
                        <Button
                        // className="btn-round btn-icon"
                        id="setContentBoxActiveDates"
                        type="button"
                        style={{ backgroundColor: 'rgb(11, 192, 223) !important', color: 'white', position: 'absolute', transform: 'translateY(540%)', right: '-12px', borderRadius: '30px', height: '30px', width: '30px', padding: '4px 9px', zIndex: '1049'}}
                        size="sm"
                        onClick={() => toggleSetActiveDatesModal()}
                        // onClick={null}
                        >
                          <i className="fa fa-calendar" />
                        </Button>
                          <UncontrolledTooltip
                            delay={0}
                            target="setContentBoxActiveDates"
                            placement="left"
                          >
                              Set Active Dates
                          </UncontrolledTooltip>
                        </>
                      ) :
                      (
                        <>
                        <Button
                        // className="btn-round btn-icon"
                        id="sendBoxToStorage"
                        type="button"
                        style={{ backgroundColor: 'rgb(11, 192, 223) !important', color: 'white', position: 'absolute', transform: 'translateY(540%)', right: '-12px', borderRadius: '30px', height: '30px', width: '30px', padding: '5px 8px', zIndex: '1049'}}
                        size="sm"
                        onClick={() => sendBoxToStorage()}
                        // onClick={null}
                        >
                          <i className="nc-icon nc-box" />
                        </Button>
                          <UncontrolledTooltip
                            delay={0}
                            target="sendBoxToStorage"
                            placement="left"
                          >
                              Send Box to Storage
                          </UncontrolledTooltip>
                        </>
                      )}
                    <Button
                      // className="btn-round btn-icon"
                      id="copyBox"
                      type="button"
                      style={{ backgroundColor: 'rgb(11, 192, 223) !important', color: 'white', position: 'absolute', transform: 'translateY(660%)', right: '-12px', borderRadius: '30px', height: '30px', width: '30px', padding: '5px 8px', zIndex: '1049'}}
                      size="sm"
                      onClick={() => {setBoxToCopyId(content.contentBoxId); toggleCopyBoxModal()}}
                      >
                        <i className="fa fa-copy" />
                      </Button>
                        <UncontrolledTooltip
                          delay={0}
                          target="copyBox"
                          placement="left"
                        >
                            Duplicate Box
                        </UncontrolledTooltip>

                    <Button
                      // className="btn-round btn-icon"
                      id="saveContentBoxAsTemplate"
                      type="button"
                      style={{ backgroundColor: 'rgb(11, 192, 223) !important', color: 'white', position: 'absolute', transform: 'translateY(780%)', right: '-12px', borderRadius: '30px', height: '30px', width: '30px', padding: '4px 8px', zIndex: '1049'}}
                      size="sm"
                      onClick={() => addTemplateAlert(content)}
                    >
                      <img
                          src={stampIcon}
                          alt="stamp"
                          style={{ height: '13px'}}
                      />
                    </Button>
                      <UncontrolledTooltip
                        delay={0}
                        target="saveContentBoxAsTemplate"
                        placement="left"
                      >
                          Save as Template
                      </UncontrolledTooltip>

                      <Button
                        // className="btn-round btn-icon"
                        id="makeLinkedBox"
                        type="button"
                        style={{ backgroundColor: 'rgb(11, 192, 223) !important', color: 'white', position: 'absolute', transform: 'translateY(900%)', right: '-12px', borderRadius: '30px', height: '30px', width: '30px', padding: '4px 8px', zIndex: '1049'}}
                        size="sm"
                        onClick={() => {setBoxToLinkId(content.contentBoxId); toggleLinkedBoxModal()}}
                      >
                        <i class="fa fa-link"></i>
                      </Button>
                      <UncontrolledTooltip
                        delay={0}
                        target="makeLinkedBox"
                        placement="left"
                      >
                          Make Linked Box (Beta)
                      </UncontrolledTooltip>
                    {/* <Button
                    // className="btn-round btn-icon"
                    id="saveContentBoxAsTemplate"
                    type="button"
                    style={{ backgroundColor: 'rgb(11, 192, 223) !important', color: 'white', position: 'absolute', transform: 'translateY(420%)', right: '-12px', borderRadius: '30px', height: '30px', width: '30px', padding: '4px 8px', zIndex: '9999'}}
                    size="sm"
                    onClick={() => addTemplateAlert(content)}
                    >
                      <i className="fa fa-copy" />
                    </Button>
                      <UncontrolledTooltip
                        delay={0}
                        target="saveContentBoxAsTemplate"
                        placement="left"
                      >
                          Save as Template
                      </UncontrolledTooltip> */}
                    <Button
                    className="button-delete"
                    id="deleteContentBox"
                    type="button"
                    style={{ color: 'white', position: 'absolute', transform: 'translateY(1020%)', right: '-12px', borderRadius: '30px', height: '30px', width: '30px', padding: '4px 9px', zIndex: '1049'}}
                    size="sm"
                    onClick={() => warningWithConfirmAndCancelMessage(content.contentBoxId)}
                    >
                      <i className="fa fa-trash" />
                    </Button>
                      <UncontrolledTooltip
                        delay={0}
                        target="deleteContentBox"
                        placement="left"
                      >
                          Delete
                      </UncontrolledTooltip>
                  </>
                  )}
            </>
          )}

          {content.steps.length === 0 ? (
            <>
              <div style={{ height: '100%', overflowY: 'auto', alignContent: 'center', textAlign: 'center' }}>
                <Button
                  onClick={() => setShowEditAgendaForm(!showEditAgendaForm)}
                >
                  Edit Agenda
                </Button>
              </div>
            </>
          ) : (
            <>
              <AgendaBoxCopyForDebug navbarMD={navbarMD} navigationVisible={navigationVisible} content={boxData}/>
            </>
          )}

        </Card>
      </Col>

<ActiveDatesModal activeDatesModal={activeDatesModal} toggleSetActiveDatesModal={toggleSetActiveDatesModal} activeDates={activeDates} saveActiveDates={saveContentBoxActiveDates}/>
        
        <Modal
          isOpen={backgroundContentBoxModal}
          toggle={toggleBackgroundContentBoxModal}
          backdrop={false}
          scrollable
        >
          <ModalHeader toggle={toggleBackgroundContentBoxModal} className="background-modal-header"/>
          <ModalBody style={{padding: '0'}} className="agenda-body">
            <Container>
              <Row xs="6">
                {backgroundOptionsData.map((option, index) => (
                  <Col key={index} onClick={() => saveBackgroundContentBox(option.image)} style={{ padding: '0px' }}>
                    <div className="image-container">
                      <img src={option.thumbnail} alt={`Background option ${index + 1}`} />
                    </div>
                  </Col>
                ))}
              </Row>
            </Container>
          </ModalBody>
        </Modal>

        <Modal
          className="agenda-edit-modal"
          isOpen={showEditAgendaForm}
          toggle={toggleSetShowEditAgendaFormModal}
          backdrop={true}
          scrollable
          style={{
            display: "flex",
            flexDirection: "column",
            maxHeight: "90vh", // Prevents modal from growing beyond the screen
          }}
        >
          <ModalHeader className="agenda-edit-modal-header" toggle={toggleSetShowEditAgendaFormModal} style={{ flexShrink: 0 }}>
            <h4 style={{ fontWeight: "bold", color: "#004aad", margin: "0px", padding: '0px' }}>Agenda Editor</h4>
          </ModalHeader>
          <ModalBody
            style={{
              flex: "1 1 auto", // Allows the body to grow while keeping footer fixed
              padding: "0px"
            }}
            className="agenda-body"
          >
          <Row>
          <Col 
            md="7"
            style={{
              borderRight: "1px solid #ccc",
              overflowY: "auto", // Enables scrolling inside this column
            }}
          >
            <Row style={{padding: "15px 40px"}}>
              <Col md="8">
                <FormGroup>
                  <Label>Title</Label>
                  <Input
                    defaultValue={localAgendaBoxData.heading}
                    placeholder="Ex: Agenda"
                    onChange={(e) => handleAgendaTitleChange(e)}
                  ></Input>
                </FormGroup>
              </Col>
              <Col md="4">
                <FormGroup>
                  <Label>Start Time</Label>
                  {/* <ReactDatetime
                    dateFormat={false}
                    timeFormat={"h:mm A"} // Ensure proper format with uppercase "A" for AM/PM
                    value={moment(localAgendaBoxData?.startTime, "h:mm A")} // Ensure correct parsing
                    inputProps={{
                      className: "form-control",
                      placeholder: "Click to select time..."
                    }}
                    onChange={handleAgendaStartTimeChange}
                  /> */}
                  <Input
                    placeholder="Click to select time..."
                    onChange={handleAgendaStartTimeChange}
                    type="time"
                    id="startTime"
                    value={localAgendaBoxData?.startTime}
                    step="1"  // Allows seconds input
                    style={{ borderRadius: '8px' }}
                  />    
                </FormGroup>
              </Col>
            </Row>
            <hr></hr>
            <Row>
              <Col md="4" style={{padding: '0px 60px'}}>
                <FormGroup>
                  <Label>Title Font</Label>
                  <FontStylePicker
                    id={"titleFont"}
                    onChangeFunction={handleAgendaFontChange}
                    selectedFont={localAgendaBoxData.titleFont || "montserrat"}
                  />
                </FormGroup>
                <FormGroup>
                  <Label>Title Font Color</Label>
                  <Input
                    type="color"
                    placeholder=""
                    id="titleColor"
                    style={{height: '39px'}}
                    onChange={(e) => handleAgendaFontChange(e)}
                    value={localAgendaBoxData.titleColor}
                  ></Input>
                </FormGroup>
              </Col>
              <Col md="4" style={{padding: '0px 60px', borderLeft: '1px solid #ccc', borderRight: '1px solid #ccc'}}>
                <FormGroup>
                  <Label>Step Font</Label>
                  <FontStylePicker
                    id={"stepFont"}
                    onChangeFunction={handleAgendaFontChange}
                    selectedFont={localAgendaBoxData.stepFont || "montserrat"}
                  />
                </FormGroup>
                <FormGroup>
                  <Label>Step Font Color</Label>
                  <Input
                    type="color"
                    placeholder=""
                    id="stepColor"
                    style={{height: '39px'}}
                    onChange={(e) => handleAgendaFontChange(e)}
                    value={localAgendaBoxData.stepColor}
                  ></Input>
                </FormGroup>
              </Col>
              <Col md="4" style={{padding: '0px 60px'}}>
                <FormGroup>
                  <Label>Sub-step Font</Label>
                  <FontStylePicker
                    id={"subTaskStepFont"}
                    onChangeFunction={handleAgendaFontChange}
                    selectedFont={localAgendaBoxData.subTaskStepFont || "montserrat"}
                  />
                </FormGroup>
                <FormGroup>
                  <Label>Sub-step Font Color</Label>
                  <Input
                    type="color"
                    placeholder=""
                    id="subTaskStepColor"
                    style={{height: '39px'}}
                    onChange={(e) => handleAgendaFontChange(e)}
                    value={localAgendaBoxData.subTaskStepColor}
                  ></Input>
                </FormGroup>
              </Col>
            </Row>
            {localAgendaBoxData && localAgendaBoxData.steps?.map((step, index) => (
              <AgendaStep
                key={index}
                step={step}
                stepsLength={localAgendaBoxData.steps?.length}
                index={index}
                deleteAgendaStep={deleteAgendaStep}
                handleStepTextChange={handleStepTextChange}
                handleStepDurationChange={handleStepDurationChange}
                addAgendaSubTaskStep={addAgendaSubTaskStep}
                deleteAgendaSubTaskStep={deleteAgendaSubTaskStep}
                handleSubTaskStepTextChange={handleSubTaskStepTextChange}
                handleSubTaskStepDurationChange={handleSubTaskStepDurationChange}
                handleStepMove={handleStepMove}
                handleSubTaskStepMove={handleSubTaskStepMove}
                handleAddResourceLinkClick={handleAddResourceLinkClick}
              />
            ))}
            <hr></hr>
            <Row>
              <Col style={{left: '50px'}}>
                <p onClick={() => addAgendaStep()} style={{textDecoration: 'underline', cursor: 'pointer'}}><i className="fa fa-plus" />{' '}Add Step</p>
              </Col>
            </Row>
          </Col>
            <Col>
              <div style={{zoom: '.9'}}>
                <Col style={{ flex: "1", minHeight: "0", padding: '4px',  height: '100%', border: showButtons ? 'rgb(11, 192, 223) solid 2px' : 'transparent solid 2px', borderRadius: '15px' }}>
                  <Card 
                    key={`edit-modal-preview-${content?.contentBoxId}`}
                    style={{ height: location.pathname === '/print' ? '800px' : cardHeight.card, backgroundImage: location.pathname !== '/print' && content?.background || '', zoom: location.pathname === '/print' ? '.6' : '1', border: printActive === true ? '4px solid blue' : location.pathname === '/print' ? 'solid 1px' : 'none'}}
                  >
                    <AgendaBoxCopyForDebug content={localAgendaBoxData} key={agendaBoxVersion} />
                  </Card>
                </Col>
              </div>
            </Col>
          </Row>
          </ModalBody>
          <ModalFooter
            style={{
              flexShrink: 0,
              position: "sticky",
              bottom: "0",
              backgroundColor: "white",
              width: "100%",
              boxShadow: "0 -2px 10px rgba(0, 0, 0, 0.1)", // Adds shadow for better visibility
              zIndex: 10,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Col>
              <b>Total Duration: </b> {calculateTotalTime()}
            </Col>
            <Col style={{textAlign: 'center'}}>
              <Button
                // className="btn-round btn-icon"
                id="submitAgenda"
                type="button"
                size="sm"
                onClick={() => saveAgendaBoxData()}
                style={{
                  padding: "10px 30px",
                  borderRadius: "30px",
                  fontSize: "1.1rem",
                  fontWeight: "bold",
                }}
              >
                Save Agenda
              </Button>
            </Col>
            <Col></Col>
          </ModalFooter>
        </Modal>

    </>
  );
}

export default AgendaBox;