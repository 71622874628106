import userSlice from 'features/userSlice';
import React, {useEffect, useState} from 'react';
import { Card, CardBody, Container, Row } from 'reactstrap'
import { useSelector } from "react-redux";
import { selectUser } from "features/userSlice";
import { useLocation } from "react-router-dom";
import { doc, getDoc } from "firebase/firestore";
import { db } from "/Users/michaeltucker/Documents/coding-projects/client/src/firebase.js";

function PricingTable() {

    const user = useSelector(selectUser)
    const location = useLocation();
    const [pricingData, setPricingData] = useState(null); // Initialize as null for clear checks

  useEffect(() => {
    let isMounted = true;

    const fetchData = async () => {
      try {
        const docRef = doc(db, "dynamicData", "tqZ8VusxvnaBEQRoDTeW");
        const docSnap = await getDoc(docRef);

        if (docSnap.exists() && isMounted) {
          const docData = docSnap.data();

          // Set pricingData to the whole pricingData object, not just `changeDate`
          setPricingData(docData.pricingData);
        } else {
          console.log("No such document!");
        }
      } catch (error) {
        console.error("Error fetching document:", error);
      }
    };

    fetchData();

    return () => {
      isMounted = false;
    };
  }, []);

  const determinePricingTable = (user, pricingChangeDate) => {
    if (user?.accountCreated?.seconds && pricingChangeDate?.seconds) {
      const accountCreatedDate = new Date(user.accountCreated.seconds * 1000);
      const pricingChangeDateDate = new Date(pricingChangeDate.seconds * 1000);

      // Debugging Logs
      if (false) {
        console.log("************************ START ************************");
        console.log("user.accountCreated => ", user.accountCreated);
        console.log("user.accountCreated converted => ", accountCreatedDate);
        console.log("pricingChangeDate => ", pricingChangeDate);
        console.log("pricingChangeDate converted => ", pricingChangeDateDate);
        console.log(
          "user.accountCreated <= pricingChangeDate = ",
          accountCreatedDate <= pricingChangeDateDate
        );
        console.log("************************* END *************************");
      }

      return accountCreatedDate <= pricingChangeDateDate;
    } else {
      console.error(
        "Invalid data: user.accountCreated or pricingChangeDate is undefined or malformed.",
        { userAccountCreated: user?.accountCreated, pricingChangeDate }
      );
      return null; // Return null if data is invalid
    }
  };

  const showFirstPricingTable =
    pricingData && pricingData.changeDate && user.accountCreated
      ? determinePricingTable(user, pricingData.changeDate)
      : pricingData && pricingData.changeDate && user.accountCreated === undefined
      ? true
      : null;

    return (
      location.pathname.includes("non-renew") ? (
        <div style={{ backgroundColor: "rgb(11, 192, 223)", display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100vh' }}>
            <Card>
              <CardBody>
                <stripe-buy-button
                  buy-button-id="buy_btn_1PiLdHEcrsj3dDGxqfH9U2fO"
                  publishable-key="pk_live_51Nu711Ecrsj3dDGxkLAjXv80asu2XZSy93BwoTvLzMJZIS40tt1BSVy6yy0yTZzvcsyLKgyyCeIcOitrB78uCvvn00OlAZth85"
                >
                </stripe-buy-button>
              </CardBody>
            </Card>
        </div>
      )
      :
      (
        user && (
          <div
            style={{
              backgroundColor: "rgb(11, 192, 223)",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              height: "100vh",
            }}
          >
            {/* {showFirstPricingTable === null ? (
              <p>Error: Unable to load pricing information.</p>
            ) : ( */}
              <Card style={{ padding: '40px 60px' }}>
                <Row style={{ alignItems: 'center', justifyContent: 'center' }}>
                  <Card style={{ margin: '10px', border: '1px solid lightgrey', width: '400px' }}>
                    <CardBody>
                      {showFirstPricingTable ? (
                        // Old Pricing Monthly - 2.99
                        <stripe-pricing-table
                          pricing-table-id="prctbl_1QpwvGEcrsj3dDGxwnPVDiZ4"
                          publishable-key="pk_live_51Nu711Ecrsj3dDGxkLAjXv80asu2XZSy93BwoTvLzMJZIS40tt1BSVy6yy0yTZzvcsyLKgyyCeIcOitrB78uCvvn00OlAZth85"
                          client-reference-id={user.uid}
                        ></stripe-pricing-table>
                      ) : (
                        // New Pricing Monthly - 4.99
                        <stripe-pricing-table
                          pricing-table-id="prctbl_1Qpwj4Ecrsj3dDGxfMjv9P5O"
                          publishable-key="pk_live_51Nu711Ecrsj3dDGxkLAjXv80asu2XZSy93BwoTvLzMJZIS40tt1BSVy6yy0yTZzvcsyLKgyyCeIcOitrB78uCvvn00OlAZth85"
                          client-reference-id={user.uid}
                        ></stripe-pricing-table>
                      )}
                      <hr style={{ marginTop: '0px' }}></hr>
                      <ul
                        style={{
                          listStyleType: 'none',
                          padding: 0,
                          color: '#555',
                          fontSize: '1.1rem',
                          marginBottom: '30px',
                          marginLeft: '60px',
                        }}
                      >
                        <li>
                          <i
                            className="fa fa-check-circle"
                            style={{ color: '#11c0df', marginRight: '10px' }}
                          ></i>
                          Full access to all features
                        </li>
                        <li>
                          <i
                            className="fa fa-check-circle"
                            style={{ color: '#11c0df', marginRight: '10px' }}
                          ></i>
                          Cancel anytime
                        </li>
                        <li>
                          <i
                            className="fa fa-check-circle"
                            style={{ color: '#11c0df', marginRight: '10px' }}
                          ></i>
                          Affordable and flexible
                        </li>
                      </ul>
                    </CardBody>
                  </Card>
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      fontStyle: 'italic',
                      margin: '0 10px',
                      height: '100%', // Ensures it spans the full height of the Row
                    }}
                  >
                    <h3 style={{ margin: 0, fontWeight: '600' }}>- OR -</h3>
                  </div>
                  <Card style={{ margin: '10px', border: '1px solid lightgrey', width: '400px' }}>
                    <CardBody>
                      {showFirstPricingTable ? (
                        // Old Pricing Yearly - 29.99
                        <stripe-pricing-table
                          pricing-table-id="prctbl_1QpwwzEcrsj3dDGxjxYt3F3v"
                          publishable-key="pk_live_51Nu711Ecrsj3dDGxkLAjXv80asu2XZSy93BwoTvLzMJZIS40tt1BSVy6yy0yTZzvcsyLKgyyCeIcOitrB78uCvvn00OlAZth85"
                          client-reference-id={user.uid}
                        ></stripe-pricing-table>
                      ) : (
                        // New Pricing Yearly - 39.99
                        <stripe-pricing-table
                          pricing-table-id="prctbl_1QpwIQEcrsj3dDGxQj6iv0aV"
                          publishable-key="pk_live_51Nu711Ecrsj3dDGxkLAjXv80asu2XZSy93BwoTvLzMJZIS40tt1BSVy6yy0yTZzvcsyLKgyyCeIcOitrB78uCvvn00OlAZth85"
                          client-reference-id={user.uid}
                        ></stripe-pricing-table>
                      )}
                      <hr style={{ marginTop: '0px' }}></hr>
                      <ul
                        style={{
                          listStyleType: 'none',
                          padding: 0,
                          color: '#555',
                          fontSize: '1.1rem',
                          marginBottom: '30px',
                          marginLeft: '30px',
                        }}
                      >
                        <li>
                          <i
                            className="fa fa-check-circle"
                            style={{ color: '#11c0df', marginRight: '10px' }}
                          ></i>
                          Save <b><em>{pricingData && pricingData.savings}</em></b> compared to monthly
                        </li>
                        <li>
                          <i
                            className="fa fa-check-circle"
                            style={{ color: '#11c0df', marginRight: '10px' }}
                          ></i>
                          Full access to all features
                        </li>
                        <li>
                          <i
                            className="fa fa-check-circle"
                            style={{ color: '#11c0df', marginRight: '10px' }}
                          ></i>
                          Best value for educators
                        </li>
                      </ul>
                    </CardBody>
                  </Card>
                </Row>
              </Card>
            {/* )} */}
          </div>
        )
      )
    );
  }

export default PricingTable;