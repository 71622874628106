import React, { useState, useEffect, useRef } from 'react'
import { doc, getDoc } from "firebase/firestore";
import { db } from "/Users/michaeltucker/Documents/coding-projects/client/src/firebase.js";
import { NavLink } from 'react-router-dom';
import { Card, CardBody, Row, Col, Navbar, NavbarBrand, Carousel, Container, CardImgOverlay, CardText, CardTitle,
     NavItem, UncontrolledAccordion, AccordionItem,
      AccordionBody, Button, Badge, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem, CardHeader, CardFooter, CardImg } from "reactstrap";
import logo from "/Users/michaeltucker/Documents/coding-projects/client/src/assets/img/launchpad.png";
import { Link } from 'react-router-dom';
import LandingCarousel from 'components/LandingCarousel';
import featureAnnouncement from "/Users/michaeltucker/Documents/coding-projects/client/src/assets/img/feature-announcement.svg";
import featureAutoAdvance from "/Users/michaeltucker/Documents/coding-projects/client/src/assets/img/feature-auto-advance.svg";
import featureClock from "/Users/michaeltucker/Documents/coding-projects/client/src/assets/img/feature-clock.svg";
import featureCountdownTimers from "/Users/michaeltucker/Documents/coding-projects/client/src/assets/img/feature-countdown-timers.svg";
import featureGoogleSlides from "/Users/michaeltucker/Documents/coding-projects/client/src/assets/img/feature-google-slides.svg";
import featureReminders from "/Users/michaeltucker/Documents/coding-projects/client/src/assets/img/feature-reminders.svg";
import featureStopwatch from "/Users/michaeltucker/Documents/coding-projects/client/src/assets/img/feature-stopwatch.svg";
import featureTextEditor from "/Users/michaeltucker/Documents/coding-projects/client/src/assets/img/feature-text-editor.svg";
import featureTimer from "/Users/michaeltucker/Documents/coding-projects/client/src/assets/img/feature-timer.svg";
import featureBirthday from "/Users/michaeltucker/Documents/coding-projects/client/src/assets/img/feature-auto-happy-birthday.svg";
import featureRandomizer from "/Users/michaeltucker/Documents/coding-projects/client/src/assets/img/feature-randomizer.svg";
import featurePrint from "/Users/michaeltucker/Documents/coding-projects/client/src/assets/img/feature-print-lesson-plan.svg";
import featureAgenda from "/Users/michaeltucker/Documents/coding-projects/client/src/assets/img/feature-auto-agenda.svg";
import featureCanva from "/Users/michaeltucker/Documents/coding-projects/client/src/assets/img/feature-canva.svg";
import featureGoogleDoc from "/Users/michaeltucker/Documents/coding-projects/client/src/assets/img/feature-google-doc.svg";
import featureShare from "/Users/michaeltucker/Documents/coding-projects/client/src/assets/img/feature-share.svg";
import logoWhite from "/Users/michaeltucker/Documents/coding-projects/client/src/assets/img/logo-white.svg";
import featurePowerPoint from "/Users/michaeltucker/Documents/coding-projects/client/src/assets/img/feature-power-point-box.svg";
import featurePDF from "/Users/michaeltucker/Documents/coding-projects/client/src/assets/img/feature-pdf-box.svg";
import featureAutoMusic from "/Users/michaeltucker/Documents/coding-projects/client/src/assets/img/feature-auto-music.svg";
import featureWebEmbed from "/Users/michaeltucker/Documents/coding-projects/client/src/assets/img/feature-web-embed.svg";
import TestimonialCarousel from 'components/TestimonialCarousel';
import headshot from '/Users/michaeltucker/Documents/coding-projects/client/src/assets/img/michael-head-shot.png';
import LandingPageTopNav from './LandingPageTopNav';
import LandingPageFooter from './LandingPageFooter';

const LandingPage = () => {

    const features = [
        {
          id: "featureAutoAdvance",
          title: "Auto Class Advance",
          imgSrc: featureAutoAdvance,
          automation: true,
          description:
            "Lesson Launchpad will automatically transition to your next class when it's time. You can be free to take care of any tasks instead of fiddling with your computer and trying to get the next class's content on the screen.",
        },
        {
          id: "featureReminders",
          title: "Auto Reminders",
          imgSrc: featureReminders,
          automation: true,
          description:
            "Always forget to take attendance? Have a student that needs to get dismissed at a certain time? Schedule custom reminders that will automatically pop up on screen during class.",
        },
        {
          id: "featureCountdownTimers",
          title: "Auto Timers",
          imgSrc: featureCountdownTimers,
          automation: true,
          description:
            "Schedule custom timers that will automatically appear to let your students know how much longer they have to complete a task. Students need to be in their seats by two minutes after the bell? Here's your solution.",
        },
        {
          id: "featureAgenda",
          title: "Auto Agenda",
          imgSrc: featureAgenda,
          automation: true,
          description:
            "Want an Agenda that keeps you on track? Schedule each part of your lesson and Lesson Launchpad will automatically keep you on track by showing a countdown for each part of your lesson.",
        },
        {
          id: "featureAnnouncement",
          title: "Scrolling Marquee",
          imgSrc: featureAnnouncement,
          description:
            "A scrolling marquee for your announcements, objectives, or anything else you want to use it for.",
        },
        {
          id: "featureClock",
          title: "Clock",
          imgSrc: featureClock,
          description:
            "A simple clock to keep them and you on task. Don't want it? You can turn it off in settings.",
        },
        {
          id: "featureTextEditor",
          title: "Advanced Text Editor",
          imgSrc: featureTextEditor,
          description:
            "Lesson Launchpad comes with an advanced text editor that allows you to format your lesson content in any way you want.",
        },
        {
          id: "featureTimer",
          title: "Timer",
          imgSrc: featureTimer,
          description:
            "In the middle of a lesson and decide you need a timer? With Lesson Launchpad, you'll always have one available one click away.",
        },
        {
          id: "featureStopwatch",
          title: "Stopwatch",
          imgSrc: featureStopwatch,
          description:
            "In the middle of a lesson and decide you need a stopwatch? With Lesson Launchpad, you'll always have one available one click away.",
        },
        {
          id: "featureGoogleSlides",
          title: "Google Slides Compatible",
          imgSrc: featureGoogleSlides,
          description:
            "You can easily add all of your Google Slides presentations right into Lesson Launchpad so you can get all of the features of Lesson Launchpad without having to re-create all of your lesson content.",
        },
        {
          id: "featureCanva",
          title: "Canva Compatible",
          imgSrc: featureCanva,
          description:
            "You can easily add all of your Canva Projects right into Lesson Launchpad.",
        },
        {
          id: "featureBirthday",
          title: "Auto Happy Birthday",
          imgSrc: featureBirthday,
          automation: true,
          description:
            "Bad at remembering students' birthdays? Let Lesson Launchpad help you out. Lesson Launchpad will automatically pop up a happy birthday message during class.",
        },
        {
          id: "featureRandomizer",
          title: "Randomizer",
          imgSrc: featureRandomizer,
          description:
            "Pick a random student, put students into groups, randomize custom data.",
        },
        {
          id: "featurePrint",
          title: "Print Lesson Plan",
          imgSrc: featurePrint,
          description:
            "Easily turn your Lesson Launchpad content into lesson plans to share with your administrators.",
        },
        {
          id: "featureGoogleDoc",
          title: "Google Doc Compatible",
          imgSrc: featureGoogleDoc,
          description:
            "Open and Edit Google Docs or Google Sheets right inside Lesson Launchpad.",
        },
        {
          id: "featureShare",
          title: "Sharable",
          imgSrc: featureShare,
          description:
            "Want to share your Lesson Launchpad content with parents or students through a link? You can do that with Lesson Launchpad.",
        },
        {
          id: "featurePDF",
          title: "Use PDFs",
          imgSrc: featurePDF,
          description: "Add your own PDFs to use as lesson content.",
        },
        {
          id: "featurePowerPoint",
          title: "PowerPoint Compatible",
          imgSrc: featurePowerPoint,
          description:
            "You can easily add all of your PowerPoint presentations right into Lesson Launchpad so you can get all of the features of Lesson Launchpad without having to re-create all of your lesson content.",
        },
        {
            id: "featureWebEmbed",
            title: "Embed Webpage",
            imgSrc: featureWebEmbed,
            description:
              "Easily access another web page from right within Lesson Launchpad.",
          },
        {
            id: "featureAutoMusic",
            title: "Auto Music",
            imgSrc: featureAutoMusic,
            automation: true,
            description:
            "Want to play music between classes that automatically starts and stops without you having to do anything? Lesson Launchpad can do that.",
        },
    ];

    const [hoveredFeature, setHoveredFeature] = useState(null);
    const [testimonialData, setTestimonialData] = useState({});
    const [imageDimensions, setImageDimensions] = useState({});
    const pricingRef = useRef(null);
    const [pricingData, setPricingData] = useState();
    const videoRef = useRef(null);

    useEffect(() => {
      const observer = new IntersectionObserver(
        (entries) => {
          entries.forEach((entry) => {
            if (entry.isIntersecting) {
              videoRef.current.play(); // Start the video when in view
            } else {
              videoRef.current.pause(); // Pause when out of view
            }
          });
        },
        { threshold: 0.5 } // Trigger when 50% of the video is visible
      );

      if (videoRef.current) {
        observer.observe(videoRef.current);
      }

      return () => {
        if (videoRef.current) {
          observer.unobserve(videoRef.current);
        }
      };
    }, []);

    useEffect(() => {
        let isMounted = true;
    
        const fetchData = async () => {
        try {
            const docRef = doc(db, "dynamicData", "tqZ8VusxvnaBEQRoDTeW");
            const docSnap = await getDoc(docRef);
    
            if (docSnap.exists() && isMounted) {
            const docData = docSnap.data();

            setPricingData(docData.pricingData);

            } else {
            console.log("No such document!");
            }
        } catch (error) {
            console.error('Error fetching document:', error);
        }
        };
    
        fetchData();
    
        // Cleanup function to set isMounted to false when the component is unmounted
        return () => {
        isMounted = false;
        };
    }, []);

    const scrollToPricing = (event) => {
        event.preventDefault(); // Prevent the default anchor behavior
        if (pricingRef.current) {
            pricingRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    };

    const isMobile = /Mobi/.test(navigator.userAgent);
      
    useEffect(() => {
        let isMounted = true;
      
        const fetchData = async () => {
          try {
            const docRef = doc(db, "testimonials", "lEvNFHdJpNmFRQp1aI1A");
            const docSnap = await getDoc(docRef);
      
            if (docSnap.exists() && isMounted) {
              const testimonialData = docSnap.data();
    
            //   const sortedKeys = Object.keys(testimonialData).sort((a, b) => parseFloat(a) - parseFloat(b));
    
            //   const sortedData = sortedKeys.reduce((acc, key) => {
            //       acc[key] = testimonialData[key];
            //       return acc;
            //   }, {});
    
              setTestimonialData(testimonialData);
    
            } else {
              console.log("No such document!");
            }
          } catch (error) {
            console.error('Error fetching document:', error);
          }
        };
      
        fetchData();
      
        // Cleanup function to set isMounted to false when the component is unmounted
        return () => {
          isMounted = false;
        };
    }, []);

    const handleImageLoad = (id, event) => {
        const { naturalWidth, naturalHeight } = event.target;
        const aspectRatio = naturalWidth / naturalHeight;
        const newWidth = !isMobile ? 180 * aspectRatio : 700 * aspectRatio; // Calculate width based on fixed height
        setImageDimensions(prevDimensions => ({
            ...prevDimensions,
            [id]: newWidth
        }));
    };

    const handleImageClick = (url) => {
        window.open(url, '_blank'); // Open the image URL in a new tab
    };

    return (
    <>
    { !isMobile ? (
            <div style={{ backgroundColor: 'white' }}>

            <LandingPageTopNav showPricingLink={true} scrollToPricing={scrollToPricing}/>
    
    {/************************************************* HEADLINE *************************************************/}
    
            <Row style={{ height: '510px', backgroundColor: "white", alignItems: 'center', justifyContent: 'center' }}>
            <div style={{ textAlign: 'center', marginBottom: '280px' }}>
                {/* <h1 style={{ color: 'rgb(11, 192, 223)', fontWeight: '1000', fontSize: '8rem', marginTop: '-1rem' }}>
                <div style={{ textShadow: '2px 2px 0px #004aad'}}><b><i>Plan</i></b></div>
                </h1>
                <h1 style={{ color: 'rgb(11, 192, 223)', fontWeight: '1000', fontSize: '8rem', marginTop: '-3rem' }}>
                <div style={{ textShadow: '2px 2px 0px #004aad'}}><b><i>Present</i></b></div>
                </h1>
                <h1 style={{ color: 'rgb(11, 192, 223)', fontWeight: '1000', fontSize: '8rem', marginTop: '-3rem', marginBottom: '1rem'}}>
                <div style={{ textShadow: '2px 2px 0px #004aad'}}><b><i>Automate</i></b></div>
                </h1> */}
                <div className="animated-text-container">
                  <h1 className="animated-text plan">Plan</h1>
                  <h1 className="animated-text present">Present</h1>
                  <h1 className="animated-text automate">Automate</h1>
                </div>
                <h1 style={{ color: '#004aad', fontWeight: '1000', fontSize: '2.25rem', marginTop: '1rem', marginBottom: '1rem', textTransform: 'uppercase'}}>
                <b>The Ultimate Classroom Automation Tool for Teachers</b>
                </h1>
                <Button
                  tag={Link}
                  className="pulsing-button"
                  to="/auth/register"
                  // className="landing-btn-no-hover"
                  style={{
                      // backgroundColor: 'rgb(11, 192, 223)',
                      color: 'white',
                      height: '50px',
                      width: '200px',
                      fontSize: '1.5rem',
                      borderRadius: '30px',
                  }}
                  onMouseEnter={(e) => (e.currentTarget.style.transform = "scale(1.08)")}
                  onMouseLeave={(e) => (e.currentTarget.style.transform = "scale(1)")}
                  >
                  TRY IT NOW
                </Button>
            </div>
            {/* <img
                className='landing-page-macbook'
                src={macbook}
                alt="lesson-launchpad-on-macbook"
            /> */}
            </Row>
    
    {/************************************************* Carousel *************************************************/}
    
            <LandingCarousel />

    {/************************************************* About *************************************************/}
    
        <Row style={{ height: '400px', backgroundColor: "#004aad", alignItems: 'center', justifyContent: 'center', padding: '40px 0px', clipPath: 'polygon(0 0, 100% 0, 100% 100%, 0% 90%)' }}>
            <Col md="5">
                <div style={{ textAlign: 'right', marginLeft: '10vw', textAlign: 'center' }}>
                    <h1 style={{ color: 'white', fontWeight: '1000', fontSize: '2.5rem' }}>
                      <div>Why</div>
                    <b>Lesson Launchpad?</b>
                    </h1>
                </div>
            </Col>
            <Col md="7">
                <div style={{ textAlign: 'left', marginRight: '10vw' }}>
                    <h1 style={{ color: 'white', fontWeight: '500', fontSize: '1.1rem', fontStyle: 'italic', lineHeight: '1.5' }}>
                    When I was a band director, I always wanted a better solution to the "agenda slide". I wanted to be able to present more information, but in a neat uncluttered way. I wanted a clock visible on the screen, a scrolling announcement marquee, an automatic timer to pop up to alert my students when it's time to be in their seats. And, I wanted it to do it all automatically - change classes for me at the correct time, launch reminders, etc. so that way I would be free to teach instead of fiddling with the computer. Even though I'm no longer a band director, I built Lesson Launchpad as a solution to those problems and more.
                    </h1>
                    <h1 style={{ color: 'white', fontWeight: '500', fontSize: '1.1rem' }}>
                    <img 
                        style={{height: '75px', width: 'auto'}}
                        src={headshot}
                    >
                    </img>
                        Michael - <em>Lesson Launchpad Founder</em>
                    <br></br>
                    </h1>
                </div>
            </Col>
        </Row>

    
    {/************************************************* FEATURES *************************************************/}

    <Row style={{display: 'flex', alignContent: 'center', justifyContent: 'center', padding: '50px 0px 20px 0px'}}>
      <video
        ref={videoRef}
        id="videoPlayer"
        width="800"
        height="450"
        src="https://firebasestorage.googleapis.com/v0/b/lesson-launchpad.appspot.com/o/resources%2Fmisc%2FAutomation%20Demo.mp4?alt=media&token=d0fe3b48-d5bb-4f3b-9d9e-8a7d5ad6009a"
        controls={false}
        muted
        autoPlay={false}
        style={{ borderRadius: '20px' }}
      >
        Your browser does not support the video tag.
      </video>
    </Row>

    <div style={{ textAlign: 'center', marginBottom: '50px' }}>    
    <h1 style={{ fontWeight: '1000', fontSize: '4rem', textTransform: 'uppercase', color: '#004aad', textShadow: '3px 2px 2px grey' }}>
            <b>Automate your Class</b>
        </h1>
    </div>
    <Container>
      <Row xs="3" className="my-5">
        {features.filter((feature) => feature.automation === true).map(({ id, title, imgSrc, description }) => (
          <Col key={id}>
            <Card
              className="border"
              onMouseEnter={(e) => {setHoveredFeature(id); (e.currentTarget.style.transform = "scale(1.04)")}}
              onMouseLeave={(e) => {setHoveredFeature(null); (e.currentTarget.style.transform = "scale(1)")}}
            >
              <CardBody className="text-center">
                <img
                  src={imgSrc}
                  alt={title}
                  style={{ width: "100px", height: "auto" }}
                />
              </CardBody>
              <CardHeader className="h5 text-center" id="landing-feature">
                {title}
              </CardHeader>
              {hoveredFeature === id && (
                <CardImgOverlay
                  style={{
                    background: "#004aad",
                    opacity: "95%",
                    color: "white",
                    fontSize: "15px",
                    borderRadius: '10px'
                  }}
                >
                  <CardText>{description}</CardText>
                </CardImgOverlay>
              )}
            </Card>
          </Col>
        ))}
      </Row>
    </Container>


    <div style={{ textAlign: 'center', marginBottom: '50px', marginTop: '100px' }}>    
    <h1 style={{ fontWeight: '1000', fontSize: '4rem', textTransform: 'uppercase', color: '#004aad', textShadow: '3px 2px 2px grey' }}>
            <b>Features</b>
        </h1>
    </div>
    <Container>
      <Row xs="3" className="my-5">
        {features.filter((feature) => feature.automation != true).map(({ id, title, imgSrc, description }) => (
          <Col key={id}>
            <Card
              className="border"
              onMouseEnter={(e) => {setHoveredFeature(id); (e.currentTarget.style.transform = "scale(1.04)")}}
              onMouseLeave={(e) => {setHoveredFeature(null); (e.currentTarget.style.transform = "scale(1)")}}
            >
              <CardBody className="text-center">
                <img
                  src={imgSrc}
                  alt={title}
                  style={{ width: "100px", height: "auto" }}
                />
              </CardBody>
              <CardHeader className="h5 text-center" id="landing-feature">
                {title}
              </CardHeader>
              {hoveredFeature === id && (
                <CardImgOverlay
                  style={{
                    background: "#004aad",
                    opacity: "95%",
                    color: "white",
                    fontSize: "15px",
                    borderRadius: '10px'
                  }}
                >
                  <CardText>{description}</CardText>
                </CardImgOverlay>
              )}
            </Card>
          </Col>
        ))}
      </Row>
    </Container>
            
    {/*********************************************** Testimonials **********************************************/}


    <TestimonialCarousel testimonialData={testimonialData} imageDimensions={imageDimensions} handleImageLoad={handleImageLoad} handleImageClick={handleImageClick}/>

    
    {/************************************************* PRICING *************************************************/}

        {pricingData &&
            <Row
                style={{
                    height: 'auto',
                    alignItems: 'center',
                    justifyContent: 'center',
                    padding: '50px 20px',
                    backgroundColor: 'white',
                    flexDirection: 'column',
                }}
            >
                {/* Header Section */}
                <div ref={pricingRef} id="pricing-section" style={{ textAlign: 'center', marginBottom: '20px' }}>
                    <h1
                        style={{
                            color: '#004aad',
                            textShadow: '3px 2px 2px grey',
                            fontSize: '3rem',
                            marginBottom: '20px',
                        }}
                    >
                        <b>Cool! How much does it cost?</b>
                    </h1>
                    <p style={{ color: '#555', fontSize: '1.2rem', marginBottom: '20px' }}>
                        Unlock the power of Lesson Launchpad with plans designed for every educator!
                    </p>
                </div>

                {/* Pricing Cards */}
                <div
                    className="d-flex flex-wrap justify-content-center"
                    style={{ gap: '30px', marginBottom: '20px' }}
                >
                    {/* Monthly Plan */}
                    <div
                        className="card"
                        style={{
                            width: '350px',
                            padding: '20px',
                            border: '1px solid #004aad',
                            borderRadius: '12px',
                            boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
                        }}
                    >
                        <h2 style={{ color: '#004aad', fontWeight: '700', marginBottom: '10px' }}>
                            Monthly Plan
                        </h2>
                        <p
                            style={{
                                fontSize: '1.5rem',
                                color: '#11c0df',
                                fontWeight: '600',
                                marginBottom: '20px',
                            }}
                        >
                            {`$${pricingData.monthlyPrice}`} <span style={{ fontSize: '1rem', color: '#555' }}>per month</span>
                        </p>
                        <hr style={{marginTop: '0px'}}></hr>
                        <ul style={{ textAlign: 'left', fontSize: '1rem', color: '#555' }}>
                            <li>Full access to all features</li>
                            <li>Cancel anytime</li>
                            <li>Affordable and flexible</li>
                        </ul>
                    </div>

                    {/* Annual Plan */}
                    <div
                        className="card"
                        style={{
                            width: '350px',
                            padding: '20px',
                            border: '1px solid #004aad',
                            borderRadius: '12px',
                            boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
                        }}
                    >
                        <h2 style={{ color: '#004aad', fontWeight: '700', marginBottom: '10px' }}>
                            Annual Plan
                        </h2>            
                        <p
                            style={{
                                fontSize: '1.5rem',
                                color: '#11c0df',
                                fontWeight: '600',
                                marginBottom: '20px',
                            }}
                        >
                            {`$${pricingData.yearlyPrice}`} <span style={{ fontSize: '1rem', color: '#555' }}>per year</span>
                        </p>
                        <hr style={{marginTop: '0px'}}></hr>
                        <ul style={{ textAlign: 'left', fontSize: '1rem', color: '#555' }}>
                            <li>Save <b><em>{pricingData.savings}</em></b> compared to monthly</li>
                            <li>Full access to all features</li>
                            <li>Best value for educators</li>
                        </ul>
                    </div>
                </div>

                {/* "But wait, there's more!" Section */}
                <div style={{ textAlign: 'center' }}>
                    <h3 style={{ color: '#004aad', marginBottom: '20px', fontWeight: '600' }}>
                        But wait, there's more!
                    </h3>
                    <ul
                        style={{
                            listStyleType: 'none',
                            padding: 0,
                            color: '#555',
                            fontSize: '1.1rem',
                            marginBottom: '30px',
                        }}
                    >
                        <li>
                            <i
                                className="fa fa-check-circle"
                                style={{ color: '#11c0df', marginRight: '10px' }}
                            ></i>
                            Dive in risk-free with a 30-day full-featured trial
                        </li>
                        <li>
                            <i
                                className="fa fa-check-circle"
                                style={{ color: '#11c0df', marginRight: '10px' }}
                            ></i>
                            No credit card required until you're ready to commit
                        </li>
                    </ul>
                    <Button
                        tag={Link}
                        to="/auth/register"
                        className="landing-btn-no-hover pulsing-button"
                        style={{
                            border: "1px solid #004aad",
                            backgroundColor: 'white',
                            color: '#004aad',
                            height: '60px',
                            padding: '0 30px',
                            fontSize: '1.2rem',
                            borderRadius: '30px',
                            display: 'inline-flex',
                            alignItems: 'center',
                            gap: '15px',
                        }}
                    >
                        <img
                            src={logo}
                            alt="lesson-launchpad-logo"
                            style={{ width: '40px', height: 'auto' }}
                        />
                        Click HERE to start your 30-day FREE trial!
                    </Button>
                </div>
            </Row>
        }
        
        </div>

    ) : (

        <div style={{ backgroundColor: 'white' }}>
            <LandingPageTopNav showPricingLink={true} scrollToPricing={scrollToPricing} />

{/************************************************* HEADLINE *************************************************/}

        <Row style={{ height: '800px', backgroundColor: "white", alignItems: 'center', justifyContent: 'center' }}>
        <div style={{ textAlign: 'center', marginBottom: '100px' }}>
            <h1 style={{ color: 'rgb(11, 192, 223)', fontWeight: '1000', fontSize: '6rem', textShadow: '1px 1px 0px #004aad' }}>
            <b>Your Lesson</b>
            </h1>
            <h1 style={{ color: 'rgb(11, 192, 223)', fontWeight: '1000', fontSize: '12rem', marginTop: '-1rem' }}>
            <div style={{ textShadow: '2px 2px 0px #004aad'}}><b><i>AUTOMATED</i></b></div>
            </h1>
            <Button
                tag={Link}
                to="/auth/register"
                className="landing-btn-no-hover pulsing-button"
                style={{
                    backgroundColor: 'rgb(11, 192, 223)',
                    color: 'white',
                    height: '150px',
                    width: '800px',
                    fontSize: '90px',
                    marginTop: '100px'
                }}
            >
            Get Started
            </Button>
        </div>
        </Row>

{/************************************************* MACBOOK IMAGE CAROUSEL *************************************************/}

        <LandingCarousel />
        <hr></hr>


        <Row style={{ height: 'auto', backgroundColor: "#004aad", alignItems: 'center', justifyContent: 'center', padding: '50px 150px' }}>
            <div style={{padding: '20px 0px'}}>
                <div style={{ textAlign: 'center'}}>
                    <h1 style={{ color: 'white', fontWeight: '1000', fontSize: '90px' }}>
                    <b>Why Lesson Launchpad?</b>
                    </h1>
                </div>
            </div>
            <div style={{padding: '20px 0px'}}>
                <div style={{ textAlign: 'center'}}>
                    <h1 style={{ color: 'white', fontWeight: '500', fontSize: '70px', fontStyle: 'italic', lineHeight: '1.5' }}>
                    When I was a band director, I always wanted a better solution to the "agenda slide". I wanted to be able to present more information, but in a neat uncluttered way. I wanted a clock visible on the screen, a scrolling announcement marquee, an automatic timer to pop up to alert my students when it's time to be in their seats. And, I wanted it to do it all automatically - change classes for me at the correct time, launch reminders, etc. so that way I would be free to teach instead of fiddling with the computer. Even though I'm no longer a band director, I built Lesson Launchpad as a solution to those problems and more.
                    </h1>
                </div>
            </div>
            <div style={{padding: '20px 0px'}}>
                <img 
                    style={{height: '300px', width: 'auto'}}
                    src={headshot}
                >
                </img>
            </div>
            <div style={{padding: '20px 0px', textAlign: 'center'}}>
                <h1 style={{ color: 'white', fontWeight: '500', fontSize: '80px' }}>
                    Michael - <em>Lesson Launchpad Founder</em>
                </h1>
            </div>
        </Row>


{/************************************************* FEATURES *************************************************/}

    <div style={{ textAlign: 'center', marginBottom: '50px', marginTop: '100px' }}>    
      <h1 style={{ fontWeight: '1000', fontSize: '10rem', textTransform: 'uppercase', color: '#004aad', textShadow: '6px 4px 4px grey' }}>
          <b>Automation</b>          
        </h1>
    </div>
    <Container>
      <Row xs="1" className="my-5">
        {features.filter((feature) => feature.automation === true).map(({ id, title, imgSrc, description }) => (
          <Col key={id}>
            <Card
              style={{borderRadius: '60px'}}
              className="border"
              onMouseEnter={() => setHoveredFeature(id)}
              onMouseLeave={() => setHoveredFeature(null)}
            >
              <CardBody className="text-center">
                <img
                  src={imgSrc}
                  alt={title}
                  style={{width: '250px', height: 'auto'}}
                />
              </CardBody>
              <CardHeader className="h5 text-center" id="landing-feature" style={{fontSize: '70px'}}>
                {title}
              </CardHeader>
              {hoveredFeature === id && (
                <CardImgOverlay
                    style={{background: '#004aad', opacity: '95%', color: 'white', fontSize: '45px', borderRadius: '60px'}}
                >
                  <CardText>{description}</CardText>
                </CardImgOverlay>
              )}
            </Card>
          </Col>
        ))}
      </Row>
    </Container>
    <Row style={{display: 'flex', alignContent: 'center', justifyContent: 'center', padding: '50px 0px 20px 0px'}}>
      <video
        ref={videoRef}
        id="videoPlayer"
        width="1200"
        height="675"
        src="https://firebasestorage.googleapis.com/v0/b/lesson-launchpad.appspot.com/o/resources%2Fmisc%2FAutomation%20Demo.mp4?alt=media&token=d0fe3b48-d5bb-4f3b-9d9e-8a7d5ad6009a"
        controls={false}
        muted
        autoPlay={false}
        style={{ borderRadius: '20px' }}
      ></video>
    </Row>
    <hr></hr>
    <div style={{ textAlign: 'center', marginBottom: '50px', marginTop: '100px' }}>    
      <h1 style={{ fontWeight: '1000', fontSize: '10rem', textTransform: 'uppercase', color: '#004aad', textShadow: '6px 4px 4px grey' }}>
        <b>Features</b>          
      </h1>
    </div>
    <Container>
      <Row xs="1" className="my-5">
        {features.filter((feature) => feature.automation !== true).map(({ id, title, imgSrc, description }) => (
          <Col key={id}>
            <Card
              style={{borderRadius: '60px'}}
              className="border"
              onMouseEnter={() => setHoveredFeature(id)}
              onMouseLeave={() => setHoveredFeature(null)}
            >
              <CardBody className="text-center">
                <img
                  src={imgSrc}
                  alt={title}
                  style={{width: '250px', height: 'auto'}}
                />
              </CardBody>
              <CardHeader className="h5 text-center" id="landing-feature" style={{fontSize: '70px'}}>
                {title}
              </CardHeader>
              {hoveredFeature === id && (
                <CardImgOverlay
                    style={{background: '#004aad', opacity: '95%', color: 'white', fontSize: '45px', borderRadius: '60px'}}
                >
                  <CardText>{description}</CardText>
                </CardImgOverlay>
              )}
            </Card>
          </Col>
        ))}
      </Row>
    </Container>

{/************************************************* Testimonial Carousel *************************************************/}

    <TestimonialCarousel testimonialData={testimonialData} imageDimensions={imageDimensions} handleImageLoad={handleImageLoad} handleImageClick={handleImageClick}/>

{/************************************************* PRICING *************************************************/}

      {pricingData &&
      // <Row style={{ height: '1600px', backgroundColor: "white", alignItems: 'center', justifyContent: 'center', color: '#004aad',  }}>
      //     <div ref={pricingRef} id="pricing-section" style={{ textAlign: 'center', marginBottom: '100px', marginLeft: '5%', marginRight: '5%', marginTop: '50px' }}>
      //         <div style={{ textAlign: 'center', marginBottom: '50px', marginTop: '50px' }}>    
      //             <h1 style={{ color: 'rgb(11, 192, 223)', fontWeight: '1000', fontSize: '10rem', textShadow: '1px 1px 0px #004aad' }}>
      //                 <b>Pricing:</b>
      //             </h1>
      //         </div>
      //         <h3 style={{ marginBottom: '5px', fontWeight: '600', fontSize: '70px'}} >
      //         Unlock the power of Lesson Launchpad at an unbeatable value:
      //         </h3>
      //         <h5 style={{ fontSize: '60px', marginTop: '30px'}} >
      //         <div>Just <strong><em>{`$${pricingData.monthlyPrice}`}</em></strong> per month.</div>
      //         </h5>
      //         <h5 style={{ fontSize: '60px'}} >
      //         <div>Or enjoy a full year of access for only <strong><em>{`$${pricingData.yearlyPrice}`}</em></strong>.</div>
      //         </h5>
      //         <h3 style={{ marginBottom: '5px', marginTop: '50px', fontWeight: '600', fontSize: '70px'}} >
      //         But wait, there's more:
      //         </h3>
      //         <h5 style={{ fontSize: '60px', marginTop: '30px'}} >
      //         <div>Dive in risk-free with a 30-day full-featured trial.</div>
      //         </h5>
      //         <h5 style={{ fontSize: '60px'}} >
      //         <div>No credit card required until you're ready to take the plunge.</div>
      //         </h5>
      //         <Button tag={Link} to="/auth/register" className="landing-btn-no-hover" 
      //             style={{
      //                 backgroundColor: 'white', 
      //                 color: "rgb(11, 192, 223)", 
      //                 height: '150px', 
      //                 width: 'auto', 
      //                 fontSize: '50px', 
      //                 borderRadius: '30px', 
      //                 marginTop: '50px', 
      //                 zoom: '1.5',
      //                 border: '5px solid rgb(11, 192, 223)'
      //             }}
      //         >
      //             <div className="d-flex align-items-center">
      //             <img src={logo} alt="lesson-launchpad-logo" style={{ width: '125px', height: 'auto', marginRight: '15px' }} />
      //             Try it now!
      //             </div>
      //         </Button>
      //     </div>
      // </Row>

      <Row
                style={{
                    height: '3000px',
                    alignItems: 'center',
                    justifyContent: 'center',
                    padding: '50px 20px',
                    backgroundColor: 'white',
                    display: 'flex',
                }}
            >
                {/* Header Section */}
                <Row ref={pricingRef} id="pricing-section" style={{ textAlign: 'center', marginBottom: '20px' }}>
                    <h1
                        style={{
                            color: '#004aad',
                            textShadow: '6px 4px 4px grey',
                            fontSize: '9rem',
                            marginBottom: '50px',
                        }}
                    >
                        <b>Cool! How much does it cost?</b>
                    </h1>
                    <p style={{ color: '#555', fontSize: '4rem', marginBottom: '20px' }}>
                        Unlock the power of Lesson Launchpad with plans designed for every educator!
                    </p>
                </Row>

                {/* Pricing Cards */}
                <Row
                    className="d-flex flex-wrap justify-content-center"
                    style={{ gap: '30px', marginBottom: '20px' }}
                >
                    {/* Monthly Plan */}
                    <div
                        className="card"
                        style={{
                            width: '1100px',
                            padding: '60px',
                            border: '3px solid #004aad',
                            borderRadius: '36px',
                            boxShadow: '0px 8px 12px rgba(0, 0, 0, 0.1)',
                        }}
                    >
                        <h2 style={{ color: '#004aad', fontWeight: '700', marginBottom: '30px', fontSize: '110px' }}>
                            Monthly Plan
                        </h2>
                        <p
                            style={{
                                fontSize: '6rem',
                                color: '#11c0df',
                                fontWeight: '600',
                                marginBottom: '20px',
                            }}
                        >
                            {`$${pricingData.monthlyPrice}`} <span style={{ fontSize: '3rem', color: '#555' }}>per month</span>
                        </p>
                        <hr style={{marginTop: '0px'}}></hr>
                        <ul style={{ textAlign: 'left', fontSize: '3.5rem', color: '#555' }}>
                            <li>Full access to all features</li>
                            <li>Cancel anytime</li>
                            <li>Affordable and flexible</li>
                        </ul>
                    </div>
                  </Row>
                  <Row>
                    {/* Annual Plan */}
                    <div
                        className="card"
                        style={{
                            width: '1100px',
                            padding: '60px',
                            border: '3px solid #004aad',
                            borderRadius: '36px',
                            boxShadow: '0px 8px 12px rgba(0, 0, 0, 0.1)',
                        }}
                    >
                        <h2 style={{ color: '#004aad', fontWeight: '700', marginBottom: '30px', fontSize: '110px' }}>
                            Annual Plan
                        </h2>            
                        <p
                          style={{
                            fontSize: '6rem',
                            color: '#11c0df',
                            fontWeight: '600',
                            marginBottom: '20px',
                          }}
                        >
                            {`$${pricingData.yearlyPrice}`} <span style={{ fontSize: '3rem', color: '#555' }}>per year</span>
                        </p>
                        <hr style={{marginTop: '0px'}}></hr>
                        <ul style={{ textAlign: 'left', fontSize: '3.5rem', color: '#555' }}>
                            <li>Save <b><em>{pricingData.savings}</em></b> compared to monthly</li>
                            <li>Full access to all features</li>
                            <li>Best value for educators</li>
                        </ul>
                    </div>
                </Row>

                {/* "But wait, there's more!" Section */}
                <Row style={{ display: 'flex', alignContent: 'center', justifyContent: 'center' }}>
                  <Row>
                    <h3 style={{ color: '#004aad', marginBottom: '20px', fontWeight: '600', fontSize: '110px' }}>
                        But wait, there's more!
                    </h3>
                  </Row>
                  <Row style={{marginBottom: '30px'}}>
                    <ul
                        style={{
                            listStyleType: 'none',
                            padding: 5,
                            color: '#555',
                            fontSize: '3rem',
                            marginBottom: '30px',
                        }}
                    >
                        <li>
                            <i
                                className="fa fa-check-circle"
                                style={{ color: '#11c0df', marginRight: '30px' }}
                            ></i>
                            Dive in risk-free with a 30-day full-featured trial
                        </li>
                        <li>
                            <i
                                className="fa fa-check-circle"
                                style={{ color: '#11c0df', marginRight: '30px' }}
                            ></i>
                            No credit card required until you're ready to commit
                        </li>
                    </ul>
                  </Row>
                  <Row>
                    <Button
                        tag={Link}
                        to="/auth/register"
                        className="landing-btn-no-hover pulsing-button"
                        style={{
                            border: "3px solid #004aad",
                            backgroundColor: 'white',
                            color: '#004aad',
                            height: '180px',
                            padding: '0 30px',
                            fontSize: '2.5rem',
                            borderRadius: '90px',
                            display: 'inline-flex',
                            alignItems: 'center',
                            gap: '15px',
                        }}
                    >
                        <img
                            src={logo}
                            alt="lesson-launchpad-logo"
                            style={{ width: '80px', height: 'auto' }}
                        />
                        Click HERE to start your 30-day FREE trial!
                    </Button>
                  </Row>
                </Row>
            </Row>

      
      }
    </div>
    )}

    {/************************************************* FOOTER *************************************************/}

        <LandingPageFooter />

   </>
  )
}

export default LandingPage
