
import React, { useState, useEffect, useRef } from "react";
import { Editor } from '@tinymce/tinymce-react';
import { auth, db } from "/Users/michaeltucker/Documents/coding-projects/client/src/firebase.js";
import { setDoc, doc } from "firebase/firestore";
import NotificationAlert from "react-notification-alert";
import backgroundOptionsData from "/Users/michaeltucker/Documents/coding-projects/client/src/BackgroundOptionsData.js";
import ReactBSAlert from "react-bootstrap-sweetalert";
import { saveContentBoxTemplate } from "saveTemplate";
import { useLocation } from "react-router-dom";
import { Card, CardBody, Col, CardFooter, Button, Modal, ModalBody, ModalHeader, ModalFooter, Container, Row, FormGroup, Label, Input, UncontrolledTooltip, FormFeedback} from "reactstrap";
import canvaShare from "/Users/michaeltucker/Documents/coding-projects/client/src/assets/img/canva-share-link.gif";
import { createRandomId } from "RandomId";
import FullscreenModal from "../FullscreenModal";
import ActiveDatesModal from "../ActiveDatesModal";

function IframeBox({content, setHeading, updateContentBox, classId, user, navbarMD, view, addContentBox, multiBox, updateContentBoxWithinMultiBox, printActive, navigationVisible, inFullscreenModal, setClockKey}) {
  const [editedContent, setEditedContent] = useState();
  const [cardHeight, setCardHeight] = useState({card: "auto", editor: "auto"}); // Initially set the height to 'auto'
  // const [showSave, setShowSave] = useState(false);
  const [headingInsideContentBox, setHeadingInsideContentBox] = useState()
  const [contentPreview, setContentPreview] = useState();
  const [showMainEditButton, setShowMainEditButton] = useState(false);
  const [showButtons, setShowButtons] = useState(false);
  const [alert, setAlert] = useState(null);
  const location = useLocation()
  const date = new Date().toLocaleDateString('en-CA')
  const [boxToCopyId, setBoxToCopyId] = useState()
  const [copyBoxModal, setCopyBoxModal] = useState(false)
  const [classesSelectedForContentBoxCopy, setClassesSelectedForContentBoxCopy] = useState([]);
  const [fullscreenModal, setFullscreenModal] = useState(false);

  const toggleFullscreenModal = () => {
    setFullscreenModal(!fullscreenModal);
    fullscreenModal && setClockKey(1);
  }

  useEffect(() => {
    // Function to update the card height based on window height
    const updateCardHeight = () => {
      const windowHeight = window.innerHeight;
      const desiredDistance = navbarMD?.clock === '0' && navbarMD?.marquee === '0' ? 93 : 188; // Desired distance from the bottom
      const cardHeight = windowHeight - desiredDistance;
      // setCardHeight({card: `${cardHeight}px`, editor: `${cardHeight-25}px`});
      setCardHeight({card: `${navigationVisible ? cardHeight : cardHeight+50}px`, editor: `${cardHeight-25}px`});
    };

    // Call the updateCardHeight function when the window is resized
    window.addEventListener("resize", updateCardHeight);

    // Initial call to set the card height
    updateCardHeight();

    // Remove the event listener when the component is unmounted
    return () => {
      window.removeEventListener("resize", updateCardHeight);
    };
  }, [navbarMD, navigationVisible]);

  // to stop the warning of calling setState of unmounted component
  React.useEffect(() => {
    return function cleanup() {
      var id = window.setTimeout(null, 0);
      while (id--) {
        window.clearTimeout(id);
      }
    };
  }, []);

  const handleSave = () => {
    updateContentBox(content.contentBoxId, {...editedContent})
    setEditedContent()
    setShowSave(false)
  }

  const autoSaveContentBox = async (contentBoxId, updatedContent) => {
    try {
      const docRef = doc(db, "users", user.uid);
  
      const userCopy = JSON.parse(JSON.stringify(user));
  
      // Find the class index based on classId
      const classIndex = userCopy.classes.findIndex(
        (c) => c.classId === classId
      );
  
      if (classIndex !== -1) {
        // Find the content box index based on contentBoxId
        const contentBoxIndex = userCopy.classes[classIndex].contentBoxes.findIndex(
          (box) => box.contentBoxId === contentBoxId
        );
  
        if (contentBoxIndex !== -1) {
          // Make a copy of the existing content box using the spread operator
          const updatedContentBox = { ...userCopy.classes[classIndex].contentBoxes[contentBoxIndex] };
          // console.log(updatedContentBox)
  
          // Update the content box with the new data
          updatedContentBox.content = updatedContent.content;
          if (headingInsideContentBox) {
            if (headingInsideContentBox.length > 9) {
              updatedContentBox.heading = headingInsideContentBox.substring(0, 9) + "..."
            } else if (headingInsideContentBox.length === 0) {
              updatedContentBox.heading = "Type here..."
            } else {
              updatedContentBox.heading = headingInsideContentBox
            }
          }

          updatedContentBox.contentPreview = contentPreview?.substring(0, 30) + "..."

          // Create a new Date object
          var currentDate = new Date();

          // Get individual components of the date
          var year = currentDate.getFullYear();
          var month = currentDate.getMonth() + 1; // Months are zero-based, so add 1
          var day = currentDate.getDate();

          // Create a formatted string
          var formattedDate = (month < 10 ? '0' + month : month) + '/' + (day < 10 ? '0' + day : day) + '/' + year;

          updatedContentBox.lastEdited = formattedDate;
  
          // Update the content box in the document data
          userCopy.classes[classIndex].contentBoxes[contentBoxIndex] = updatedContentBox;
  
          // Update the document in Firestore
          await setDoc(docRef, userCopy);
          showAlertForContentBoxSave();
        }
      }
    } catch (error) {
      console.error('Error updating document:', error);
      // Check if the error message does not include the specific error you want to exclude
      if (!error.message.includes("Cannot read properties of undefined (reading 'content')")) {
        showAlertForContentBoxSaveFailure();
      }
    }
};

const [activeDatesModal, setActiveDatesModal] = useState(false)

  const toggleSetActiveDatesModal = () => {
    setActiveDatesModal(!activeDatesModal)
  }

  const [activeDates, setActiveDates] = useState({
    startDate: content.startDate === undefined ? "" : content.startDate,
    endDate: content.endDate === undefined ? "" : content.endDate
  });

  const saveContentBoxActiveDates = async (datesFromSetActiveDatesModal) => {
    try {
      const docRef = doc(db, "users", user.uid);
  
      const userCopy = JSON.parse(JSON.stringify(user));
  
      // Find the class index based on classId
      const classIndex = userCopy.classes.findIndex(
        (c) => c.classId === classId
      );
  
      if (classIndex !== -1) {
        // Find the content box index based on contentBoxId
        const contentBoxIndex = userCopy.classes[classIndex].contentBoxes.findIndex(
          (box) => box.contentBoxId === content.contentBoxId
        );
  
        if (contentBoxIndex !== -1) {
          // Make a copy of the existing content box using the spread operator
          const updatedContentBox = { ...userCopy.classes[classIndex].contentBoxes[contentBoxIndex]};
  
          // Update the content box with the new data
          updatedContentBox.startDate = datesFromSetActiveDatesModal.startDate;
          updatedContentBox.endDate = datesFromSetActiveDatesModal.endDate;
  
          // Update the content box in the document data
          userCopy.classes[classIndex].contentBoxes[contentBoxIndex] = updatedContentBox;
  
          // Update the document in Firestore
          await setDoc(docRef, userCopy);
  
        }
        toggleSetActiveDatesModal();
      }
    } catch (error) {
      console.error('Error updating document:', error);
    }
};

const sendBoxToStorage = async () => {
  try {
    const docRef = doc(db, "users", user.uid);

    const userCopy = JSON.parse(JSON.stringify(user));

    const classIndex = userCopy.classes.findIndex(
      (c) => c.classId === classId
    );

    const contentBoxIndex = userCopy.classes[classIndex].contentBoxes.findIndex(
      (box) => box.contentBoxId === content.contentBoxId
    );

    userCopy.classes[classIndex].contentBoxes[contentBoxIndex].active = false;

    // Update the document in Firestore
    await setDoc(docRef, userCopy);

  } catch (error) {
    console.error('Error updating document:', error);
  }
}

const [backgroundContentBoxModal, setBackgroundContentBoxModal] = useState(false);
const [backgroundContentBoxIdToChange, setBackgroundContentBoxIdToChange] = useState('');

const toggleBackgroundContentBoxModal = (contentBoxId) => {
  setBackgroundContentBoxModal(!backgroundContentBoxModal);
  setBackgroundContentBoxIdToChange(contentBoxId);
};

const saveBackgroundContentBox = async (imageFileName) => {
  const thing = `url(${imageFileName})`
  try {
    const docRef = doc(db, "users", user.uid);

    const userCopy = JSON.parse(JSON.stringify(user));

    const classIndex = userCopy.classes.findIndex(
      (c) => c.classId === classId
    );

    if (multiBox) {
      updateContentBoxWithinMultiBox({...content, background: thing }, true)
    } else {
      const contentBoxIndex = userCopy.classes[classIndex].contentBoxes.findIndex(
        (box) => box.contentBoxId === content.contentBoxId
      );
    
      userCopy.classes[classIndex].contentBoxes[contentBoxIndex].background = thing;
    
      // Update the document in Firestore
      await setDoc(docRef, userCopy);
    }

  } catch (error) {
    console.error('Error updating document:', error);
  }
}

const notificationAlert = useRef();

function showAlertForContentBoxSave() {
  var options = {};
  options = {
    place: 'tc',
    message: (
      <div>
        <div>
          <b>Content Box Saved!</b>
        </div>
      </div>
    ),
    type: 'info',
    icon: "now-ui-icons ui-1_bell-53",
    autoDismiss: 3,
    closeButton: false,
  };
  notificationAlert.current.notificationAlert(options);
}

function showAlertForContentBoxSaveFailure() {
  var options = {};
  options = {
    place: 'tc',
    message: (
      <div>
        <div>
          <b>Content Box NOT Saved!</b>
        </div>
      </div>
    ),
    type: 'danger',
    icon: "now-ui-icons ui-1_bell-53",
    autoDismiss: 3,
    closeButton: false,
  };
  notificationAlert.current.notificationAlert(options);
}

const editBoxNameAlert = (contentBoxId) => {
  setAlert(
    <ReactBSAlert
      input
      showCancel
      style={{ display: "block", marginTop: "-100px" }}
      title="What would you like to name this box?"
      onConfirm={(e) => updateContentBoxHeading(contentBoxId, e)}
      onCancel={() => hideAlert()}
      confirmBtnBsStyle="info"
      cancelBtnBsStyle="danger"
      btnSize=""
      defaultValue={content.heading}
    />
  );
};

const updateContentBoxHeading = async (contentBoxId, updatedHeading) => {
  hideAlert()
  try {
    const docRef = doc(db, "users", user.uid);

    const userCopy = JSON.parse(JSON.stringify(user));

    // Find the class index based on classId
    const classIndex = userCopy.classes.findIndex(
      (c) => c.classId === classId
    );

    if (classIndex !== -1) {
      // Find the content box index based on contentBoxId
      const contentBoxIndex = userCopy.classes[classIndex].contentBoxes.findIndex(
        (box) => box.contentBoxId === contentBoxId
      );

      if (contentBoxIndex !== -1) {
        // Make a copy of the existing content box using the spread operator
        const updatedContentBox = { ...userCopy.classes[classIndex].contentBoxes[contentBoxIndex], userHeading: true };

        // Update the content box with the new data
        if (updatedHeading.length > 9) {
          updatedContentBox.heading = updatedHeading.substring(0, 9) + "..."
        } else if (updatedHeading.length === 0) {
          updatedContentBox.heading = "Type here..."
        } else {
          updatedContentBox.heading = updatedHeading
        }

        // Update the content box in the document data
        userCopy.classes[classIndex].contentBoxes[contentBoxIndex] = updatedContentBox;

        // Update the document in Firestore
        await setDoc(docRef, userCopy); // Replace with the correct path to your document

        // Update the local state with the updated document data
        setBoxes(userCopy.classes[classIndex].contentBoxes);

        successAlert()
      }
    }
  } catch (error) {
    console.error('Error updating document:', error);
  }
};

const warningWithConfirmAndCancelMessage = (id) => {
  setAlert(
    <ReactBSAlert
      warning
      style={{ display: "block", marginTop: "-100px" }}
      title="Are you sure?"
      onConfirm={() => deleteContentBox(id)}
      onCancel={() => cancelDelete()}
      confirmBtnBsStyle="info"
      cancelBtnBsStyle="danger"
      confirmBtnText="Yes, delete it!"
      cancelBtnText="Cancel"
      showCancel
      btnSize=""
    >
      You will not be able to recover this content box!
    </ReactBSAlert>
  );
};

const successDelete = () => {
  setAlert(
    <ReactBSAlert
      success
      style={{ display: "block", marginTop: "-100px" }}
      title="Deleted!"
      onConfirm={() => hideAlert()}
      onCancel={() => hideAlert()}
      confirmBtnBsStyle="info"
      btnSize=""
    >
      Your content box has been deleted.
    </ReactBSAlert>
  );
};

const cancelDelete = () => {
  setAlert(
    <ReactBSAlert
      danger
      style={{ display: "block", marginTop: "-100px" }}
      title="Cancelled"
      onConfirm={() => hideAlert()}
      onCancel={() => hideAlert()}
      confirmBtnBsStyle="info"
      btnSize=""
    >
      Your content box is safe :)
    </ReactBSAlert>
  );
};

const deleteContentBox = async (contentBoxId) => {
  try {
    const docRef = doc(db, "users", user.uid);

    const userCopy = JSON.parse(JSON.stringify(user));

    const classIndex = userCopy.classes.findIndex(
      (c) => c.classId === classId
    );

    const contentBoxIndex = userCopy.classes[classIndex].contentBoxes.findIndex(
      (box) => box.contentBoxId === contentBoxId
    );

    // Remove the content box at the specified index
    userCopy.classes[classIndex].contentBoxes.splice(contentBoxIndex, 1);

    // Update the document in Firestore
    await setDoc(docRef, userCopy);

    // // Update the local state with the updated document data
    // setStateContent(userCopy);

    successDelete()

    console.log('Content box deleted successfully!');
  } catch (error) {
    console.error('Error deleting content box:', error);
  }
}


const addTemplateAlert = (contentBox) => {
  setAlert(
    <ReactBSAlert
      input
      showCancel
      style={{ display: "block", marginTop: "-100px" }}
      title="What would you like to call this template?"
      onConfirm={(e) => addTemplateConfirmAlert(e, contentBox)}
      onCancel={() => hideAlert()}
      confirmBtnBsStyle="info"
      cancelBtnBsStyle="danger"
      btnSize=""
      defaultValue={contentBox.heading}
    />
  );
};

const addTemplateConfirmAlert = (e, contentBox) => {
  saveContentBoxTemplate(user, e, contentBox);
  setAlert(
    <ReactBSAlert
      style={{ display: "block", marginTop: "-100px" }}
      onConfirm={() => hideAlert()}
      onCancel={() => hideAlert()}
      confirmBtnBsStyle="info"
      btnSize=""
      title={
        <p>
          You entered: <b>{e}</b>
        </p>
      }
    />
  );
};
  
const successAlert = () => {
  setAlert(
    <ReactBSAlert
      success
      style={{ display: "block", marginTop: "-100px" }}
      title="Saved!"
      onConfirm={() => hideAlert()}
      onCancel={() => hideAlert()}
      confirmBtnBsStyle="info"
      btnSize=""
    >
    </ReactBSAlert>
  );
};

const hideAlert = () => {
  setAlert(null);
};

  const [showCanvaBoxAlert, setShowCanvaBoxAlert] = useState(false);
  const [canvaLink, setCanvaLink] = useState();
  const [isCanvaLinkValid, setIsCanvaLinkValid] = useState(true);

  // const handleWebLinkChange = (value) => {
  //   let appendedValue = value;
  
  //   // Ensure the URL starts with "http://" or "https://"
  //   if (!/^https?:\/\//i.test(value)) {
  //     appendedValue = "https://" + value;
  //   }
  
  //   // Check if it's a YouTube URL and transform to embed format
  //   const youtubeRegex = /(?:https?:\/\/)?(?:www\.)?youtube\.com\/watch\?v=([^&]+)/;
  //   const youtubeShortRegex = /(?:https?:\/\/)?(?:www\.)?youtu\.be\/([^?&]+)/;
  
  //   if (youtubeRegex.test(appendedValue)) {
  //     const videoId = appendedValue.match(youtubeRegex)[1];
  //     appendedValue = `https://www.youtube.com/embed/${videoId}`;
  //   } else if (youtubeShortRegex.test(appendedValue)) {
  //     const videoId = appendedValue.match(youtubeShortRegex)[1];
  //     appendedValue = `https://www.youtube.com/embed/${videoId}`;
  //   }
  
  //   // Update state
  //   setIsCanvaLinkValid(true); // Adjust this if specific validation is required
  //   setCanvaLink(appendedValue);
  // };


  const handleWebLinkChange = (value) => {
    let appendedValue = value;
  
    // Ensure the URL starts with "http://" or "https://"
    if (!/^https?:\/\//i.test(value)) {
      appendedValue = "https://" + value;
    }
  
    // Check if it's a YouTube URL and transform to embed format
    const youtubeRegex = /(?:https?:\/\/)?(?:www\.)?youtube\.com\/watch\?v=([^&]+)/;
    const youtubeShortRegex = /(?:https?:\/\/)?(?:www\.)?youtu\.be\/([^?&]+)/;
  
    let embedUrl = "";
    if (youtubeRegex.test(appendedValue)) {
      const videoId = appendedValue.match(youtubeRegex)[1];
      embedUrl = `https://www.youtube.com/embed/${videoId}`;
    } else if (youtubeShortRegex.test(appendedValue)) {
      const videoId = appendedValue.match(youtubeShortRegex)[1];
      embedUrl = `https://www.youtube.com/embed/${videoId}`;
    }
  
    // If it's a YouTube embed URL, process parameters
    if (embedUrl) {
      const urlParams = new URLSearchParams(new URL(appendedValue).search);
  
      // Handle `t` or `start` parameters
      if (urlParams.has("t")) {
        const startValue = urlParams.get("t");
        embedUrl += `?start=${startValue}`;
      } else if (urlParams.has("start")) {
        const startValue = urlParams.get("start");
        embedUrl += `?start=${startValue}`;
      }
  
      appendedValue = embedUrl;
    }
  
    // Update state
    setIsCanvaLinkValid(true); // Adjust this if specific validation is required
    setCanvaLink(appendedValue);
  };
  
  

  const toggleCanvaBoxAlert = () => {
    setShowCanvaBoxAlert(prev => !prev)
    setCanvaLink("");
  }

  const addCanvaBoxConfirmAlert = (e) => {
    if (true) {
      if (!multiBox) {
        updateContentBox(
          content.contentBoxId, 
          {...content, content: canvaLink}
        )
      } else {
        updateContentBoxWithinMultiBox({
          ...content,
          content: canvaLink
        })
      }
      setAlert(
        <ReactBSAlert
          success
          style={{ display: "block", marginTop: "-100px" }}
          title="Web Box Updated!"
          onConfirm={() => hideAlert()}
          onCancel={() => hideAlert()}
          confirmBtnBsStyle="info"
          btnSize=""
        >
        </ReactBSAlert>
      );
      toggleCanvaBoxAlert()
    } else {
      setAlert(
        <ReactBSAlert
          warning
          style={{ display: "block", marginTop: "-100px" }}
          title="Invalid Website Link!"
          onConfirm={() => hideAlert()}
          onCancel={() => hideAlert()}
          confirmBtnBsStyle="info"
          btnSize=""
        >
          <p><b>You entered:</b> {canvaLink.replace(/\?embed$/, '')}</p>
          <br></br>
          <p>A valid URL will start with 'https://www.canva.com/design' and end with '/view'.</p>
        </ReactBSAlert>
      );
    }
  };

const isMobile = false // /Mobi/.test(navigator.userAgent);

const today = new Date().toISOString().slice(0,10)

const toggleCopyBoxModal = () => {
  setCopyBoxModal(!copyBoxModal)
  setClassesSelectedForContentBoxCopy([])
}

const handleCheckboxChange = (classId) => {
  setClassesSelectedForContentBoxCopy(prev => {
    if (prev.includes(classId)) {
      // If classId is already selected, remove it
      return prev.filter(id => id !== classId);
    } else {
      // If classId is not selected, add it
      return [...prev, classId];
    }
  });
};

const copyBoxToClasses = async () => {
  try {
    const docRef = doc(db, "users", user.uid);

    const userCopy = JSON.parse(JSON.stringify(user));

    // Find the class index based on classId
    const classIndex = userCopy.classes.findIndex(
      (c) => c.classId === classId
    );

    let boxToCopy = {};

    if (multiBox) {
      boxToCopy = {...content, header: 'Canva Box'}
    } else {
      boxToCopy = userCopy.classes[classIndex].contentBoxes.find(box => box.contentBoxId === boxToCopyId);
    }

    boxToCopy = { ...boxToCopy, contentBoxId: createRandomId() };

    let classesCopy = [...userCopy.classes];

    classesSelectedForContentBoxCopy.forEach(classId => {
      const classIndex = classesCopy.findIndex(c => c.classId === classId);

      if (classIndex !== -1) {
        // Copy the contentBoxes array and add the new box
        const updatedContentBoxes = [...classesCopy[classIndex].contentBoxes, boxToCopy];
        classesCopy[classIndex] = {
          ...classesCopy[classIndex],
          contentBoxes: updatedContentBoxes
        };
      } else {
        console.log(`Class with ID ${classId} not found.`);
      }
    });

    userCopy.classes = classesCopy;

    await setDoc(docRef, userCopy);

    toggleCopyBoxModal()

  } catch (error) {
    console.log("failed", error);
  }
};

  return (
    <>
    {showCanvaBoxAlert && (
          <ReactBSAlert
          showCancel
          title="Add Web Box"
          style={{ display: "block", marginTop: "-100px" }}
          onConfirm={(e) => addCanvaBoxConfirmAlert(e)}
          onCancel={() => toggleCanvaBoxAlert()}
          confirmBtnBsStyle="info"
          cancelBtnBsStyle="danger"
          btnSize=""
          // validationRegex={/^https:\/\/www\.canva\.com\/design\/[^\/]+\/[^\/]+\/view$/}
          // validationMsg={"Invalid URL. A valid URL will start with 'https://www.canva.com/design' and end with '/view'."}
        >
        {/* <img src={canvaShare} alt="canva-share-gif" style={{ width: '400px', height: 'auto', marginBottom: '20px', border: '2px solid black' }}/> */}
        <div>
          <hr />
          <br></br>
          <div>
            <p>Input the link to the website:</p>
            <hr />
            <Input
              type="text"
              onChange={(e) => handleWebLinkChange(e.target.value)}
              placeholder={"Your website link here..."}
              required={true}
              // valid={isCanvaLinkValid}
              // invalid={!isCanvaLinkValid}
            />
            <hr />
            <p><em>Note: Not all websites will allow embeds for security reasons.</em></p>
            <FormFeedback type="invalid">
              {/* Validation error message */}
              {/* {isCanvaLinkValid ? null : 'Invalid input!'} */}
            </FormFeedback>
          </div>
        </div>
        </ReactBSAlert>
    )}

    <Modal isOpen={copyBoxModal} toggle={toggleCopyBoxModal}>
      <ModalBody>
        <h5>Copy box to:</h5>
        {user && user.classes && user.classes.map(x => 
          <FormGroup check>
            <Label check>
            <Input
              type="checkbox"
              defaultChecked={classesSelectedForContentBoxCopy.length > 0 && classesSelectedForContentBoxCopy.includes(x.classId)}
              onChange={() => handleCheckboxChange(x.classId)}
            />                
            <span className="form-check-sign" />
            {x.className}
            </Label>
          </FormGroup>
        )}
      </ModalBody>
      <ModalFooter>
        <Button disabled={classesSelectedForContentBoxCopy.length < 1} onClick={() => copyBoxToClasses()}>Copy</Button>
        <Button onClick={toggleCopyBoxModal}>Cancel</Button>
      </ModalFooter>
    </Modal>

    <Col 
      style={{ flex: "1", minHeight: "0", padding: '4px', height: '100%', border: showButtons ? 'rgb(11, 192, 223) solid 2px' : 'transparent solid 2px', borderRadius: '15px' }}
      onMouseEnter={() => setShowMainEditButton(true)}
      onMouseLeave={() => {setShowMainEditButton(false); setShowButtons(false)}}
    >
      {alert}
        <Card 
          key={content.contentBoxId}
          style={{ height: multiBox || inFullscreenModal ? '100%' : cardHeight.card, backgroundImage: content.background || '', zoom: location.pathname === '/print' ? '.6' : '1', border: printActive === true ? '4px solid blue' : location.pathname === '/print' ? 'solid 1px' : 'none'}}
        >
          {!inFullscreenModal && showMainEditButton && location.pathname !== '/add-edit-classes' && location.pathname !== '/print' && !location.pathname.includes('/share/') && (
            <>
              <Button
                // className="btn-round btn-icon"
                id={`options-${content.contentBoxId}`}
                type="button"
                style={{ backgroundColor: 'rgb(11, 192, 223) !important', color: 'white', position: 'absolute', transform: 'translateY(-60%)', right: '-12px', borderRadius: '30px', height: '30px', width: '30px', padding: '7px 11px', zIndex: '1049', overflow: 'visible'}}
                size="sm"
                onClick={() => setShowButtons(!showButtons)}
              >
                <i className={showButtons === false ? "fa fa-caret-down" : "fa fa-caret-up"} />
              </Button>
                <UncontrolledTooltip
                  delay={0}
                  target={`options-${content.contentBoxId}`}
                  placement="left"
                >
                    Options
                </UncontrolledTooltip>
                {showButtons && location.pathname !== '/add-edit-classes' && (
                  <>
                    {!multiBox &&
                      <>
                        <Button
                          // className="btn-round btn-icon"
                          id="editContentBoxHeading"
                          type="button"
                          style={{ backgroundColor: 'rgb(11, 192, 223) !important', color: 'white', position: 'absolute', transform: 'translateY(60%)', right: '-12px', borderRadius: '30px', height: '30px', width: '30px', padding: '6px 10px', zIndex: '1049', overflow: 'visible'}}
                          size="sm"
                          onClick={() => editBoxNameAlert(content.contentBoxId)}
                        >
                          <i className="fa fa-pencil" />
                        </Button>
                        <UncontrolledTooltip
                          delay={0}
                          target="editContentBoxHeading"
                          placement="left"
                        >
                            Edit Heading
                        </UncontrolledTooltip>
                      </>
                    }
                      <Button
                        // className="btn-round btn-icon"
                        id="changeCanvaLink"
                        type="button"
                        style={{ backgroundColor: 'rgb(11, 192, 223) !important', color: 'white', position: 'absolute', transform: multiBox ? 'translateY(60%)' : 'translateY(180%)', right: '-12px', borderRadius: '30px', height: '30px', width: '30px', padding: '6px 9px', zIndex: '1049', overflow: 'visible'}}
                        size="sm"
                        onClick={() => setShowCanvaBoxAlert(!showCanvaBoxAlert)}
                      >
                        <i className="fa fa-link" />
                      </Button>
                      <UncontrolledTooltip
                        delay={0}
                        target="changeCanvaLink"
                        placement="left"
                      >
                          Change Website Link
                      </UncontrolledTooltip>
                    <Button
                    // className="btn-round btn-icon"
                    id="changeContentBoxBackground"
                    type="button"
                    style={{ backgroundColor: 'rgb(11, 192, 223) !important', color: 'white', position: 'absolute', transform: multiBox ? 'translateY(180%)' : 'translateY(300%)', right: '-12px', borderRadius: '30px', height: '30px', width: '30px', padding: '6px 8px', zIndex: '1049'}}
                    size="sm"
                    onClick={() => toggleBackgroundContentBoxModal()}
                    >
                      <i class="nc-icon nc-palette"></i>             
                    </Button>
                      <UncontrolledTooltip
                        delay={0}
                        target="changeContentBoxBackground"
                        placement="left"
                      >
                          Change Background
                      </UncontrolledTooltip>
                      {user.settings.useActiveDates && !multiBox ? (
                        <>
                        <Button
                        // className="btn-round btn-icon"
                        id="setContentBoxActiveDates"
                        type="button"
                        style={{ backgroundColor: 'rgb(11, 192, 223) !important', color: 'white', position: 'absolute', transform: 'translateY(420%)', right: '-12px', borderRadius: '30px', height: '30px', width: '30px', padding: '4px 9px', zIndex: '1049'}}
                        size="sm"
                        onClick={() => toggleSetActiveDatesModal()}
                        // onClick={null}
                        >
                          <i className="fa fa-calendar" />
                        </Button>
                          <UncontrolledTooltip
                            delay={0}
                            target="setContentBoxActiveDates"
                            placement="left"
                          >
                              Set Active Dates
                          </UncontrolledTooltip>
                        </>
                      ) : !multiBox &&
                      (
                        <>
                        <Button
                        // className="btn-round btn-icon"
                        id="sendBoxToStorage"
                        type="button"
                        style={{ backgroundColor: 'rgb(11, 192, 223) !important', color: 'white', position: 'absolute', transform: 'translateY(420%)', right: '-12px', borderRadius: '30px', height: '30px', width: '30px', padding: '5px 8px', zIndex: '1049'}}
                        size="sm"
                        onClick={() => sendBoxToStorage()}
                        // onClick={null}
                        >
                          <i className="nc-icon nc-box" />
                        </Button>
                          <UncontrolledTooltip
                            delay={0}
                            target="sendBoxToStorage"
                            placement="left"
                          >
                              Send Box to Storage
                          </UncontrolledTooltip>
                        </>
                      )}
                    <Button
                      // className="btn-round btn-icon"
                      id="copyBox"
                      type="button"
                      style={{ backgroundColor: 'rgb(11, 192, 223) !important', color: 'white', position: 'absolute', transform: multiBox ? 'translateY(300%)' : 'translateY(540%)', right: '-12px', borderRadius: '30px', height: '30px', width: '30px', padding: '5px 8px', zIndex: '1049'}}
                      size="sm"
                      onClick={() => {setBoxToCopyId(content.contentBoxId); toggleCopyBoxModal()}}
                      // onClick={null}
                    >
                      <i className="fa fa-copy" />
                    </Button>
                        <UncontrolledTooltip
                          delay={0}
                          target="copyBox"
                          placement="left"
                        >
                            Duplicate Box
                        </UncontrolledTooltip>
                    <Button
                        // className="btn-round btn-icon"
                        id="toggleFullscreenModal"
                        type="button"
                        style={{ backgroundColor: 'rgb(11, 192, 223) !important', color: 'white', position: 'absolute', transform: multiBox ? 'translateY(420%)' : 'translateY(660%)', right: '-12px', borderRadius: '30px', height: '30px', width: '30px', padding: '4px 8px', zIndex: '1049'}}
                        size="sm"
                        onClick={() => {toggleFullscreenModal()}}
                    >
                        <i className="fa fa-expand"></i>                        
                      </Button>
                        <UncontrolledTooltip
                          delay={0}
                          target="toggleFullscreenModal"
                          placement="left"
                        >
                            Expand
                        </UncontrolledTooltip>
                    {/* <Button
                    // className="btn-round btn-icon"
                    id="saveContentBoxAsTemplate"
                    type="button"
                    style={{ backgroundColor: 'rgb(11, 192, 223) !important', color: 'white', position: 'absolute', transform: 'translateY(420%)', right: '-12px', borderRadius: '30px', height: '30px', width: '30px', padding: '4px 8px', zIndex: '1049'}}
                    size="sm"
                    onClick={() => addTemplateAlert(content)}
                    >
                      <i className="fa fa-copy" />
                    </Button>
                      <UncontrolledTooltip
                        delay={0}
                        target="saveContentBoxAsTemplate"
                        placement="left"
                      >
                          Save as Template
                      </UncontrolledTooltip> */}
                    {!multiBox &&
                      <>
                        <Button
                          className="button-delete"
                          id="deleteContentBox"
                          type="button"
                          style={{ color: 'white', position: 'absolute', transform: 'translateY(780%)', right: '-12px', borderRadius: '30px', height: '30px', width: '30px', padding: '4px 9px', zIndex: '1049'}}
                          size="sm"
                          onClick={() => warningWithConfirmAndCancelMessage(content.contentBoxId)}
                        >
                          <i className="fa fa-trash" />
                        </Button>
                        <UncontrolledTooltip
                          delay={0}
                          target="deleteContentBox"
                          placement="left"
                        >
                            Delete
                        </UncontrolledTooltip>
                      </>
                    }
                  </>
                  )}
            </>
          )}
          <CardBody>
            {content.content !== null ?
              <div style={{ minHeight: '100%', overflowY: 'auto' }}>
                <iframe
                  loading="lazy"
                  style={{position: "absolute", width: "100%", height: "100%", top: 0, left: 0, border: "none", padding: "10px", margin: "0px"}}
                  src={content.content}
                  allowFullScreen
                  allow="fullscreen"
                >
                </iframe>
              </div>
            :
              <div style={{ height: '100%', overflowY: 'auto', alignContent: 'center', textAlign: 'center' }}>
                <Button
                  onClick={() => setShowCanvaBoxAlert(!showCanvaBoxAlert)}
                >
                  Add Website Link
                </Button>
              </div>
            }
          </CardBody>
        </Card>

        <ActiveDatesModal activeDatesModal={activeDatesModal} toggleSetActiveDatesModal={toggleSetActiveDatesModal} activeDates={activeDates} saveActiveDates={saveContentBoxActiveDates}/>

          <Modal 
            isOpen={backgroundContentBoxModal}
            toggle={toggleBackgroundContentBoxModal}
            backdrop={false}
            scrollable
          >
            <ModalHeader toggle={toggleBackgroundContentBoxModal} />
            <ModalBody style={{padding: '0'}} className="agenda-body">
              <Container>
                <Row xs="6">
                  {backgroundOptionsData.map((option, index) => (
                    <Col key={index} onClick={() => saveBackgroundContentBox(option.image)} style={{ padding: '0px' }}>
                      <div className="image-container">
                        <img src={option.thumbnail} alt={`Background option ${index + 1}`} />
                      </div>
                    </Col>
                  ))}
                </Row>
              </Container>
            </ModalBody>
          </Modal>
          <FullscreenModal 
            fullscreenModal={fullscreenModal} 
            toggleFullscreenModal={toggleFullscreenModal}
            box={content} 
            navbarMD={navbarMD} 
            classIdRef={classId}
            view={view}
            user={user}
            multiBox={true}
            addContentBox={addContentBox}
            updateContentBox={updateContentBox}
            navigationVisible={navigationVisible}
          />
        </Col>
    </>
  );
}

export default IframeBox;
