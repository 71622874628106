import React, { useEffect, useState } from 'react';
import { doc, getDoc } from "firebase/firestore";
import { db } from "/Users/michaeltucker/Documents/coding-projects/client/src/firebase.js";
import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Table,
  Row,
  Col
} from "reactstrap";

const Changelog = () => {

  const [changelogData, setChangelogData] = useState();

  useEffect(() => {
    let isMounted = true;
  
    const fetchData = async () => {
      try {
        const docRef = doc(db, "changelog", "LuNLZFQfBnGc1zeE2lNA");
        const docSnap = await getDoc(docRef);
  
        if (docSnap.exists() && isMounted) {
          const changelogData = docSnap.data();

          const sortedKeys = Object.keys(changelogData).sort((a, b) => parseFloat(a) - parseFloat(b));

          const sortedData = sortedKeys.reduce((acc, key) => {
              acc[key] = changelogData[key];
              return acc;
          }, {});

          setChangelogData(sortedData);

        } else {
          console.log("No such document!");
        }
      } catch (error) {
        console.error('Error fetching document:', error);
      }
    };
  
    fetchData();
  
    // Cleanup function to set isMounted to false when the component is unmounted
    return () => {
      isMounted = false;
    };
  }, []);

        
  return (
      <Col md='12' style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>                      
          <Card style={{ margin: '0px 10px', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <CardHeader style={{minHeight: '0px'}}>
            <h2 style={{ fontWeight: "bold", color: "#004aad", margin: "0px" }}>Changelog</h2>
          </CardHeader>
          <CardBody>
            <Table responsive>
              <thead className="text-primary">
                <tr>
                  <th className="text-center" id="lakjsdfadsf" style={{width: '10%'}}>Date</th>
                  <th className="text-left" id="ubqweoivafa" style={{width: '90%'}}>Changes</th>
                </tr>
              </thead>
              {changelogData && Object.entries(changelogData).map(([key, value]) => {
                return (
                  <tbody key={key}>
                    <tr>
                      <td className="text-center">{value.date}</td>
                      <td className="text-left">
                        {value.image ? (
                          <a href={value.image} target="_blank" rel="noopener noreferrer">
                            {value.changes}
                          </a>
                        ) : (
                          value.changes
                        )}
                      </td>
                    </tr>
                  </tbody>
                );
              })}
            </Table>
          </CardBody>
        </Card>
      </Col>
  )
}

export default Changelog