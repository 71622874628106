import React, { useState, useEffect } from 'react';
import { Col, Card, Button, CardBody } from 'reactstrap';

const Stopwatch = ({id}) => {
  const [milliseconds, setMilliseconds] = useState(0);
  const [seconds, setSeconds] = useState(0);
  const [minutes, setMinutes] = useState(0);
  const [hours, setHours] = useState(0);
  const [isActive, setIsActive] = useState(false);

  useEffect(() => {
    let intervalId;

    if (isActive) {
      intervalId = setInterval(() => {
        updateTimer();
      }, 10);
    } else if (!isActive) {
      clearInterval(intervalId);
    }

    return () => clearInterval(intervalId);
  }, [isActive]);

  const updateTimer = () => {
    setMilliseconds((prevMilliseconds) => {
      if (prevMilliseconds >= 990) {
        setSeconds((prevSeconds) => {
          if (prevSeconds >= 59) {
            setMinutes((prevMinutes) => {
              if (prevMinutes >= 59) {
                setHours((prevHours) => prevHours + 1);
                return 0;
              }
              return prevMinutes + 1;
            });
            return 0;
          }
          return prevSeconds + 1;
        });
        return 0;
      }
      return prevMilliseconds + 10;
    });
  };

  const startTimer = () => {
    setIsActive(true);
  };

  const pauseTimer = () => {
    setIsActive(false);
  };

  const resetTimer = () => {
    setIsActive(false);
    setMilliseconds(0);
    setSeconds(0);
    setMinutes(0);
    setHours(0);
  };

  const [fontSize, setFontSize] = useState()

  useEffect(() => {
    const element = document.getElementById(`stopwatch-${id}`);
    
    if (element) {
      const resizeObserver = new ResizeObserver(entries => {
        for (let entry of entries) {
          const newWidth = entry.contentRect.width;
          setFontSize(newWidth / 8); // Adjust this formula as needed
        }
      });
  
      // Start observing the element
      resizeObserver.observe(element);
  
      // Clean up the observer when the component unmounts
      return () => {
        resizeObserver.unobserve(element);
      };
    }
  }, [id]);
  
  return (
        <CardBody className="text-center" id={`stopwatch-${id}`}>
          {/* <h1>Stopwatch</h1> */}
          <h1 className='stopwatch-digits' style={{fontSize: fontSize}}>
            {hours > 0 && String(hours).padStart(2, '0')}{hours > 0 && ':'}{String(minutes).padStart(2, '0')}:{String(seconds).padStart(2, '0')}<span style={{ fontSize: '0.5em' }}>:{String(milliseconds).padStart(3, '0')}</span>
          </h1>
          <div style={{marginTop: '20px'}}>
            {isActive ? (
              <Button style={{backgroundColor: '#0bc0df', borderRadius: '30px' }} onClick={pauseTimer}>Pause</Button>
            ) : (
              <Button style={{backgroundColor: '#0bc0df', borderRadius: '30px' }} onClick={startTimer}>Start</Button>
            )}
            <Button style={{backgroundColor: '#0bc0df', borderRadius: '30px' }} onClick={resetTimer}>Reset</Button>
          </div>
        </CardBody>
  );
};

export default Stopwatch;
