import React, { useState } from 'react';

const CollapsibleObject = ({ data, level = 0 }) => {
    const [collapsed, setCollapsed] = useState(() => {
        const initialState = {};
        Object.keys(data).forEach(key => {
            if (typeof data[key] === 'object' && data[key] !== null) {
                initialState[key] = true; // Default to collapsed
            }
        });
        return initialState;
    });

    const toggleCollapse = (key) => {
        setCollapsed((prev) => ({
            ...prev,
            [key]: !prev[key],
        }));
    };

    if (typeof data !== 'object' || data === null) {
        return <span>{String(data)}</span>;
    }

    return (
        <div style={{ marginLeft: level * 15, fontFamily: 'monospace' }}>
            {Object.entries(data).map(([key, value]) => (
                <div key={key}>
                    {typeof value === 'object' && value !== null ? (
                        <>
                            <button
                                onClick={() => toggleCollapse(key)}
                                style={{ marginRight: 5, cursor: 'pointer' }}
                            >
                                {collapsed[key] ? '+' : '-'}
                            </button>
                            <strong>{key}:</strong>
                            {!collapsed[key] && (
                                <div>
                                    <CollapsibleObject data={value} level={level + 1} />
                                </div>
                            )}
                        </>
                    ) : (
                        <span>
                            <strong>{key}:</strong> {String(value)}
                        </span>
                    )}
                </div>
            ))}
        </div>
    );
};

import { Progress } from 'reactstrap';

const UserDataViewer = ({ userData }) => {
    const totalBytes = new Blob([JSON.stringify(userData)]).size;
    const maxBytes = 1048576;
    const progressPercentage = ((totalBytes / maxBytes) * 100).toFixed(1);

    return (
        <div>
            <h3>User Data</h3>
            <label>Storage Space:</label>
            <Progress value={progressPercentage} max={100} color={progressPercentage > 80 ? 'danger' : 'primary'}>
                {`${progressPercentage}%`}
            </Progress>
            <hr></hr>
            <CollapsibleObject data={userData} />
        </div>
    );
};

export default UserDataViewer;
