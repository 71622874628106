/*!

=========================================================
* Paper Dashboard PRO React - v1.3.1
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import {React, useState, useEffect, useRef} from "react";
import { useSelector } from "react-redux";
import { selectUser } from "features/userSlice";
import { setDoc, doc, updateDoc, arrayUnion } from "firebase/firestore";
import { db } from "/Users/michaeltucker/Documents/coding-projects/client/src/firebase.js";
import ReactBSAlert from "react-bootstrap-sweetalert";
import { useLocation, useHistory } from 'react-router-dom';
import { createRandomId } from "RandomId";
import { NavLink } from 'react-router-dom';
import isScheduleNormal from "ScheduleChecker";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
  FormFeedback,
  UncontrolledTooltip,
  ModalHeader,
  Label
} from "reactstrap";

function AddEditClassForm() {

  const location = useLocation();
  const { state } = location;
  const item = state?.item;
  const user = useSelector(selectUser);
  const history = useHistory();
  const classIdForNewClass = useRef(createRandomId())
  const announcementIdForNewClass = useRef(createRandomId())
  const [validated, setValidated] = useState(false);
  const [multiWeek, setMultiWeek] = useState(user.settings.multiWeek);
  const [differentTimes, setDifferentTimes] = useState(user.settings.differentTimes)
  const [numberOfWeeks, setNumberOfWeeks] = useState(user.settings.numberOfWeeks || 2)
  const [currentWeek, setCurrentWeek] = useState("A") //this is not the settings currentWeek - "A" should remain the default
  const currentDate = new Date();
  const month = currentDate.getMonth() + 1; // Months are zero-indexed, so add 1
  const day = currentDate.getDate();
  const year = currentDate.getFullYear();
  const formattedDate = `${month}/${day}/${year}`;
  
  const addWeeksToSchedule = async () => {
    if (item !== undefined) {
      if (!('G' in item.schedule)) {
        try {
          const docRef = doc(db, "users", user.uid);
    
          const userCopy = JSON.parse(JSON.stringify(user));
    
          const classIndex = userCopy.classes.findIndex(
            (c) => c.classId === data.classId
          );
    
          const newWeeks = {
            "E": {
              "Monday": {active: true, startTime: '00:00', endTime: '00:00'},
              "Tuesday": {active: true, startTime: '00:00', endTime: '00:00'},
              "Wednesday": {active: true, startTime: '00:00', endTime: '00:00'},
              "Thursday": {active: true, startTime: '00:00', endTime: '00:00'},
              "Friday": {active: true, startTime: '00:00', endTime: '00:00'},
              "Saturday": {active: false, startTime: '00:00', endTime: '00:00'},
              "Sunday": {active: false, startTime: '00:00', endTime: '00:00'},
            },
            "F": {
              "Monday": {active: true, startTime: '00:00', endTime: '00:00'},
              "Tuesday": {active: true, startTime: '00:00', endTime: '00:00'},
              "Wednesday": {active: true, startTime: '00:00', endTime: '00:00'},
              "Thursday": {active: true, startTime: '00:00', endTime: '00:00'},
              "Friday": {active: true, startTime: '00:00', endTime: '00:00'},
              "Saturday": {active: false, startTime: '00:00', endTime: '00:00'},
              "Sunday": {active: false, startTime: '00:00', endTime: '00:00'},
            },
            "G": {
              "Monday": {active: true, startTime: '00:00', endTime: '00:00'},
              "Tuesday": {active: true, startTime: '00:00', endTime: '00:00'},
              "Wednesday": {active: true, startTime: '00:00', endTime: '00:00'},
              "Thursday": {active: true, startTime: '00:00', endTime: '00:00'},
              "Friday": {active: true, startTime: '00:00', endTime: '00:00'},
              "Saturday": {active: false, startTime: '00:00', endTime: '00:00'},
              "Sunday": {active: false, startTime: '00:00', endTime: '00:00'},
            },
          };
    
          userCopy.classes[classIndex].schedule = { ...userCopy.classes[classIndex].schedule, ...newWeeks };
    
          // Update the document in Firestore
          await setDoc(docRef, userCopy);

          // Update the data state
          setData((prevData) => ({
            ...prevData,
            schedule: { ...prevData.schedule, ...newWeeks },
          }));
    
        } catch (error) {
          console.error("Error updating schedule: ", error);
        }
      }
    }
  };

  useEffect(() => {
    addWeeksToSchedule();
  }, []);

  function cycleWeeks(direction) {
    const weekLetterOptions = ["A", "B", "C", "D", "E", "F", "G"];
    let currentIndex = weekLetterOptions.indexOf(currentWeek);

    if (direction === "forward") {
      if (currentIndex === numberOfWeeks-1) {
        currentIndex = 0;
      } else {
        currentIndex++;
      }
    } else if (direction === "backward") {
      if (currentIndex === 0) {
        currentIndex = numberOfWeeks-1;
      } else {
        currentIndex--;
      }
    }
  
    setCurrentWeek(weekLetterOptions[currentIndex]);
  }

  const blankSchedule = {
    "A": {
      "Monday": {active: true, startTime: '00:00', endTime: '00:00'},
      "Tuesday": {active: true, startTime: '00:00', endTime: '00:00'},
      "Wednesday": {active: true, startTime: '00:00', endTime: '00:00'},
      "Thursday": {active: true, startTime: '00:00', endTime: '00:00'},
      "Friday": {active: true, startTime: '00:00', endTime: '00:00'},
      "Saturday": {active: false, startTime: '00:00', endTime: '00:00'},
      "Sunday": {active: false, startTime: '00:00', endTime: '00:00'},
    },
    "B": {
      "Monday": {active: true, startTime: '00:00', endTime: '00:00'},
      "Tuesday": {active: true, startTime: '00:00', endTime: '00:00'},
      "Wednesday": {active: true, startTime: '00:00', endTime: '00:00'},
      "Thursday": {active: true, startTime: '00:00', endTime: '00:00'},
      "Friday": {active: true, startTime: '00:00', endTime: '00:00'},
      "Saturday": {active: false, startTime: '00:00', endTime: '00:00'},
      "Sunday": {active: false, startTime: '00:00', endTime: '00:00'},
    },
    "C": {
      "Monday": {active: true, startTime: '00:00', endTime: '00:00'},
      "Tuesday": {active: true, startTime: '00:00', endTime: '00:00'},
      "Wednesday": {active: true, startTime: '00:00', endTime: '00:00'},
      "Thursday": {active: true, startTime: '00:00', endTime: '00:00'},
      "Friday": {active: true, startTime: '00:00', endTime: '00:00'},
      "Saturday": {active: false, startTime: '00:00', endTime: '00:00'},
      "Sunday": {active: false, startTime: '00:00', endTime: '00:00'},
    },
    "D": {
      "Monday": {active: true, startTime: '00:00', endTime: '00:00'},
      "Tuesday": {active: true, startTime: '00:00', endTime: '00:00'},
      "Wednesday": {active: true, startTime: '00:00', endTime: '00:00'},
      "Thursday": {active: true, startTime: '00:00', endTime: '00:00'},
      "Friday": {active: true, startTime: '00:00', endTime: '00:00'},
      "Saturday": {active: false, startTime: '00:00', endTime: '00:00'},
      "Sunday": {active: false, startTime: '00:00', endTime: '00:00'},
    },
    "E": {
      "Monday": {active: true, startTime: '00:00', endTime: '00:00'},
      "Tuesday": {active: true, startTime: '00:00', endTime: '00:00'},
      "Wednesday": {active: true, startTime: '00:00', endTime: '00:00'},
      "Thursday": {active: true, startTime: '00:00', endTime: '00:00'},
      "Friday": {active: true, startTime: '00:00', endTime: '00:00'},
      "Saturday": {active: false, startTime: '00:00', endTime: '00:00'},
      "Sunday": {active: false, startTime: '00:00', endTime: '00:00'},
    },
    "F": {
      "Monday": {active: true, startTime: '00:00', endTime: '00:00'},
      "Tuesday": {active: true, startTime: '00:00', endTime: '00:00'},
      "Wednesday": {active: true, startTime: '00:00', endTime: '00:00'},
      "Thursday": {active: true, startTime: '00:00', endTime: '00:00'},
      "Friday": {active: true, startTime: '00:00', endTime: '00:00'},
      "Saturday": {active: false, startTime: '00:00', endTime: '00:00'},
      "Sunday": {active: false, startTime: '00:00', endTime: '00:00'},
    },
    "G": {
      "Monday": {active: true, startTime: '00:00', endTime: '00:00'},
      "Tuesday": {active: true, startTime: '00:00', endTime: '00:00'},
      "Wednesday": {active: true, startTime: '00:00', endTime: '00:00'},
      "Thursday": {active: true, startTime: '00:00', endTime: '00:00'},
      "Friday": {active: true, startTime: '00:00', endTime: '00:00'},
      "Saturday": {active: false, startTime: '00:00', endTime: '00:00'},
      "Sunday": {active: false, startTime: '00:00', endTime: '00:00'},
    },
  }

  const [data, setData] = useState({
    autoHappyBirthday: item?.autoHappyBirthday || null,
    classId: item?.classId,
    className: item?.className || null,
    period: item?.period || null,
    startTime: item?.startTime || '',
    endTime: item?.endTime || '',
    showMarquee: item?.showMarquee === false ? false : item?.showMarquee === true ? true : true,
    showClock: item?.showClock === false ? false : item?.showClock === true ? true : true,
    active: item?.active === false ? false : item?.active === true ? true : true,
    contentBoxes: item?.contentBoxes || [],
    orderOfAnnouncements: item?.orderOfAnnouncements || [],
    days: [],
    schedule: item?.schedule || blankSchedule,
    rosterData: item?.rosterData || null,
    background: item?.background || null,
    backgroundAnnouncements: item?.backgroundAnnouncements || null,
    backgroundClock: item?.backgroundClock || null,
    clockVersion: item?.clockVersion || null,
    clockFontColor: item?.clockFontColor || null,
  });

  const [alert, setAlert] = useState(null);
  // to stop the warning of calling setState of unmounted component
  useEffect(() => {
    return function cleanup() {
      var id = window.setTimeout(null, 0);
      while (id--) {
        window.clearTimeout(id);
      }
    };
  }, []);

  useEffect(() => {
    if (
      !data.className || data.className.length < 1 || data.className.length > 30 || 
      data.period?.length < 1 || data.period?.length > 5 || 
      /["#%&'()+,\/:;<=>?@[\\\]^`{|}]/.test(data.className)
    ) {
      setValidated(false);
    } else {
      setValidated(true);
    }
  }, [data]);
  
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validated) {
      try {
        const docRef = doc(db, "users", user.uid);
  
        const userCopy = JSON.parse(JSON.stringify(user));
  
        const newClass = {
          classId: classIdForNewClass.current,
          period: data.period,
          className: data.className,
          days: data.days,
          schedule: data.schedule,
          startTime: data.startTime,
          endTime: data.endTime,
          active: data.active === false ? false : true,
          showClock: data.showClock === false ? false : true,
          showMarquee: data.showMarquee === false ? false : true,
          orderOfAnnouncements: [announcementIdForNewClass.current],
          contentBoxes: [
            {
              contentBoxId: createRandomId(),
              heading: "Type here...",
              show: true,
              active: true
            }
          ]
        }
    
        const sampleAnnouncementToAdd = {
          html: "<p>This is a sample announcement.</p>",
          text: "This is a sample announcement.",
          active: true,
          show: true,
          startDate: ["Mondays", "Tuesdays", "Wednesdays", "Thursdays", "Fridays"],
          endDate: "",
          lastEdited: formattedDate,
          dateCreated: formattedDate,
          classIds: [classIdForNewClass.current],
          id: announcementIdForNewClass.current
        }
  
        const classIndex = userCopy.classes.findIndex(
          (c) => c.classId === data.classId
        );
  
        // Update the content box in the document data
        if (item === undefined) {
          userCopy.classes.push(newClass);
          userCopy.announcements.push(sampleAnnouncementToAdd);
        } else {
          userCopy.classes[classIndex] = data
        }
  
        // Update the document in Firestore
        await setDoc(docRef, userCopy);
  
        successAlert();
      } catch (error) {
        console.log('Error updating document:', error);
      }
    } else {
      invalidEntryAlert()
    }
  }

  const potentialInvalidScheduleAlert = (e) => {
    const event = e;
    setAlert(
      <ReactBSAlert
        warning
        style={{ display: "block", marginTop: "-100px" }}
        title="Are you sure?"
        onConfirm={(e) => (!data.active) ? classNotSetToActiveAlert(event) : handleSubmit(event)}
        onCancel={() => hidePotentialInvalidScheduleAlert()}
        confirmBtnBsStyle="info"
        confirmBtnStyle={{borderRadius: '30px', backgroundColor: 'rgb(11, 192, 223)'}}
        cancelBtnBsStyle="danger"
        cancelBtnStyle={{borderRadius: '30px', backgroundColor: '#fa5656'}}
        confirmBtnText="Continue with Save!"
        cancelBtnText="Let me check again."
        showCancel
        btnSize=""
      >
        It appears as though your scheduled start time for this class isn't between the hours of 7am and 5pm which isn't typical.
      </ReactBSAlert>
    );
  }

  const classNotSetToActiveAlert = (e) => {
    const event = e;
    setAlert(
      <ReactBSAlert
        warning
        style={{ display: "block", marginTop: "-100px" }}
        title="Are you sure?"
        onConfirm={(e) => handleSubmit(event)}
        onCancel={() => hideClassNotSetToActiveAlert()}
        confirmBtnBsStyle="info"
        confirmBtnStyle={{borderRadius: '30px', backgroundColor: 'rgb(11, 192, 223)'}}
        cancelBtnBsStyle="danger"
        cancelBtnStyle={{borderRadius: '30px', backgroundColor: '#fa5656'}}
        confirmBtnText="Continue with Save!"
        cancelBtnText="Let me check again."
        showCancel
        btnSize=""
      >
        You do not have the Active checkbox checked for this class which means it will not appear in your "Present Classes" dropdown.
      </ReactBSAlert>
    );
  }

  const invalidEntryAlert = () => {
    setAlert(
      <ReactBSAlert
        danger
        style={{ display: "block", marginTop: "-100px" }}
        title="Invalid entry!"
        onConfirm={() => hideInvalidEntryAlert()}
        onCancel={() => hideInvalidEntryAlert()}
        confirmBtnBsStyle="info"
        btnSize=""
      />
    );
  };

  const successAlert = () => {
    setAlert(
      <ReactBSAlert
        success
        style={{ display: "block", marginTop: "-100px" }}
        title="Class Saved!"
        onConfirm={() => hideAlert()}
        onCancel={() => hideAlert()}
        confirmBtnBsStyle="info"
        btnSize=""
      >
      </ReactBSAlert>
    );
  };

  const hidePotentialInvalidScheduleAlert = () => {
    setAlert(null);
  }

  const hideClassNotSetToActiveAlert = () => {
    setAlert(null);
  }

  const hideAlert = () => {
    setAlert(null);
    history.push("/add-edit-classes")
  };

  const hideInvalidEntryAlert = () => {
    setAlert(null);
  };

  function handleFormNonDays(e) {
    const { id, value, type, checked } = e.target;

    // Check if the input element is a checkbox
    if (type === 'checkbox') {
      // Update the state based on the checkbox's checked property
      setData((prevData) => ({
        ...prevData,
        [id]: checked, // Update the corresponding property in the state
      }));
    } else {
      // For non-checkbox inputs, update the state based on the input's value
      setData((prevData) => ({
        ...prevData,
        [id]: value,
      }));
    }
  }

  const handleTimesForSingleTimeClasses = (e) => {
    const newTime = e.target.value;

    const id = e.target.id

    const updatedSchedule = JSON.parse(JSON.stringify(data.schedule));

    for (const weekKey in updatedSchedule) {
      if (updatedSchedule.hasOwnProperty(weekKey)) {
        const week = updatedSchedule[weekKey];
        for (const dayKey in week) {
          if (week.hasOwnProperty(dayKey)) {
            // Update the startTime property for each day
            week[dayKey][id] = newTime;
          }
        }
      }
    }

    setData((prevData) => ({
      ...prevData,
      schedule: updatedSchedule,
    }));
  }

  const handleScheduleChange = (week, day, field, value) => {

    // Create a copy of the schedule to avoid mutating the state directly
    const updatedSchedule = JSON.parse(JSON.stringify(data.schedule));
    
    // Update the specified field in the schedule
    updatedSchedule[week][day][field] = value;
  
    // Set the updated schedule in your state or wherever you store it
    setData((prevData) => ({
      ...prevData,
      schedule: updatedSchedule,
    }));
  };

  const daysOfWeek = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday"];

  const ScheduleCheckboxes = ({ schedule, handleScheduleChange, week }) => (
    <Row style={{ margin: "10px 0px", display: "flex", justifyContent: "space-evenly" }}>
      {daysOfWeek.map((day) => (
        <Col key={day} style={{ display: "flex", alignItems: "center", flexDirection: "column" }}>
          <Label for={day.toLowerCase()} style={{ fontSize: "14px", fontWeight: "bold" }}>{day}</Label>
          <FormGroup style={{ display: "flex", alignItems: "center" }}>
            <Input
              onChange={(e) => handleScheduleChange(week, day, "active", e.target.checked)}
              type="checkbox"
              id={day.toLowerCase()}
              checked={schedule?.[week]?.[day]?.active || false}
              style={{
                transform: "scale(2)",
                position: "initial",
                marginLeft: "0px",
              }}
            />
          </FormGroup>
        </Col>
      ))}
    </Row>
  );

  const WeekSelectorForMultiWeek = () => (
    <Row
      style={{
        marginTop: "30px",
        marginBottom: "10px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <i
        className="nc-icon nc-minimal-left"
        style={{ cursor: "pointer", fontSize: "20px" }}
        onClick={() => cycleWeeks("backward")}
        onMouseEnter={(e) => (e.currentTarget.style.transform = "scale(1.12)")}
        onMouseLeave={(e) => (e.currentTarget.style.transform = "scale(1)")}
      />
      <h3
        style={{
          marginLeft: "15px",
          marginRight: "15px",
          marginBottom: "0px",
          fontWeight: "bold", 
          color: "#004aad"
        }}
      >
        Week {currentWeek}
      </h3>
      <i
        className="nc-icon nc-minimal-right"
        style={{ cursor: "pointer", fontSize: "20px" }}
        onClick={() => cycleWeeks("forward")}
        onMouseEnter={(e) => (e.currentTarget.style.transform = "scale(1.12)")}
        onMouseLeave={(e) => (e.currentTarget.style.transform = "scale(1)")}
      />
    </Row>
  )

  const ScheduleRow = ({ week, day, handleScheduleChange, data }) => (
    <Row style={{ marginLeft: "10px", display: "flex", justifyContent: "space-evenly" }}>
      {/* Checkbox for Active Days */}
      <Col md="3"></Col>
      <Col
        md="2"
        style={{ display: "flex", alignItems: "center", flexDirection: "column" }}
      >
        <label id={day.toLowerCase()} style={{ fontSize: "14px" }}>
          {day}
        </label>
        <FormGroup style={{ display: "flex", alignItems: "center" }}>
          <Input
            onChange={(e) => handleScheduleChange(week, day, "active", e.target.checked)}
            type="checkbox"
            id={day.toLowerCase()}
            checked={data.schedule[week]?.[day]?.active || false}
            style={{ transform: "scale(2)", position: "initial", marginLeft: "0px", marginTop: '14px' }}
          />
        </FormGroup>
      </Col>
  
      {/* Start Time Input */}
      <Col md="4">
        <label id="timeTip" style={{ fontSize: "14px" }}>
          Start Time
        </label>
        <FormGroup>
          <Input
            onChange={(e) => handleScheduleChange(week, day, "startTime", e.target.value)}
            type="time"
            id="startTime"
            style={{borderRadius: '8px'}}
            value={
              data.schedule[week]?.[day]?.active
                ? data.schedule[week]?.[day]?.startTime || ""
                : ""
            }
            step="1"  // Allows seconds input
          />
        </FormGroup>
      </Col>
      <Col md="3"></Col>
    </Row>
  );

  return (
    <>
      <div className="content">
        <Row style={{display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100vh'}}>
          <Col md="6">
            <Card style={{ padding: '20px' }}>
              <ModalHeader className="add-edit-form-close-toggle" toggle={() =>  history.goBack()} style={{border: 'none', padding: '0px'}}>
                <h4 style={{ fontWeight: "bold", color: "#004aad", margin: "0px" }}>
                  {item === undefined ? "Add Class" : "Edit Class"}
                </h4>
              </ModalHeader>
              <CardBody>
                <Form
                  className="form-horizontal"
                  noValidate // Remove 'noValidate' if you want to use HTML5 validation as well
                  validated={validated.toString()}
                >
                  <Row>
                    <Col>
                      <label style={{ fontSize: '14px', fontWeight: "bold"}}>Class Name</label>
                      <FormGroup>
                        <Input
                          onChange={(e) => handleFormNonDays(e)}
                          placeholder="Ex: 7th Grade Band"
                          type="text"
                          id="className"
                          value={data.className}
                          required // Add 'required' for HTML5 validation
                          valid={data.className?.length > 0 && data.className?.length <= 30} // Display valid feedback
                          invalid={data.className != null && data.className?.length === 0 || data.className?.length > 30 || /["#%&'()+,\/:;<=>?@[\\\]^`{|}]/.test(data.className)}
                          style={{borderRadius: '8px'}}
                        />
                        {data.className?.length === 0 ? (
                          <FormFeedback invalid>Please provide a class name.</FormFeedback>
                        ) : data.className?.length > 30 ? (
                          <FormFeedback invalid>Class name is too long (max 30 characters).</FormFeedback>
                        ) : /["#%&'()+,\/:;<=>?@[\\\]^`{|}]/.test(data.className) ? (
                          <FormFeedback invalid>{"Your class contains invalid characters. Please avoid using the following characters: \" # % & ' ( ) + , / : ; < = > ? @ [ \\ ] ^ ` { | }"}</FormFeedback>
                        ) : (
                          <FormFeedback valid>Looks good!</FormFeedback>
                        )}
                      </FormGroup>
                    </Col>
                    <Col>
                      <label style={{ fontSize: '14px', fontWeight: "bold" }}>Period</label>
                      <FormGroup>
                        <Input
                          onChange={(e) => handleFormNonDays(e)}
                          placeholder="Ex: 1st"
                          type="text"
                          id="period"
                          value={data.period}
                          required
                          valid={data.period?.length > 0 && data.period?.length <= 4} // Display valid feedback
                          invalid={data.period != null && data.period?.length === 0 || data.period?.length > 4} // Display invalid feedback
                          style={{borderRadius: '8px'}}
                        >
                        </Input>
                        <small id="periodHelpBlock" class="form-text text-muted">
                          Must be between 1-4 characters.
                        </small>
                        {data.period?.length === 0 ? (
                          <FormFeedback invalid>Please provide a 4 character or less period identifier.</FormFeedback>
                        ) : data.period?.length > 4 ? (
                          <FormFeedback invalid>Period identifier is too long (max 4 characters).</FormFeedback>
                        ) : (
                          <FormFeedback valid>Looks good!</FormFeedback>
                        )}
                      </FormGroup>
                    </Col>
                  </Row>

                  <hr></hr>

                  <Row style={{marginTop: "10px", marginLeft: "10px", marginBottom: '30px', display:'flex', justifyContent: 'space-evenly'}}>
                  <Col md="2" style={{display: 'flex', alignItems: 'center', flexDirection: 'column'}}>
                    <label id="showMarqueeTip" style={{ fontSize: '14px', fontWeight: "bold" }}>Marquee</label>
                    <FormGroup style={{display: 'flex', alignItems: 'center'}}>
                    <UncontrolledTooltip
                        delay={0}
                        target={"showMarqueeTip"}
                        placement="top"
                      >
                        If checked, class will include a scrolling marquee you can use for announcements, etc.
                      </UncontrolledTooltip>
                        <Input 
                          onChange={(e) => handleFormNonDays(e)} 
                          type="checkbox" 
                          id="showMarquee" 
                          checked={data.showMarquee}
                          defaultChecked={true}
                          style={{
                            position: 'initial', 
                            marginLeft: '0px',
                            transform: 'scale(2)'
                          }}
                        />
                      </FormGroup>
                    </Col>
                    <Col md="2" style={{display: 'flex', alignItems: 'center', flexDirection: 'column'}}>
                      <label id="showClockTip" style={{ fontSize: '14px', fontWeight: "bold" }}>Clock</label>
                      <FormGroup style={{display: 'flex', alignItems: 'center'}}>
                      <UncontrolledTooltip
                        delay={0}
                        target={"showClockTip"}
                        placement="top"
                      >
                        If checked, class will include a clock.
                      </UncontrolledTooltip>
                        <Input 
                          onChange={(e) => handleFormNonDays(e)} 
                          type="checkbox" 
                          id="showClock" 
                          checked={data.showClock}
                          defaultChecked={true}
                          style={{
                            position: 'initial', 
                            marginLeft: '0px',
                            transform: 'scale(2)'
                          }}
                        />
                      </FormGroup>
                    </Col>
                    <Col md="2" style={{display: 'flex', alignItems: 'center', flexDirection: 'column'}}>
                      <label id="activeTip" style={{ fontSize: '14px', fontWeight: "bold" }}>Active</label>
                      <FormGroup style={{display: 'flex', alignItems: 'center'}}>
                      <UncontrolledTooltip
                        delay={0}
                        target={"activeTip"}
                        placement="top"
                      >
                        If checked, class will appear in classes dropdown.
                      </UncontrolledTooltip>
                        <Input 
                          onChange={(e) => handleFormNonDays(e)} 
                          type="checkbox" 
                          id="active" 
                          checked={data.active}
                          defaultChecked={true}
                          style={{
                            position: 'initial', 
                            marginLeft: '0px',
                            transform: 'scale(2)'
                          }}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <hr></hr>



                {!differentTimes && 
                  <Row style={{marginTop: "30px", marginLeft: "10px", marginBottom: '30px', display:'flex', justifyContent: 'space-evenly'}}>
                  <Col>
                      <UncontrolledTooltip
                        delay={0}
                        target={"timeTip"}
                        placement="top"
                      >
                        Providing class times will allow Lesson Launchpad to auto navigate to your next class when it's time for it to start.
                      </UncontrolledTooltip>
                      <label id="timeTip" style={{ fontSize: '14px', fontWeight: "bold" }}>Start Time</label>
                      <FormGroup>
                        <Input onChange={(e) => handleTimesForSingleTimeClasses(e)} type="time" id="startTime" value={data.schedule.A.Monday.startTime} style={{borderRadius: '8px'}}/>
                      </FormGroup>
                    </Col>
                    <UncontrolledTooltip
                        delay={0}
                        target={"timeTip"}
                        placement="top"
                      >
                        Providing class times will allow Lesson Launchpad to auto navigate to your next class when it's time for it to start.
                      </UncontrolledTooltip>
                    <Col>
                      <label id="timeTip" style={{ fontSize: '14px', fontWeight: "bold" }}>End Time</label>
                      <FormGroup>
                        <Input onChange={(e) => handleTimesForSingleTimeClasses(e)} type="time" id="endTime" value={data.schedule.A.Monday.endTime} style={{borderRadius: '8px'}}/>
                      </FormGroup>
                    </Col>
                  </Row>
                  }
                  <hr></hr>

                  {/* 
                  {!differentTimes && !multiWeek &&
                  <Row style={{marginTop: "30px", marginLeft: "10px", display:'flex', justifyContent: 'space-evenly'}}>
                    <Col style={{display: 'flex', alignItems: 'center', flexDirection: 'column'}}>
                      <label id="monday" style={{ fontSize: '14px', fontWeight: "bold" }}>Monday</label>
                      <FormGroup style={{display: 'flex', alignItems: 'center'}}>
                      <UncontrolledTooltip
                        delay={0}
                        target={"monday"}
                        placement="top"
                      >
                        Check if this class meets on Monday.
                      </UncontrolledTooltip>
                        <Input 
                          onChange={(e) => handleScheduleChange("A", "Monday", "active", e.target.checked)} 
                          type="checkbox" 
                          id="monday" 
                          checked={data.schedule.A.Monday.active}
                          // value={"M"}
                          style={{
                            transform: 'scale(2)',
                            position: 'initial', 
                            marginLeft: '0px'
                          }}
                        />
                      </FormGroup>
                    </Col>
                    <Col style={{display: 'flex', alignItems: 'center', flexDirection: 'column'}}>
                      <label id="tuesday" style={{ fontSize: '14px', fontWeight: "bold" }}>Tuesday</label>
                      <FormGroup style={{display: 'flex', alignItems: 'center'}}>
                      <UncontrolledTooltip
                        delay={0}
                        target={"tuesday"}
                        placement="top"
                      >
                        Check if this class meets on Tuesday.
                      </UncontrolledTooltip>
                        <Input 
                          onChange={(e) => handleScheduleChange("A", "Tuesday", "active", e.target.checked)} 
                          type="checkbox" 
                          id="tuesday" 
                          checked={data.schedule.A.Tuesday.active}
                          // value={"T"}
                          style={{
                            transform: 'scale(2)',
                            position: 'initial', 
                            marginLeft: '0px'
                          }}
                        />
                      </FormGroup>
                    </Col>
                    <Col style={{display: 'flex', alignItems: 'center', flexDirection: 'column'}}>
                      <label id="wednesday" style={{ fontSize: '14px', fontWeight: "bold" }}>Wednesday</label>
                      <FormGroup style={{display: 'flex', alignItems: 'center'}}>
                      <UncontrolledTooltip
                        delay={0}
                        target={"wednesday"}
                        placement="top"
                      >
                        Check if this class meets on Wednesday.
                      </UncontrolledTooltip>
                        <Input 
                          onChange={(e) => handleScheduleChange("A", "Wednesday", "active", e.target.checked)} 
                          type="checkbox" 
                          id="wednesday" 
                          checked={data.schedule.A.Wednesday.active}
                          // value={"W"}
                          style={{
                            transform: 'scale(2)',
                            position: 'initial', 
                            marginLeft: '0px'
                          }}
                        />
                      </FormGroup>
                    </Col>
                    <Col style={{display: 'flex', alignItems: 'center', flexDirection: 'column'}}>
                      <label id="thursday" style={{ fontSize: '14px', fontWeight: "bold" }}>Thursday</label>
                      <FormGroup style={{display: 'flex', alignItems: 'center'}}>
                      <UncontrolledTooltip
                        delay={0}
                        target={"thursday"}
                        placement="top"
                      >
                        Check if this class meets on Thursday.
                      </UncontrolledTooltip>
                        <Input 
                          onChange={(e) => handleScheduleChange("A", "Thursday", "active", e.target.checked)} 
                          type="checkbox" 
                          id="thursday" 
                          checked={data.schedule.A.Thursday.active}
                          // value={"Th"}
                          style={{
                            transform: 'scale(2)',
                            position: 'initial', 
                            marginLeft: '0px'
                          }}
                        />
                      </FormGroup>
                    </Col>
                    <Col style={{display: 'flex', alignItems: 'center', flexDirection: 'column'}}>
                      <label id="friday" style={{ fontSize: '14px', fontWeight: "bold" }}>Friday</label>
                      <FormGroup style={{display: 'flex', alignItems: 'center'}}>
                      <UncontrolledTooltip
                        delay={0}
                        target={"friday"}
                        placement="top"
                      >
                        Check if this class meets on Friday.
                      </UncontrolledTooltip>
                        <Input 
                          onChange={(e) => handleScheduleChange("A", "Friday", "active", e.target.checked)} 
                          type="checkbox" 
                          id="friday" 
                          checked={data.schedule.A.Friday.active}
                          // value={"F"}
                          style={{
                            transform: 'scale(2)',
                            position: 'initial', 
                            marginLeft: '0px'
                          }}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  }




                  {multiWeek && !differentTimes &&
                  <>
                  <Row style={{marginTop: "30px", marginLeft: "10px", display:'flex', justifyContent: 'space-evenly'}}>
                  <Col style={{display: 'flex', flexDirection: 'column', justifyContent: 'center'}}>
                    Week A
                  </Col>
                  <Col style={{display: 'flex', alignItems: 'center', flexDirection: 'column'}}>
                    <label id="monday" style={{ fontSize: '14px' }}>Monday</label>
                    <FormGroup style={{display: 'flex', alignItems: 'center'}}>
                      <Input 
                          onChange={(e) => handleScheduleChange("A", "Monday", "active", e.target.checked)} 
                          type="checkbox" 
                        id="monday" 
                        checked={data.schedule.A.Monday.active}
                        // value={"M"}
                        style={{
                          transform: 'scale(2)',
                          position: 'initial', 
                          marginLeft: '0px'
                        }}
                      />
                    </FormGroup>
                  </Col>
                  <Col style={{display: 'flex', alignItems: 'center', flexDirection: 'column'}}>
                    <label id="tuesday" style={{ fontSize: '14px' }}>Tuesday</label>
                    <FormGroup style={{display: 'flex', alignItems: 'center'}}>
                    <Input 
                          onChange={(e) => handleScheduleChange("A", "Tuesday", "active", e.target.checked)} 
                          type="checkbox" 
                        id="tuesday" 
                        checked={data.schedule.A.Tuesday.active}
                        // value={"T"}
                        style={{
                          transform: 'scale(2)',
                          position: 'initial', 
                          marginLeft: '0px'
                        }}
                      />
                    </FormGroup>
                  </Col>
                  <Col style={{display: 'flex', alignItems: 'center', flexDirection: 'column'}}>
                    <label id="wednesday" style={{ fontSize: '14px' }}>Wednesday</label>
                    <FormGroup style={{display: 'flex', alignItems: 'center'}}>
                   <Input 
                          onChange={(e) => handleScheduleChange("A", "Wednesday", "active", e.target.checked)} 
                          type="checkbox" 
                        id="wednesday" 
                        checked={data.schedule.A.Wednesday.active}
                        // value={"W"}
                        style={{
                          transform: 'scale(2)',
                          position: 'initial', 
                          marginLeft: '0px'
                        }}
                      />
                    </FormGroup>
                  </Col>
                  <Col style={{display: 'flex', alignItems: 'center', flexDirection: 'column'}}>
                    <label id="thursday" style={{ fontSize: '14px' }}>Thursday</label>
                    <FormGroup style={{display: 'flex', alignItems: 'center'}}>
                    <Input 
                          onChange={(e) => handleScheduleChange("A", "Thursday", "active", e.target.checked)} 
                          type="checkbox" 
                        id="thursday" 
                        checked={data.schedule.A.Thursday.active}
                        // value={"Th"}
                        style={{
                          transform: 'scale(2)',
                          position: 'initial', 
                          marginLeft: '0px'
                        }}
                      />
                    </FormGroup>
                  </Col>
                  <Col style={{display: 'flex', alignItems: 'center', flexDirection: 'column'}}>
                    <label id="friday" style={{ fontSize: '14px' }}>Friday</label>
                    <FormGroup style={{display: 'flex', alignItems: 'center'}}>
                    <Input 
                          onChange={(e) => handleScheduleChange("A", "Friday", "active", e.target.checked)} 
                          type="checkbox" 
                        id="friday" 
                        checked={data.schedule.A.Friday.active}
                        // value={"F"}
                        style={{
                          transform: 'scale(2)',
                          position: 'initial', 
                          marginLeft: '0px'
                        }}
                      />
                    </FormGroup>
                  </Col>
                </Row>



                <Row style={{marginTop: "30px", marginLeft: "10px", display:'flex', justifyContent: 'space-evenly'}}>
                  <Col style={{display: 'flex', flexDirection: 'column', justifyContent: 'center'}}>
                    Week B
                  </Col>
                  <Col style={{display: 'flex', alignItems: 'center', flexDirection: 'column'}}>
                    <label id="monday" style={{ fontSize: '14px' }}>Monday</label>
                    <FormGroup style={{display: 'flex', alignItems: 'center'}}>
                      <Input 
                          onChange={(e) => handleScheduleChange("B", "Monday", "active", e.target.checked)} 
                          type="checkbox" 
                        id="monday" 
                        checked={data.schedule.B.Monday.active}
                        // value={"M"}
                        style={{
                          transform: 'scale(2)',
                          position: 'initial', 
                          marginLeft: '0px'
                        }}
                      />
                    </FormGroup>
                  </Col>
                  <Col style={{display: 'flex', alignItems: 'center', flexDirection: 'column'}}>
                    <label id="tuesday" style={{ fontSize: '14px' }}>Tuesday</label>
                    <FormGroup style={{display: 'flex', alignItems: 'center'}}>
                    <Input 
                          onChange={(e) => handleScheduleChange("B", "Tuesday", "active", e.target.checked)} 
                          type="checkbox" 
                        id="tuesday" 
                        checked={data.schedule.B.Tuesday.active}
                        // value={"T"}
                        style={{
                          transform: 'scale(2)',
                          position: 'initial', 
                          marginLeft: '0px'
                        }}
                      />
                    </FormGroup>
                  </Col>
                  <Col style={{display: 'flex', alignItems: 'center', flexDirection: 'column'}}>
                    <label id="wednesday" style={{ fontSize: '14px' }}>Wednesday</label>
                    <FormGroup style={{display: 'flex', alignItems: 'center'}}>
                   <Input 
                          onChange={(e) => handleScheduleChange("B", "Wednesday", "active", e.target.checked)} 
                          type="checkbox" 
                        id="wednesday" 
                        checked={data.schedule.B.Wednesday.active}
                        // value={"W"}
                        style={{
                          transform: 'scale(2)',
                          position: 'initial', 
                          marginLeft: '0px'
                        }}
                      />
                    </FormGroup>
                  </Col>
                  <Col style={{display: 'flex', alignItems: 'center', flexDirection: 'column'}}>
                    <label id="thursday" style={{ fontSize: '14px' }}>Thursday</label>
                    <FormGroup style={{display: 'flex', alignItems: 'center'}}>
                    <Input 
                          onChange={(e) => handleScheduleChange("B", "Thursday", "active", e.target.checked)} 
                          type="checkbox" 
                        id="thursday" 
                        checked={data.schedule.B.Thursday.active}
                        // value={"Th"}
                        style={{
                          transform: 'scale(2)',
                          position: 'initial', 
                          marginLeft: '0px'
                        }}
                      />
                    </FormGroup>
                  </Col>
                  <Col style={{display: 'flex', alignItems: 'center', flexDirection: 'column'}}>
                    <label id="friday" style={{ fontSize: '14px' }}>Friday</label>
                    <FormGroup style={{display: 'flex', alignItems: 'center'}}>
                    <Input 
                          onChange={(e) => handleScheduleChange("B", "Friday", "active", e.target.checked)} 
                          type="checkbox" 
                        id="friday" 
                        checked={data.schedule.B.Friday.active}
                        // value={"F"}
                        style={{
                          transform: 'scale(2)',
                          position: 'initial', 
                          marginLeft: '0px'
                        }}
                      />
                    </FormGroup>
                  </Col>
                </Row>

                

                {numberOfWeeks === "3" && multiWeek && 
                <Row style={{marginTop: "30px", marginLeft: "10px", display:'flex', justifyContent: 'space-evenly'}}>
                    <Col style={{display: 'flex', flexDirection: 'column', justifyContent: 'center'}}>
                      Week C
                    </Col>
                    <Col style={{display: 'flex', alignItems: 'center', flexDirection: 'column'}}>
                      <label id="monday" style={{ fontSize: '14px' }}>Monday</label>
                      <FormGroup style={{display: 'flex', alignItems: 'center'}}>
                        <Input 
                          onChange={(e) => handleScheduleChange("C", "Monday", "active", e.target.checked)} 
                          type="checkbox" 
                          id="monday" 
                          checked={data.schedule.C.Monday.active}
                          // value={"M"}
                          style={{
                            transform: 'scale(2)',
                            position: 'initial', 
                            marginLeft: '0px'
                          }}
                        />
                      </FormGroup>
                    </Col>
                    <Col style={{display: 'flex', alignItems: 'center', flexDirection: 'column'}}>
                      <label id="tuesday" style={{ fontSize: '14px' }}>Tuesday</label>
                      <FormGroup style={{display: 'flex', alignItems: 'center'}}>
                      <Input 
                          onChange={(e) => handleScheduleChange("C", "Tuesday", "active", e.target.checked)} 
                          type="checkbox" 
                          id="tuesday" 
                          checked={data.schedule.C.Tuesday.active}
                          // value={"T"}
                          style={{
                            transform: 'scale(2)',
                            position: 'initial', 
                            marginLeft: '0px'
                          }}
                        />
                      </FormGroup>
                    </Col>
                    <Col style={{display: 'flex', alignItems: 'center', flexDirection: 'column'}}>
                      <label id="wednesday" style={{ fontSize: '14px' }}>Wednesday</label>
                      <FormGroup style={{display: 'flex', alignItems: 'center'}}>
                     <Input 
                          onChange={(e) => handleScheduleChange("C", "Wednesday", "active", e.target.checked)} 
                          type="checkbox" 
                          id="wednesday" 
                          checked={data.schedule.C.Wednesday.active}
                          // value={"W"}
                          style={{
                            transform: 'scale(2)',
                            position: 'initial', 
                            marginLeft: '0px'
                          }}
                        />
                      </FormGroup>
                    </Col>
                    <Col style={{display: 'flex', alignItems: 'center', flexDirection: 'column'}}>
                      <label id="thursday" style={{ fontSize: '14px' }}>Thursday</label>
                      <FormGroup style={{display: 'flex', alignItems: 'center'}}>
                      <Input 
                          onChange={(e) => handleScheduleChange("C", "Thursday", "active", e.target.checked)} 
                          type="checkbox" 
                          id="thursday" 
                          checked={data.schedule.C.Thursday.active}
                          // value={"Th"}
                          style={{
                            transform: 'scale(2)',
                            position: 'initial', 
                            marginLeft: '0px'
                          }}
                        />
                      </FormGroup>
                    </Col>
                    <Col style={{display: 'flex', alignItems: 'center', flexDirection: 'column'}}>
                      <label id="friday" style={{ fontSize: '14px' }}>Friday</label>
                      <FormGroup style={{display: 'flex', alignItems: 'center'}}>
                      <Input 
                          onChange={(e) => handleScheduleChange("C", "Friday", "active", e.target.checked)} 
                          type="checkbox" 
                          id="friday" 
                          checked={data.schedule.C.Friday.active}
                          // value={"F"}
                          style={{
                            transform: 'scale(2)',
                            position: 'initial', 
                            marginLeft: '0px'
                          }}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  }

                {numberOfWeeks === "4" && multiWeek &&
                <>
                  <Row style={{marginTop: "30px", marginLeft: "10px", display:'flex', justifyContent: 'space-evenly'}}>
                    <Col style={{display: 'flex', flexDirection: 'column', justifyContent: 'center'}}>
                      Week C
                    </Col>
                    <Col style={{display: 'flex', alignItems: 'center', flexDirection: 'column'}}>
                      <label id="monday" style={{ fontSize: '14px' }}>Monday</label>
                      <FormGroup style={{display: 'flex', alignItems: 'center'}}>
                        <Input 
                          onChange={(e) => handleScheduleChange("C", "Monday", "active", e.target.checked)} 
                          type="checkbox" 
                          id="monday" 
                          checked={data.schedule.C.Monday.active}
                          // value={"M"}
                          style={{
                            transform: 'scale(2)',
                            position: 'initial', 
                            marginLeft: '0px'
                          }}
                        />
                      </FormGroup>
                    </Col>
                    <Col style={{display: 'flex', alignItems: 'center', flexDirection: 'column'}}>
                      <label id="tuesday" style={{ fontSize: '14px' }}>Tuesday</label>
                      <FormGroup style={{display: 'flex', alignItems: 'center'}}>
                      <Input 
                          onChange={(e) => handleScheduleChange("C", "Tuesday", "active", e.target.checked)} 
                          type="checkbox" 
                          id="tuesday" 
                          checked={data.schedule.C.Tuesday.active}
                          // value={"T"}
                          style={{
                            transform: 'scale(2)',
                            position: 'initial', 
                            marginLeft: '0px'
                          }}
                        />
                      </FormGroup>
                    </Col>
                    <Col style={{display: 'flex', alignItems: 'center', flexDirection: 'column'}}>
                      <label id="wednesday" style={{ fontSize: '14px' }}>Wednesday</label>
                      <FormGroup style={{display: 'flex', alignItems: 'center'}}>
                     <Input 
                          onChange={(e) => handleScheduleChange("C", "Wednesday", "active", e.target.checked)} 
                          type="checkbox" 
                          id="wednesday" 
                          checked={data.schedule.C.Wednesday.active}
                          // value={"W"}
                          style={{
                            transform: 'scale(2)',
                            position: 'initial', 
                            marginLeft: '0px'
                          }}
                        />
                      </FormGroup>
                    </Col>
                    <Col style={{display: 'flex', alignItems: 'center', flexDirection: 'column'}}>
                      <label id="thursday" style={{ fontSize: '14px' }}>Thursday</label>
                      <FormGroup style={{display: 'flex', alignItems: 'center'}}>
                      <Input 
                          onChange={(e) => handleScheduleChange("C", "Thursday", "active", e.target.checked)} 
                          type="checkbox" 
                          id="thursday" 
                          checked={data.schedule.C.Thursday.active}
                          // value={"Th"}
                          style={{
                            transform: 'scale(2)',
                            position: 'initial', 
                            marginLeft: '0px'
                          }}
                        />
                      </FormGroup>
                    </Col>
                    <Col style={{display: 'flex', alignItems: 'center', flexDirection: 'column'}}>
                      <label id="friday" style={{ fontSize: '14px' }}>Friday</label>
                      <FormGroup style={{display: 'flex', alignItems: 'center'}}>
                      <Input 
                          onChange={(e) => handleScheduleChange("C", "Friday", "active", e.target.checked)} 
                          type="checkbox" 
                          id="friday" 
                          checked={data.schedule.C.Friday.active}
                          // value={"F"}
                          style={{
                            transform: 'scale(2)',
                            position: 'initial', 
                            marginLeft: '0px'
                          }}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row style={{marginTop: "30px", marginLeft: "10px", display:'flex', justifyContent: 'space-evenly'}}>
                    <Col style={{display: 'flex', flexDirection: 'column', justifyContent: 'center'}}>
                      Week D
                    </Col>
                    <Col style={{display: 'flex', alignItems: 'center', flexDirection: 'column'}}>
                      <label id="monday" style={{ fontSize: '14px' }}>Monday</label>
                      <FormGroup style={{display: 'flex', alignItems: 'center'}}>
                        <Input 
                          onChange={(e) => handleScheduleChange("D", "Monday", "active", e.target.checked)} 
                          type="checkbox" 
                          id="monday" 
                          checked={data.schedule.D.Monday.active}
                          // value={"M"}
                          style={{
                            transform: 'scale(2)',
                            position: 'initial', 
                            marginLeft: '0px'
                          }}
                        />
                      </FormGroup>
                    </Col>
                    <Col style={{display: 'flex', alignItems: 'center', flexDirection: 'column'}}>
                      <label id="tuesday" style={{ fontSize: '14px' }}>Tuesday</label>
                      <FormGroup style={{display: 'flex', alignItems: 'center'}}>
                      <Input 
                          onChange={(e) => handleScheduleChange("D", "Tuesday", "active", e.target.checked)} 
                          type="checkbox" 
                          id="tuesday" 
                          checked={data.schedule.D.Tuesday.active}
                          // value={"T"}
                          style={{
                            transform: 'scale(2)',
                            position: 'initial', 
                            marginLeft: '0px'
                          }}
                        />
                      </FormGroup>
                    </Col>
                    <Col style={{display: 'flex', alignItems: 'center', flexDirection: 'column'}}>
                      <label id="wednesday" style={{ fontSize: '14px' }}>Wednesday</label>
                      <FormGroup style={{display: 'flex', alignItems: 'center'}}>
                     <Input 
                          onChange={(e) => handleScheduleChange("D", "Wednesday", "active", e.target.checked)} 
                          type="checkbox" 
                          id="wednesday" 
                          checked={data.schedule.D.Wednesday.active}
                          // value={"W"}
                          style={{
                            transform: 'scale(2)',
                            position: 'initial', 
                            marginLeft: '0px'
                          }}
                        />
                      </FormGroup>
                    </Col>
                    <Col style={{display: 'flex', alignItems: 'center', flexDirection: 'column'}}>
                      <label id="thursday" style={{ fontSize: '14px' }}>Thursday</label>
                      <FormGroup style={{display: 'flex', alignItems: 'center'}}>
                      <Input 
                          onChange={(e) => handleScheduleChange("D", "Thursday", "active", e.target.checked)} 
                          type="checkbox" 
                          id="thursday" 
                          checked={data.schedule.D.Thursday.active}
                          // value={"Th"}
                          style={{
                            transform: 'scale(2)',
                            position: 'initial', 
                            marginLeft: '0px'
                          }}
                        />
                      </FormGroup>
                    </Col>
                    <Col style={{display: 'flex', alignItems: 'center', flexDirection: 'column'}}>
                      <label id="friday" style={{ fontSize: '14px' }}>Friday</label>
                      <FormGroup style={{display: 'flex', alignItems: 'center'}}>
                      <Input 
                          onChange={(e) => handleScheduleChange("D", "Friday", "active", e.target.checked)} 
                          type="checkbox" 
                          id="friday" 
                          checked={data.schedule.D.Friday.active}
                          // value={"F"}
                          style={{
                            transform: 'scale(2)',
                            position: 'initial', 
                            marginLeft: '0px'
                          }}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  </>
                  }
                </>
                }



                {numberOfWeeks === "5" && multiWeek && !differentTimes &&
                <>
                  <Row style={{marginTop: "30px", marginLeft: "10px", display:'flex', justifyContent: 'space-evenly'}}>
                    <Col style={{display: 'flex', flexDirection: 'column', justifyContent: 'center'}}>
                      Week C
                    </Col>
                    <Col style={{display: 'flex', alignItems: 'center', flexDirection: 'column'}}>
                      <label id="monday" style={{ fontSize: '14px' }}>Monday</label>
                      <FormGroup style={{display: 'flex', alignItems: 'center'}}>
                        <Input 
                          onChange={(e) => handleScheduleChange("C", "Monday", "active", e.target.checked)} 
                          type="checkbox" 
                          id="monday" 
                          checked={data.schedule.C.Monday.active}
                          // value={"M"}
                          style={{
                            transform: 'scale(2)',
                            position: 'initial', 
                            marginLeft: '0px'
                          }}
                        />
                      </FormGroup>
                    </Col>
                    <Col style={{display: 'flex', alignItems: 'center', flexDirection: 'column'}}>
                      <label id="tuesday" style={{ fontSize: '14px' }}>Tuesday</label>
                      <FormGroup style={{display: 'flex', alignItems: 'center'}}>
                      <Input 
                          onChange={(e) => handleScheduleChange("C", "Tuesday", "active", e.target.checked)} 
                          type="checkbox" 
                          id="tuesday" 
                          checked={data.schedule.C.Tuesday.active}
                          // value={"T"}
                          style={{
                            transform: 'scale(2)',
                            position: 'initial', 
                            marginLeft: '0px'
                          }}
                        />
                      </FormGroup>
                    </Col>
                    <Col style={{display: 'flex', alignItems: 'center', flexDirection: 'column'}}>
                      <label id="wednesday" style={{ fontSize: '14px' }}>Wednesday</label>
                      <FormGroup style={{display: 'flex', alignItems: 'center'}}>
                     <Input 
                          onChange={(e) => handleScheduleChange("C", "Wednesday", "active", e.target.checked)} 
                          type="checkbox" 
                          id="wednesday" 
                          checked={data.schedule.C.Wednesday.active}
                          // value={"W"}
                          style={{
                            transform: 'scale(2)',
                            position: 'initial', 
                            marginLeft: '0px'
                          }}
                        />
                      </FormGroup>
                    </Col>
                    <Col style={{display: 'flex', alignItems: 'center', flexDirection: 'column'}}>
                      <label id="thursday" style={{ fontSize: '14px' }}>Thursday</label>
                      <FormGroup style={{display: 'flex', alignItems: 'center'}}>
                      <Input 
                          onChange={(e) => handleScheduleChange("C", "Thursday", "active", e.target.checked)} 
                          type="checkbox" 
                          id="thursday" 
                          checked={data.schedule.C.Thursday.active}
                          // value={"Th"}
                          style={{
                            transform: 'scale(2)',
                            position: 'initial', 
                            marginLeft: '0px'
                          }}
                        />
                      </FormGroup>
                    </Col>
                    <Col style={{display: 'flex', alignItems: 'center', flexDirection: 'column'}}>
                      <label id="friday" style={{ fontSize: '14px' }}>Friday</label>
                      <FormGroup style={{display: 'flex', alignItems: 'center'}}>
                      <Input 
                          onChange={(e) => handleScheduleChange("C", "Friday", "active", e.target.checked)} 
                          type="checkbox" 
                          id="friday" 
                          checked={data.schedule.C.Friday.active}
                          // value={"F"}
                          style={{
                            transform: 'scale(2)',
                            position: 'initial', 
                            marginLeft: '0px'
                          }}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row style={{marginTop: "30px", marginLeft: "10px", display:'flex', justifyContent: 'space-evenly'}}>
                    <Col style={{display: 'flex', flexDirection: 'column', justifyContent: 'center'}}>
                      Week D
                    </Col>
                    <Col style={{display: 'flex', alignItems: 'center', flexDirection: 'column'}}>
                      <label id="monday" style={{ fontSize: '14px' }}>Monday</label>
                      <FormGroup style={{display: 'flex', alignItems: 'center'}}>
                        <Input 
                          onChange={(e) => handleScheduleChange("D", "Monday", "active", e.target.checked)} 
                          type="checkbox" 
                          id="monday" 
                          checked={data.schedule.D.Monday.active}
                          // value={"M"}
                          style={{
                            transform: 'scale(2)',
                            position: 'initial', 
                            marginLeft: '0px'
                          }}
                        />
                      </FormGroup>
                    </Col>
                    <Col style={{display: 'flex', alignItems: 'center', flexDirection: 'column'}}>
                      <label id="tuesday" style={{ fontSize: '14px' }}>Tuesday</label>
                      <FormGroup style={{display: 'flex', alignItems: 'center'}}>
                      <Input 
                          onChange={(e) => handleScheduleChange("D", "Tuesday", "active", e.target.checked)} 
                          type="checkbox" 
                          id="tuesday" 
                          checked={data.schedule.D.Tuesday.active}
                          // value={"T"}
                          style={{
                            transform: 'scale(2)',
                            position: 'initial', 
                            marginLeft: '0px'
                          }}
                        />
                      </FormGroup>
                    </Col>
                    <Col style={{display: 'flex', alignItems: 'center', flexDirection: 'column'}}>
                      <label id="wednesday" style={{ fontSize: '14px' }}>Wednesday</label>
                      <FormGroup style={{display: 'flex', alignItems: 'center'}}>
                     <Input 
                          onChange={(e) => handleScheduleChange("D", "Wednesday", "active", e.target.checked)} 
                          type="checkbox" 
                          id="wednesday" 
                          checked={data.schedule.D.Wednesday.active}
                          // value={"W"}
                          style={{
                            transform: 'scale(2)',
                            position: 'initial', 
                            marginLeft: '0px'
                          }}
                        />
                      </FormGroup>
                    </Col>
                    <Col style={{display: 'flex', alignItems: 'center', flexDirection: 'column'}}>
                      <label id="thursday" style={{ fontSize: '14px' }}>Thursday</label>
                      <FormGroup style={{display: 'flex', alignItems: 'center'}}>
                      <Input 
                          onChange={(e) => handleScheduleChange("D", "Thursday", "active", e.target.checked)} 
                          type="checkbox" 
                          id="thursday" 
                          checked={data.schedule.D.Thursday.active}
                          // value={"Th"}
                          style={{
                            transform: 'scale(2)',
                            position: 'initial', 
                            marginLeft: '0px'
                          }}
                        />
                      </FormGroup>
                    </Col>
                    <Col style={{display: 'flex', alignItems: 'center', flexDirection: 'column'}}>
                      <label id="friday" style={{ fontSize: '14px' }}>Friday</label>
                      <FormGroup style={{display: 'flex', alignItems: 'center'}}>
                      <Input 
                          onChange={(e) => handleScheduleChange("D", "Friday", "active", e.target.checked)} 
                          type="checkbox" 
                          id="friday" 
                          checked={data.schedule.D.Friday.active}
                          // value={"F"}
                          style={{
                            transform: 'scale(2)',
                            position: 'initial', 
                            marginLeft: '0px'
                          }}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row style={{marginTop: "30px", marginLeft: "10px", display:'flex', justifyContent: 'space-evenly'}}>
                    <Col style={{display: 'flex', flexDirection: 'column', justifyContent: 'center'}}>
                      Week E
                    </Col>
                    <Col style={{display: 'flex', alignItems: 'center', flexDirection: 'column'}}>
                      <label id="monday" style={{ fontSize: '14px' }}>Monday</label>
                      <FormGroup style={{display: 'flex', alignItems: 'center'}}>
                        <Input 
                          onChange={(e) => handleScheduleChange("E", "Monday", "active", e.target.checked)} 
                          type="checkbox" 
                          id="monday" 
                          checked={data.schedule.E.Monday.active}
                          // value={"M"}
                          style={{
                            transform: 'scale(2)',
                            position: 'initial', 
                            marginLeft: '0px'
                          }}
                        />
                      </FormGroup>
                    </Col>
                    <Col style={{display: 'flex', alignItems: 'center', flexDirection: 'column'}}>
                      <label id="tuesday" style={{ fontSize: '14px' }}>Tuesday</label>
                      <FormGroup style={{display: 'flex', alignItems: 'center'}}>
                      <Input 
                          onChange={(e) => handleScheduleChange("E", "Tuesday", "active", e.target.checked)} 
                          type="checkbox" 
                          id="tuesday" 
                          checked={data.schedule.E.Tuesday.active}
                          // value={"T"}
                          style={{
                            transform: 'scale(2)',
                            position: 'initial', 
                            marginLeft: '0px'
                          }}
                        />
                      </FormGroup>
                    </Col>
                    <Col style={{display: 'flex', alignItems: 'center', flexDirection: 'column'}}>
                      <label id="wednesday" style={{ fontSize: '14px' }}>Wednesday</label>
                      <FormGroup style={{display: 'flex', alignItems: 'center'}}>
                     <Input 
                          onChange={(e) => handleScheduleChange("E", "Wednesday", "active", e.target.checked)} 
                          type="checkbox" 
                          id="wednesday" 
                          checked={data.schedule.E.Wednesday.active}
                          // value={"W"}
                          style={{
                            transform: 'scale(2)',
                            position: 'initial', 
                            marginLeft: '0px'
                          }}
                        />
                      </FormGroup>
                    </Col>
                    <Col style={{display: 'flex', alignItems: 'center', flexDirection: 'column'}}>
                      <label id="thursday" style={{ fontSize: '14px' }}>Thursday</label>
                      <FormGroup style={{display: 'flex', alignItems: 'center'}}>
                      <Input 
                          onChange={(e) => handleScheduleChange("E", "Thursday", "active", e.target.checked)} 
                          type="checkbox" 
                          id="thursday" 
                          checked={data.schedule.E.Thursday.active}
                          // value={"Th"}
                          style={{
                            transform: 'scale(2)',
                            position: 'initial', 
                            marginLeft: '0px'
                          }}
                        />
                      </FormGroup>
                    </Col>
                    <Col style={{display: 'flex', alignItems: 'center', flexDirection: 'column'}}>
                      <label id="friday" style={{ fontSize: '14px' }}>Friday</label>
                      <FormGroup style={{display: 'flex', alignItems: 'center'}}>
                      <Input 
                          onChange={(e) => handleScheduleChange("E", "Friday", "active", e.target.checked)} 
                          type="checkbox" 
                          id="friday" 
                          checked={data.schedule.E.Friday.active}
                          // value={"F"}
                          style={{
                            transform: 'scale(2)',
                            position: 'initial', 
                            marginLeft: '0px'
                          }}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                </>
                }




                {numberOfWeeks === "6" && multiWeek && !differentTimes &&
                <>
                  <Row style={{marginTop: "30px", marginLeft: "10px", display:'flex', justifyContent: 'space-evenly'}}>
                    <Col style={{display: 'flex', flexDirection: 'column', justifyContent: 'center'}}>
                      Week C
                    </Col>
                    <Col style={{display: 'flex', alignItems: 'center', flexDirection: 'column'}}>
                      <label id="monday" style={{ fontSize: '14px' }}>Monday</label>
                      <FormGroup style={{display: 'flex', alignItems: 'center'}}>
                        <Input 
                          onChange={(e) => handleScheduleChange("C", "Monday", "active", e.target.checked)} 
                          type="checkbox" 
                          id="monday" 
                          checked={data.schedule.C.Monday.active}
                          // value={"M"}
                          style={{
                            transform: 'scale(2)',
                            position: 'initial', 
                            marginLeft: '0px'
                          }}
                        />
                      </FormGroup>
                    </Col>
                    <Col style={{display: 'flex', alignItems: 'center', flexDirection: 'column'}}>
                      <label id="tuesday" style={{ fontSize: '14px' }}>Tuesday</label>
                      <FormGroup style={{display: 'flex', alignItems: 'center'}}>
                      <Input 
                          onChange={(e) => handleScheduleChange("C", "Tuesday", "active", e.target.checked)} 
                          type="checkbox" 
                          id="tuesday" 
                          checked={data.schedule.C.Tuesday.active}
                          // value={"T"}
                          style={{
                            transform: 'scale(2)',
                            position: 'initial', 
                            marginLeft: '0px'
                          }}
                        />
                      </FormGroup>
                    </Col>
                    <Col style={{display: 'flex', alignItems: 'center', flexDirection: 'column'}}>
                      <label id="wednesday" style={{ fontSize: '14px' }}>Wednesday</label>
                      <FormGroup style={{display: 'flex', alignItems: 'center'}}>
                     <Input 
                          onChange={(e) => handleScheduleChange("C", "Wednesday", "active", e.target.checked)} 
                          type="checkbox" 
                          id="wednesday" 
                          checked={data.schedule.C.Wednesday.active}
                          // value={"W"}
                          style={{
                            transform: 'scale(2)',
                            position: 'initial', 
                            marginLeft: '0px'
                          }}
                        />
                      </FormGroup>
                    </Col>
                    <Col style={{display: 'flex', alignItems: 'center', flexDirection: 'column'}}>
                      <label id="thursday" style={{ fontSize: '14px' }}>Thursday</label>
                      <FormGroup style={{display: 'flex', alignItems: 'center'}}>
                      <Input 
                          onChange={(e) => handleScheduleChange("C", "Thursday", "active", e.target.checked)} 
                          type="checkbox" 
                          id="thursday" 
                          checked={data.schedule.C.Thursday.active}
                          // value={"Th"}
                          style={{
                            transform: 'scale(2)',
                            position: 'initial', 
                            marginLeft: '0px'
                          }}
                        />
                      </FormGroup>
                    </Col>
                    <Col style={{display: 'flex', alignItems: 'center', flexDirection: 'column'}}>
                      <label id="friday" style={{ fontSize: '14px' }}>Friday</label>
                      <FormGroup style={{display: 'flex', alignItems: 'center'}}>
                      <Input 
                          onChange={(e) => handleScheduleChange("C", "Friday", "active", e.target.checked)} 
                          type="checkbox" 
                          id="friday" 
                          checked={data.schedule.C.Friday.active}
                          // value={"F"}
                          style={{
                            transform: 'scale(2)',
                            position: 'initial', 
                            marginLeft: '0px'
                          }}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row style={{marginTop: "30px", marginLeft: "10px", display:'flex', justifyContent: 'space-evenly'}}>
                    <Col style={{display: 'flex', flexDirection: 'column', justifyContent: 'center'}}>
                      Week D
                    </Col>
                    <Col style={{display: 'flex', alignItems: 'center', flexDirection: 'column'}}>
                      <label id="monday" style={{ fontSize: '14px' }}>Monday</label>
                      <FormGroup style={{display: 'flex', alignItems: 'center'}}>
                        <Input 
                          onChange={(e) => handleScheduleChange("D", "Monday", "active", e.target.checked)} 
                          type="checkbox" 
                          id="monday" 
                          checked={data.schedule.D.Monday.active}
                          // value={"M"}
                          style={{
                            transform: 'scale(2)',
                            position: 'initial', 
                            marginLeft: '0px'
                          }}
                        />
                      </FormGroup>
                    </Col>
                    <Col style={{display: 'flex', alignItems: 'center', flexDirection: 'column'}}>
                      <label id="tuesday" style={{ fontSize: '14px' }}>Tuesday</label>
                      <FormGroup style={{display: 'flex', alignItems: 'center'}}>
                      <Input 
                          onChange={(e) => handleScheduleChange("D", "Tuesday", "active", e.target.checked)} 
                          type="checkbox" 
                          id="tuesday" 
                          checked={data.schedule.D.Tuesday.active}
                          // value={"T"}
                          style={{
                            transform: 'scale(2)',
                            position: 'initial', 
                            marginLeft: '0px'
                          }}
                        />
                      </FormGroup>
                    </Col>
                    <Col style={{display: 'flex', alignItems: 'center', flexDirection: 'column'}}>
                      <label id="wednesday" style={{ fontSize: '14px' }}>Wednesday</label>
                      <FormGroup style={{display: 'flex', alignItems: 'center'}}>
                     <Input 
                          onChange={(e) => handleScheduleChange("D", "Wednesday", "active", e.target.checked)} 
                          type="checkbox" 
                          id="wednesday" 
                          checked={data.schedule.D.Wednesday.active}
                          // value={"W"}
                          style={{
                            transform: 'scale(2)',
                            position: 'initial', 
                            marginLeft: '0px'
                          }}
                        />
                      </FormGroup>
                    </Col>
                    <Col style={{display: 'flex', alignItems: 'center', flexDirection: 'column'}}>
                      <label id="thursday" style={{ fontSize: '14px' }}>Thursday</label>
                      <FormGroup style={{display: 'flex', alignItems: 'center'}}>
                      <Input 
                          onChange={(e) => handleScheduleChange("D", "Thursday", "active", e.target.checked)} 
                          type="checkbox" 
                          id="thursday" 
                          checked={data.schedule.D.Thursday.active}
                          // value={"Th"}
                          style={{
                            transform: 'scale(2)',
                            position: 'initial', 
                            marginLeft: '0px'
                          }}
                        />
                      </FormGroup>
                    </Col>
                    <Col style={{display: 'flex', alignItems: 'center', flexDirection: 'column'}}>
                      <label id="friday" style={{ fontSize: '14px' }}>Friday</label>
                      <FormGroup style={{display: 'flex', alignItems: 'center'}}>
                      <Input 
                          onChange={(e) => handleScheduleChange("D", "Friday", "active", e.target.checked)} 
                          type="checkbox" 
                          id="friday" 
                          checked={data.schedule.D.Friday.active}
                          // value={"F"}
                          style={{
                            transform: 'scale(2)',
                            position: 'initial', 
                            marginLeft: '0px'
                          }}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row style={{marginTop: "30px", marginLeft: "10px", display:'flex', justifyContent: 'space-evenly'}}>
                    <Col style={{display: 'flex', flexDirection: 'column', justifyContent: 'center'}}>
                      Week E
                    </Col>
                    <Col style={{display: 'flex', alignItems: 'center', flexDirection: 'column'}}>
                      <label id="monday" style={{ fontSize: '14px' }}>Monday</label>
                      <FormGroup style={{display: 'flex', alignItems: 'center'}}>
                        <Input 
                          onChange={(e) => handleScheduleChange("E", "Monday", "active", e.target.checked)} 
                          type="checkbox" 
                          id="monday" 
                          checked={data.schedule.E.Monday.active}
                          // value={"M"}
                          style={{
                            transform: 'scale(2)',
                            position: 'initial', 
                            marginLeft: '0px'
                          }}
                        />
                      </FormGroup>
                    </Col>
                    <Col style={{display: 'flex', alignItems: 'center', flexDirection: 'column'}}>
                      <label id="tuesday" style={{ fontSize: '14px' }}>Tuesday</label>
                      <FormGroup style={{display: 'flex', alignItems: 'center'}}>
                      <Input 
                          onChange={(e) => handleScheduleChange("E", "Tuesday", "active", e.target.checked)} 
                          type="checkbox" 
                          id="tuesday" 
                          checked={data.schedule.E.Tuesday.active}
                          // value={"T"}
                          style={{
                            transform: 'scale(2)',
                            position: 'initial', 
                            marginLeft: '0px'
                          }}
                        />
                      </FormGroup>
                    </Col>
                    <Col style={{display: 'flex', alignItems: 'center', flexDirection: 'column'}}>
                      <label id="wednesday" style={{ fontSize: '14px' }}>Wednesday</label>
                      <FormGroup style={{display: 'flex', alignItems: 'center'}}>
                     <Input 
                          onChange={(e) => handleScheduleChange("E", "Wednesday", "active", e.target.checked)} 
                          type="checkbox" 
                          id="wednesday" 
                          checked={data.schedule.E.Wednesday.active}
                          // value={"W"}
                          style={{
                            transform: 'scale(2)',
                            position: 'initial', 
                            marginLeft: '0px'
                          }}
                        />
                      </FormGroup>
                    </Col>
                    <Col style={{display: 'flex', alignItems: 'center', flexDirection: 'column'}}>
                      <label id="thursday" style={{ fontSize: '14px' }}>Thursday</label>
                      <FormGroup style={{display: 'flex', alignItems: 'center'}}>
                      <Input 
                          onChange={(e) => handleScheduleChange("E", "Thursday", "active", e.target.checked)} 
                          type="checkbox" 
                          id="thursday" 
                          checked={data.schedule.E.Thursday.active}
                          // value={"Th"}
                          style={{
                            transform: 'scale(2)',
                            position: 'initial', 
                            marginLeft: '0px'
                          }}
                        />
                      </FormGroup>
                    </Col>
                    <Col style={{display: 'flex', alignItems: 'center', flexDirection: 'column'}}>
                      <label id="friday" style={{ fontSize: '14px' }}>Friday</label>
                      <FormGroup style={{display: 'flex', alignItems: 'center'}}>
                      <Input 
                          onChange={(e) => handleScheduleChange("E", "Friday", "active", e.target.checked)} 
                          type="checkbox" 
                          id="friday" 
                          checked={data.schedule.E.Friday.active}
                          // value={"F"}
                          style={{
                            transform: 'scale(2)',
                            position: 'initial', 
                            marginLeft: '0px'
                          }}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row style={{marginTop: "30px", marginLeft: "10px", display:'flex', justifyContent: 'space-evenly'}}>
                    <Col style={{display: 'flex', flexDirection: 'column', justifyContent: 'center'}}>
                      Week F
                    </Col>
                    <Col style={{display: 'flex', alignItems: 'center', flexDirection: 'column'}}>
                      <label id="monday" style={{ fontSize: '14px' }}>Monday</label>
                      <FormGroup style={{display: 'flex', alignItems: 'center'}}>
                        <Input 
                          onChange={(e) => handleScheduleChange("F", "Monday", "active", e.target.checked)} 
                          type="checkbox" 
                          id="monday" 
                          checked={data.schedule.F.Monday.active}
                          // value={"M"}
                          style={{
                            transform: 'scale(2)',
                            position: 'initial', 
                            marginLeft: '0px'
                          }}
                        />
                      </FormGroup>
                    </Col>
                    <Col style={{display: 'flex', alignItems: 'center', flexDirection: 'column'}}>
                      <label id="tuesday" style={{ fontSize: '14px' }}>Tuesday</label>
                      <FormGroup style={{display: 'flex', alignItems: 'center'}}>
                      <Input 
                          onChange={(e) => handleScheduleChange("F", "Tuesday", "active", e.target.checked)} 
                          type="checkbox" 
                          id="tuesday" 
                          checked={data.schedule.F.Tuesday.active}
                          // value={"T"}
                          style={{
                            transform: 'scale(2)',
                            position: 'initial', 
                            marginLeft: '0px'
                          }}
                        />
                      </FormGroup>
                    </Col>
                    <Col style={{display: 'flex', alignItems: 'center', flexDirection: 'column'}}>
                      <label id="wednesday" style={{ fontSize: '14px' }}>Wednesday</label>
                      <FormGroup style={{display: 'flex', alignItems: 'center'}}>
                     <Input 
                          onChange={(e) => handleScheduleChange("F", "Wednesday", "active", e.target.checked)} 
                          type="checkbox" 
                          id="wednesday" 
                          checked={data.schedule.F.Wednesday.active}
                          // value={"W"}
                          style={{
                            transform: 'scale(2)',
                            position: 'initial', 
                            marginLeft: '0px'
                          }}
                        />
                      </FormGroup>
                    </Col>
                    <Col style={{display: 'flex', alignItems: 'center', flexDirection: 'column'}}>
                      <label id="thursday" style={{ fontSize: '14px' }}>Thursday</label>
                      <FormGroup style={{display: 'flex', alignItems: 'center'}}>
                      <Input 
                          onChange={(e) => handleScheduleChange("F", "Thursday", "active", e.target.checked)} 
                          type="checkbox" 
                          id="thursday" 
                          checked={data.schedule.F.Thursday.active}
                          // value={"Th"}
                          style={{
                            transform: 'scale(2)',
                            position: 'initial', 
                            marginLeft: '0px'
                          }}
                        />
                      </FormGroup>
                    </Col>
                    <Col style={{display: 'flex', alignItems: 'center', flexDirection: 'column'}}>
                      <label id="friday" style={{ fontSize: '14px' }}>Friday</label>
                      <FormGroup style={{display: 'flex', alignItems: 'center'}}>
                      <Input 
                          onChange={(e) => handleScheduleChange("F", "Friday", "active", e.target.checked)} 
                          type="checkbox" 
                          id="friday" 
                          checked={data.schedule.F.Friday.active}
                          // value={"F"}
                          style={{
                            transform: 'scale(2)',
                            position: 'initial', 
                            marginLeft: '0px'
                          }}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                </>
                }



                {numberOfWeeks === "7" && multiWeek && !differentTimes &&
                <>
                  <Row style={{marginTop: "30px", marginLeft: "10px", display:'flex', justifyContent: 'space-evenly'}}>
                    <Col style={{display: 'flex', flexDirection: 'column', justifyContent: 'center'}}>
                      Week C
                    </Col>
                    <Col style={{display: 'flex', alignItems: 'center', flexDirection: 'column'}}>
                      <label id="monday" style={{ fontSize: '14px' }}>Monday</label>
                      <FormGroup style={{display: 'flex', alignItems: 'center'}}>
                        <Input 
                          onChange={(e) => handleScheduleChange("C", "Monday", "active", e.target.checked)} 
                          type="checkbox" 
                          id="monday" 
                          checked={data.schedule.C.Monday.active}
                          // value={"M"}
                          style={{
                            transform: 'scale(2)',
                            position: 'initial', 
                            marginLeft: '0px'
                          }}
                        />
                      </FormGroup>
                    </Col>
                    <Col style={{display: 'flex', alignItems: 'center', flexDirection: 'column'}}>
                      <label id="tuesday" style={{ fontSize: '14px' }}>Tuesday</label>
                      <FormGroup style={{display: 'flex', alignItems: 'center'}}>
                      <Input 
                          onChange={(e) => handleScheduleChange("C", "Tuesday", "active", e.target.checked)} 
                          type="checkbox" 
                          id="tuesday" 
                          checked={data.schedule.C.Tuesday.active}
                          // value={"T"}
                          style={{
                            transform: 'scale(2)',
                            position: 'initial', 
                            marginLeft: '0px'
                          }}
                        />
                      </FormGroup>
                    </Col>
                    <Col style={{display: 'flex', alignItems: 'center', flexDirection: 'column'}}>
                      <label id="wednesday" style={{ fontSize: '14px' }}>Wednesday</label>
                      <FormGroup style={{display: 'flex', alignItems: 'center'}}>
                     <Input 
                          onChange={(e) => handleScheduleChange("C", "Wednesday", "active", e.target.checked)} 
                          type="checkbox" 
                          id="wednesday" 
                          checked={data.schedule.C.Wednesday.active}
                          // value={"W"}
                          style={{
                            transform: 'scale(2)',
                            position: 'initial', 
                            marginLeft: '0px'
                          }}
                        />
                      </FormGroup>
                    </Col>
                    <Col style={{display: 'flex', alignItems: 'center', flexDirection: 'column'}}>
                      <label id="thursday" style={{ fontSize: '14px' }}>Thursday</label>
                      <FormGroup style={{display: 'flex', alignItems: 'center'}}>
                      <Input 
                          onChange={(e) => handleScheduleChange("C", "Thursday", "active", e.target.checked)} 
                          type="checkbox" 
                          id="thursday" 
                          checked={data.schedule.C.Thursday.active}
                          // value={"Th"}
                          style={{
                            transform: 'scale(2)',
                            position: 'initial', 
                            marginLeft: '0px'
                          }}
                        />
                      </FormGroup>
                    </Col>
                    <Col style={{display: 'flex', alignItems: 'center', flexDirection: 'column'}}>
                      <label id="friday" style={{ fontSize: '14px' }}>Friday</label>
                      <FormGroup style={{display: 'flex', alignItems: 'center'}}>
                      <Input 
                          onChange={(e) => handleScheduleChange("C", "Friday", "active", e.target.checked)} 
                          type="checkbox" 
                          id="friday" 
                          checked={data.schedule.C.Friday.active}
                          // value={"F"}
                          style={{
                            transform: 'scale(2)',
                            position: 'initial', 
                            marginLeft: '0px'
                          }}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row style={{marginTop: "30px", marginLeft: "10px", display:'flex', justifyContent: 'space-evenly'}}>
                    <Col style={{display: 'flex', flexDirection: 'column', justifyContent: 'center'}}>
                      Week D
                    </Col>
                    <Col style={{display: 'flex', alignItems: 'center', flexDirection: 'column'}}>
                      <label id="monday" style={{ fontSize: '14px' }}>Monday</label>
                      <FormGroup style={{display: 'flex', alignItems: 'center'}}>
                        <Input 
                          onChange={(e) => handleScheduleChange("D", "Monday", "active", e.target.checked)} 
                          type="checkbox" 
                          id="monday" 
                          checked={data.schedule.D.Monday.active}
                          // value={"M"}
                          style={{
                            transform: 'scale(2)',
                            position: 'initial', 
                            marginLeft: '0px'
                          }}
                        />
                      </FormGroup>
                    </Col>
                    <Col style={{display: 'flex', alignItems: 'center', flexDirection: 'column'}}>
                      <label id="tuesday" style={{ fontSize: '14px' }}>Tuesday</label>
                      <FormGroup style={{display: 'flex', alignItems: 'center'}}>
                      <Input 
                          onChange={(e) => handleScheduleChange("D", "Tuesday", "active", e.target.checked)} 
                          type="checkbox" 
                          id="tuesday" 
                          checked={data.schedule.D.Tuesday.active}
                          // value={"T"}
                          style={{
                            transform: 'scale(2)',
                            position: 'initial', 
                            marginLeft: '0px'
                          }}
                        />
                      </FormGroup>
                    </Col>
                    <Col style={{display: 'flex', alignItems: 'center', flexDirection: 'column'}}>
                      <label id="wednesday" style={{ fontSize: '14px' }}>Wednesday</label>
                      <FormGroup style={{display: 'flex', alignItems: 'center'}}>
                     <Input 
                          onChange={(e) => handleScheduleChange("D", "Wednesday", "active", e.target.checked)} 
                          type="checkbox" 
                          id="wednesday" 
                          checked={data.schedule.D.Wednesday.active}
                          // value={"W"}
                          style={{
                            transform: 'scale(2)',
                            position: 'initial', 
                            marginLeft: '0px'
                          }}
                        />
                      </FormGroup>
                    </Col>
                    <Col style={{display: 'flex', alignItems: 'center', flexDirection: 'column'}}>
                      <label id="thursday" style={{ fontSize: '14px' }}>Thursday</label>
                      <FormGroup style={{display: 'flex', alignItems: 'center'}}>
                      <Input 
                          onChange={(e) => handleScheduleChange("D", "Thursday", "active", e.target.checked)} 
                          type="checkbox" 
                          id="thursday" 
                          checked={data.schedule.D.Thursday.active}
                          // value={"Th"}
                          style={{
                            transform: 'scale(2)',
                            position: 'initial', 
                            marginLeft: '0px'
                          }}
                        />
                      </FormGroup>
                    </Col>
                    <Col style={{display: 'flex', alignItems: 'center', flexDirection: 'column'}}>
                      <label id="friday" style={{ fontSize: '14px' }}>Friday</label>
                      <FormGroup style={{display: 'flex', alignItems: 'center'}}>
                      <Input 
                          onChange={(e) => handleScheduleChange("D", "Friday", "active", e.target.checked)} 
                          type="checkbox" 
                          id="friday" 
                          checked={data.schedule.D.Friday.active}
                          // value={"F"}
                          style={{
                            transform: 'scale(2)',
                            position: 'initial', 
                            marginLeft: '0px'
                          }}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row style={{marginTop: "30px", marginLeft: "10px", display:'flex', justifyContent: 'space-evenly'}}>
                    <Col style={{display: 'flex', flexDirection: 'column', justifyContent: 'center'}}>
                      Week E
                    </Col>
                    <Col style={{display: 'flex', alignItems: 'center', flexDirection: 'column'}}>
                      <label id="monday" style={{ fontSize: '14px' }}>Monday</label>
                      <FormGroup style={{display: 'flex', alignItems: 'center'}}>
                        <Input 
                          onChange={(e) => handleScheduleChange("E", "Monday", "active", e.target.checked)} 
                          type="checkbox" 
                          id="monday" 
                          checked={data.schedule.E.Monday.active}
                          // value={"M"}
                          style={{
                            transform: 'scale(2)',
                            position: 'initial', 
                            marginLeft: '0px'
                          }}
                        />
                      </FormGroup>
                    </Col>
                    <Col style={{display: 'flex', alignItems: 'center', flexDirection: 'column'}}>
                      <label id="tuesday" style={{ fontSize: '14px' }}>Tuesday</label>
                      <FormGroup style={{display: 'flex', alignItems: 'center'}}>
                      <Input 
                          onChange={(e) => handleScheduleChange("E", "Tuesday", "active", e.target.checked)} 
                          type="checkbox" 
                          id="tuesday" 
                          checked={data.schedule.E.Tuesday.active}
                          // value={"T"}
                          style={{
                            transform: 'scale(2)',
                            position: 'initial', 
                            marginLeft: '0px'
                          }}
                        />
                      </FormGroup>
                    </Col>
                    <Col style={{display: 'flex', alignItems: 'center', flexDirection: 'column'}}>
                      <label id="wednesday" style={{ fontSize: '14px' }}>Wednesday</label>
                      <FormGroup style={{display: 'flex', alignItems: 'center'}}>
                     <Input 
                          onChange={(e) => handleScheduleChange("E", "Wednesday", "active", e.target.checked)} 
                          type="checkbox" 
                          id="wednesday" 
                          checked={data.schedule.E.Wednesday.active}
                          // value={"W"}
                          style={{
                            transform: 'scale(2)',
                            position: 'initial', 
                            marginLeft: '0px'
                          }}
                        />
                      </FormGroup>
                    </Col>
                    <Col style={{display: 'flex', alignItems: 'center', flexDirection: 'column'}}>
                      <label id="thursday" style={{ fontSize: '14px' }}>Thursday</label>
                      <FormGroup style={{display: 'flex', alignItems: 'center'}}>
                      <Input 
                          onChange={(e) => handleScheduleChange("E", "Thursday", "active", e.target.checked)} 
                          type="checkbox" 
                          id="thursday" 
                          checked={data.schedule.E.Thursday.active}
                          // value={"Th"}
                          style={{
                            transform: 'scale(2)',
                            position: 'initial', 
                            marginLeft: '0px'
                          }}
                        />
                      </FormGroup>
                    </Col>
                    <Col style={{display: 'flex', alignItems: 'center', flexDirection: 'column'}}>
                      <label id="friday" style={{ fontSize: '14px' }}>Friday</label>
                      <FormGroup style={{display: 'flex', alignItems: 'center'}}>
                      <Input 
                          onChange={(e) => handleScheduleChange("E", "Friday", "active", e.target.checked)} 
                          type="checkbox" 
                          id="friday" 
                          checked={data.schedule.E.Friday.active}
                          // value={"F"}
                          style={{
                            transform: 'scale(2)',
                            position: 'initial', 
                            marginLeft: '0px'
                          }}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row style={{marginTop: "30px", marginLeft: "10px", display:'flex', justifyContent: 'space-evenly'}}>
                    <Col style={{display: 'flex', flexDirection: 'column', justifyContent: 'center'}}>
                      Week F
                    </Col>
                    <Col style={{display: 'flex', alignItems: 'center', flexDirection: 'column'}}>
                      <label id="monday" style={{ fontSize: '14px' }}>Monday</label>
                      <FormGroup style={{display: 'flex', alignItems: 'center'}}>
                        <Input 
                          onChange={(e) => handleScheduleChange("F", "Monday", "active", e.target.checked)} 
                          type="checkbox" 
                          id="monday" 
                          checked={data.schedule.F.Monday.active}
                          // value={"M"}
                          style={{
                            transform: 'scale(2)',
                            position: 'initial', 
                            marginLeft: '0px'
                          }}
                        />
                      </FormGroup>
                    </Col>
                    <Col style={{display: 'flex', alignItems: 'center', flexDirection: 'column'}}>
                      <label id="tuesday" style={{ fontSize: '14px' }}>Tuesday</label>
                      <FormGroup style={{display: 'flex', alignItems: 'center'}}>
                      <Input 
                          onChange={(e) => handleScheduleChange("F", "Tuesday", "active", e.target.checked)} 
                          type="checkbox" 
                          id="tuesday" 
                          checked={data.schedule.F.Tuesday.active}
                          // value={"T"}
                          style={{
                            transform: 'scale(2)',
                            position: 'initial', 
                            marginLeft: '0px'
                          }}
                        />
                      </FormGroup>
                    </Col>
                    <Col style={{display: 'flex', alignItems: 'center', flexDirection: 'column'}}>
                      <label id="wednesday" style={{ fontSize: '14px' }}>Wednesday</label>
                      <FormGroup style={{display: 'flex', alignItems: 'center'}}>
                     <Input 
                          onChange={(e) => handleScheduleChange("F", "Wednesday", "active", e.target.checked)} 
                          type="checkbox" 
                          id="wednesday" 
                          checked={data.schedule.F.Wednesday.active}
                          // value={"W"}
                          style={{
                            transform: 'scale(2)',
                            position: 'initial', 
                            marginLeft: '0px'
                          }}
                        />
                      </FormGroup>
                    </Col>
                    <Col style={{display: 'flex', alignItems: 'center', flexDirection: 'column'}}>
                      <label id="thursday" style={{ fontSize: '14px' }}>Thursday</label>
                      <FormGroup style={{display: 'flex', alignItems: 'center'}}>
                      <Input 
                          onChange={(e) => handleScheduleChange("F", "Thursday", "active", e.target.checked)} 
                          type="checkbox" 
                          id="thursday" 
                          checked={data.schedule.F.Thursday.active}
                          // value={"Th"}
                          style={{
                            transform: 'scale(2)',
                            position: 'initial', 
                            marginLeft: '0px'
                          }}
                        />
                      </FormGroup>
                    </Col>
                    <Col style={{display: 'flex', alignItems: 'center', flexDirection: 'column'}}>
                      <label id="friday" style={{ fontSize: '14px' }}>Friday</label>
                      <FormGroup style={{display: 'flex', alignItems: 'center'}}>
                      <Input 
                          onChange={(e) => handleScheduleChange("F", "Friday", "active", e.target.checked)} 
                          type="checkbox" 
                          id="friday" 
                          checked={data.schedule.F.Friday.active}
                          // value={"F"}
                          style={{
                            transform: 'scale(2)',
                            position: 'initial', 
                            marginLeft: '0px'
                          }}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row style={{marginTop: "30px", marginLeft: "10px", display:'flex', justifyContent: 'space-evenly'}}>
                    <Col style={{display: 'flex', flexDirection: 'column', justifyContent: 'center'}}>
                      Week G
                    </Col>
                    <Col style={{display: 'flex', alignItems: 'center', flexDirection: 'column'}}>
                      <label id="monday" style={{ fontSize: '14px' }}>Monday</label>
                      <FormGroup style={{display: 'flex', alignItems: 'center'}}>
                        <Input 
                          onChange={(e) => handleScheduleChange("G", "Monday", "active", e.target.checked)} 
                          type="checkbox" 
                          id="monday" 
                          checked={data.schedule.G.Monday.active}
                          // value={"M"}
                          style={{
                            transform: 'scale(2)',
                            position: 'initial', 
                            marginLeft: '0px'
                          }}
                        />
                      </FormGroup>
                    </Col>
                    <Col style={{display: 'flex', alignItems: 'center', flexDirection: 'column'}}>
                      <label id="tuesday" style={{ fontSize: '14px' }}>Tuesday</label>
                      <FormGroup style={{display: 'flex', alignItems: 'center'}}>
                      <Input 
                          onChange={(e) => handleScheduleChange("G", "Tuesday", "active", e.target.checked)} 
                          type="checkbox" 
                          id="tuesday" 
                          checked={data.schedule.G.Tuesday.active}
                          // value={"T"}
                          style={{
                            transform: 'scale(2)',
                            position: 'initial', 
                            marginLeft: '0px'
                          }}
                        />
                      </FormGroup>
                    </Col>
                    <Col style={{display: 'flex', alignItems: 'center', flexDirection: 'column'}}>
                      <label id="wednesday" style={{ fontSize: '14px' }}>Wednesday</label>
                      <FormGroup style={{display: 'flex', alignItems: 'center'}}>
                     <Input 
                          onChange={(e) => handleScheduleChange("G", "Wednesday", "active", e.target.checked)} 
                          type="checkbox" 
                          id="wednesday" 
                          checked={data.schedule.G.Wednesday.active}
                          // value={"W"}
                          style={{
                            transform: 'scale(2)',
                            position: 'initial', 
                            marginLeft: '0px'
                          }}
                        />
                      </FormGroup>
                    </Col>
                    <Col style={{display: 'flex', alignItems: 'center', flexDirection: 'column'}}>
                      <label id="thursday" style={{ fontSize: '14px' }}>Thursday</label>
                      <FormGroup style={{display: 'flex', alignItems: 'center'}}>
                      <Input 
                          onChange={(e) => handleScheduleChange("G", "Thursday", "active", e.target.checked)} 
                          type="checkbox" 
                          id="thursday" 
                          checked={data.schedule.G.Thursday.active}
                          // value={"Th"}
                          style={{
                            transform: 'scale(2)',
                            position: 'initial', 
                            marginLeft: '0px'
                          }}
                        />
                      </FormGroup>
                    </Col>
                    <Col style={{display: 'flex', alignItems: 'center', flexDirection: 'column'}}>
                      <label id="friday" style={{ fontSize: '14px' }}>Friday</label>
                      <FormGroup style={{display: 'flex', alignItems: 'center'}}>
                      <Input 
                          onChange={(e) => handleScheduleChange("G", "Friday", "active", e.target.checked)} 
                          type="checkbox" 
                          id="friday" 
                          checked={data.schedule.G.Friday.active}
                          // value={"F"}
                          style={{
                            transform: 'scale(2)',
                            position: 'initial', 
                            marginLeft: '0px'
                          }}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                </>
                }
                


                  { differentTimes && !multiWeek &&
                  <>
                  <Row style={{marginTop: "30px", marginLeft: "10px", display:'flex', justifyContent: 'space-evenly'}}>
                    <Col md="2" style={{display: 'flex', alignItems: 'center', flexDirection: 'column'}}>
                      <label id="monday" style={{ fontSize: '14px' }}>Monday</label>
                      <FormGroup style={{display: 'flex', alignItems: 'center'}}>
                        <Input 
                          onChange={(e) => handleScheduleChange("A", "Monday", "active", e.target.checked)} 
                          type="checkbox" 
                          id="monday" 
                          checked={data.schedule["A"].Monday.active}
                          // value={"M"}
                          style={{transform: 'scale(2)', position: 'initial', marginLeft: '0px'
                          }}
                        />
                      </FormGroup>
                    </Col>
                    <Col md="5">
                      <label id="timeTip" style={{ fontSize: '14px' }}>Start Time</label>
                      <FormGroup>
                        <Input onChange={(e) => handleScheduleChange("A", "Monday", "startTime", e.target.value)} type="time" id="startTime" value={data.schedule["A"].Monday.active ? data.schedule["A"].Monday.startTime : null}/>
                      </FormGroup>
                    </Col>
                    <Col md="5">
                      <label id="timeTip" style={{ fontSize: '14px' }}>End Time</label>
                      <FormGroup>
                        <Input onChange={(e) => handleScheduleChange("A", "Monday", "endTime", e.target.value)} type="time" id="endTime" value={data.schedule["A"].Monday.active ? data.schedule["A"].Monday.endTime : null}/>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row style={{marginLeft: "10px", display:'flex', justifyContent: 'space-evenly'}}>
                  <Col md="2" style={{display: 'flex', alignItems: 'center', flexDirection: 'column'}}>
                    <label id="tuesday" style={{ fontSize: '14px' }}>Tuesday</label>
                    <FormGroup style={{display: 'flex', alignItems: 'center'}}>
                      <Input 
                        onChange={(e) => handleScheduleChange("A", "Tuesday", "active", e.target.checked)} 
                        type="checkbox" 
                        id="tuesday" 
                        checked={data.schedule["A"].Tuesday.active}
                        // value={"T"}
                        style={{transform: 'scale(2)', position: 'initial', marginLeft: '0px'
                        }}
                      />
                    </FormGroup>
                  </Col>
                  <Col md="5">
                    <label id="timeTip" style={{ fontSize: '14px' }}>Start Time</label>
                    <FormGroup>
                    <Input onChange={(e) => handleScheduleChange("A", "Tuesday", "startTime", e.target.value)} type="time" id="startTime" value={data.schedule["A"].Tuesday.active ? data.schedule["A"].Tuesday.startTime : null}/>
                    </FormGroup>
                  </Col>
                  <Col md="5">
                    <label id="timeTip" style={{ fontSize: '14px' }}>End Time</label>
                    <FormGroup>
                    <Input onChange={(e) => handleScheduleChange("A", "Tuesday", "endTime", e.target.value)} type="time" id="endTime" value={data.schedule["A"].Tuesday.active ? data.schedule["A"].Tuesday.endTime : null}/>
                    </FormGroup>
                  </Col>
                </Row>
                <Row style={{marginLeft: "10px", display:'flex', justifyContent: 'space-evenly'}}>
                  <Col md="2" style={{display: 'flex', alignItems: 'center', flexDirection: 'column'}}>
                    <label id="wednesday" style={{ fontSize: '14px' }}>Wednesday</label>
                    <FormGroup style={{display: 'flex', alignItems: 'center'}}>
                      <Input 
                        onChange={(e) => handleScheduleChange("A", "Wednesday", "active", e.target.checked)} 
                        type="checkbox" 
                        id="wednesday" 
                        checked={data.schedule["A"].Wednesday.active}
                        // value={"W"}
                        style={{transform: 'scale(2)', position: 'initial', marginLeft: '0px'
                        }}
                      />
                    </FormGroup>
                  </Col>
                  <Col md="5">
                    <label id="timeTip" style={{ fontSize: '14px' }}>Start Time</label>
                    <FormGroup>
                    <Input onChange={(e) => handleScheduleChange("A", "Wednesday", "startTime", e.target.value)} type="time" id="startTime" value={data.schedule["A"].Wednesday.active ? data.schedule["A"].Wednesday.startTime : null}/>
                    </FormGroup>
                  </Col>
                  <Col md="5">
                    <label id="timeTip" style={{ fontSize: '14px' }}>End Time</label>
                    <FormGroup>
                    <Input onChange={(e) => handleScheduleChange("A", "Wednesday", "endTime", e.target.value)} type="time" id="endTime" value={data.schedule["A"].Wednesday.active ? data.schedule["A"].Wednesday.endTime : null}/>
                    </FormGroup>
                  </Col>
                </Row>
                <Row style={{marginLeft: "10px", display:'flex', justifyContent: 'space-evenly'}}>
                  <Col md="2" style={{display: 'flex', alignItems: 'center', flexDirection: 'column'}}>
                    <label id="thursday" style={{ fontSize: '14px' }}>Thursday</label>
                    <FormGroup style={{display: 'flex', alignItems: 'center'}}>
                      <Input 
                        onChange={(e) => handleScheduleChange("A", "Thursday", "active", e.target.checked)}
                        type="checkbox" 
                        id="thursday" 
                        checked={data.schedule["A"].Thursday.active}
                        // value={"Th"}
                        style={{transform: 'scale(2)', position: 'initial', marginLeft: '0px'
                        }}
                      />
                    </FormGroup>
                  </Col>
                  <Col md="5">
                    <label id="timeTip" style={{ fontSize: '14px' }}>Start Time</label>
                    <FormGroup>
                    <Input onChange={(e) => handleScheduleChange("A", "Thursday", "startTime", e.target.value)} type="time" id="startTime" value={data.schedule["A"].Thursday.active ? data.schedule["A"].Thursday.startTime : null}/>
                    </FormGroup>
                  </Col>
                  <Col md="5">
                    <label id="timeTip" style={{ fontSize: '14px' }}>End Time</label>
                    <FormGroup>
                    <Input onChange={(e) => handleScheduleChange("A", "Thursday", "endTime", e.target.value)} type="time" id="endTime" value={data.schedule["A"].Thursday.active ? data.schedule["A"].Thursday.endTime : null}/>
                    </FormGroup>
                  </Col>
                </Row>
                <Row style={{marginLeft: "10px", display:'flex', justifyContent: 'space-evenly'}}>
                  <Col md="2" style={{display: 'flex', alignItems: 'center', flexDirection: 'column'}}>
                    <label id="friday" style={{ fontSize: '14px' }}>Friday</label>
                    <FormGroup style={{display: 'flex', alignItems: 'center'}}>
                      <Input 
                        onChange={(e) => handleScheduleChange("A", "Friday", "active", e.target.checked)}
                        type="checkbox" 
                        id="friday" 
                        checked={data.schedule["A"].Friday.active}
                        // value={"F"}
                        style={{transform: 'scale(2)', position: 'initial', marginLeft: '0px'
                        }}
                      />
                    </FormGroup>
                  </Col>
                  <Col md="5">
                    <label id="timeTip" style={{ fontSize: '14px' }}>Start Time</label>
                    <FormGroup>
                    <Input onChange={(e) => handleScheduleChange("A", "Friday", "startTime", e.target.value)} type="time" id="startTime" value={data.schedule["A"].Friday.active ? data.schedule["A"].Friday.startTime : null}/>
                    </FormGroup>
                  </Col>
                  <Col md="5">
                    <label id="timeTip" style={{ fontSize: '14px' }}>End Time</label>
                    <FormGroup>
                    <Input onChange={(e) => handleScheduleChange("A", "Friday", "endTime", e.target.value)} type="time" id="endTime" value={data.schedule["A"].Friday.active ? data.schedule["A"].Friday.endTime : null}/>
                    </FormGroup>
                  </Col>
                </Row>
                </>
                  }




                { differentTimes && multiWeek &&
                  <>
                  <Row style={{marginTop: "30px", marginBottom: '10px', display:'flex', justifyContent: 'center', alignItems: 'center'}}>
                    <i className="nc-icon nc-minimal-left" style={{cursor: 'pointer'}} onClick={(() => cycleWeeks("backward"))}/>
                      <h3 style={{marginLeft: '15px', marginRight: '15px', marginBottom: '0px'}}>Week {currentWeek}</h3>
                    <i className="nc-icon nc-minimal-right"style={{cursor: 'pointer'}} onClick={(() => cycleWeeks("forward"))}/>
                  </Row>
                  <Row style={{marginLeft: "10px", display:'flex', justifyContent: 'space-evenly'}}>
                    <Col md="2" style={{display: 'flex', alignItems: 'center', flexDirection: 'column'}}>
                      <label id="monday" style={{ fontSize: '14px' }}>Monday</label>
                      <FormGroup style={{display: 'flex', alignItems: 'center'}}>
                        <Input 
                          onChange={(e) => handleScheduleChange(currentWeek, "Monday", "active", e.target.checked)} 
                          type="checkbox" 
                          id="monday" 
                          checked={data.schedule[currentWeek]?.Monday.active}
                          // value={"M"}
                          style={{transform: 'scale(2)', position: 'initial', marginLeft: '0px'
                          }}
                        />
                      </FormGroup>
                    </Col>
                    <Col md="5">
                      <label id="timeTip" style={{ fontSize: '14px' }}>Start Time</label>
                      <FormGroup>
                      <Input onChange={(e) => handleScheduleChange(currentWeek, "Monday", "startTime", e.target.value)} type="time" id="startTime" value={data.schedule[currentWeek].Monday.active ? data.schedule[currentWeek].Monday.startTime : null}/>
                      </FormGroup>
                    </Col>
                    <Col md="5">
                      <label id="timeTip" style={{ fontSize: '14px' }}>End Time</label>
                      <FormGroup>
                      <Input onChange={(e) => handleScheduleChange(currentWeek, "Monday", "endTime", e.target.value)} type="time" id="endTime" value={data.schedule[currentWeek].Monday.active ? data.schedule[currentWeek].Monday.endTime : null}/>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row style={{marginLeft: "10px", display:'flex', justifyContent: 'space-evenly'}}>
                  <Col md="2" style={{display: 'flex', alignItems: 'center', flexDirection: 'column'}}>
                    <label id="tuesday" style={{ fontSize: '14px' }}>Tuesday</label>
                    <FormGroup style={{display: 'flex', alignItems: 'center'}}>
                      <Input 
                        onChange={(e) => handleScheduleChange(currentWeek, "Tuesday", "active", e.target.checked)} 
                        type="checkbox" 
                        id="tuesday" 
                        checked={data.schedule[currentWeek]?.Tuesday.active}
                        // value={"T"}
                        style={{transform: 'scale(2)', position: 'initial', marginLeft: '0px'
                        }}
                      />
                    </FormGroup>
                  </Col>
                  <Col md="5">
                    <label id="timeTip" style={{ fontSize: '14px' }}>Start Time</label>
                    <FormGroup>
                    <Input onChange={(e) => handleScheduleChange(currentWeek, "Tuesday", "startTime", e.target.value)} type="time" id="startTime" value={data.schedule[currentWeek].Tuesday.active ? data.schedule[currentWeek].Tuesday.startTime : null}/>
                    </FormGroup>
                  </Col>
                  <Col md="5">
                    <label id="timeTip" style={{ fontSize: '14px' }}>End Time</label>
                    <FormGroup>
                    <Input onChange={(e) => handleScheduleChange(currentWeek, "Tuesday", "endTime", e.target.value)} type="time" id="endTime" value={data.schedule[currentWeek].Tuesday.active ? data.schedule[currentWeek].Tuesday.endTime : null}/>
                    </FormGroup>
                  </Col>
                </Row>
                <Row style={{marginLeft: "10px", display:'flex', justifyContent: 'space-evenly'}}>
                  <Col md="2" style={{display: 'flex', alignItems: 'center', flexDirection: 'column'}}>
                    <label id="wednesday" style={{ fontSize: '14px' }}>Wednesday</label>
                    <FormGroup style={{display: 'flex', alignItems: 'center'}}>
                      <Input 
                        onChange={(e) => handleScheduleChange(currentWeek, "Wednesday", "active", e.target.checked)} 
                        type="checkbox" 
                        id="wednesday" 
                        checked={data.schedule[currentWeek]?.Wednesday.active}
                        // value={"W"}
                        style={{transform: 'scale(2)', position: 'initial', marginLeft: '0px'
                        }}
                      />
                    </FormGroup>
                  </Col>
                  <Col md="5">
                    <label id="timeTip" style={{ fontSize: '14px' }}>Start Time</label>
                    <FormGroup>
                    <Input onChange={(e) => handleScheduleChange(currentWeek, "Wednesday", "startTime", e.target.value)} type="time" id="startTime" value={data.schedule[currentWeek].Wednesday.active ? data.schedule[currentWeek].Wednesday.startTime : null}/>
                    </FormGroup>
                  </Col>
                  <Col md="5">
                    <label id="timeTip" style={{ fontSize: '14px' }}>End Time</label>
                    <FormGroup>
                    <Input onChange={(e) => handleScheduleChange(currentWeek, "Wednesday", "endTime", e.target.value)} type="time" id="endTime" value={data.schedule[currentWeek].Wednesday.active ? data.schedule[currentWeek].Wednesday.endTime : null}/>
                    </FormGroup>
                  </Col>
                </Row>
                <Row style={{marginLeft: "10px", display:'flex', justifyContent: 'space-evenly'}}>
                  <Col md="2" style={{display: 'flex', alignItems: 'center', flexDirection: 'column'}}>
                    <label id="thursday" style={{ fontSize: '14px' }}>Thursday</label>
                    <FormGroup style={{display: 'flex', alignItems: 'center'}}>
                      <Input 
                        onChange={(e) => handleScheduleChange(currentWeek, "Thursday", "active", e.target.checked)} 
                        type="checkbox" 
                        id="thursday" 
                        checked={data.schedule[currentWeek]?.Thursday.active}
                        // value={"Th"}
                        style={{transform: 'scale(2)', position: 'initial', marginLeft: '0px'
                        }}
                      />
                    </FormGroup>
                  </Col>
                  <Col md="5">
                    <label id="timeTip" style={{ fontSize: '14px' }}>Start Time</label>
                    <FormGroup>
                    <Input onChange={(e) => handleScheduleChange(currentWeek, "Thursday", "startTime", e.target.value)} type="time" id="startTime" value={data.schedule[currentWeek].Thursday.active ? data.schedule[currentWeek].Thursday.startTime : null}/>
                    </FormGroup>
                  </Col>
                  <Col md="5">
                    <label id="timeTip" style={{ fontSize: '14px' }}>End Time</label>
                    <FormGroup>
                    <Input onChange={(e) => handleScheduleChange(currentWeek, "Thursday", "endTime", e.target.value)} type="time" id="endTime" value={data.schedule[currentWeek].Thursday.active ? data.schedule[currentWeek].Thursday.endTime : null}/>
                    </FormGroup>
                  </Col>
                </Row>
                <Row style={{marginLeft: "10px", display:'flex', justifyContent: 'space-evenly'}}>
                  <Col md="2" style={{display: 'flex', alignItems: 'center', flexDirection: 'column'}}>
                    <label id="friday" style={{ fontSize: '14px' }}>Friday</label>
                    <FormGroup style={{display: 'flex', alignItems: 'center'}}>
                      <Input 
                        onChange={(e) => handleScheduleChange(currentWeek, "Friday", "active", e.target.checked)} 
                        type="checkbox" 
                        id="friday" 
                        checked={data.schedule[currentWeek]?.Friday.active}
                        // value={"F"}
                        style={{transform: 'scale(2)', position: 'initial', marginLeft: '0px'
                        }}
                      />
                    </FormGroup>
                  </Col>
                  <Col md="5">
                    <label id="timeTip" style={{ fontSize: '14px' }}>Start Time</label>
                    <FormGroup>
                    <Input onChange={(e) => handleScheduleChange(currentWeek, "Friday", "startTime", e.target.value)} type="time" id="startTime" value={data.schedule[currentWeek].Friday.active ? data.schedule[currentWeek].Friday.startTime : null}/>
                    </FormGroup>
                  </Col>
                  <Col md="5">
                    <label id="timeTip" style={{ fontSize: '14px' }}>End Time</label>
                    <FormGroup>
                    <Input onChange={(e) => handleScheduleChange(currentWeek, "Friday", "endTime", e.target.value)} type="time" id="endTime" value={data.schedule[currentWeek].Friday.active ? data.schedule[currentWeek].Friday.endTime : null}/>
                    </FormGroup>
                  </Col>
                </Row>
                </>
                  } */}




                  { differentTimes && !multiWeek && (
                    <>
                      {daysOfWeek.map((day) => (
                        <ScheduleRow 
                          key={day} 
                          week="A" 
                          day={day} 
                          handleScheduleChange={handleScheduleChange} 
                          data={data} 
                        />
                      ))}
                    </>
                  )}

                  {differentTimes && multiWeek && (
                    <>
                      <WeekSelectorForMultiWeek />
                      {/* Days of the Week Selection with Start Time Input */}
                        {daysOfWeek.map((day) => (
                          <ScheduleRow 
                            key={day} 
                            week={currentWeek} 
                            day={day} 
                            handleScheduleChange={handleScheduleChange} 
                            data={data} 
                          />
                        ))}
                    </>
                  )}

                  {!multiWeek && !differentTimes && 
                    <ScheduleCheckboxes schedule={data.schedule} handleScheduleChange={handleScheduleChange} week="A" />
                  }

                  {multiWeek && !differentTimes && (
                    <>
                      <WeekSelectorForMultiWeek />
                      <ScheduleCheckboxes schedule={data.schedule} handleScheduleChange={handleScheduleChange} week={currentWeek} />             
                    </>
                  )}



                <Row style={{marginTop: "30px", display: 'flex', alignContent: 'center', justifyContent: 'center'}}>
                  <NavLink to="/settings" style={{ color: 'black' }}>
                    Need different scheduling options?
                  </NavLink>
                </Row>




                </Form>
              </CardBody>
              <CardFooter style={{display: 'flex', alignContent: 'center', justifyContent: 'center'}}>
                <Button 
                  className="btn-round"
                  color="info"
                  type="submit"
                  onClick={(e) => (isScheduleNormal(data.schedule) && data.active) ? handleSubmit(e) : isScheduleNormal(data.schedule) === false ? potentialInvalidScheduleAlert(e) : !data.duration ? classNotSetToActiveAlert(e) : null}
                  style={{
                    padding: "10px 30px",
                    borderRadius: "30px",
                    fontSize: "1.1rem",
                    fontWeight: "bold",
                  }}>
                  Save
                </Button>
              </CardFooter>
            </Card>
            {alert}
          </Col>
        </Row>
      </div>
    </>
  );
}

export default AddEditClassForm;
