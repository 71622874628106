import React, {useState} from 'react'
import { selectUser } from "features/userSlice";
import { useSelector } from "react-redux";
import { db } from "/Users/michaeltucker/Documents/coding-projects/client/src/firebase.js";
import { setDoc, doc } from "firebase/firestore";
import { Table, Input, Button, Modal, ModalBody, ModalFooter, FormGroup, Label, Row, Col } from "reactstrap";
import { createRandomId } from 'RandomId';

function RandomizerCustomListTable({classId, listToEditId}) {

    const initialListData = {
        listId: "",
        listName: "",
        list:  [
            {listItem: "", fileName: "", filePath: ""}
        ]
    }

    const user = useSelector(selectUser);
    const classIndex = user.classes.findIndex((c) => c.classId === classId);
    const [editedCustomListData, setEditedCustomListData] = useState(() => {
        // Get the user's class by classIndex
        const customLists = user?.classes?.[classIndex]?.customLists;
    
        // Find the list with the matching listId, if it exists
        const existingList = customLists?.find((list) => list.listId === listToEditId);
    
        // If the listToEditId exists, use the matching list; otherwise, use initialListData
        return existingList || initialListData;
    });
    const [copyRosterToClassModal, setCopyRosterToClassModal] = useState(false);
    const [classesSelectedForRosterCopy, setClassesSelectedForRosterCopy] = useState([])
    const toggleCopyRosterToClassModal = () => {
        setCopyRosterToClassModal(!copyRosterToClassModal);
        setClassesSelectedForRosterCopy([])
    }

    const handleCheckboxChangeForRosterCopy = (classId) => {
        setClassesSelectedForRosterCopy(prev => {
          if (prev.includes(classId)) {
            // If classId is already selected, remove it
            return prev.filter(id => id !== classId);
          } else {
            // If classId is not selected, add it
            return [...prev, classId];
          }
        });
    };

    const copyRosterToClasses = async () => {
        try {
          const docRef = doc(db, "users", user.uid);
      
          // Make a deep copy of the user object
          const userCopy = JSON.parse(JSON.stringify(user));
      
          // Find the index of the class that has the roster to be copied
          const classIndex = userCopy.classes.findIndex((c) => c.classId === classId);
      
          if (classIndex === -1) {
            console.log(`Class with ID ${classId} not found.`);
            return;
          }
      
          // Get the roster to copy
          let rosterToCopy = userCopy.classes[classIndex].rosterData.roster;
      
          // Copy the roster to the selected classes
          let classesCopy = [...userCopy.classes];
      
          classesSelectedForRosterCopy.forEach((classId) => {
            const targetClassIndex = classesCopy.findIndex((c) => c.classId === classId);
      
            if (targetClassIndex !== -1) {
                if (classesCopy[targetClassIndex].rosterData === undefined) { //Some classes won't have rosterData Object if their roster hasn't been edited yet.
                    // Initialize the rosterData object and roster array
                    classesCopy[targetClassIndex].rosterData = {
                        roster: [...rosterToCopy].filter(
                        (rosterEntry) =>
                            rosterEntry.name !== "" ||
                            rosterEntry.descriptor1 !== "" ||
                            rosterEntry.descriptor2 !== "" ||
                            rosterEntry.birthday !== ""
                        ),
                        descriptors: {
                            descriptor1: "Descriptor 1",
                            descriptor2: "Descriptor 2",
                        }
                    };
                } else {
                    // Append the roster to the target class without overwriting
                    classesCopy[targetClassIndex].rosterData.roster = [
                        ...classesCopy[targetClassIndex].rosterData.roster, 
                        ...rosterToCopy
                    ].filter(
                        rosterEntry => rosterEntry.name !== "" || 
                                    rosterEntry.descriptor1 !== "" || 
                                    rosterEntry.descriptor2 !== "" || 
                                    rosterEntry.birthday !== ""
                    );
                }
            } else {
              console.log(`Class with ID ${classId} not found.`);
            }
          });
      
          // Update the user's classes with the modified data
          userCopy.classes = classesCopy;
      
          // Update the Firestore document with the modified user data
          await setDoc(docRef, userCopy);
      
          // Optionally close the modal
          toggleCopyRosterToClassModal();
      
        } catch (error) {
          console.log("Failed to copy roster", error);
        }
    };
      
      

    const saveCustomList = async () => {
        try {
            const docRef = doc(db, "users", user.uid);
    
            // Create a deep copy of the user object
            const userCopy = JSON.parse(JSON.stringify(user));
    
            // Ensure classes exist
            if (!userCopy.classes) {
                throw new Error("User data is missing 'classes'.");
            }
    
            // Find the index of the class by classId
            const classIndex = userCopy.classes.findIndex((c) => c.classId === classId);
    
            if (classIndex === -1) {
                throw new Error(`Class with ID '${classId}' not found.`);
            }
    
            // Ensure customLists exists
            if (!userCopy.classes[classIndex].customLists) {
                userCopy.classes[classIndex].customLists = [];
            }
    
            // Check if the list exists by listId
            const listIndex = userCopy.classes[classIndex].customLists.findIndex(
                (list) => list.listId === listToEditId
            );
    
            if (listIndex !== -1) {
                // Update the existing list
                userCopy.classes[classIndex].customLists[listIndex] = {
                    ...editedCustomListData, // Ensure it's not nested
                    listId: listToEditId,   // Maintain the existing listId
                };
            } else {
                // Add a new list
                const newList = { 
                    ...editedCustomListData, 
                    listId: createRandomId() // Add a new unique listId
                };
                console.log("newList => ", newList)
                userCopy.classes[classIndex].customLists.push(newList); // Add directly
            }
            console.log("userCopy.classes[classIndex].customLists => ", userCopy.classes[classIndex].customLists)

            // Save the updated user data
            await setDoc(docRef, userCopy);
        
        } catch (error) {
            alert("Error updating document: " + error.message);
            console.log("Error updating document:", error);
        }
    };
    

    function handleCustomListDataEdit(event, index, key) {
        const { value } = event.target;
    
        setEditedCustomListData((prevData) => {
            if (key === "listName") {
                // Update the listName
                return {
                    ...prevData,
                    listName: value, // Update listName directly
                };
            }
    
            if (key === "listItem" && index < prevData.list.length) {
                // Update a specific listItem in the list
                return {
                    ...prevData,
                    list: prevData.list.map((item, itemIndex) => {
                        if (itemIndex === index) {
                            return { ...item, listItem: value };
                        }
                        return item;
                    }),
                };
            }
    
            return prevData; // Return the unchanged state
        });
    }
    

    const handleRosterRowDelete = (index) => {
        const updatedData = { ...editedCustomListData };
    
        // Create a new array by copying the existing roster array
        updatedData.list = [...updatedData.list];
    
        // Remove the item at the specified index
        updatedData.list.splice(index, 1);
    
        // Update the state with the new roster array
        setEditedCustomListData(updatedData);
    };
    
    const handleAddRow = () => {
    const blankRow = {listItem: "", fileName: "", filePath: ""};
    setEditedCustomListData((prev) => ({ ...prev, list: [...prev.list, blankRow] }));
    };

    const [editMode, setEditMode] = useState(true)

    return (
        <div>
            <Row className="mb-3">
                <Col>
                    {editMode ? (
                        <Input
                            type="text"
                            value={editedCustomListData.listName}
                            onChange={(e) => handleCustomListDataEdit(e, 0, 'listName')} // Pass the index of the list
                            invalid={editedCustomListData.listName.length < 1}
                            placeholder="Enter List Name"
                            style={{
                                fontSize: "18px",
                                fontWeight: "bold",
                                textAlign: "center",
                                borderRadius: '8px'
                            }}
                        />
                    ) : (
                        <h4 style={{ textAlign: "center", fontWeight: "bold", marginBottom: "10px" }}>
                            {editedCustomListData.listName || "Untitled List"}
                        </h4>
                    )}
                </Col>
            </Row>

        <div className='playlist-table' style={{ maxHeight: "400px", overflowY: "auto", border: "1px solid #ddd", borderRadius: "8px" }}>
            <Table>
                <thead style={{color: 'rgb(11, 192, 223)'}}>
                    <tr>
                        <th style={{width: '10%'}}>#</th>
                        <th style={{width: '90%'}}>List Item</th>
                    </tr>
                </thead>
                <tbody>
                    {editedCustomListData?.list?.map((data, index) => (
                        <tr key={index}>
                            <th scope="row">{index + 1}</th>
                            <td>
                                {editMode ? (
                                    <Input
                                        key={index}
                                        type="text"
                                        name="listItem"
                                        defaultValue={data.listItem}
                                        value={data.listItem}
                                        onChange={(e) => handleCustomListDataEdit(e, index, 'listItem')} // Pass the index of the item
                                    />
                                ) : (
                                    <>
                                        {data.listItem}
                                    </>
                                )}
                            </td>
                            <td>
                                {editMode ? (
                                    <Button
                                        className="btn-icon btn-neutral"
                                        color="danger"
                                        id="tooltip808966390"
                                        size="md"
                                        type="button"
                                        onClick={() => handleRosterRowDelete(index)}
                                    >
                                        <i className="fa fa-trash icon-delete" />
                                    </Button>
                                ) : (
                                    <>
                                        
                                    </>
                                )}
                            </td>
                        </tr>
                    ))}
                </tbody>
            </Table>
        </div>

            <Row className="text-center" style={{ marginTop: '0px' }}>
                <Col className={editedCustomListData.list.length > 24 ? "add-row-to-playlist-col-disabled" : "add-row-to-playlist-col"} onClick={handleAddRow}>
                    <h6>{editedCustomListData.list.length > 24 ? "No More Rows Allowed" : "Add Row"}</h6>
                </Col>
            </Row>

            <div className="m-2" style={{ textAlign: 'center' }}>
                <Button
                    disabled={editedCustomListData.listName === ""}
                    onClick={() => saveCustomList()}
                    style={{
                        borderRadius: '30px',
                        width: '100px'
                    }}
                >
                    Save
                </Button>
            </div>

            <Modal scrollable isOpen={copyRosterToClassModal} toggle={toggleCopyRosterToClassModal} style={{width: '350px'}}>
                <ModalBody>
                <h5>Copy roster to:</h5>
                {user && user.classes && user.classes.filter(x => x.classId != classId).map(x => 
                    <FormGroup check>
                        <Label check>
                        <Input
                        type="checkbox"
                        defaultChecked={classesSelectedForRosterCopy.length > 0 && classesSelectedForRosterCopy.includes(x.classId)}
                        onChange={() => handleCheckboxChangeForRosterCopy(x.classId)}
                        />                
                        <span className="form-check-sign" />
                        {x.className}
                        </Label>
                    </FormGroup>
                )}
                </ModalBody>
                <ModalFooter>
                    <Button disabled={classesSelectedForRosterCopy.length < 1} onClick={() => copyRosterToClasses()}>Copy</Button>
                    <Button onClick={toggleCopyRosterToClassModal}>Cancel</Button>
                </ModalFooter>
            </Modal>
        </div>
    );
}
export default RandomizerCustomListTable;
