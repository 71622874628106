import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Input
} from 'reactstrap';

function FontStylePicker({id, onChangeFunction, selectedFont, fromEditorToolbar}) {

    const [dropdownOpen, setDropdownOpen] = useState(false);
    const location = useLocation()

    const fontList = [
        { name: 'Acme', style: 'acme' },
        { name: 'Advent Pro', style: 'advent pro' },
        { name: 'Aladin', style: 'aladin' },
        { name: 'Alata', style: 'alata' },
        { name: 'Alegreya Sans SC', style: 'alegreya sans sc' },
        { name: 'Alice', style: 'alice' },
        { name: 'Amatic SC', style: 'amatic sc' },
        { name: 'Andale Mono', style: 'andale mono,times' },
        { name: 'Architects Daughter', style: 'architects daughter' },
        { name: 'Arial', style: 'arial,helvetica,sans-serif' },
        { name: 'Arial Black', style: 'arial black,avant garde' },
        { name: 'Audiowide', style: 'audiowide' },
        { name: 'Bad Scripts', style: 'bad scripts' },
        { name: 'Bangers', style: 'bangers' },
        { name: 'Belleza', style: 'belleza' },
        { name: 'Berkshire Swash', style: 'berkshire swash' },
        { name: 'Booga', style: 'boogaloo' },
        { name: 'Book Antiqua', style: 'book antiqua,palatino' },
        { name: 'Bowlby One SC', style: 'bowlby one sc' },
        { name: 'Bungee', style: 'bungee' },
        { name: 'Bungee Shade', style: 'bungee shade' },
        { name: 'Cabin Sketch', style: 'cabin sketch' },
        { name: 'Carter One', style: 'carter one' },
        { name: 'Caveat', style: 'caveat' },
        { name: 'Changa One', style: 'changa one' },
        { name: 'Chewy', style: 'chewy' },
        { name: 'Cinzel', style: 'cinzel' },
        { name: 'Comic Sans MS', style: 'comic sans ms,sans-serif' },
        { name: 'Coming Soon', style: 'coming soon' },
        { name: 'Concert One', style: 'concert one' },
        { name: 'Cookie', style: 'cookie' },
        { name: 'Corben', style: 'corben' },
        { name: 'Courier New', style: 'courier new,courier' },
        { name: 'Creepster', style: 'creepster' },
        { name: 'Dancing Script', style: 'dancing script' },
        { name: 'Fjalla One', style: 'fjalla one' },
        { name: 'Fredericka the Great', style: 'fredericka the great' },
        { name: 'Georgia', style: 'georgia,palatino' },
        { name: 'Graduate', style: 'graduate' },
        { name: 'Great Vibes', style: 'great vibes' },
        { name: 'Gruppo', style: 'gruppo' },
        { name: 'Helvetica', style: 'helvetica' },
        { name: 'Henny Penny', style: 'henny penny' },
        { name: 'Holtwood One SC', style: 'holtwood one sc' },
        { name: 'IM Fell English SC', style: 'im fell english sc' },
        { name: 'Impact', style: 'impact,chicago' },
        { name: 'Indie Flower', style: 'indie flower' },
        { name: 'Irish Grover', style: 'irish grover' },
        { name: 'Julius Sans One', style: 'julius sans one' },
        { name: 'Just Another Hand', style: 'just another hand' },
        { name: 'Kaushan Script', style: 'kaushan script' },
        { name: 'Knewave', style: 'knewave' },
        { name: 'Lato Black', style: 'lato' },
        { name: 'Lemon', style: 'lemon' },
        { name: 'Lilita One', style: 'lilita one' },
        { name: 'Lobster', style: 'lobster' },
        { name: 'Lobster Two', style: 'lobster two' },
        { name: 'Luckiest Guy', style: 'luckiest guy' },
        { name: 'Macondo', style: 'macondo' },
        { name: 'Marcellus SC', style: 'marcellus sc' },
        { name: 'Mate SC', style: 'mate sc' },
        { name: 'Merienda', style: 'merienda' },
        { name: 'Modak', style: 'modak' },
        { name: 'Monoton', style: 'monoton' },
        { name: 'Nanum Pen Script', style: 'nanum pen script' },
        { name: 'Newsreader', style: 'newsreader' },
        { name: 'Nunito', style: 'nunito' },
        { name: 'Orbitron', style: 'orbitron' },
        { name: 'Pacifico', style: 'pacifico' },
        { name: 'Patrick Hand', style: 'patrick hand' },
        { name: 'Patua One', style: 'patua one' },
        { name: 'Permanent Marker', style: 'permanent marker' },
        { name: 'Poiret One', style: 'poiret one' },
        { name: 'Poppins', style: 'poppins' },
        { name: 'Press Start 2P', style: 'press start 2p' },
        { name: 'Protest Guerrilla', style: 'protest guerrilla' },
        { name: 'Protest Revolution', style: 'protest revolution' },
        { name: 'Protest Riot', style: 'protest riot' },
        { name: 'Protest Strike', style: 'protest strike' },
        { name: 'Quando', style: 'quando' },
        { name: 'Raleway', style: 'raleway' },
        { name: 'Rancho', style: 'rancho' },
        { name: 'Righteous', style: 'righteous' },
        { name: 'Roboto', style: 'roboto' },
        { name: 'Rock Salt', style: 'rock salt' },
        { name: 'Rowdies', style: 'rowdies' },
        { name: 'Rubik Moonrocks', style: 'rubik moonrocks' },
        { name: 'Rye', style: 'rye' },
        { name: 'Sacramento', style: 'sacramento' },
        { name: 'Salsa', style: 'salsa' },
        { name: 'Satisfy', style: 'satisfy' },
        { name: 'Shadows Into Light', style: 'shadows into light' },
        { name: 'Single Day', style: 'single day' },
        { name: 'Symbol', style: 'symbol' },
        { name: 'Tahoma', style: 'tahoma,arial,helvetica,sans-serif' },
        { name: 'Terminal', style: 'terminal,monaco' },
        { name: 'Times New Roman', style: 'times new roman,times' },
        { name: 'Titan One', style: 'titan one' },
        { name: 'Trebuchet MS', style: 'trebuchet ms,geneva' },
        { name: 'Ultra', style: 'ultra' },
        { name: 'Unbounded', style: 'unbounded' },
        { name: 'Verdana', style: 'verdana,geneva' },
        { name: 'Yellowtail', style: 'yellowtail' },
        { name: 'Yesteryear', style: 'yesteryear' }
      ];
      

    const toggle = () => setDropdownOpen((prevState) => !prevState); 

    return (
        <Dropdown 
            className={
                location.pathname === "/announcement-form" ? "font-style-picker-announcements" 
                : fromEditorToolbar ? "font-style-picker" 
                : "font-size-picker"
            } 
            isOpen={dropdownOpen} 
            toggle={toggle} 
            direction='down'
            style={{width: '100%'}} //needed to make settings page look good
        >
            <DropdownToggle caret style={{ fontFamily: selectedFont }}>{selectedFont}</DropdownToggle>
            <DropdownMenu className="font-size-picker-menu">
                {fontList.map((font, index) => (
                    <DropdownItem key={index} style={{ fontFamily: font.style }} id={id} value={font.style} onClick={(e) => onChangeFunction(e)}>
                        {dropdownOpen && (
                            <>{font.name}</>
                        )}
                    </DropdownItem>
                ))}
            </DropdownMenu>
        </Dropdown>
    )
}

export default FontStylePicker